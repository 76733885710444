import {useEffect, useState} from "react";
import Select from "react-select";
import TextInput from "../../components/Selectors/TextInput";
import {
  canRenderComponent,
  isEmptyString,
  makeSelectField,
} from "../../utils/common";
import {useSelector} from "react-redux";

import {ownerField} from "../../utils/field";
import DataForm from "../../components/DataForm/DataForm";
import SelectAdd from "../../components/Selectors/SelectAdd";
import {addObservedSystem} from "../../network/DataManagementApi";
import {COMPONENTS} from "../../constants/component";

const ObservedSystemData = ({
                              observedSystem,
                              setObservedSystem,
                              isMulti = false,
                            }) => {
  const data = useSelector((state) => state.data);
  const [isLoading, setLoading] = useState(false);

  const [authorizedObs, setAuthorizedObs] = useState(
    canRenderComponent(COMPONENTS.OBSERVED_SYSTEM_CREATION)
  );
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  useEffect(() => {
    setAuthorizedObs(canRenderComponent(COMPONENTS.OBSERVED_SYSTEM_CREATION));
  }, [authorizedComponent]);
  console.debug(authorizedObs, authorizedComponent);
  const onSelectChange = (selected, options) => {
    onChange(options.name, selected.value);
  };
  const onChange = (name, value) => {
    setObservedSystem({
      ...observedSystem,
      [name]: value,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystem["storage_system_id"],
      name: observedSystem["name"],
      description: observedSystem["description"],
      membership: observedSystem["membership"],
      owner_ids:
        observedSystem["membership"] === "structure"
          ? []
          : [observedSystem["owner_ids"]],
    };
    addObservedSystem(data, () => setLoading(false));
  };

  const field = [
    {
      id: 3,
      name: "Instance",
      type: (
        <InstanceSelector
          selectField={makeSelectField(data.storage_system, [
            "name",
            "description",
          ])}
          value={makeSelectField(data.storage_system, [
            "name",
            "description",
          ]).find(
            (value) => value.value === observedSystem["storage_system_id"]
          )}
          callback={onChange}
        />
      ),
      tooltip: false,
    },

    {
      id: 4,
      name: "Lien",
      type: (
        <Select
          isSearchable={false}
          className="py-0.5"
          name="membership"
          options={ownerField}
          onChange={onSelectChange}
        />
      ),
      tooltip: (
        <ul>
          <li>
            <span className="text-gray-100 font-semibold">Structure:</span> Le
            système observé est directement lié à la structure.
          </li>
          <li>
            <span className="text-gray-100 font-semibold">
              Système observé{" "}
            </span>{" "}
            Le système observé est lié à un ou plusieurs systèmes observés.
          </li>
        </ul>
      ),
    },
    {
      id: 1,
      name: "Nom",
      type: (
        <TextInput
          name={"name"}
          value={observedSystem["name"]}
          onChange={onChange}
          placeholder={"Nom du système observé"}
        />
      ),
      tooltip: false,
    },
    {
      id: 2,
      name: "Description",
      type: (
        <TextInput
          name={"description"}
          value={observedSystem["description"]}
          onChange={onChange}
          placeholder={"Description du système observé"}
        />
      ),
      tooltip: false,
    },
  ];
  if (observedSystem["membership"] === "observed_system") {
    field.splice(2, 0, {
      id: 5,
      name: "Système(s) observé(s) à lier",
      type: (
        <Select
          name={"owner_ids"}
          options={makeSelectField(data.observed_system, [
            "name",
            "description",
          ])}
          onChange={onSelectChange}
        />
      ),
      tooltip: false,
    });
  }
  const canAdd =
    isEmptyString(observedSystem["name"]) ||
    isEmptyString(observedSystem["description"]) ||
    isEmptyString(observedSystem["storage_system_id"]) ||
    isEmptyString(observedSystem["membership"]);

  return (
    authorizedObs && (
      <DataForm
        isLoading={isLoading}
        disabled={canAdd}
        isMulti={isMulti}
        information="Système observé"
        description="Création d'un nouveau système observé."
        field={field}
        callback={handleAdd}
      />
    )
  );
};
export default ObservedSystemData;

const InstanceSelector = ({selectField, value, callback}) => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedInstance, setAuthorizedInstance] = useState(
    canRenderComponent(COMPONENTS.INSTANCE_CREATION)
  );

  useEffect(() => {
    return () => {
      setAuthorizedInstance(canRenderComponent(COMPONENTS.INSTANCE_CREATION));
    };
  }, [authorizedComponent]);
  if (authorizedInstance)
    return (
      <SelectAdd
        selectField={selectField}
        name="storage_system_id"
        value={value}
        callback={callback}
      />
    );
  return (
    <Select
      className="py-0.5"
      options={selectField}
      name="storage_system_id"
      value={value}
      onChange={callback}
    />
  );
};
