import React, { useState } from "react";
import ObservedSystemData from "../multi/ObservedSystemData";
import MultiFormFile from "../multi/MultistepFormCSV";
import { addObservedSystem, fileUpload } from "../../network/DataManagementApi";

const SystemAndFileUpload = () => {
  const [observedSystemInfo, setObservedSystemInfo] = useState({
    storage_system_id: sessionStorage.getItem("storageSystemId"),
    membership: "",
    description: "",
    name: "",
    owner_ids: [],
  });
  const handleAdd = (file, data_conf, setLoading) => {
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystemInfo["storage_system_id"],
      name: observedSystemInfo["name"],
      description: observedSystemInfo["description"],
      membership: observedSystemInfo["membership"],
      owner_ids:
        observedSystemInfo["membership"] === "structure"
          ? []
          : [observedSystemInfo["owner_ids"]],
    };
    addObservedSystem(data, (res) => {
      console.debug(res.data.id);
      console.debug(setLoading);
      fileUpload(res.data.id, file, data_conf, (res) => {
        setLoading(false);
      });
    });
  };
  return (
    <div>
      <ObservedSystemData
        observedSystem={observedSystemInfo}
        setObservedSystem={setObservedSystemInfo}
        isMulti={true}
      />
      <MultiFormFile selectObs={false} callback={handleAdd} />
    </div>
  );
};
export default SystemAndFileUpload;
