import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import React, { Fragment, useState } from "react";
import Select from "react-select";
import ActionButtons from "../Selectors/ActionButtons";
import { Dialog, Transition } from "@headlessui/react";
import IconButton from "../IconButton";
import { MdClose as Close } from "react-icons/md";
import Button from "../Selectors/Button";
import { handleTypeChange, typeRender } from "../../utils/fileUtils";

const FileInformationList = ({ data, setData, callback, onFileChange }) => {
  const typeOptions = [
    {
      value: "int",
      label: "Entier",
    },
    {
      value: "float",
      label: "Flottant",
    },
    {
      value: "string",
      label: "Chaine de caractère",
    },
    {
      value: "categorical",
      label: "Categoriel",
    },
    {
      value: "date",
      label: "Date",
    },
  ];

  const [selected, setSelected] = useState(null);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row.column, {
      id: "header",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "type",
      header: "Type",
      cell: (info) => {
        const row = info.row.original;
        const type = row.type;
        const selectValue = typeOptions.find((value) => value.value === type);
        const index = info.row.index;
        return (
          <Select
            hideSelectedOptions
            onChange={(newValue) =>
              handleTypeChange(newValue.value, index, data, setData)
            }
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            options={typeOptions}
            value={selectValue}
          />
        );
      },
    }),
    columnHelper.display({
      id: "values",
      header: "Valeurs",
      cell: (info) => {
        const name = info.row.original.column;
        let id = 0;
        const values = data.preview.map((value) => {
          const beforeIncr = id;
          ++id;
          return {
            value: beforeIncr + value[name],
            label: value[name],
          };
        });
        return (
          <Select
            className={""}
            classNames={{
              multiValue: () => "font-semibold text-primary-theme-900 px-2",
            }}
            components={{
              MultiValueRemove: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isDisabled
            isMulti
            value={values}
            options={values}
          />
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            setSelected(info.row.index);
          }}
          canEdit={true}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data: data.col_type,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3"></div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3 w-1/6"></div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <input
              onChange={onFileChange}
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="file"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {selected !== null && (
        <EditModal
          data={data}
          setData={setData}
          callback={callback}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

const EditModal = ({ selected, setSelected, data, setData, callback }) => {
  const closeModal = () => setSelected(null);
  return (
    <Transition appear show={selected !== null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Parametrage des données
                  <IconButton
                    callback={closeModal}
                    Icon={
                      <Close
                        className={
                          "absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        }
                        size={40}
                      />
                    }
                  />
                </Dialog.Title>
                <div className="text-center text-theme1">
                  {typeRender(
                    data.col_type[selected].type,
                    selected,
                    callback,
                    data,
                    closeModal,
                    setData
                  )}
                </div>
                <div className="flex flex-col lg:flex-row justify-between mt-2">
                  <div className="flex flex-row align-center px-3"></div>

                  <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
                    <Button callback={closeModal} label={"Valider"} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default FileInformationList;
