import Checkbox from "../../../components/Selectors/Checkbox";
import Select from "react-select";
import Button from "../../../components/Selectors/Button";
import {variableOptions} from "../utils";

const DetectionAnomalieVariableTable = ({variables, bloc, setBloc, add, mode =  0}) => {

    const onCheckbox = (index) => {
        const tmp = {...bloc.to_add} // what if bloc.to_add is undefined ?
        tmp[index] = !tmp[index]
        if (bloc.cur[index] === undefined && bloc.hist[index] === undefined) {
            const tmpHist = {...bloc.hist}
            tmpHist[index] = {
                type: 0,
                id: index,
                value: "identity",
                label: "identity",
                name: variables[0].options[index].label
            }
            const tmpCur = {...bloc.cur}
            tmpCur[index] = {
                type: 1,
                id: index,
                value: "identity",
                label: "identity",
                name: variables[0].options[index].label
            }
            setBloc({
                ...bloc,
                hist: tmpHist,
                cur: tmpCur,
                to_add: tmp
            })
        } else {
            setBloc({
                ...bloc,
                to_add: tmp
            })
        }


    }
    const onTypeSelect = (value) => {
        const type = value.type === 0 ? "hist" : "cur"
        const tmp = {...bloc[type]}
        tmp[value.id] = value
        setBloc({
            ...bloc,
            [type]: tmp
        })

    }
    return <div className="flex flex-col col-span-3 max-h-[35vh]">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                    <table className="min-w-full">
                        <thead className="border-b  shadow-sm">
                        <tr>
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                                Variable
                            </th>
                            {mode === 0 &&
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                                    Agrégation historique
                                </th>}
                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                                Agrégation courante
                            </th>

                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2 text-left">
                                <Button
                                    disabled={!Object.keys(bloc.to_add).some(value => bloc.to_add[value] === true)}
                                    extraStyle="px-2 h-fit w-fit"
                                    label={"Ajouter"} callback={add}/>
                            </th>


                        </tr>
                        </thead>
                        <tbody>
                        {variables[0].options.map((variable) => {
                            const label = variable.label
                            return (
                                <tr className="border-b">
                                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                        {label}
                                    </td>

                                    {mode === 0 &&
                                        <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                            <Select
                                                value={bloc.hist[variables[0].options.indexOf(variable)]}
                                                menuPosition={"fixed"}
                                                className="w-full  rounded-lg outline-none"
                                                onChange={onTypeSelect}
                                                options={variableOptions(variables[0].options.indexOf(variable), label, 0)}/>
                                        </td>}
                                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                        <Select
                                            menuPosition={"fixed"}
                                            className="w-full  rounded-lg outline-none"
                                            onChange={onTypeSelect}
                                            value={bloc.cur[variables[0].options.indexOf(variable)]}
                                            options={variableOptions(variables[0].options.indexOf(variable), label, 1)}/>
                                    </td>

                                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                        <Checkbox value={bloc.to_add[variables[0].options.indexOf(variable)]}
                                                  onChange={() => onCheckbox(variables[0].options.indexOf(variable))}/>
                                    </td>

                                </tr>
                            )
                        })}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
export default DetectionAnomalieVariableTable;