import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import Card from "../../../components/Card/Card";
import Select from "react-select";
import {getConfigurationsFromCrudeSource} from "../../../utils/common";


const DataParamView = ({data}) => {
    const crudeSource = useSelector(state => state.data.crude_source)
    const dataSources = data.parameters.current.data_params.map(value => {
        const tmp = Object.entries(crudeSource).find(value1 => value1[1].measure_identifier === value.measure_identifier)
        if (tmp) return {crude_source_id: tmp[0], crude_source_name: tmp[1].name, data_params: value}
        return undefined
    })
    const columns = useMemo(() => [{
        accessorFn: row => {
            return row.crude_source_name
        }, //className: "w-11/12 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "name", header: 'Nom', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return <Select isDisabled={true} isMulti={true} value={row.data_params.configured_sources.map(value => {
                return {value: value, label: getConfigurationsFromCrudeSource(row.crude_source_id)[value].description}
            })}/>
        }, //className: "w-11/12 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "conf", header: 'Configurations', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row.data_params.time_field
        }, //className: "w-11/12 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "timefield", header: 'Variable temporelle', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return <Select isDisabled={true} isMulti={true}
                           value={Object.entries(row.data_params.preprocess).map(value => {
                               return {value: value[0], label: value[0] + " - " + value[1]}
                           })}/>
        }, //className: "w-11/12 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "preprocesses", header: 'Variables', cell: info => info.getValue(),
    }], [])
    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">

        <h2 className="text-theme1 text-2xl font-semibold">Paramètres des données</h2>
        <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Source de
            données</label>
        {dataSources[0] !== undefined ?
            <Card name={"test"} data={dataSources} columns={columns}/> : "Wrong Observed System. WIP element."}
    </div>
}
export default DataParamView;