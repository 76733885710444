import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import "react-tooltip/dist/react-tooltip.css";
import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";

import ThresholdList from "../../components/Table/ThresholdList";

const Threshold = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedThresold, setAuthorizedThresold] = useState(
    canRenderComponent(COMPONENTS.THRESHOLD_DISPLAY)
  );
  useEffect(() => {
    return () => {
      setAuthorizedThresold(canRenderComponent(COMPONENTS.THRESHOLD_DISPLAY));
    };
  }, [authorizedComponent]);

  return authorizedThresold && <ThresholdList />;
};
export default Threshold;
