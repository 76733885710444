import {useDispatch, useSelector} from "react-redux";
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  MdClear as Disabled,
  MdDelete as Delete,
  MdDone as Enabled,
  MdSettings as Settings,
} from "react-icons/md";
import React, {useCallback, useMemo, useState} from "react";
import {TableFilter} from "../../components/Table/TableFilter";
import {TableHeader} from "../../components/Table/TableHeader";
import {TableBody} from "../../components/Table/TableBody";
import {TablePagination} from "../../components/Table/TablePagination";
import {set} from "../../reducers/AdminSlice";
import IconButton from "../../components/IconButton";
import {useNavigate} from "react-router-dom";
import Button from "../../components/Selectors/Button";
import DeleteModal from "../../components/Selectors/DeleteModal";
import {makeSelectField} from "../../utils/common";


const User = () => {
  const profiles = useSelector(state => state.data.profiles)
  const profileOption = useCallback(
    () => {
      return makeSelectField(profiles, ["name"])
    },
    [profiles],
  );
  const [modalVisible, setModalVisible] = useState(false)
  const data = useSelector(state => state.data.users);
  const [globalFilter, setGlobalFilter] = useState('');
  const dispatch = useDispatch()
  const history = useNavigate()


  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return row.firstName;
        },
        className:
          "w-1/4 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "firstName",
        header: "Prénom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          return row.lastName;
        },
        className:
          "w-1/4 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "lastName",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          return row.email;
        },
        className:
          "w-1/3 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "email",
        header: "Email",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: row => {
          return row.enabled ? <Enabled size={20} className={"text-theme2"}/> :
            <Disabled className={"text-red-600"} size={20}/>
        },
        className:
          "w-1/10 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "enabled",
        header: 'Etat',
        enableSorting: false,
        cell: info => info.getValue(),
      },
      {
        accessorFn: row => {
          return profileOption().find(value => value.value === row.attributes.profile_id[0]).label
        },
        className:
          "w-1/10 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "profile_id",
        header: 'Type',
        cell: info => info.getValue(),
      },
      {
        accessorFn: row => {
          return <div className="flex justify-center">
            <IconButton Icon={<Settings size={20}/>} callback={() => {
              dispatch(set({type: "user", data: row}));
              history("/settings/users/edit/" + row.id);
            }}/>
            <IconButton Icon={<Delete size={20}/>} callback={() => {
              setModalVisible(true)
              dispatch(set({type: "user", data: row}));
            }}/>
          </div>
        },
        className:
          "w-1/10 group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        enableSorting: false,
        id: "setting",
        header: 'Actions',
        cell: info => info.getValue(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="bg-white h-[85vh] m-4">
      <div className="flex flex-row gap-4 justify-between m-2">
        <div>
          <TableFilter
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
          />
        </div>
        <div className="mt-2 w-1/3 px-3">
          <Button callback={() => history("/settings/users/create")} label="Créer un utilisateur"/>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="mx-20 shadow overflow-hidden border-b border-gray-200">
              <table className="w-full">
                <TableHeader table={table}/>
                <TableBody table={table}/>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />

      <TablePagination table={table}/>
    </div>
  );
};
export default User;
