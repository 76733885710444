import Select from "react-select";
import {
  getConfigurationsFromCrudeSource,
  makeSelectField,
  makeVariableField,
} from "../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {setModuleData, setOne} from "../../reducers/indicatorSlice";
import {DetectionAnomalieModuleParam} from "./utils";
import {
  configurationAlerteMode,
  DetectionAnomalieMode,
} from "../../utils/field";
import FormuleAnalytiqueVariable from "./FormuleAnalytique/FormuleAnalytiqueVariable";
import DetectionAnomalieVariable from "./DetectionAnomalie/DetectionAnomalieVariable";

const IndicatorDataConfiguration = ({
                                      isMethod = false,
                                      isTwoBloc = false,
                                      isDataParam = true,
                                      isVariable = true,
                                    }) => {
  const dispatch = useDispatch();
  const selCrudeSource = useSelector((state) => state.indicator.selSource);
  const crudeSources = useSelector((state) => state.data.crude_source);
  const mode = useSelector((state) => state.indicator.moduleData.mode);
  const selConfiguredSource = useSelector(
    (state) => state.indicator.selConfiguration
  );
  const selVariable = useSelector((state) => state.indicator.selVariable);
  const onCrudeSourceSelect = (option) => {
    dispatch(setOne({type: "selSource", data: option}));
    dispatch(setOne({data: [], type: "selConfiguration"}));
  };
  const handleMethod = () => {
    return (
      <div className=" py-2  border-b border-theme1">
        <h1 className="text-sm w-fit font-bold text-gray-700 dark:text-white tracking-wide py-2">
          Méthode
        </h1>
        <Select
          className="w-full  rounded-lg outline-none"
          onChange={(e) => {
            dispatch(setModuleData({type: "type", data: e.value}));
            dispatch(
              setModuleData({
                type: "params",
                data: DetectionAnomalieModuleParam(e.value),
              })
            );
          }}
          options={DetectionAnomalieMode}
        />
      </div>
    );
  };

  const handleTwoBlocs = () => {
    return (
      <div className=" py-2  border-b border-theme1">
        <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
          Méthode
        </label>
        <Select
          className="w-full  rounded-lg outline-none"
          onChange={(e) => {
            dispatch(setModuleData({type: "mode", data: e}));
          }}
          value={mode}
          options={configurationAlerteMode}
        />
      </div>
    );
  };
  const handleDataParam = () => {
    if (!isDataParam) {
      return (
        <div className=" py-2  border-b border-theme1">
          <label className="py-2 text-sm   font-bold text-gray-700 dark:text-white tracking-wide ">
            Valeur remontées à considérer
          </label>
          <Select
            className="w-full col-span-2 rounded-lg outline-none"
            onChange={(e) => dispatch(setOne({data: e, type: "selVariable"}))}
            isMulti={true}
            menuPlacement={"auto"}
            menuPosition={"fixed"}
            value={selVariable}
            options={makeVariableField(
              0,
              selCrudeSource.value,
              crudeSources[selCrudeSource.value]["measure"]
            )}
          />
        </div>
      );
    }
    if (isDataParam && !isTwoBloc) {
      return <FormuleAnalytiqueVariable/>;
    }
    if (isDataParam && isTwoBloc) {
      return <DetectionAnomalieVariable/>;
    }
  };
  const handleVariable = () => {
    if (isVariable)
      return (
        <div>
          {isMethod && handleMethod()}
          {isTwoBloc && !isMethod && handleTwoBlocs()}
          <div className=" py-2  border-b border-theme1">
            <label className="py-2 text-sm   font-bold text-gray-700 dark:text-white tracking-wide ">
              Configurations
            </label>
            <Select
              className="w-full  rounded-lg outline-none"
              onChange={(e) =>
                dispatch(setOne({data: e, type: "selConfiguration"}))
              }
              isMulti={true}
              value={selConfiguredSource}
              options={
                selCrudeSource === undefined
                  ? []
                  : makeSelectField(
                    getConfigurationsFromCrudeSource(selCrudeSource.value),
                    ["description"]
                  )
              }
            />
          </div>
          {selCrudeSource !== undefined && handleDataParam()}
        </div>
      );
  };
  return (
    <div className="px-10 bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
      <div className=" py-2  border-b border-theme1">
        <label className="py-2 text-sm   font-bold text-gray-700 dark:text-white tracking-wide ">
          Source de données
        </label>
        <Select
          value={selCrudeSource}
          className={"w-full  rounded-lg outline-none"}
          options={makeSelectField(crudeSources, ["description"])}
          onChange={(e) => onCrudeSourceSelect(e)}
        />
      </div>
      {handleVariable()}
    </div>
  );
};
export default IndicatorDataConfiguration;
