import React from "react";

import TextInput from "../Selectors/TextInput";
import { handleParamChange } from "../../utils/fileUtils";
import { mappingField } from "../../utils/field";
import Select from "react-select";

const CategoricalParam = ({ data, setData, id }) => {
  const options = [];
  mappingField["1"].format.forEach((field) => {
    options.push({
      id: id,
      value: field.value,
      label: field.label,
      field_id: field.id,
    });
  });
  return (
    <div className=" grid grid-cols-2 gap-1 mt-10 py-2 ">
      <div className="text-gray-700">
        <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
          Type
        </label>
        <Select
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          options={options}
          onChange={(newValue) =>
            handleParamChange(newValue.value, id, data, setData, "type")
          }
          value={options.find(
            (value) => value.value === data.col_type[id].param.type
          )}
        />
      </div>
      <div className="">
        <TextInput
          name={"label"}
          onChange={(name, value) =>
            handleParamChange(value, id, data, setData, name)
          }
          label={"Valeurs"}
          value={data.col_type[id].param.length}
        />
      </div>
    </div>
  );
};
export default CategoricalParam;
