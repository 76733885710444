//React librairies
import React from "react";

//Icons
import {
  IoAnalyticsOutline as Analytics,
  IoHomeOutline as Dashboard,
} from "react-icons/io5";
import { VscSettings as Indicator } from "react-icons/vsc";
import {
  HomeIcon,
  AdjustmentsHorizontalIcon,
  Cog8ToothIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import {
  MdAutoGraph as Learning,
  MdFileUpload as Upload,
  MdOutlineCreateNewFolder as Create,
  MdEvent as Event,
} from "react-icons/md";
import { FiSettings as Preferences } from "react-icons/fi";
import { AiOutlineRadiusSetting as Threshold } from "react-icons/ai";
import Section from "./Section";
import { useSelector } from "react-redux";
import { isEmptyObject } from "../../utils/common";

const Sidebar = () => {
  const structure_id = useSelector((state) => state.data.structure);
  const menuSections = [
    {
      id: 1,
      icon: <Dashboard size={20} />,
      name: "Tableau de bord",
      path: "/",
    },
  ];

  //SETTINGS section array (we are going to loop across it)
  const settingSections = [
    {
      id: 14,
      icon: <Preferences size={20} />,
      name: "Utilisateurs",
      path: "/settings/users",
    },
    {
      id: 16,
      icon: <Preferences size={20} />,
      name: "Historique",
      path: "/internal/log",
    },
    {
      id: 17,
      icon: <Preferences size={20} />,
      name: "Profile",
      path: "/internal/profile",
    },
    // {
    //   id: 17,
    //   icon: <Users size={20}/>,
    //   name: "Profil",
    //   path: "/settings/profile/" + sessionStorage.getItem("id"),
    // },
  ];

  const configurationSections = [
    {
      //     id: 0, icon: <Create size={20}/>, name: "Structure", path: "/configuration/structure", user: role,
      // }, {
      id: 2,
      icon: <Create size={20} />,
      name: "Instance",
      path: "/configuration/instance",
    },
    {
      id: 3,
      icon: <Create size={20} />,
      name: "Système observé",
      path: "/configuration/system",
    },
    {
      id: 4,
      icon: <Create size={20} />,
      name: "Système et Source",
      path: "/configuration/create",
    },
    {
      id: 5,
      icon: <Create size={20} />,
      name: "Source de donnée",
      path: "/configuration/data",
    },
    {
      id: 6,
      icon: <Create size={20} />,
      name: "Configuration de source",
      path: "/configuration/configured",
    },
    {
      id: 7,
      icon: <Upload size={20} />,
      name: "Dépot de fichier",
      path: "/configuration/upload",
    },
    {
      id: 8,
      icon: <Upload size={20} />,
      name: "Système et Dépot de fichier",
      path: "/configuration/uploadv2",
    },
  ];
  const AnalyticsSection = [
    {
      id: 9,
      icon: <Indicator size={20} />,
      name: "Indicateurs",
      path: "/analytics/indicator",
    },
    {
      id: 10,
      icon: <Analytics size={20} />,
      name: "Graphique",
      path: "/analytics/graphOptions",
    },
    {
      id: 11,
      icon: <Event size={20} />,
      name: "Evenement",
      path: "/analytics/event",
    },
    {
      id: 12,
      icon: <Learning size={20} />,
      name: "Modèle d'apprentissage",
      path: "/analytics/model",
    },
    {
      id: 13,
      icon: <Threshold color={"white"} size={20} />,
      name: "Seuil",
      path: "/analytics/threshold",
    },
  ];
  const AdminSection = [
    {
      id: 14,
      icon: <Preferences size={20} />,
      name: "Utilisateurs",
      path: "/internal/users",
    },
    {
      id: 15,
      icon: <Preferences size={20} />,
      name: "Structures",
      path: "/internal/structure",
    },
    {
      id: 16,
      icon: <Preferences size={20} />,
      name: "Historique",
      path: "/internal/log",
    },
    {
      id: 17,
      icon: <Preferences size={20} />,
      name: "Gestion des profils",
      path: "/internal/profile",
    },
  ];
  //Access ENV variables (.env)
  const { REACT_APP_VERSION } = process.env;
  const user_profil = sessionStorage.getItem("user_profil");
  return (
    <aside
      id="default-sidebar"
      className="fixed top-14 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div className="h-full px-3 py-4 overflow-y-auto bg-primary-theme-800 text-white">
        {!isEmptyObject(structure_id) ? (
          <ul className="space-y-2 font-medium">
            <Section
              name="MENU"
              section={menuSections}
              icon={<HomeIcon className={"text-gray-400 uppercase w-5 h-5"} />}
            />
            <Section
              name="Configuration"
              section={configurationSections}
              icon={
                <WrenchIcon className={"text-gray-400 uppercase w-5 h-5"} />
              }
            />
            <Section
              name="Analytics"
              section={AnalyticsSection}
              icon={
                <AdjustmentsHorizontalIcon
                  className={"text-gray-400 uppercase w-5 h-5"}
                />
              }
            />
            <Section
              name="Administration"
              section={user_profil === "1" ? AdminSection : settingSections}
              icon={
                <Cog8ToothIcon className={"text-gray-400 uppercase w-5 h-5"} />
              }
            />
          </ul>
        ) : (
          user_profil === "1" && (
            <div className="px-1 py-4 opacity-75">
              Veuillez selectionner une structure
            </div>
          )
        )}

        {/* FOOTER (VERSION NUMBER) */}
        <p className="tracking-wide truncate mb-14 px-5 py-3 text-center text-xs">
          Version {REACT_APP_VERSION} - Copyright @2023
        </p>
      </div>
    </aside>
  );
};

export default Sidebar;
