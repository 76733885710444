import IndicatorMailingForm from "../IndicatorMailingForm";
import IndicatorAccordion from "../IndicatorAccordion";
import MRDParameters from "./MRDParameters";
import IndicatorMode from "./IndicatorMode";

const MRDRightPanel = () => {
  return (
    <div className="bg-white grid grid-flow-row auto-rows-max h-[80vh] overflow-auto shadow-lg">
      <IndicatorAccordion
        label={"Programmation d'exécution du module"}
        composant={<IndicatorMode/>}
        open={false}
      />
      <IndicatorAccordion
        label={"Notification via courier électronique"}
        composant={<IndicatorMailingForm/>}
      />
      <IndicatorAccordion
        label={"Paramètres du suivi de la remontée de données"}
        composant={<MRDParameters/>}
      />
    </div>
  );
};
export default MRDRightPanel;
