import { Tab } from "@headlessui/react";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";

/**
 *
 * @param {string[]} headers
 * @param {number} current
 * @param {(index: number) => void} changeCurrent
 * @param {Object[]} panel
 * @constructor
 */
const TabGroup = ({ headers, current, changeCurrent, panel }) => {
  return (
    <Tab.Group selectedIndex={current} onChange={changeCurrent}>
      <Tab.List
        className={`grid grid-cols-2 gap-4 rounded-l bg-theme1 text-white py-1 m-1`}
      >
        <TabHeader headers={headers} />
      </Tab.List>
      <TabPanel panel={panel} />
    </Tab.Group>
  );
};
export default TabGroup;
