import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import { TableFilter } from "./TableFilter";
import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { getEvent } from "../../network/DataManagementApi";
import store from "../../store";
import { set } from "../../reducers/dataSlice";
import Select from "react-select";

const EventList = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );

  const [authorizedEventCreation, setAuthorizedEventCreation] = useState(
    canRenderComponent(COMPONENTS.EVENT_CREATION)
  );
  useEffect(() => {
    return () => {
      setAuthorizedEventCreation(canRenderComponent(COMPONENTS.EVENT_CREATION));
    };
  }, [authorizedComponent]);
  const [refresh, setRefresh] = useState(false);

  const events = useSelector((state) => state.data.event);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const history = useNavigate();
  const createEvent = () => history("/analytics/event/create");

  useEffect(() => {
    setData(Object.entries(events));
  }, [events]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[0], {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1]["function_name"], {
      id: "function_name",
      header: "Fonction",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1]["module_name"], {
      id: "module_name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1]["creation_timestamp"], {
      id: "creation_timestamp",
      header: "Date de création",
      cell: (info) => info.getValue(),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>

        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getEvent().then((res) => {
                  store.dispatch(
                    set({
                      type: "event",
                      data: res.data.data,
                    })
                  );
                  setRefresh(false);
                });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedEventCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button callback={createEvent} label="Créer un evenement" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default EventList;
