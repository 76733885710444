import React from "react";
import TextInput from "../../../components/Selectors/TextInput";
import Select from "react-select";


const MailView = ({data}) => {

    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètre de l'email</h2>
        <TextInput extraStyle="w-full py-2 rounded-lg outline-none border border-2px opacity-50" disabled={true}
                   value={data["parameters"].sender} label={"Expediteur"}/>
        <div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Destinataires</label>
            <Select isDisabled={true}
                    isMulti={true}
                    value={data["parameters"].receivers.map(value => {
                        return {value: value, label: value}
                    })}
            />
        </div>
        <TextInput disabled={true} value={data["parameters"].subject} label={"Sujet"} name="subject"/>
        <TextInput disabled={true} value={data["parameters"].message} label={"Message"} name="message"/>
    </div>
}
export default MailView;