import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import MailView from "./IndicatorViewTemplate/MailView";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import KalmanView from "./IndicatorViewTemplate/KalmanView";
import {useEffect} from "react";

const IndicatorView = () => {
    let {id_indic} = useParams();
    const history = useNavigate()
    const indicator = useSelector(state => state.data.event)[id_indic]
    useEffect(() => {
        if (indicator === undefined) history("/analytics/indicator");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const renderIndicator = () => {
        switch (indicator.module_name) {
            case "Email":
                return <MailView data={indicator}/>
            case "Kalman":
                return <KalmanView data={indicator}/>
            case "Autoregressiveprediction":
                return <KalmanView data={indicator}/>
            default:
                return "WIP"
        }
    }
    return (indicator !== undefined && <div>
        <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <div className="flex">
                <IconButton bg={true} Icon={<Prev size={40}/>} value={""}
                            callback={() => history("/analytics/indicator")}/>
                <h2 className="text-theme1 text-3xl font-semibold">Indicateur {indicator.module_name}</h2>
            </div>
        </div>
        {renderIndicator()}
    </div>);

}
export default IndicatorView;
