import { useSelector } from "react-redux";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

import React, { useEffect, useState } from "react";
import {
  canRenderAction,
  getConfigurationsFromCrudeSource,
} from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButtons from "../Selectors/ActionButtons";
import { TableFilter } from "./TableFilter";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";

const ConfiguredSourceList = () => {
  const selectedCrudeSourceId = useSelector(
    (state) => state.selected.crude_source
  );
  console.debug(selectedCrudeSourceId);
  const [canEdit] = useState(
    canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.CONFIGURED_SOURCE_DISPLAY)
  );
  const [canCreate] = useState(
    canRenderAction(ACTION_FLAGS.CANCREATE, COMPONENTS.FILE_UPLOAD)
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const crudeSourceList = useSelector((state) => state.data.crude_source);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    const newData = Object.entries(
      getConfigurationsFromCrudeSource(selectedCrudeSourceId)
    );
    setData(newData);
  }, [crudeSourceList, selectedCrudeSourceId]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[1].description, {
      id: "desc",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "is_active",
      header: "Etat",
      cell: (info) => {
        const row = info.row.original;
        if (row[1].is_active)
          return <CheckIcon className={"w-5 h-5 text-primary-theme-400 "} />;
        return <XMarkIcon className={"text-red-600 w-5 h-5"} />;
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/configured_source/edit/" + row[0]);
          }}
          canEdit={canEdit}
          canDelete={false}
          canCreate={canCreate}
          handleCreate={() => {
            const row = info.row.original;
            history("/configured_source/upload/" + row[0]);
          }}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default ConfiguredSourceList;
