//React librairies
import React from 'react';
import ReactDOM from 'react-dom/client';

//Components
import App from './App';

//Redux
import {Provider} from "react-redux";
import store from "./store";

//Styles
import './index.css';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
