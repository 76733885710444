import { getGridClass } from "../../utils/common";
import React, { useEffect, useState } from "react";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import TextInput from "../../components/Selectors/TextInput";

import { useNavigate } from "react-router-dom";
import SwitchButton from "../../components/Selectors/SwitchButton";
import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";
import { ACTION_FLAGS } from "../../constants/action_flags";
import DataForm from "../../components/DataForm/DataForm";
import { postProfile } from "../../network/UserManagementApi";

async function fetchData(setData) {
  const { REACT_APP_KEYCLOCK_HOST } = process.env;
  const response = await fetch(REACT_APP_KEYCLOCK_HOST + "pages");
  const jsonData = await response.json();
  setData(jsonData.data);
}

/**
 *
 * @param {Object} pages
 * @param {Function} setSelectedPage
 * @param {Function} setSelectedComponent
 * @param {Array<string>} selectedPage
 * @param {Array<string>} selectedComponent
 * @returns {JSX.Element}
 * @version 1
 */
const PageSelector = ({
  pages,
  setSelectedPage,
  selectedPage,
  selectedComponent,
  setSelectedComponent,
}) => {
  // Formatted pages
  const pagesList = Object.keys(pages);
  // Value for the switchbutton selector
  const allSelected = pagesList.every((element) =>
    selectedPage.includes(element)
  );

  const selectElement = (id, selected, setSelected) => {
    const tmp = [...selected];
    tmp.push(id);
    setSelected(tmp);
  };
  const unselectedElement = (id, selected, setSelected) => {
    const tmp = [...selected];
    setSelected(
      tmp.filter((value) =>
        typeof id === "string"
          ? value !== id
          : Object.keys(value)[0] !== Object.keys(id)[0]
      )
    );
  };
  const handleSwitchButton = (value, page_id, selected, setSelected) => {
    if (value) selectElement(page_id, selected, setSelected);
    else unselectedElement(page_id, selected, setSelected);
  };
  const handleSelectAll = (value) => {
    if (value) {
      const combinedArray = Object.keys(pages).concat(selectedPage);
      setSelectedPage(
        combinedArray.reduce((accumulator, currentValue) => {
          // If the current value is not already in the accumulator array, add it
          if (!accumulator.includes(currentValue)) {
            accumulator.push(currentValue);
          }
          // Return the accumulator for the next iteration
          return accumulator;
        }, [])
      );
    } else {
      const tmp = [...selectedPage];
      setSelectedPage(
        tmp.filter((value) => {
          return !pagesList.includes(value);
        })
      );
    }
  };
  return (
    <div>
      <label className=" text-sm font-bold text-gray-700 dark:text-white ">
        Pages
      </label>
      <div className={"py-2"}>
        <SwitchButton
          value={allSelected}
          label={"Toutes"}
          onChange={(value) => handleSelectAll(value)}
        />
      </div>
      {Object.keys(pages).map((value) => {
        return (
          <div className={"py-1.5 border-b "}>
            <SwitchButton
              color={"bg-primary-theme-800"}
              label={pages[value].description}
              value={selectedPage.includes(value)}
              onChange={(val) =>
                handleSwitchButton(val, value, selectedPage, setSelectedPage)
              }
            />
            {selectedPage.includes(value) &&
              Object.keys(pages[value].components).map((component) => {
                const description =
                  pages[value].components[component].description;
                const availabe_action =
                  pages[value].components[component].actions;
                const selectedComponentsKey = selectedComponent.map(
                  (compo) => Object.keys(compo)[0]
                );
                return (
                  <div key={component} className={"ml-10 py-0.5"}>
                    <SwitchButton
                      color={"bg-primary-theme-600"}
                      value={selectedComponentsKey.includes(component)}
                      label={description}
                      onChange={(val) =>
                        handleSwitchButton(
                          val,
                          { [component]: ACTION_FLAGS.CANVIEW },
                          selectedComponent,
                          setSelectedComponent
                        )
                      }
                    />
                    {selectedComponentsKey.includes(component) && (
                      <RenderActions
                        actions={availabe_action}
                        setSelectedComponent={setSelectedComponent}
                        selectedComponent={selectedComponent}
                        component={component}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
const RenderActions = ({
  actions,
  selectedComponent,
  setSelectedComponent,
  component,
}) => {
  const index = selectedComponent
    .map((tmp) => Object.keys(tmp)[0])
    .findIndex((val) => val === component);
  const updateAction = (value, action_flag) => {
    setSelectedComponent((previousState) => {
      const to_update = [...previousState];
      const index = to_update
        .map((tmp) => Object.keys(tmp)[0])
        .findIndex((val) => val === component);
      to_update[index][component] = value
        ? to_update[index][component] + action_flag
        : to_update[index][component] - action_flag;
      return to_update;
    });
  };
  const methods = useSelector((state) => state.data.methods);
  return (
    <div className={"ml-10 py-1.5"}>
      <div className={"py-0.5"}>
        {(actions & methods["canCreate"].value) !== 0 && (
          <SwitchButton
            color={"bg-primary-theme-300"}
            value={selectedComponent[index][component] & ACTION_FLAGS.CANCREATE}
            label={"Création"}
            onChange={(value) => updateAction(value, ACTION_FLAGS.CANCREATE)}
          />
        )}
      </div>
      <div className={"py-0.5"}>
        {(actions & methods["canView"].value) !== 0 && (
          <SwitchButton
            color={"bg-primary-theme-300"}
            value={selectedComponent[index][component] & ACTION_FLAGS.CANVIEW}
            label={"Récupération"}
            onChange={(value) => updateAction(value, ACTION_FLAGS.CANVIEW)}
          />
        )}
      </div>
      <div className={"py-0.5"}>
        {(actions & methods["canEdit"].value) !== 0 && (
          <SwitchButton
            color={"bg-primary-theme-300"}
            value={selectedComponent[index][component] & ACTION_FLAGS.CANEDIT}
            label={"Modification"}
            onChange={(value) => updateAction(value, ACTION_FLAGS.CANEDIT)}
          />
        )}
      </div>
      <div className={"py-0.5"}>
        {(actions & methods["canDelete"].value) !== 0 && (
          <SwitchButton
            color={"bg-primary-theme-300"}
            value={selectedComponent[index][component] & ACTION_FLAGS.CANDELETE}
            label={"Suppression"}
            onChange={(value) => updateAction(value, ACTION_FLAGS.CANDELETE)}
          />
        )}
      </div>
    </div>
  );
};
const TabsPage = ({
  pages,
  setSelectedComponent,
  selectedComponent,
  selectedPage,
  setSelectedPage,
}) => {
  /**
   * @returns {JSX.Element}
   * @version 1
   */
  const RenderTabHeader = () => {
    return Object.keys(pages).map((value) => {
      return (
        <Tab
          className={
            "mx-1.5 rounded-lg py-2.5  text-sm font-medium leading-5 ui-selected:font-bold  ui-selected:bg-theme3 bg-theme1"
          }
        >
          {value}
        </Tab>
      );
    });
  };
  const renderTabPanel = () => {
    const element = pages[Object.keys(pages)[selectedIndex]];
    return (
      <div>
        <PageSelector
          setSelectedComponent={setSelectedComponent}
          selectedComponent={selectedComponent}
          selectedPage={selectedPage}
          pages={element}
          setSelectedPage={setSelectedPage}
        />
      </div>
    );
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const gridClass = getGridClass(Object.keys(pages).length);
  return (
    pages.length !== 0 && (
      <div className={"col-span-2"}>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List
            className={`grid ${gridClass} gap-4 rounded-l bg-theme1 text-white py-1 m-1`}
          >
            {RenderTabHeader()}
          </Tab.List>
        </Tab.Group>
        {renderTabPanel()}
      </div>
    )
  );
};
const AdminCreateProfile = () => {
  const history = useNavigate();
  const [selectedPage, setSelectedPage] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState({ value: "", isValid: false });
  const [description, setDescription] = useState({ value: "", isValid: false });
  const [pages, setPages] = useState([]);

  useEffect(() => {
    fetchData(setPages);
  }, []);
  const handleCreateProfile = () => {
    setLoading(true);
    postProfile(
      name.value,
      description.value,
      selectedPage,
      selectedComponent,
      () => setLoading(false)
    );
  };
  const canAdd =
    name.isValid &&
    description.isValid &&
    selectedPage.length !== 0 &&
    selectedComponent.length !== 0;
  return (
    <div>
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <div className="flex gap-x-1.5">
          <IconButton
            bg={true}
            Icon={<Prev size={40} />}
            value={""}
            callback={() => history("/internal/profile")}
          />
          <h2 className="text-theme1 text-3xl font-semibold">
            Création d'un profil
          </h2>
        </div>
      </div>
      <DataForm
        callback={handleCreateProfile}
        disabled={!canAdd}
        isLoading={loading}
        field={[
          {
            type: (
              <TextInput
                required={true}
                name={"name"}
                value={name.value}
                onChange={(value_name, value, isValid) => {
                  setName({
                    ...name,
                    value: value,
                    isValid: isValid,
                  });
                }}
              />
            ),
            name: "Nom",
            tooltip: false,
          },
          {
            type: (
              <TextInput
                required={true}
                name={"description"}
                value={description.value}
                onChange={(value_name, value, isValid) => {
                  setDescription({
                    ...description,
                    value: value,
                    isValid: isValid,
                  });
                }}
              />
            ),
            name: "Description",
            tooltip: false,
          },
          {
            type: (
              <div className={"grid grid-cols-3"}>
                <TabsPage
                  selectedComponent={selectedComponent}
                  setSelectedComponent={setSelectedComponent}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  pages={pages}
                />
              </div>
            ),
            name: "Selection des droits",
            tooltip: false,
          },
        ]}
      />
    </div>
  );
};
export default AdminCreateProfile;
