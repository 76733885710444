import {createSlice} from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    structures: {},
    structure: {},
    users: [],
    user: {},
    user_log: []
  },
  reducers: {
    set: (state, action) => {
      state[action.payload.type] = action.payload.data;
    },
    remove: (state, action) => {
      switch (action.payload.type) {
        case "REMOVE_USER":
          state.users = state.users.filter(
            (users) => users.id !== action.payload.id
          );
          break;
        case "REMOVE_STRUCTURE":
          delete state.structures[action.payload.id];
          break;
        default:
          return;
      }
    },
    add: (state, action) => {
      switch (action.payload.type) {
        case "ADD_USER":
          state.users.push(action.payload.data);
          break;
        default:
          return;
      }
    },
    edit: (state, action) => {

      switch (action.payload.type) {
        case "EDIT_USER":
          let id = action.payload.id;
          let updates = action.payload.updates
          console.debug(updates);
          let index = state.users.findIndex((value) => value.id === id);
          console.debug(index);
          state.users[index] = {...state.users[index], ...updates};
          break;
        case "EDIT_STRUCTURE":
          let id2 = action.payload.id;
          let updates2 = action.payload.updates
          state.structures[id2] = {...state.structures[id2], ...updates2};
          break;
        default:
          return;
      }
    },
  },
});

export const {set, remove, add, edit} = adminSlice.actions;

export default adminSlice.reducer;
