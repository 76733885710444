import {createSlice} from "@reduxjs/toolkit";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isAuthenticated: sessionStorage.getItem("isAuthenticated") === "true",
    token: sessionStorage.getItem("token"),
    structure: sessionStorage.getItem("structure_id"),
    user_profil: sessionStorage.getItem("user_profil"),
    observed_system: sessionStorage.getItem("observed_system_id") ?? "",
    polling: false,
    authorized_pages: [],
    authorized_components: {},
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.token = action.payload.token;
      state.structure = action.payload.structure;
      state.user_profil = action.payload.user_profil;
      state.polling = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = "";
      state.structure = "";
      state.user_profil = "";
      state.observed_system = "";
      state.polling = false;
      state.authorized_pages = [];
      state.authorized_components = {};
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    setObservedSystem: (state, action) => {
      state.observed_system = action.payload;
    },
    setPolling: (state, action) => {
      state.polling = action.payload;
    },
    setAuthorizedElement: (state, action) => {
      switch (action.payload.type) {
        case "authorized_pages":
          state.authorized_pages = action.payload.data;
          break;
        default:
          const updatedAuthorizedElement = {
            ...state[action.payload.type],
            ...action.payload.data,
          };
          // Create a new state object that copies the existing state and updates the authorized_components key
          return {...state, [action.payload.type]: updatedAuthorizedElement};
      }
    },
  },
});

export const {
  login,
  logout,
  token,
  setObservedSystem,
  setPolling,
  setAuthorizedElement,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
