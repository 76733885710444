import {useState} from "react";
import {
  getThreshold,
  variableField,
} from "../utils";
import Checkbox from "../../../components/Selectors/Checkbox";
import SelectMulti from "../../../components/Selectors/SelectMulti";
import SelectCreatable from "../../../components/Selectors/SelectReactCreate";
import {parser} from "mathjs";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setDeep} from "../../../reducers/indicatorSlice";
import {addThreshold} from "../../../network/DataManagementApi";

const IndustrialParamHisto = () => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.indicator.moduleData.current);
  const historical = useSelector(
    (state) => state.indicator.moduleData.historical
  );
  const module = useSelector((state) => state.indicator.moduleData);
  const obs = sessionStorage.getItem("observed_system_id");
  const threshold = getThreshold(obs);
  const [selectValue, setSelectValue] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [modal, setModal] = useState(false);

  const handleCheckbox = () => {
    const data = {
      type: "indicator_params",
      first: "time_indicators",
      data: !module.indicator_params.time_indicators,
    };
    dispatch(setDeep(data));
  };
  const setLabel = (value, option) => {
    if (option.action === "clear") value = "";
    const data = {
      type: "indicator_params",
      first: "threshold_label",
      data: value.value,
    };
    dispatch(setDeep(data));
    const data2 = {
      type: "indicator_params",
      first: "state_i",
      data: [],
    };
    dispatch(setDeep(data2));
    setSelectValue([]);
    setSelectedField(value);
  };
  const createLabel = (data) => {
    setSelectValue([]);
    setSelectedField({});
    let error = false;
    const to_send = {...data};
    const formulas = {};
    const parse = parser();
    variableField(current.data_params).forEach((obj) =>
      parse.set("c_" + obj.value, 1)
    );
    variableField(historical.data_params).forEach((obj) =>
      parse.set("h_" + obj.value, 1)
    );
    to_send["formula"].forEach((formula) => {
      let tmpFormula = formula.formula;
      try {
        parse.evaluate(formula.formula);
        current.data_params.forEach((block) => {
          const tmp = Object.assign({}, block);
          Object.keys(tmp.preprocess).forEach((value) => {
            if (!tmpFormula.includes("c['" + value + "']"))
              tmpFormula = tmpFormula.replaceAll(
                "c_" + value,
                "c['" + value + "']"
              );
          });
        });
        historical.data_params.forEach((block) => {
          const tmp = Object.assign({}, block);
          Object.keys(tmp.preprocess).forEach((value) => {
            if (!tmpFormula.includes("h['" + value + "']"))
              tmpFormula = tmpFormula.replaceAll(
                "h_" + value,
                "h['" + value + "']"
              );
          });
        });
        tmpFormula = tmpFormula.replaceAll(" = ", " == "); // TODO  <= and >= can be problematic
        formulas[formula.name] = tmpFormula;
      } catch (e) {
        if (e instanceof SyntaxError) {
          toast.error("Formule incorrect");
          error = true;
        } else {
          console.log(e);
          const wrongVariable = e.message.split(" ")[2];
          toast.error(
            "Variable incorrect: " +
            wrongVariable +
            " Formule: " +
            formula.formula
          );
          error = true;
        }
      }
    });
    if (!error) {
      to_send.formula = formulas;
      addThreshold(to_send, obs, setModal);
    }
    return error;
  };
  const setField = () => {
    const field = [];

    threshold.forEach((value) => {
      field.push({label: value.label, value: value.label});
    });
    return field;
  };
  const setMultiField = () => {
    const options = [];
    const index = threshold.findIndex(
      (value) => value.label === module.indicator_params.threshold_label
    );

    const formula = threshold[index].formula;
    Object.keys(formula).forEach((value) =>
      options.push({value: value, label: value + " : " + formula[value]})
    );
    return options;
  };
  const setStates = (options) => {
    setSelectValue(options);
    const state_i = [];
    options.forEach((item) => {
      state_i.push(item.value);
    });
    const data = {
      type: "indicator_params",
      first: "state_i",
      data: state_i,
    };
    dispatch(setDeep(data));
  };
  return (
    <div className="grid grid-cols-2 gap-y-2">
      <h1 className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
        Indicateur de temps
      </h1>

      <Checkbox
        checked={module.indicator_params.time_indicators}
        onChange={() => handleCheckbox()}
      />

      <h1 className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
        Seuil à considérer
      </h1>
      <SelectCreatable
        isLoading={modal}
        setIsLoading={setModal}
        onCreate={createLabel}
        value={selectedField}
        module={current}
        onChange={setLabel}
        field={setField()}
        setValue={setSelectedField}
      />

      {module.indicator_params.threshold_label !== "" && !modal ? (
        <>
          <h1 className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
            Etat à considérer
          </h1>
          <SelectMulti
            value={selectValue}
            onChange={setStates}
            options={setMultiField()}
          />{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default IndustrialParamHisto;
