import {useEffect, useState} from "react";

import TextInput from "../../components/Selectors/TextInput";

import Checkbox from "../../components/Selectors/Checkbox";
import SourceParametersModal from "../../components/Modals/SourceParametersModal";
import {sourceField} from "../../utils/field";

import {
  canRenderComponent,
  isEmptyObject,
  isEmptyString,
  makeSelectField,
  parseCategorical,
} from "../../utils/common";
import SelectAdd from "../../components/Selectors/SelectAdd";

import {useSelector} from "react-redux";
import Select from "react-select";
import DataForm from "../../components/DataForm/DataForm";
import MappingValueModal from "../../components/Modals/MappingValueModal";
import {addDataSource} from "../../network/DataManagementApi";
import {COMPONENTS} from "../../constants/component";

const ConfiguredSourceData = ({
                                source,
                                setSource,
                                mappingValue,
                                updateMappingValue,
                                sourceParametersValue,
                                updateSourceParametersValue,
                                isMulti = false,
                                selectObs = true,
                                selectMappingValues = true,
                              }) => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedDataSource, setAuthorizedDataSource] = useState(
    canRenderComponent(COMPONENTS.DATA_SOURCE_CREATION) ||
    canRenderComponent(COMPONENTS.FILE_UPLOAD)
  );
  useEffect(() => {
    return () => {
      setAuthorizedDataSource(
        canRenderComponent(COMPONENTS.DATA_SOURCE_CREATION) ||
        canRenderComponent(COMPONENTS.FILE_UPLOAD)
      );
    };
  }, [authorizedComponent]);

  const data = useSelector((state) => state.data);
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [SpModal, setSpModal] = useState(false);
  const [sameStorageType, setSameStorageType] = useState(true);
  const onSelectChange = (selected, options) => {
    onChange(options.name, selected.value);
  };
  const onChange = (name, value) => {
    setSource({
      ...source,
      [name]: value,
    });
  };
  const onCheckboxChange = (e) => {
    const name = e.target.name;
    setSource({
      ...source,
      [name]: e.target.checked,
    });
  };
  const onMeasureChange = (checked) => {
    setSameStorageType(!sameStorageType);
    if (checked) {
      onChange("storage_system_id", "");
    }
  };

  const handleAdd = () => {
    setLoading(true);
    const mapping = {};
    const mapping_information = {};
    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value:
          param.type === "date"
            ? param.value.date + param.value.offset
            : param.value,
        name: param.name,
        type: param.type,
      });
    });
    mappingValue.forEach((val) => {
      mapping[val.name] = val.format;
      if (val.type === "categorial")
        mapping_information[val.name] = parseCategorical(val.format, val.param);
      else if (val.type === "numeric")
        mapping_information[val.name] = {
          min: parseFloat(val.param.minValue),
          max: parseFloat(val.param.maxValue),
          min_included: true,
          max_included: true,
        };
      else if (val.type === "string") mapping_information[val.name] = val.param;
      else mapping_information[val.name] = {};
    });

    const data = {
      name: source.name,
      description: source.description,
      source_type: source.source_type,
      configured_source_description: source.configured_source_description,
      system: source.system,
      mapping: mapping,
      mapping_information: mapping_information,
      is_active: source.is_active,
      alert_delay: parseInt(source.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: source.storage_system_id,
      id: source.id === "" ? null : source.id,
    };
    addDataSource(data, () => setLoading(false));
  };
  const field = [
    {
      id: 0,
      name: "Système observé",
      type: (
        <Select
          value={
            !isEmptyObject(data.observed_system) && source.system !== null
              ? {
                value: source.system,
                label: data.observed_system[source?.system]?.name,
              }
              : undefined
          }
          options={makeSelectField(data.observed_system, [
            "name",
            "description",
          ])}
          onChange={onSelectChange}
          name="system"
          id="system"
        />
      ),
      tooltip: (
        <p>
          {" "}
          Système observé où seront stockées les informations de la source de
          données
        </p>
      ),
    },
    {
      id: 1,
      name: "Type de source",
      type: (
        <Select
          options={sourceField}
          onChange={onSelectChange}
          name="source_type"
          id="source_type"
          isSearchable={false}
          placeholder={"Type de source..."}
        />
      ),
      tooltip: "Type de source qui remonte les données",
    },
    {
      id: 2,
      name: "Nom",
      type: (
        <TextInput
          name={"name"}
          value={source["name"]}
          onChange={onChange}
          placeholder={"Nom de la source de données"}
        />
      ),
      tooltip: false,
    },
    {
      id: 3,
      name: "Description",
      type: (
        <TextInput
          name={"description"}
          value={source["description"]}
          onChange={onChange}
          placeholder={"Description de la source de donnée"}
        />
      ),
      tooltip: false,
    },
    {
      id: 4,
      name: "Configuration",
      type: (
        <TextInput
          name={"configured_source_description"}
          value={source["configured_source_description"]}
          onChange={onChange}
          placeholder={"Description de la configuration"}
        />
      ),
      tooltip: false,
    },
    {
      id: 5,
      name: "id",
      type: (
        <TextInput
          name={"id"}
          value={source["id"]}
          onChange={onChange}
          placeholder={"id de la source configurée"}
        />
      ),
      tooltip: (
        <p>
          {" "}
          Id de la configuration. si le champ est laissé vide, l'id est généré.
        </p>
      ),
    },
    {
      id: 6,
      type: (
        <Checkbox
          name="SameStorageType"
          checked={sameStorageType}
          label={"Stockage dans la même instance"}
          tooltip={
            <ul>
              <li>
                <span className="text-gray-100 font-semibold">Activé:</span> Les
                mesures sont stockées dans la même instance.
              </li>
              <li>
                <span className="text-gray-100 font-semibold">Non activé </span>{" "}
                Les mesures sont stockées dans une autre instance sélectionnable.
              </li>
            </ul>
          }
          onChange={(e) => {
            onMeasureChange(e.target.checked);
          }}
        />
      ),
      tooltip: false,
    },
    {
      id: 7,
      name: "Alerte",
      type: (
        <TextInput
          min={0}
          type={"number"}
          name={"alert_delay"}
          value={source["alert_delay"]}
          onChange={onChange}
        />
      ),
      tooltip:
        "Délai d'alerte pour la remontée de données",
    },
    {
      id: 8,
      name: "Paramètre",
      type: (
        <SourceParametersModal
          label={"Ajouter des paramètres de source"}
          openModal={SpModal}
          sourceParametersValue={sourceParametersValue}
          updateSourceParametersValue={updateSourceParametersValue}
          callback={() => {
            setSpModal(!SpModal);
          }}
        />
      ),
      tooltip: false,
    },

    {
      id: 9,
      type: (
        <Checkbox
          label={"Activation"}
          name={"is_active"}
          onChange={onCheckboxChange}
        />
      ),
      tooltip: false,
    },
    {
      id: 10,
      name: "Valeurs remontées",
      type: (
        <MappingValueModal
          label={"Ajouter des variables"}
          openModal={modal}
          mappingValue={mappingValue}
          updateMappingValue={updateMappingValue}
          callback={() => {
            setModal(!modal);
          }}
        />
      ),
      tooltip: false,
    },
  ];
  if (!selectMappingValues) {
    field.splice(10, 1);
  }
  if (sameStorageType === false) {
    field.splice(7, 0, {
      id: 11,
      name: "Instance de la mesure",
      type: (
        <SelectAdd
          selectField={makeSelectField(data.storage_system, [
            "name",
            "description",
            "storage_type",
          ])}
          callback={onChange}
          name="storage_system_id"
          value={makeSelectField(data.storage_system, [
            "name",
            "description",
          ]).find((value) => value.value === source["storage_system_id"])}
        />
      ),
      tooltip: false,
    });
  }
  if (!selectObs) {
    field.splice(0, 1);
  }

  const canAdd =
    isEmptyString(source["name"]) ||
    isEmptyString(source["description"]) ||
    isEmptyString(source["source_type"]) ||
    isEmptyString(source["configured_source_description"]);
  return (
    authorizedDataSource && (
      <DataForm
        isMulti={isMulti}
        isLoading={isLoading}
        disabled={canAdd}
        information="Source de donnée"
        description="Formulaire regroupant les informations nécessaires à la création d'une nouvelle source de donnée."
        field={field}
        callback={handleAdd}
      />
    )
  );
};
export default ConfiguredSourceData;
