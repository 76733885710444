//React librairies
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Navigate, Outlet, useNavigate} from "react-router-dom";

import {getPages} from "../network/UserManagementApi";

const PrivateRoute = () => {
  //Use navigate
  const history = useNavigate();

  //Use state
  const authentication = useSelector((state) => state.authentication);

  useEffect(() => {
    if (!authentication.isAuthenticated) return;
    getPages(window.location.pathname, () => history("/login"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return authentication.isAuthenticated ? <Outlet/> : <Navigate to="/login"/>;
};
export default PrivateRoute;
