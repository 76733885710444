import Button from "../Selectors/Button";
import BasicTooltip from "../Tooltip/BasicTooltip";

/**
 * @param isLoading {boolean}
 * @param information {string}
 * @param description {string}
 * @param disabled {boolean}
 * @param field  {Object} id,name,type,tooltip
 * @param callback {function}
 * @param isMulti {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const DataForm = ({isLoading, information, description, disabled, field, callback, isMulti = false}) => {
    return (<div>

        <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h2 className="text-theme1 text-2xl font-semibold">{information}</h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
            </div>
            <div className=" border-t border-gray-200">
                <div>
                    {field.map((item) => {
                        return (<div className=" p-4 hover:bg-gray-100">
                            <div className=" flex items-center gap-2">

                                <label
                                  className={"text-theme1  text-sm font-bold text-gray-700 dark:text-white tracking-wide"}> {item.name}</label>
                                {item.tooltip !== false && <BasicTooltip tip={item.tooltip}/>}
                            </div>
                            {item.type}
                        </div>);
                    })}
                </div>
                {!isMulti && <div className="flex justify-center align-center py-2">
                    <Button
                        isLoading={isLoading}
                        callback={callback}
                        label={"Ajouter"}
                        disabled={disabled}
                    />
                </div>}
            </div>
        </div>
    </div>);
};

export default DataForm;
