const IconButton = ({Icon, callback, value, bg = false}) => {
    const className = bg ? "text-white bg-theme1 hover:bg-theme3 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  text-center inline-flex items-center" :
        "text-theme1 hover:text-theme3 focus:outline-none  font-medium rounded-full   text-center inline-flex items-center"
    return (
        <button type="button"
                onClick={callback}
                value={value}
                className={className}>
            {Icon}
        </button>
    )
}
export default IconButton;