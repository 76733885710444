import { createSlice } from "@reduxjs/toolkit";
//TODO Refactor state => array
const initialState = {
  configured_source: {},
  observed_system: {},
  structure: {},
  crude_source: {},
  storage_system: {},
  event: {},
  moduleName: [],
  threshold: [],
  users: [],
  profiles: {},
  tasks: {},
  methods: {},
  profile: {},
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    setData: (state, action) => {
      const { type, data } = action.payload;
      state[type] = data;
    },
    set: (state, action) => {
      const { data, type } = action.payload;
      state[type] = data;
    },
    add: (state, action) => {
      const { data, type, index } = action.payload;
      state[type][index] = data;
    },
    push: (state, action) => {
      const { data, type } = action.payload;
      state[type].push(data);
    },
    remove: (state, action) => {
      const { index, type } = action.payload;
      delete state[type][index];
    },
    reset: (state) => {
      return { ...initialState, profile: state.profile };
    },
    edit: (state, action) => {
      if (action.payload.type === "EDIT_USER") {
        const { id, updates } = action.payload;
        let index = state.users.findIndex((value) => value.id === id);
        state.users[index] = { ...state.users[index], ...updates };
      } else {
        //things like action.payload.type === "crude_source" or "observed_system"
        const { id, updates } = action.payload;
        state[action.payload.type][id] = {
          ...state[action.payload.type][id],
          ...updates,
        };
      }
    },
  },
});

export const { setData, set, reset, add, remove, push, edit } =
  dataSlice.actions;
export default dataSlice.reducer;
