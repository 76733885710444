import {sourceField} from "../../utils/field";
import TableSP from "../../components/Selectors/TableSP";
import TableMV from "../../components/Selectors/TableMV";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";

const Recap = ({
                 obs_info,
                 conf_info,
                 mv_info,
                 sp_info,
                 storage_info,
                 os_info,
               }) => {
  const handle_mv = (data) => {
    const ret = [];
    data.forEach((elem) => {
      let param = elem.param;
      switch (elem.type) {
        case "categorial":
        case "string":
          break;
        case "date":
          param = "(GMT)" + param.timezone;
          break;
        case "numeric":
          param = param.minValue + "-" + param.maxValue;
          break;
        default:
          break;
      }
      if (elem.locked)
        ret.push({name: elem.name, type: elem.type, param: param});
    });
    return ret;
  };

  const handle_owner_ids = (owner) => {
    const stringBuilder = [];
    owner.forEach((elem) => {
      console.debug(elem);
      const system = os_info[elem.value];
      console.debug(system);
      stringBuilder.push(system.name);
    });
    return stringBuilder.join(" - ");
  };
  const obs_render = () => {
    const storage = storage_info[obs_info.storage_system_id];

    return (
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
            Système observé
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div
              className="border-l border-r border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Système de stockage
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {storage === undefined ? "" : storage.name}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nom</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {obs_info.name}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {obs_info.description}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Lien</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {obs_info.membership}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Système(s) observé(s) à lier
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {handle_owner_ids(obs_info.owner_ids)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };
  const source_render = () => {
    const source_type = sourceField.find(
      (e) => e.value === conf_info.source_type
    );
    const storage =
      conf_info.storage_system_id === ""
        ? storage_info[obs_info.storage_system_id]
        : storage_info[conf_info.storage_system_id];
    return (
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-center  text-lg leading-6 font-medium text-gray-900">
            Source
          </h3>
        </div>
        <div className="border-l border-r border-t border-gray-200">
          <dl>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Nom</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.name}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.description}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {source_type?.label ?? ""}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Stockage dans la même instance
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.is_active ? (
                  <CheckCircleIcon className={"w-4 h-4 text-green-600"}/>
                ) : (
                  <XCircleIcon className={"w-4 h-4 text-red-700"}/>
                )}
              </dd>
            </div>
            {conf_info.storage_system_id !== "" && (
              <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Type de stockage
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {storage.name ?? ""}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    );
  };
  const conf_render = () => {
    return (
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
            Configuration
          </h3>
        </div>
        <div className="border-t border-l border-r border-gray-200">
          <dl>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.configured_source_description}
              </dd>
            </div>
            <div className="border-t  border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.id}
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Activation</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.is_active ? (
                  <CheckCircleIcon className={"w-4 h-4 text-green-600"}/>
                ) : (
                  <XCircleIcon className={"w-4 h-4 text-red-700"}/>
                )}
              </dd>
            </div>

            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Alerte</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-center">
                {conf_info.alert_delay}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  const sp_render = () => {
    return (
      <>
        <div className="px-4 py-5 sm:px-6">
          <TableSP name={"Paramètres de la source de données"} data={sp_info}/>
        </div>
      </>
    );
  };
  const mv_render = () => {
    return (
      <>
        <div className="px-4 py-5 sm:px-6">
          <TableMV
            name={"Variables de la source de données"}
            data={handle_mv(mv_info)}
          />
        </div>
      </>
    );
  };
  return (
    <div>
      {obs_render()}

      <div className="grid grid-cols-2 gap-2">
        {source_render()}
        {conf_render()}
        {sp_render()}
        {mv_render()}
      </div>
    </div>
  );
};
export default Recap;
