import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {current, historical} from "../event/EventUtils";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import Select from "react-select";
import {
  canRenderComponent,
  getPastDate,
  getSource,
  makeSelectField,
} from "../../utils/common";
import {
  initEvent,
  removeDataParams,
  setAutoRegressiveParams,
  setBase,
} from "../../reducers/EventSlice";
import DataParamCreation from "../event/DataParamCreation";
import TimeParamCreation from "../event/TimeParamCreation";
import ComputationParamCreation from "../event/ComputationParamCreation";
import Button from "../../components/Selectors/Button";
import AutoRegressiveParam from "./AutoRegressiveParam";
import TextInput from "../../components/Selectors/TextInput";

import TimezoneSelect from "react-timezone-select";

import CronDualRange from "../../components/Selectors/CronDualRange";
import axios from "axios";
import {formatOffset} from "../indicator/utils";
import {toast} from "react-toastify";
import {COMPONENTS} from "../../constants/component";

const RegressionModelCreator = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedModel, setAuthorizedModel] = useState(
    canRenderComponent(COMPONENTS.LEARNING_MODULE_CREATION)
  );
  useEffect(() => {
    return () => {
      setAuthorizedModel(
        canRenderComponent(COMPONENTS.LEARNING_MODULE_CREATION)
      );
    };
  }, [authorizedComponent]);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const obs = sessionStorage.getItem("observed_system_id");
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  //date handling
  let date = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let offset = formatOffset(-date.getTimezoneOffset() / 60);
  const start_time = getPastDate(5 * 60)
    .toISOString()
    .split(".")[0];
  const init = {
    current: structuredClone(current),
    historical: structuredClone(historical),
    autoregressive_params: {
      predictor_variables: [],
      target_variable: "",
      order: 3,
      training_data_ratio: 0.8,
      model_name: "",
    },
  };
  const selectedSource = useSelector((state) => state.event.selectedSource);
  const data = useSelector((state) => state.event);
  const source = useSelector((state) => state.event.source);
  const sourceId = useSelector((state) => state.event.sourceId);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    dispatch(
      initEvent({
        data: structuredClone(init),
        source: getSource("crude_source"),
        selectedSource: null,
        histo: false,
        count: true,
        sourceId: false,
        offset: offset,
        timeZone: timeZone,
        lockedHisto: true,
        loaded: true,
        model_name: "",
        initial_timestamp: start_time,
        frequency: "5",
        validity_days: "1-5",
        time_slot: "9-18",
      })
    );
    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const create = () => {
    setLoading(true);
    const state = structuredClone(data);
    const to_send = {};
    to_send["script_name"] = "autoregressive_module";
    to_send["frequency"] = parseInt(state["frequency"]);
    to_send["validity_days"] = state["validity_days"];
    to_send["time_slot"] = state["time_slot"];
    to_send["script_parameters"] = {};
    to_send["script_parameters"]["observed_system"] = obs;
    to_send["script_parameters"]["parameters"] = state.data;
    to_send["script_parameters"]["initial_timestamp"] =
      state.initial_timestamp + state.offset;
    to_send["script_parameters"]["timezone"] = state.timeZone;
    to_send["script_parameters"]["parameters"]["autoregressive_params"][
      "predictor_variables"
      ] = to_send["script_parameters"]["parameters"]["autoregressive_params"][
      "predictor_variables"
      ].map((value) => value.label);

    delete to_send["script_parameters"]["parameters"]["historical"];
    console.debug("data", to_send);
    axios
      .put(
        "https://api.version0.prototype4.research.preditic.com/configuration/module/" +
        obs,
        to_send,
        {headers: {Authorization: "Bearer 0kdaVC4AjRZVLGfvGqNz"}}
      )
      .then((res) => {
        console.log(res); //TODO handle res
        setLoading(false);
        toast.info("Created.");
      });
  };
  return (
    authorizedModel && (
      <div>
        <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
          <div className="flex gap-x-1.5">
            <IconButton
              bg={true}
              Icon={<Prev size={40}/>}
              value={""}
              callback={() => history("/analytics/event")}
            />
            <h2 className="text-theme1 text-3xl font-semibold">
              Création d'un modèle d'apprentissage
            </h2>
          </div>
        </div>
        <div>
          {dataLoaded && (
            <div>
              <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
                <h2 className="text-theme1 text-2xl font-semibold">
                  data_params
                </h2>
                <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">
                  Source de données
                </label>
                <Select
                  placeholder={"Source de données..."}
                  openMenuOnClick={false}
                  isClearable={true}
                  isMulti={true}
                  value={selectedSource}
                  options={makeSelectField(source, ["name"])}
                  isDisabled={sourceId}
                  isLoading={sourceId}
                  onChange={(option, action) => {
                    if (action.action === "remove-value") {
                      const measure_identifier =
                        configurations[
                          source[action.removedValue.value].configuration[0]
                          ].measure_identifier;
                      dispatch(removeDataParams(measure_identifier));
                      dispatch(
                        setBase({type: "selectedSource", value: option})
                      );
                    }
                    if (action.action === "select-option") {
                      dispatch(
                        setBase({type: "selectedSource", value: option})
                      );
                      dispatch(
                        setBase({
                          type: "sourceId",
                          value: action.option.value,
                        })
                      );
                    }
                  }}
                />

                {sourceId && <DataParamCreation/>}
              </div>
              <TimeParamCreation/>
              <ComputationParamCreation/>
              <AutoRegressiveParam/>
              <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
                <h2 className="text-theme1 text-2xl font-semibold">
                  Autres paramètres
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  <TextInput
                    name={"model_name"}
                    label={"model_name"}
                    value={data.data.autoregressive_params.model_name}
                    onChange={(name, value) =>
                      dispatch(
                        setAutoRegressiveParams({type: name, value: value})
                      )
                    }
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      name={"initial_timestamp"}
                      label={"Temps"}
                      step={"1"}
                      value={data.initial_timestamp}
                      type={"datetime-local"}
                      onChange={(name, value) => {
                        dispatch(setBase({type: name, value: value}));
                      }}
                    />
                    <div>
                      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
                        {"Timezone"}
                      </label>
                      <TimezoneSelect
                        value={data.timeZone}
                        onChange={(e) => {
                          dispatch(
                            setBase({type: "timeZone", value: e.value})
                          );
                          dispatch(
                            setBase({
                              type: "offset",
                              value: e.label.substring(4, 9),
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid grid-rows-3 gap-4">
                    <TextInput
                      name={"frequency"}
                      label={"Frequence"}
                      step={"1"}
                      value={data.frequency}
                      min={1}
                      type={"number"}
                      onChange={(name, value) =>
                        dispatch(setBase({type: name, value: value}))
                      }
                    />

                    <CronDualRange
                      model={"time_slot"}
                      name={"Plage Horaire"}
                      max={23}
                      min={0}
                    />
                    <CronDualRange
                      model={"validity_days"}
                      name={"Jour(s) d'activité"}
                      max={6}
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-7 gap-4 pt-2">
            <span className="col-span-3"/>
            <Button label={"Créer"} disabled={false} callback={create}/>
          </div>
        </div>
      </div>
    )
  );
};
export default RegressionModelCreator;
