import IndicatorAccordion from "../IndicatorAccordion";
import ConfigurationAlerteParam from "./ConfigurationAlerteParam";
import ConfigurationAlerteCurrent from "./ConfigurationAlerteCurrent";
import ConfigurationAlerteHistorical from "./ConfigurationAlerteHistorical";
import {useSelector} from "react-redux";
import IndicatorRecurentForm from "../IndicatorRecurentForm";

const ConfigurationAlerteRightPanel = () => {
    const isHist = useSelector(state => state.indicator.moduleData.historical)["data_params"].length !== 0

    return <div
      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"Programmation d'exécution du module"}
                            composant={<IndicatorRecurentForm/>} open={false}/>
        {isHist && <IndicatorAccordion label={"Paramètres ( du bloc historique)"}
                                       composant={<ConfigurationAlerteHistorical/>} open={false}/>}
        <IndicatorAccordion label={"Paramètres ( du bloc courant)"}
                            composant={<ConfigurationAlerteCurrent/>}/>
        <IndicatorAccordion label={"Paramètre d'indicateur"}
                            composant={<ConfigurationAlerteParam/>}/>
    </div>
}
export default ConfigurationAlerteRightPanel