import TextInput from "../../../components/Selectors/TextInput";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {setDeep} from "../../../reducers/indicatorSlice";
import {makeSelectField} from "../../../utils/common";
import React from "react";
import CreatableSelect from "react-select/creatable";
import {useNavigate} from "react-router-dom";

const DetectionAnomalieAlertSetting = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const event = useSelector((state) => state.data.event);
  const alert_settings = useSelector(
    (state) => state.indicator.moduleData.alert_settings
  );
  const setAlertSetting = (name, value) => {
    if (name === "value") value = parseInt(value);
    dispatch(setDeep({type: "alert_settings", first: name, data: value}));
  };
  return (
    <div>
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Mode de contrôle
      </label>
      <Select
        onChange={(e) => setAlertSetting("type", e)}
        value={alert_settings.type}
        menuPosition={"fixed"}
        options={[
          {value: "counting", label: "Comptage"},
          {value: "proportion", label: "Proportion"},
        ]}
      />
      <TextInput
        label={"Seuil à considérer"}
        name={"value"}
        onChange={setAlertSetting}
        type={"number"}
        min={0}
        value={alert_settings.value}
      />
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Événement à déclencher
      </label>
      <CreatableSelect
        value={event[alert_settings.event]}
        menuPosition={"fixed"}
        options={makeSelectField(event, [
          "module_name",
          "function_name",
          "key",
        ])}
        onChange={(e) => {
          if (e?.__isNew__) history("/analytics/event/create2");
          console.debug(e);
          setAlertSetting("event", e);
        }}
      />
    </div>
  );
};
export default DetectionAnomalieAlertSetting;
