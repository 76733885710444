import {createSlice, current} from "@reduxjs/toolkit";

const initialState = {
    selectedObs: {
        value: sessionStorage.getItem("observed_system_id"),
        label: sessionStorage.getItem("observed_system_desc") + " - " + sessionStorage.getItem("observed_system_name")
    },
    crudeSources: [],
    data_sources: [], // {data_source_id,timestamp_field,variables}
    selectedSource: [],
    link: "",
    graphic_title: "",

    observedSystems: [],
    variables: [],
    selectedVariable: [],
    selectedConfiguration: [],
    selectedTimestamp: [],
    configurations: {},
    timezone: {
        label: Intl.DateTimeFormat().resolvedOptions().timeZone, value: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    mode: {label: "linéaire", value: "linear"},
    graph_span: {label: "7 dernières jours", value: "long"}
}
export const graphSlice = createSlice({
    name: "analyticGraph", initialState: initialState, reducers: {
        set: (state, action) => {
            const payload = action.payload
            state.observedSystems = payload.observedSystems
            state.crudeSources = payload.crudeSources
            state.data_sources = payload.data_sources
            state.link = payload.link
            state.graphic_title = payload.graphic_title
            state.observedSystems = payload.observedSystems

        }, setObs: (state, action) => {

            state.observedSystems = action.payload
        }, selectObs: (state, action) => {
            state.selectedObs = action.payload
            state.crudeSources = []
            state.data_sources = []
            state.configurations = {}
            state.selectedSource = []
            state.selectedVariable = []
            state.selectedTimestamp = []

        }, setSources: (state, action) => {
            state.crudeSources = action.payload
        }, selectSource: (state, action) => {
            state.selectedSource = action.payload
            const currentSource = current(state.data_sources).filter(value => action.payload.findIndex(source => source.value === value.data_source_id) !== -1)
            action.payload.forEach(source => {

                let add = true

                currentSource.forEach(value => {
                    if (value.data_source_id === source.value) add = false
                })

                if (add) {
                    currentSource.push({
                        data_source_id: source.value, timestamp_field: "internal", variables: []
                    })
                }
            })

            state.selectedVariable = current(state.selectedVariable).filter(value => action.payload.findIndex(source => source.value === value.crude_source_id) !== -1)
            state.selectedTimestamp = current(state.selectedTimestamp).filter(value => action.payload.findIndex(source => source.value === value.crude_source_id) !== -1)

            state.data_sources = currentSource
        }, selectVariable: (state, action) => {
            state.selectedVariable = action.payload
        }, selectTimestamp: (state, action) => {
            state.selectedTimestamp = action.payload
        }, setGraphTitle: (state, action) => {
            state.graphic_title = action.payload
        }, setLink: (state, action) => {
            state.link = action.payload
        }, setOne: (state, action) => {
            state[action.payload.type] = action.payload.data
        }, /**
         * Set a field of the variable params. {index,type,data}
         * @param state
         * @param action
         */
        setVariableParam: (state, action) => {
            const index = state.selectedVariable.findIndex(value => value.value === action.payload.id)
            if (action.payload.type === "threshold_parameters") state.selectedVariable[index][action.payload["type"]][action.payload["type2"]] = action.payload["data"]; else state.selectedVariable[index][action.payload["type"]] = action.payload["data"]
        }, reset: () => {
            return initialState
        }
    }
})

export const {
    set,
    setObs,
    selectObs,
    reset,
    setSources,
    selectSource,
    selectVariable,
    selectTimestamp,
    setGraphTitle,
    setLink,
    setOne,
    setVariableParam
} = graphSlice.actions
export default graphSlice.reducer