import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeOptions, renderEvent } from "./EventUtils";
import { getModule } from "../../network/DataManagementApi";

const EventCreator = () => {
  const history = useNavigate();
  const [event, setEvent] = useState(null);
  const moduleName = useSelector((state) => state.data.moduleName);
  const options = makeOptions(moduleName);
  const [eventRendered, setEventRendered] = useState();
  useEffect(() => {
    if (event !== null) setEventRendered(renderEvent(event.label));
  }, [event]);
  useEffect(() => {
    if (moduleName.length === 0) getModule();
  }, [moduleName]);

  return (
    <div>
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <div className="flex gap-x-1.5">
          <IconButton
            bg={true}
            Icon={<Prev size={40} />}
            value={""}
            callback={() => history("/analytics/event")}
          />
          <h2 className="text-theme1 text-3xl font-semibold">
            Création d'un événement
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-2 mx-20">
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              {"événement"}
            </label>
            <Select
              isSearchable={true}
              onChange={(option) => {
                setEvent(option);
              }}
              value={event}
              options={options}
            />
          </div>
        </div>
      </div>
      {eventRendered}
    </div>
  );
};
export default EventCreator;
