import React, { useState } from "react";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/Selectors/TextInput";
import Button from "../../components/Selectors/Button";
import { useSelector } from "react-redux";
import { isEmptyObject, makeSelectField } from "../../utils/common";
import { createUser } from "../../network/UserManagementApi";

const AdminCreateUser = () => {
  const structures = useSelector((state) => state.admin.structures);
  const profiles = useSelector((state) => state.data.profiles);
  const [valid, setValid] = useState({
    email: false,
    lastName: false,
    firstName: false,
  });
  console.debug(structures);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [structure, setStructure] = useState({
    value: sessionStorage.getItem("structure_id"),
    label:
      structures[sessionStorage.getItem("structure_id")]["description"] +
      " - " +
      structures[sessionStorage.getItem("structure_id")]["name"],
  });

  const [profile, setProfile] = useState(undefined);
  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    enabled: true,
    attributes: {
      profile_id: "",
      structure_id: structure.value,
      locale: ["fr"],
    },
    requiredActions: ["UPDATE_PASSWORD", "UPDATE_PROFILE", "VERIFY_EMAIL"],
    realmRoles: ["user_default"],
  });
  const onChange = (name, value, isValid) => {
    setData({
      ...data,
      [name]: value,
    });
    setValid({
      ...valid,
      [name]: isValid,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const attributes = { ...data.attributes };
    attributes.profile_id = profile.value.toString();
    attributes.structure_id = structure.value;
    const body = { ...data };
    body["username"] = body.email;
    body.attributes = attributes;
    createUser(body, () => {
      setLoading(false);
      history("/internal/users");
    });
  };
  const disabled =
    Object.values(valid).every((value) => value === true) &&
    !isEmptyObject(profile) &&
    !isEmptyObject(structure);
  return (
    <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
      <div className="flex gap-x-1.5">
        <IconButton
          bg={true}
          Icon={<Prev size={40} />}
          value={""}
          callback={() => history("/internal/users")}
        />
        <h2 className="text-theme1 text-3xl font-semibold">
          Création d'un utilisateur
        </h2>
      </div>

      <div className={"mt-2"}>
        <div className={"grid grid-cols-1 gap-4 lg:grid-cols-3"}>
          <TextInput
            required={true}
            name={"email"}
            value={data.email}
            onChange={onChange}
            label={"Adresse email"}
            type={"email"}
          />
          <TextInput
            required={true}
            name={"firstName"}
            value={data.firstName}
            onChange={onChange}
            label={"Prénom"}
            type={"text"}
          />
          <TextInput
            required={true}
            name={"lastName"}
            value={data.lastName}
            onChange={onChange}
            label={"Nom"}
            type={"text"}
          />
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Type
            </label>
            <Select
              required={true}
              onChange={(e) => setProfile(e)}
              value={profile}
              options={makeSelectField(profiles, ["name"])}
            />
          </div>
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Structure
            </label>
            <Select
              onChange={(e) => setStructure(e)}
              value={structure}
              options={makeSelectField(structures, ["name", "description"])}
            />
          </div>
        </div>
      </div>
      <div className={"flex justify-end gap-2"}>
        <Button
          label={"Annuler"}
          callback={() => history("/internal/users")}
          color={"bg-red-700"}
          hover={"hover:bg-red-800"}
          focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
          extraStyle={
            "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
          }
        />
        <Button
          disabled={!disabled}
          label={"Ajouter"}
          isLoading={loading}
          callback={() => handleAdd()}
          color={"bg-theme1"}
          hover={"hover:bg-theme-3"}
          focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
          extraStyle={
            "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
          }
        />
      </div>
    </div>
  );
};
export default AdminCreateUser;
