import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import Select from "react-select";
import {isEmptyString, makeSelectField} from "../../utils/common";
import React, {useEffect, useMemo, useState} from "react";
import Card from "../../components/Card/Card";
import TextInput from "../../components/Selectors/TextInput";
import CreatableSelect from "react-select/creatable";
import {parser} from "mathjs";
import {toast} from "react-toastify";
import Button from "../../components/Selectors/Button";

import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import {addThreshold} from "../../network/DataManagementApi";

const ThresholdCreator = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const obs = sessionStorage.getItem("observed_system_id");
  const [dataSource, setDataSource] = useState(undefined);
  const dataSources = useSelector((state) => state.data.crude_source);
  const [threshold, setThreshold] = useState({
    description: "",
    label: "",
    formula: [],
  });
  useEffect(() => {
    setDisabled(false);
    if (
      isEmptyString(threshold.description) ||
      isEmptyString(threshold.label) ||
      threshold.formula.length === 0
    )
      setDisabled(true);
  }, [threshold]);
  const handleThresholdChange = (name, value) =>
    setThreshold({
      ...threshold,
      [name]: value,
    });
  const create = () => {
    setIsLoading(true);
    const formula = {};
    threshold.formula.forEach(
      (value) => (formula[value.value] = value.formula)
    );
    const data = {
      label: threshold.label,
      description: threshold.description,
      formula: formula,
      structure_id: sessionStorage.getItem("structure_id"),
    };
    addThreshold(data, obs, () => {
      toast.success("Le seuil a été créé");
      setIsLoading(false);
    });
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return row[0];
        },
        className:
          "group  w-1/2 px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider",
        id: "name",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          return row[1].type;
        },
        className:
          "group w-fit px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider ",
        id: "type",
        header: "Type",
        cell: (info) => info.getValue(),
      },
    ],
    []
  );
  return (
    <div>
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <div className="flex gap-x-1.5">
          <IconButton
            bg={true}
            Icon={<Prev size={40}/>}
            value={""}
            callback={() => history("/analytics/threshold")}
          />
          <h2 className="text-theme1 text-3xl font-semibold">
            Création d'un seuil
          </h2>
        </div>
        <div className=" text-sm font-semibold text-gray-700 dark:text-white ">
          <p>
            Permet la création d'un nouveau seuil utilisable par les indicateurs
            ou les évenements.
          </p>
          <p>
            Il est nécéssaire de sélectionner une source de données pour
            considérer les variables de la formule.
          </p>
        </div>
        <div className="mx-20 ">
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
            {"Source de données"}
          </label>
          <Select
            isSearchable={true}
            onChange={(option) => {
              console.debug(dataSources[option.value]);
              setDataSource(option);
            }}
            value={dataSource}
            options={makeSelectField(dataSources, ["name", "description"])}
          />
        </div>
      </div>
      <div className="bg-white   m-8  shadow-lg rounded-lg max-h-[80vh]">
        <Card
          name={"Liste des variables"}
          columns={columns}
          data={
            dataSource === undefined
              ? []
              : Object.entries(dataSources[dataSource.value].measure)
          }
        />
      </div>
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
          {"Paramètre du seuil"}
        </label>
        <div className={"grid grid-cols-2 gap-4"}>
          <TextInput
            name={"label"}
            label={"Nom"}
            value={threshold.label}
            type={"text"}
            onChange={handleThresholdChange}
          />
          <TextInput
            name={"description"}
            label={"Description"}
            value={threshold.description}
            type={"text"}
            onChange={handleThresholdChange}
          />
          <div className="col-span-2">
            <LabelTooltip
              label={"Formule"}
              tip={"usage: state:formula - Exemple: BON:CHAUD >  FROID"}
            />
            <CreatableSelect
              placeholder={"usage: state:formula - Exemple: BON:CHAUD >  FROID"}
              openMenuOnClick={false}
              openMenuOnFocus={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={true}
              value={threshold.formula}
              onChange={(option, actionMeta) => {
                if (actionMeta.action === "create-option") {
                  const parse = parser();
                  const variables =
                    dataSource === undefined
                      ? []
                      : Object.entries(dataSources[dataSource.value].measure);
                  const splittedOption = actionMeta.option.value.split(":");
                  if (splittedOption.length !== 2) {
                    toast.error("Usage: Etat:Formule");
                    return;
                  }
                  let formula = splittedOption[1];
                  variables.forEach((variable) => {
                    formula = formula.replace(
                      variable[0],
                      "['" + variable[0] + "']"
                    );

                    parse.set(variable[0], 1);
                  });
                  formula = formula
                    .replaceAll("(?<!>)=(?!>)", "==")
                    .replaceAll("c_[", "c[")
                    .replaceAll("h_[", "h[");

                  try {
                    parse.evaluate(
                      splittedOption[1]
                        .replaceAll("c_", "")
                        .replaceAll("h_", "")
                    );
                    const formulas = threshold.formula;
                    formulas.push({
                      value: splittedOption[0],
                      label: splittedOption[0] + " : " + formula,
                      formula: formula,
                    });
                    handleThresholdChange("formula", formulas);
                  } catch (e) {
                    if (e instanceof SyntaxError) {
                      toast.error("Formule incorrect");
                    } else {
                      const wrongVariable = e.message.split(" ")[2];
                      toast.error(
                        "Variable incorrect: " +
                        wrongVariable +
                        " Formule: " +
                        splittedOption[1]
                      );
                    }
                  }
                } else handleThresholdChange("formula", option);
              }}
              isMulti={true}
            />
          </div>
        </div>
        <div className="grid grid-cols-7 gap-4 pt-2">
          <span className="col-span-3"/>
          <Button
            label={"Créer"}
            disabled={disabled}
            isLoading={isLoading}
            callback={create}
          />
        </div>
      </div>
    </div>
  );
};
export default ThresholdCreator;
