//React librairies
import DashboardData from "../components/DashboardData/DashboardData";
import {useSelector} from "react-redux";

const Dashboard = () => {

  const data = useSelector((state) => state.data);


  return (
    <DashboardData
      crude_source={data.crude_source}
      observed_system={data.observed_system}
      storage_system={data.storage_system}
    />
  );
};

export default Dashboard;
