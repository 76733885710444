import React, { useState } from "react";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isEmptyObject, isEmptyString } from "../../utils/common";
import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { toast } from "react-toastify";
import { getConnectionLog } from "../../network/UserManagementApi";
import { TablePagination } from "./TablePagination";
import { useSelector } from "react-redux";
import { TableFilter } from "./TableFilter";

const TableLogConnection = () => {
  const [data, setData] = useState([]);
  const structures = useSelector((state) => state.admin.structures);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("user_email", {
      id: "user_email",
      header: "utilisateur",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("structure_id", {
      id: "structure",
      header: "Structure",
      cell: (info) => {
        const structure_id = info.getValue();
        if (isEmptyString(structure_id)) return "N/A";
        if (
          !isEmptyObject(structures) &&
          structures[structure_id] !== undefined
        )
          return structures[structure_id].name;

        return structure_id;
      },
    }),
    columnHelper.accessor("login_timestamp", {
      enableGlobalFilter: false,
      id: "login",
      header: "Connexion",
      cell: (info) => new Date(info.getValue() * 1000).toLocaleString(),
    }),
    columnHelper.accessor("logout_timestamp", {
      enableGlobalFilter: false,
      id: "logout",
      header: "déconnexion",
      cell: (info) =>
        info.getValue() === null
          ? "N/A"
          : new Date(info.getValue() * 1000).toLocaleString(),
    }),
  ];
  const update = () => {
    setLoading(true);
    getConnectionLog().then((res) => {
      const formatted = JSON.parse(res.data.data);
      toast.success("Informations mises à jour.");
      setData(formatted);
      setLoading(false);
    });
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className={"bg-white h-fit m-4 pb-4 "}>
      <div className="flex flex-row gap-4 justify-between m-2">
        <div className="flex">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
          <div className="mt-2 px-3">
            <Button
              isLoading={loading}
              disabled={loading}
              callback={update}
              label="Mettre à jour"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="mx-20 shadow overflow-hidden border-b border-gray-200">
              <table className="w-full ">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
        <TablePagination table={table} />
      </div>
    </div>
  );
};
export default TableLogConnection;
