import Select from "react-select";
import {makeSelectField} from "../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setDeep, setModuleData} from "../../../reducers/indicatorSlice";
import TextInput from "../../../components/Selectors/TextInput";
import TimezoneSelect from "react-timezone-select";

const OutlierValidationParameter = () => {
    const dispatch = useDispatch()
    const event = useSelector(state => state.data.event);
    const selectedEvent = useSelector(state => state.indicator.moduleData.parameters.event)
    const params = useSelector(state => state.indicator.moduleData)
    const [options, setOptions] = useState([])
    useEffect(() => {
        setOptions(makeSelectField(event, ["module_name", "function_name", "key"]))
    }, [event])
    return <div>
        <h1 className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
            Evenement à déclencher
        </h1>
        <Select
          value={options.find(value => value.value === selectedEvent)}
          onChange={(option) => dispatch(setDeep({type: "parameters", first: "event_id", data: option.value}))}
          options={options}
        />
        <div className="grid grid-cols-2 gap-4">
            <TextInput name={"initial_timestamp"} label={"Date et heure"} step={"1"} value={params.initial_timestamp}
                       type={"datetime-local"}
                       onChange={(name, value) => dispatch(setModuleData({type: name, data: value}))}/>
            <div>
                <label
                  className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">{"fuseau horaire"}</label>
                <TimezoneSelect
                  value={params.timezone}
                  onChange={(e) => {
                      dispatch(setModuleData({type: "timezone", data: e.value}))
                      dispatch(setModuleData({type: "offset", data: e.label.substring(4, 9)}))
                  }}
                />
            </div>
        </div>
    </div>
}
export default OutlierValidationParameter;