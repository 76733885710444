import {getTrackBackground, Range} from "react-range";
import {setBase} from "../../reducers/EventSlice";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

const CronDualRange = ({model, name, min, max}) => {
    const dispatch = useDispatch()
    const data = useSelector(state => state.event)
    const values = data[model].split("-").map(value => parseInt(value))
    return <div>
        <label
            className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">{name}</label>
        <div
            className="flex justify-center flex-wrap"
        >
            <Range
                values={values}
                step={1}
                min={min}
                max={max}
                onChange={(values) => dispatch(setBase({
                    type: model, value: values.join("-")
                }))}

                renderTrack={({props, children}) => (<div
                    className={"h-10 flex w-full"}
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                >
                    <div
                        ref={props.ref}
                        className="h-1.5 w-full rounded self-center"
                        style={{
                            background: getTrackBackground({
                                values, colors: ['#cbd5e1', '#349ed0', '#cbd5e1'], min: min, max: max,
                            })
                        }}
                    >
                        {children}
                    </div>
                </div>)}
                renderThumb={({index, props, isDragged}) => (<div
                    {...props}
                    className="flex justify-center items-center h-8 w-8 shadow rounded bg-white"
                >
                    <div
                        className="absolute w-6 flex justify-center items-center -top-8 font-bold text-sm text-white bg-theme2 p-1 rounded"
                        style={{}}
                    >
                        {values[index]}
                    </div>
                    <div
                        className={`${"h-4 w-1.5"} ${isDragged ? "bg-theme2" : "bg-slate-300"}`}
                    />
                </div>)}
            />
        </div>
    </div>
}
export default CronDualRange;