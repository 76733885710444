import {useSelector} from "react-redux";
import uniqueId from "lodash.uniqueid";
import {useState} from "react";
import {
  AiFillLeftCircle as Prev,
  AiOutlineReload as Reload,
} from "react-icons/ai";
import Button from "../../components/Selectors/Button";
import {useNavigate} from "react-router-dom";
import {getPastDate} from "../../utils/common";

const Graph = () => {
  const history = useNavigate();

  const [id, setId] = useState(uniqueId());
  const [endDate, setEndDate] = useState(
    getPastDate(0).toISOString().split(".")[0]
  );
  const [startDate, setStartDate] = useState(
    getPastDate(21600).toISOString().split(".")[0]
  );
  const [refresh, setRefresh] = useState(true);
  const defaultLink = useSelector((state) => state.analyticsGraph["link"]);
  const [link, setLink] = useState(defaultLink);
  const title = useSelector((state) => state.analyticsGraph["graphic_title"]);

  console.debug(startDate, Math.floor(new Date(startDate).getTime() / 1000));
  console.debug(endDate, Math.floor(new Date(endDate).getTime() / 1000));
  /**
   * If refresh is true, return the default link. Return the link with the data threshold if false.
   * @returns {string}
   */
  const getLink = () => {
    return refresh ? defaultLink : link;
  };
  console.debug(getLink());
  return (
    <div className="h-[80vh]  m-10">
      <div
        className="block p-6 max-w-full h-full bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <div className=" flex flex-row gap-x-4">
          <div className={"py-2"}>
            <Prev
              className="cursor-pointer text-2xl hover:text-theme2 text-theme1 transition ease-in-out delay-150"
              onClick={() => {
                history("/analytics/graphOptions");
              }}
            />
          </div>
          <div className={"py-2"}>
            <Reload
              className="  cursor-pointer text-2xl hover:text-theme2 hover:rotate-180 transition ease-in-out delay-150 text-theme1"
              onClick={() => {
                setRefresh(true);
                setEndDate(getPastDate(0).toISOString().split(".")[0]);
                setStartDate(getPastDate(21600).toISOString().split(".")[0]);
                setId(id + 1);
              }}
            />
          </div>

          <div>
            <input
              id="start_date"
              required={true}
              value={startDate}
              max={endDate}
              className="text-theme1 font-semibold invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 bg-gray-50 w-fit border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              step={1}
              type={"datetime-local"}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              id="end_date"
              value={endDate}
              required={true}
              min={startDate}
              className="text-theme1 font-semibold invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 bg-gray-50 w-fit border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              step={1}
              type={"datetime-local"}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="col-span-1">
            <Button
              extraStyle={
                "block w-full rounded-lg px-3 py-2 shadow-lg cursor-pointer transition ease-in duration-300"
              }
              label={"Appliquer"}
              callback={() => {
                setLink(
                  defaultLink.replace(
                    "&refresh=5s",
                    "&from=" +
                    Math.floor(new Date(startDate).getTime()) +
                    "&to=" +
                    Math.floor(new Date(endDate).getTime())
                  )
                );
                setRefresh(false);
              }}
            ></Button>
          </div>
        </div>
        <div className="h-[65vh] w-full">
          <iframe
            key={id}
            className=" mt-4 w-full  h-full shadow"
            title={title}
            src={getLink()}
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default Graph;
