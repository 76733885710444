import store from "../store";
import {token} from "../reducers/authenticationSlice";

/**
 *
 * @param {string} refresh_token
 */
export const updateToken = (refresh_token) => {
  store.dispatch(token(refresh_token));
  sessionStorage.setItem("token", refresh_token);
}