import FormuleAnalytiqueParam from "../FormuleAnalytique/FormuleAnalytiqueParam";
import SelectMulti from "../../../components/Selectors/SelectMulti";
import {variableFieldMulti} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {setModuleData} from "../../../reducers/indicatorSlice";

const CorrelationParam = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  const onChange = (list) => {
    console.log(list);
    const target = [];
    list.forEach((opt) => target.push(opt.value));
    dispatch(setModuleData({type: "target_variables", data: target}));
  };
  return (
    <div>
      <FormuleAnalytiqueParam/>

      <label className="py-2 text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
        Cible(s)
      </label>
      <SelectMulti
        onChange={onChange}
        options={variableFieldMulti(module["data_params"])}
      />
    </div>
  );
};
export default CorrelationParam;
