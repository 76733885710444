import React, {useState} from "react";
import {data_params} from "./EventUtils";
import Select from "react-select";
import Button from "../../components/Selectors/Button";
import {makeSelectField, makeVariableField} from "../../utils/common";
import VariableTable from "./VariableTable";
import VariableTableHisto from "./VariableTableHisto";
import {useDispatch, useSelector} from "react-redux";
import {addDataParams, setBase} from "../../reducers/EventSlice";

const findAllConf = (conf, crude_source_id) => {
    const list = {}
    Object.entries(conf).forEach(value => {
        if (value[1].crude_source === crude_source_id) list[value[0]] = value[1]
    })
    return list
}
const DataParamCreation = () => {
    const sourceId = useSelector(state => state.event.sourceId)
    const source = useSelector(state => state.event.source)[sourceId]
    const histo = useSelector(state => state.event.histo)
    const dispatch = useDispatch()
    const selectedSource = useSelector(state => state.event.selectedSource)
    const configurations = useSelector(state => state.data.configured_source)
    const preprocesses = (variables) => {

        const preprocessesList = structuredClone(variables.filter(variable => variable[1].type !== "date"))
        preprocessesList.forEach(preprocess => {
            preprocess[1]["current"] = "identity"
            preprocess[1]["historical"] = "identity"
            preprocess[2] = false
        })
        return preprocessesList
    }

    const [variables, setVariables] = useState(preprocesses(Object.entries(source.measure)))
    const [data, setData] = useState(structuredClone(data_params));
    const updateData = (name, value) => {
        setData((prevState) => ({
            ...prevState, [name]: value,
        }));
    }
    const create = () => {
        const measure_identifier = configurations[source.configuration[0]].measure_identifier
        const newdata = {...data}

        newdata.measure_identifier = measure_identifier
        newdata.time_field = newdata.time_field.label
        newdata.configured_sources = newdata.configured_sources.map(value => value.value)

        variables.forEach(variable => {
            if (variable[2] === true) newdata.preprocess[variable[0]] = variable[1]["current"]
        })
        if (histo) {
            const historical = structuredClone(newdata)
            variables.forEach(variable => {
                if (variable[2] === true) historical.preprocess[variable[0]] = variable[1]["historical"]
            })
            dispatch(addDataParams({current: newdata, historical: historical}))
        } else dispatch(addDataParams({current: newdata, historical: null}))
    }

    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Timefield</label>
            <Select options={makeVariableField(1, sourceId, source.measure)}
                    value={data.time_params}
                    onChange={(option) => updateData("time_field", option)}
                    placeholder={"Index de temps..."}/>
        </div>
        <div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Configuration</label>
            <Select isMulti={true}
                    value={data.configured_sources}
                    onChange={(options) => updateData("configured_sources", options)}
                    options={makeSelectField(findAllConf(configurations, sourceId), ["description"])}
                    placeholder={"Configuration..."}/>
        </div>

        {histo ? <VariableTableHisto data={variables} setData={setVariables}/> :
            <VariableTable data={variables} setData={setVariables}/>}

        <div className="grid grid-cols-6 gap-4 pt-2 ">
            <span className="col-span-2"/>
            <Button label={"Annuler"} callback={() => {
                dispatch(setBase({
                    type: "selectedSource", value: selectedSource.filter(value => value.value !== sourceId)
                }))
                dispatch(setBase({type: "sourceId", value: false}))
            }}/>
            <Button label={"ajouter"} callback={create}/>
        </div>
    </div>
}
export default DataParamCreation;