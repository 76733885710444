import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import Select from "react-select";
import {useNavigate, useParams} from "react-router-dom";
import TextInput from "../../components/Selectors/TextInput";
import SwitchButton from "../../components/Selectors/SwitchButton";
import Button from "../../components/Selectors/Button";
import {changePwd, editUser, resetPwd} from "../../network/UserManagementApi";
import {makeSelectField} from "../../utils/common";

const EditUser = () => {
  const {id} = useParams();
  const history = useNavigate();
  const users = useSelector((state) => state.data.users);
  const [user, setUser] = useState(users.find((value) => value.id === id));
  useEffect(() => {
    if (user === undefined) {
      history("/settings/users");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [self] = useState(sessionStorage.getItem("email"));

  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const pwdRegEx = new RegExp(
    "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const profiles = useSelector(state => state.data.profiles)
  const profileOption = useCallback(
    () => {
      return makeSelectField(profiles, ["name"])
    },
    [profiles],
  );
  const [profile, setProfile] = useState(
    profileOption().find((value) => value.value === "1")
  );
  const handleEditUser = () => {
    setIsLoading2(true);
    const edit = {};
    edit["id"] = user.id;
    edit["payload"] = {
      firstName: user.firstName,
      lastName: user.lastName,
      attributes: {...user.attributes},
    };
    edit["payload"]["attributes"]["profile_id"] = profile.value;
    editUser(edit, () => {
      setIsLoading2(false);
      history("/settings/users");
    });
  };
  const onChange = (name, value) => {
    setUser({
      ...user,
      [name]: value,
    });
  };
  return (
    <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
      <div className="flex gap-x-1.5">
        <IconButton
          bg={true}
          Icon={<Prev size={40}/>}
          value={""}
          callback={() => history("/settings/users")}
        />
        <h2 className="text-theme1 text-3xl font-semibold">
          Modification d'un utilisateur
        </h2>
      </div>
      <div className={"mt-2"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <TextInput
            name={"email"}
            value={user?.email}
            disabled={true}
            onChange={onChange}
            label={"Adresse email"}
            type={"email"}
          />
          <TextInput
            name={"firstName"}
            value={user?.firstName}
            onChange={onChange}
            label={"Prénom"}
            type={"text"}
          />
          <TextInput
            name={"lastName"}
            value={user?.lastName}
            onChange={onChange}
            label={"Nom"}
            type={"text"}
          />
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Type de profil
            </label>
            <Select
              className={"pt-1"}
              onChange={(e) => setProfile(e)}
              value={profile}
              options={profileOption()}
            />
          </div>
          <SwitchButton
            onChange={() => onChange("enabled", !user?.enabled)}
            value={user?.enabled}
            name={"enabled"}
            label={user?.enabled ? "Activé" : "Désactivé"}
          />
        </div>
      </div>

      {self === user?.email && (
        <div className={"mt-2"}>
          <div className="grid grid-cols-3 gap-x-1.5 w-1/2">
            <div>
              <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
                Nouveau mot de passe
              </label>

              <input
                type={"password"}
                required
                autoComplete={"new-password"}
                pattern={
                  "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
                }
                className={
                  "w-full py-2 rounded-lg outline-none border border-2px invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                }
                name={"pwd"}
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
              />
            </div>
            <div>
              <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
                Validation
              </label>
              <input
                type={"password"}
                required
                autoComplete={"new-password"}
                pattern={
                  "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"
                }
                className={
                  "w-full py-2 rounded-lg outline-none border border-2px invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                }
                name={"pwd"}
                onChange={(e) => setPwd2(e.target.value)}
                value={pwd2}
              />
            </div>
            <div className="mt-6">
              <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide"></label>
              <Button
                label={"Modifier le mot de passe"}
                callback={() => {
                  changePwd({user: user, pwd: pwd});
                }}
                disabled={
                  !pwdRegEx.test(pwd) && !pwdRegEx.test(pwd2) && pwd !== pwd2
                }
              />
            </div>
          </div>
        </div>
      )}
      <div className={"flex justify-end gap-2"}>
        <Button
          label={"Réinitialiser le mot de passe"}
          callback={() => {
            setIsLoading(true);
            resetPwd(
              {
                username: user?.username,
              },
              () => setIsLoading(false)
            );
          }}
          color={"bg-red-700"}
          isLoading={isLoading}
          hover={"hover:bg-red-800"}
          focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
          extraStyle={
            "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
          }
        />
        <Button
          label={"Annuler"}
          callback={() => history("/settings/users")}
          color={"bg-red-700"}
          hover={"hover:bg-red-800"}
          focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
          extraStyle={
            "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
          }
        />
        <Button
          isLoading={isLoading2}
          label={"Modifier"}
          callback={() => handleEditUser()}
          color={"bg-theme1"}
          hover={"hover:bg-theme-3"}
          focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
          extraStyle={
            "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
          }
        />
      </div>
    </div>
  );
};
export default EditUser;
