import TextInput from "../../../components/Selectors/TextInput";
import TimezoneSelect from "react-timezone-select";
import {useDispatch, useSelector} from "react-redux";
import {setDeep, setModuleData} from "../../../reducers/indicatorSlice";
import Select from "react-select";
import {hasSeconds, makeVariableField} from "../../../utils/common";
import {useEffect, useState} from "react";

const DetectionAnomalieParameters = () => {
  const dispatch = useDispatch();
  const sources = useSelector((state) => state.data.crude_source);
  const configurations = useSelector((state) => state.data.configured_source);
  const {type, params} = useSelector((state) => state.indicator.moduleData);
  const data_params = useSelector(
    (state) => state.indicator.moduleData.current.data_params
  );
  const initial_timestamp = useSelector(
    (state) => state.indicator.moduleData.initial_timestamp
  );
  const timezone = useSelector((state) => state.indicator.moduleData.timezone);
  const setThreshold = (name, value) => {
    if (name === "threshold") value = parseFloat(value);
    dispatch(setDeep({type: "params", first: [name], data: value}));
  };

  const [variables, setVariables] = useState([]);
  const [timeVariables, setTimeVariables] = useState([]);
  useEffect(() => {
    const tmp = [];
    const sourceIds = [];
    setVariables([]);
    data_params.forEach((bloc) => {
      const measure_identifier = bloc.measure_identifier;
      sourceIds.push(
        Object.entries(sources).find(
          (value) =>
            measure_identifier ===
            configurations[value[1].configuration[0]].measure_identifier
        )[0]
      );
      Object.keys(bloc.preprocess).forEach((variable) =>
        tmp.push({
          label: variable,
          value: variable,
        })
      );
    });
    // if (!tmp.includes({
    //     value: params.target_variable, label: params.target_variable
    // }))
    setVariables(tmp);
    sourceIds.length !== 0
      ? setTimeVariables(
        makeVariableField(1, sourceIds[0], sources[sourceIds[0]].measure)
      )
      : setTimeVariables([]);
  }, [configurations, data_params, sources]);
  const param = () => {
    switch (type) {
      case 0:
        return (
          <div>
            <div className="grid grid-cols-2 gap-1.5">
              <div>
                <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
                  Variable cible
                </label>
                <Select
                  onChange={(e) => setThreshold("target_variable", e)}
                  value={params.target_variable}
                  options={variables}
                />
              </div>
              <div>
                <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
                  Variable temporel
                </label>
                <Select
                  onChange={(e) => setThreshold("time_field", e)}
                  value={params.time_field}
                  options={timeVariables}
                />
              </div>
              <TextInput
                min={0}
                max={1}
                step={"0.01"}
                label={"Seuil"}
                type={"number"}
                value={params.threshold}
                onChange={setThreshold}
                name={"threshold"}
              />
            </div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
              Date de début
            </label>
            <div className="grid grid-cols-2 gap-1.5">
              <TextInput
                value={initial_timestamp}
                name={"initial_timestamp"}
                onChange={(name, value) => {
                  dispatch(
                    setModuleData({
                      type: name,
                      data: hasSeconds(value) ? value : value + ":00",
                    })
                  );
                }}
                type={"datetime-local"}
                step={"1"}
              />
              <TimezoneSelect
                value={timezone}
                onChange={(e) => {
                  dispatch(
                    setModuleData({
                      type: "timezoneOffset",
                      data: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
                    })
                  );
                  dispatch(
                    setModuleData({
                      type: "timezone",
                      data: e.value,
                    })
                  );
                }}
              />
            </div>
          </div>
        );
      default:
        return <p> TODO</p>;
    }
  };
  return param();
};
export default DetectionAnomalieParameters;
