import {addBlock, setDataParam} from "../../reducers/indicatorSlice";
import {configurationAlerteMode} from "../../utils/field";
import store from "../../store";
import {getPastDate, isEmptyString} from "../../utils/common";

/**
 * Create tooltip
 * @param block
 * @returns {string}
 */
export const getBlockInfo = (block) => {
  let ret = "Timestamp utilisé: " + block.time_field + "<br />";
  Object.keys(block.preprocess).forEach((item) => {
    ret = ret + item + ": " + block.preprocess[item] + "<br />";
  });
  ret = ret + "<br />";
  ret = ret + " Configurations:<br />";
  if (Object.keys(block.configured_sources).length === 0)
    ret = ret + "Toutes les configurations sont sélectionnées <br />";
  Object.keys(block.configured_sources).forEach((item) => {
    ret = ret + block.configured_sources[item] + "<br />";
  });
  return ret;
};
export const formatOffset = (offset) => {
  let hours = Math.trunc(offset);
  let minutes = Math.abs(offset - hours) * 60;
  return (
    (offset >= 0 ? "+" : "-") +
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes
  );
};

export const initModule = (value) => {
  //TODO meilleure gestion de la date initial
  let date = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let offset = formatOffset(-date.getTimezoneOffset() / 60);
  const start_time = getPastDate(5 * 60)
    .toISOString()
    .split(".")[0];
  const end_time = getPastDate(0).toISOString().split(".")[0];
  switch (value) {
    case 0:
      return {
        mode: 1,
        isValid: true,
        name: value,
        isMailing: false,
        timezone: timeZone,
        timezoneOffset: offset,
        initial_timestamp: start_time,
        final_timestamp: end_time,
        parameter: {
          frequency: 10,
          validity_days: "1-5",
          time_slot: "9-18",
        },
        mailing: {
          destinataire: "",
          sujet: "",
          expediteur: "notifications-retd@preditic.com",
        },
        timestamp: "",
        aggregation_mode: 0,
        control_mode_parameter: 0,
        control_mode: {value: "counting", label: "Comptage"},
        delay: 0,
      };
    case 1:
      return {
        mode: 1,
        isValid: true,
        timezone: timeZone,
        timezoneOffset: offset,
        execution_interval: 0,
        initial_timestamp: start_time,
        parameter: {
          frequency: 10,
          validity_days: "1-5",
          time_slot: "9-18",
        },
        name: value,
        dataParam: [],
        type: configurationAlerteMode[1],
        alert_settings: {
          type: {value: "counting", label: "Comptage"},
          value: 1,
          event: undefined,
        },
        params: {}, //depend du type
        historical: {
          time_params: {
            type: "count",
            count: {
              delay: 3,
              length: 5,
            },
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        current: {
          time_params: {
            start_time: "",
            end_time: "",
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
      };
    case 2:
      return {
        isValid: false,
        timezone: timeZone,
        timezoneOffset: offset,
        name: value,
        data_params: [],
        target_variables: [],
        computation_params: {
          formula: "",
          function: {
            //TODO
            name: "",
            variables: {},
          },
        },
        time_params: {
          start_time: start_time,
          end_time: end_time,
          bucket_size: -1,
        },
      };
    case 3:
      return {
        isValid: false,
        timezone: timeZone,
        timezoneOffset: offset,
        name: value,
        time_params: {
          start_time: date,
          end_time: date,
          bucket_size: -1,
        },
        computation_params: {
          formula: "",
          function: {
            //TODO
            name: "",
            variables: {},
          },
        },
        data_params: [],
        formula: "",
        indicator_params: {
          time_indicators: true,
          threshold_label: "",
          state_i: [], //      selected.push("state_i": ["INSUFFISANT"]) quelque chose dans le genre
        },
      };
    case 4: // CONFIGURATION ALERTE
      return {
        parameter: {
          frequency: 10,
          validity_days: "1-5",
          time_slot: "9-18",
        },
        mode: configurationAlerteMode[1],
        type: configurationAlerteMode[1],
        isValid: false,
        timezone: timeZone,
        timezoneOffset: offset,
        initial_timestamp: start_time,
        name: value,
        alert_params: {
          alert_name: "",
          threshold_label: "",
          state_i: [],
          mode: {
            type: {value: "counting", label: "Comptage"},
            value: 10,
          },
          event: "",
        },
        properties_params: {
          observed_system: [],
          configured_source: {
            id: "",
            fields: [],
          },
        },
        historical: {
          time_params: {
            type: "count",
            count: {
              delay: 0,
              length: 0,
            },
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        current: {
          time_params: {
            start_time: start_time,
            end_time: end_time,
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
      };
    case 5:
      return {
        isValid: false,
        timezone: timeZone,
        timezoneOffset: offset,
        name: value,
        time_params: {
          start_time: start_time,
          end_time: end_time,
          bucket_size: -1,
        },
        data_params: [],
        computation_params: {
          formula: "",
          function: {
            //TODO
            name: "",
            variables: {},
          },
        },
      };
    case 6:
      return {
        mode: configurationAlerteMode[1],
        isValid: false,
        timezone: timeZone,
        timezoneOffset: offset,
        name: value,
        historical: {
          time_params: {
            type: "count",
            count: {
              delay: 0,
              length: 0,
            },
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        current: {
          time_params: {
            start_time: start_time,
            end_time: end_time,
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        indicator_params: {
          time_indicators: true,
          threshold_label: "",
          state_i: [], //      selected.push("state_i": ["INSUFFISANT"]) quelque chose dans le genre
        },
      };
    case 7:
      return {
        parameter: {
          frequency: 10,
          validity_days: "1-5",
          time_slot: "9-18",
        },
        mode: configurationAlerteMode[1],
        isValid: false,
        timezone: timeZone,
        offset: offset,
        initial_timestamp: start_time,
        event: undefined,
        name: value,
        historical: {
          time_params: {
            type: "count",
            count: {
              delay: 0,
              length: 0,
            },
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        current: {
          time_params: {
            start_time: start_time,
            end_time: end_time,
            bucket_size: -1,
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              //TODO
              name: "",
              variables: {},
            },
          },
        },
        indicator_params: {
          time_threshold: 600,
          time_indicators: true,
          threshold_label: "",
          state_i: [], //      selected.push("state_i": ["INSUFFISANT"]) quelque chose dans le genre
        },
      };
    case 8:
      return {
        parameter: {
          frequency: 10,
          validity_days: "1-5",
          time_slot: "9-18",
        },
        timezone: timeZone,
        timezoneOffset: offset,
        initial_timestamp: start_time,
        name: value,
        parameters: {
          measure_identifier: null,
          event_id: undefined,
          start_time: "",
          end_time: "",
        },
      };
    default:
      return {};
  }
};

/**
 * Creates the field used in selector with the variable used
 * @param dataparams
 * @returns {*[]}
 */
export const variableField = (dataparams) => {
  const ret = [];
  dataparams.forEach((block) => {
    const preprocess = block["preprocess"];
    Object.keys(preprocess).forEach((key) => {
      const to_push = {label: key, value: key};
      if (
        ret.find((elem) => elem.name === key && elem.value === key) ===
        undefined
      ) {
        ret.push(to_push);
      }
    });
  });
  return ret;
};
/**
 * Creates the field used in selector with the variable used
 * @param dataparams
 * @returns {*[]}
 */
export const variableFieldMulti = (dataparams) => {
  const options = [];
  options.push({
    options: [{label: "Résultat de la formule", value: "computed_field"}],
    label: "",
  });
  dataparams.forEach((block) => {
    const group = [];
    const preprocess = block["preprocess"];
    Object.keys(preprocess).forEach((key) => {
      const to_push = {label: key, value: key}; //TODO gestion si des variables ont le même nom
      if (
        group.find((elem) => elem.label === key && elem.value === key) ===
        undefined
      ) {
        group.push(to_push);
      }
    });
    options.push({options: group, label: block.crude_source});
  });
  return options;
};

export const DetectionAnomalieModuleParam = (type) => {
  switch (type) {
    case 0:
      return {
        target_variable: "",
        time_field: "",
        threshold: 0.95,
      };
    default:
      return {};
  }
};
/**
 * Get Detection Anomalie mode information based on the id passed
 * @param id
 * @returns {{param: string, name: string}|string}
 */
export const getModeInfo = (id) => {
  switch (id) {
    case 1:
      return {
        name: "kalman",
        param: "model_params",
      };
    default:
      return "default";
  }
};

export const getThreshold = (obs) => {
  let threshold_result = [];
  if (!isEmptyString(obs) || obs !== undefined) {
    const state = store.getState();
    const threshold = state.data.threshold;
    threshold_result = threshold.filter(
      (value) => value.observed_system_id === obs
    );
  }
  return threshold_result;
};

/**
 * create a dict used by a selector
 * @param label
 * @returns {{label, value: string}}
 */
export const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export const add = (
  crudeSources,
  selCrudeSource,
  bloc,
  selConfiguration,
  dispatch,
  timeVariable
) => {
  const state = store.getState();
  const configurations = state.data.configured_source;
  const configured_id = crudeSources[selCrudeSource.value].configuration[0];
  const configured_source = configurations[configured_id];
  const measure_identifier = configured_source["measure_identifier"];
  const preprocess = {};
  const timeField = timeVariable.value.replace(
    timeVariable.crude_source_id + "_",
    ""
  );
  Object.keys(bloc.to_add).forEach((value) => {
    if (bloc.to_add[value] === true) {
      preprocess[bloc.data[value].name] = bloc.data[value].value;
    }
  });
  const conf = [];
  selConfiguration.forEach((tmp) => conf.push(tmp.value));
  const data = {
    configured_sources: conf,
    time_field: timeField,
    preprocess: preprocess,
    measure_identifier: measure_identifier,
  };
  dispatch(addBlock(data));
};
export const addTwoBlocs = (
  crudeSources,
  selCrudeSource,
  bloc,
  selConfiguration,
  timeVariable,
  dispatch,
  mode
) => {
  const state = store.getState();
  const configurations = state.data.configured_source;
  const configured_id = crudeSources[selCrudeSource.value].configuration[0];
  const configured_source = configurations[configured_id];
  const measure_identifier = configured_source["measure_identifier"];
  const preprocessHist = {};
  const preprocessCur = {};

  Object.keys(bloc.to_add).forEach((value) => {
    if (bloc.to_add[value] === true) {
      preprocessHist[bloc.hist[value].name] = bloc.hist[value].value;
      preprocessCur[bloc.cur[value].name] = bloc.cur[value].value;
    }
  });
  const conf = [];
  selConfiguration.forEach((tmp) => conf.push(tmp.value));
  let timeField = timeVariable.value.replace(
    timeVariable.crude_source_id + "_",
    ""
  );
  const data = {};
  data["cur"] = {
    configured_sources: conf,
    time_field: timeField,
    preprocess: preprocessCur,
    measure_identifier: measure_identifier,
  };
  if (mode.value === 0) {
    data["hist"] = {
      configured_sources: conf,
      time_field: timeField,
      preprocess: preprocessHist,
      measure_identifier: measure_identifier,
    };
  }
  dispatch(setDataParam(data));
};

export const variableOptions = (id, name, type = 0) => {
  return [
    {
      type: type,
      id: id,
      value: "identity",
      label: "identity",
      name: name,
    },
    {
      type: type,
      id: id,
      value: "min",
      label: "min",
      name: name,
    },
    {
      type: type,
      id: id,
      value: "max",
      label: "max",
      name: name,
    },
    {
      type: type,
      id: id,
      value: "avg",
      label: "moyenne",
      name: name,
    },
  ];
};
