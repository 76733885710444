import ConfigurationAlerteRightPanel from "./ConfigurationAlerteRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const ConfigurationAlerte = () => {
    return (
        <div className="grid grid-cols-2 gap-4 mx-4 py-2">

          <IndicatorDataConfiguration isTwoBloc={true}/>
          <ConfigurationAlerteRightPanel/>
        </div>

    )
}
export default ConfigurationAlerte;