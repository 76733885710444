import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Button from "../../components/Selectors/Button";
import { editCrudeSource } from "../../network/DataManagementApi";
import { useSelector } from "react-redux";
import store from "../../store";
import { edit as editData } from "../../reducers/dataSlice";
import { toast } from "react-toastify";

const CrudeSourceEdit = () => {
  const redirect = "/";
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const crudeSources = useSelector((state) => state.data.crude_source);
  const observedSystemId = useSelector(
    (state) => state.selected.observed_system
  );
  const history = useNavigate();
  const [item, setItem] = useState(crudeSources[id]);
  const onChange = (name, value) => {
    setItem((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
      <div className="flex gap-x-1.5">
        <IconButton
          bg={true}
          Icon={<Prev size={40} />}
          value={""}
          callback={() => history(redirect)}
        />
        <h2 className="text-theme1 text-3xl font-semibold">Source brute</h2>
      </div>
      <div className={"grid grid-cols-2 gap-4  border-b pb-2"}>
        <TextInput
          name={"name"}
          value={item?.name}
          onChange={onChange}
          label={"Nom"}
        />
        <TextInput
          name={"description"}
          value={item?.description}
          onChange={onChange}
          label={"Description"}
        />
      </div>
      <div className={"flex justify-end gap-2 pt-2 "}>
        <Button
          isLoading={isLoading}
          label={"Modifier"}
          callback={() => {
            setIsLoading(true);
            editCrudeSource(
              { name: item.name, description: item.description },
              observedSystemId,
              id
            ).then((res) => {
              store.dispatch(
                editData({ type: "crude_source", updates: item, id: id })
              );
              setIsLoading(false);
              toast.success("La source de données a été modifié avec succès.");
              history(redirect);
            });
          }}
          color={"bg-theme1"}
          hover={"hover:bg-theme-3"}
          focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
          extraStyle={
            "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
          }
        />
      </div>
    </div>
  );
};
export default CrudeSourceEdit;
