import IndicatorAccordion from "../IndicatorAccordion";
import DetectionAnomalieHistorical from "./DetectionAnomalieHistorical";
import DetectionAnomalieCurrent from "./DetectionAnomalieCurrent";
import DetectionAnomalieParameters from "./DetectionAnomalieParameters";
import DetectionAnomalieAlertSetting from "./DetectionAnomalieAlertSetting";
import IndicatorRecurentForm from "../IndicatorRecurentForm";

const DetectionAnomalieRightPanel = () => {


    return <div className="bg-white  pb-2 grid grid-flow-row auto-rows-max h-[80vh] overflow-auto shadow-lg">

        <IndicatorAccordion label={"Programmation d'exécution du module"}
                            composant={<IndicatorRecurentForm/>} open={false}/>
        <IndicatorAccordion label={"Paramètres du bloc courant  "}
                            composant={<DetectionAnomalieCurrent/>}/>

        <IndicatorAccordion label={"Paramètres du bloc historique"}
                            composant={<DetectionAnomalieHistorical/>}/>
        <IndicatorAccordion label={"Paramètres"}
                            composant={<DetectionAnomalieParameters/>}/>
        <IndicatorAccordion label={"Alerte"}
                            composant={<DetectionAnomalieAlertSetting/>}/>
    </div>
}
export default DetectionAnomalieRightPanel;