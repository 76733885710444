import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderAction, isEmptyObject } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableSelector } from "../TableSelector";
import ActionButtons from "../Selectors/ActionButtons";
import { TableFilter } from "./TableFilter";
import {
  deleteObs,
  getAllMiddle,
} from "../../network/DataManagementApi";
import { toast } from "react-toastify";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import DeleteModal from "../Selectors/DeleteModal";
import { DataLoadingToast } from "../Toasts/DataLoadingToast";
import { reset } from "../../reducers/indicatorSlice";
import { set } from "../../reducers/dataSlice";
import { selectObs } from "../../reducers/graphSlice";
import {
  selectedCrudeSource,
  selectedObservedSystem,
} from "../../reducers/SelectedSlice";

const ObservedSystemList = () => {
  const [loadingToast, setLoadingToast] = useState(null);

  const [observedSystemToDelete, setObservedSystemToDelete] =
    useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const [canDelete] = useState(
    canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.OBSERVED_SYSTEM_DISPLAY)
  );
  const [canEdit] = useState(
    canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.OBSERVED_SYSTEM_DISPLAY)
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const storageSystemList = useSelector((state) => state.data.storage_system);
  const observedSystemList = useSelector((state) => state.data.observed_system);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const handleObservedSystemChange = (updater) => {
    if (loadingToast !== null) return;
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const observedSystemId = data[index][0];
    setLoadingToast(DataLoadingToast());
    dispatch(reset());
    dispatch(set({ type: "crude_source", data: {} }));
    dispatch(set({ type: "configured_source", data: {} }));
    dispatch(selectedObservedSystem(observedSystemId));
    dispatch(selectedCrudeSource(""));
    sessionStorage.setItem("observed_system_id", observedSystemId);
    sessionStorage.setItem("observed_system_desc", data[index][1].description);
    sessionStorage.setItem("observed_system_name", data[index][1].name);
    dispatch(
      selectObs({
        value: sessionStorage.getItem("observed_system_id"),
        label:
          sessionStorage.getItem("observed_system_desc") +
          " - " +
          sessionStorage.getItem("observed_system_name"),
      })
    );
    getAllMiddle(observedSystemId, () => {
      toast.dismiss(loadingToast);
      setLoadingToast(null);
    });
    setRowSelection(newSelected);
  };
  useEffect(() => {
    const newData = Object.entries(observedSystemList);
    const index = newData.findIndex(
      (value) => value[0] === sessionStorage.getItem("observed_system_id")
    );
    if (index !== -1) {
      setRowSelection({ [index]: true });
    }
    setData(newData);
  }, [observedSystemList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "select",
      cell: ({ row }) => (
        <div className="px-1">
          <TableSelector
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => row[1].name, {
      id: "name",
      header: "Nom",
      cell: (info) => (
        <p className={"max-w-[100px] truncate w-full whitespace-normal"}>
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor((row) => row[1].description, {
      id: "desc",
      header: "Description",
      cell: (info) => (
        <p className={"truncate w-full whitespace-normal"}>{info.getValue()}</p>
      ),
    }),
    columnHelper.accessor(
      (row) => {
        if (isEmptyObject(storageSystemList)) return "";
        return storageSystemList[row[1].storage_system_id].name;
      },
      {
        id: "storage_name",
        header: "Stockage",
        cell: (info) => (
          <p className={"max-w-[100px] truncate w-full whitespace-normal"}>
            {info.getValue()}
          </p>
        ),
      }
    ),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/observed_system/edit/" + row[0]);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setObservedSystemToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleObservedSystemChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {observedSystemToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            deleteObs(observedSystemToDelete[0], () => {
              setIsDeleting(false);
              setObservedSystemToDelete(undefined);
            });
          }}
          loading={isDeleting}
          text={
            "Êtes vous sûr de vouloir supprimer la structure " +
            observedSystemToDelete[1].name +
            " ?"
          }
          modalVisible={observedSystemToDelete}
          setModalVisible={setObservedSystemToDelete}
        />
      )}
    </div>
  );
};
export default ObservedSystemList;
