import { useId } from "react";

const NumericInput = ({ name, label, value, step = 1, min, max, onChange }) => {
  const id = useId();
  return (
    <div>
      <label htmlFor={id} className="block mb-1 text-sm font-medium text-gray-900">
        {label}
      </label>
      <input
        onChange={(event) => onChange(event.target.name, parseInt(event.target.value))}
        name={name}
        step={step}
        min={min}
        max={max}
        type="number"
        id={id}
        value={value}
        className="bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
        placeholder=""
        required
      />
    </div>
  );
};
export default NumericInput;
