//React librairies
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import {useSelector} from "react-redux";

//Components
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ToastWrapper from "./components/Toasts/ToastWrapper";

//Routing
import Routing from "./routing/Routing";

import { getMethod, getPages, getProfile } from "./network/UserManagementApi";

import { isEmptyString } from "./utils/common";
import { usePolling } from "./utils/Polling";
import {
  getAll,
  getModule,
  getStructureAll,
} from "./network/DataManagementApi";

const App = () => {
  //Use selector
  const isAuthenticated = useSelector(
    (state) => state?.authentication?.isAuthenticated
  );
  usePolling(async () => {
    getAll();
  }, 1000 * process.env.REACT_APP_POLLING);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (sessionStorage.getItem("user_profil") === "1") {
      getStructureAll();
    }
    if (isEmptyString(sessionStorage.getItem("structure_id"))) {
      return;
    }

    if (isAuthenticated) {
      getAll(() => {});
      // getEvent();
      getModule();
      // getUser();
      // getThreshold();
      getProfile();
      getPages("all");
      // getTask();
      getMethod();
    }
  }, [isAuthenticated]);
  return (
    <BrowserRouter basename={"/"}>
      <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-100 text-black">
        {isAuthenticated && <Header />}
        {isAuthenticated && <Sidebar />}

        <ToastWrapper />

        <div className={isAuthenticated ? "h-full ml-14 mt-14 md:ml-64" : ""}>
          <Routing />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
