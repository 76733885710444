import React, { useState } from "react";
import { changePwd } from "../network/UserManagementApi";
import { PrimaryButton, SecondaryButton } from "./Buttons/Button";
import NumericInput from "./Inputs/NumericInput";

const PreferenceSection = ({ preference, onSave, isLoading }) => {
  const [data, setData] = useState(preference);
  const handleNumericChange = (name, value) => {
    const data2 = data.map((pref) => (pref.name === name ? { ...pref, value: value } : pref));
    setData(data2);
  };
  return (
    <div>
      {data.map((pref) => (
        <div className={"w-full flex justify-center"}>
          <div className={"w-full"}>
            {pref.type === "number" ? (
              <NumericInput step={5} min={0} max={50} onChange={handleNumericChange} label={"Page(s) par table"} name={pref.name} value={pref.value} />
            ) : (
              "text"
            )}
          </div>
        </div>
      ))}
      <div className="border-t border-neutral-400 my-3 flex font-normal justify-center ">{/*<PasswordHandler />*/}</div>
      <div className={"col-start-2"}>
        <div className={"flex justify-end"}>
          <div>
            <PrimaryButton disabled={isLoading} isLoading={isLoading} label={"Sauvegarder"} callback={() => onSave(data)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferenceSection;

const PasswordHandler = () => {
  const [pwd, setPwd] = useState("");
  const [pwd2, setPwd2] = useState("");
  const pwdRegEx = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
  return (
    <div className={"block mt-2"}>
      <div>
        <p className={"text-lg inline-block align-baseline"}>Mot de passe</p>
        <div className="flex gap-x-1.5 w-full ">
          <div className={"w-full"}>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">Nouveau</label>
            <input autoComplete={"disabled"} type="password" id="pass" name="p" minLength="8" required />
          </div>
          <div className={"w-full "}>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">Validation</label>
            <input
              type={"text"}
              required
              autoComplete={"disabled"}
              pattern={"^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$"}
              className={
                "w-full py-2 rounded-lg outline-none border border-2px invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
              }
              name={"pwd"}
              onChange={(e) => setPwd2(e.target.value)}
              value={pwd2}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-center gap-x-0.5">
          <PrimaryButton
            label={"Modifier"}
            callback={() => {
              changePwd(pwd);
            }}
            disabled={!pwdRegEx.test(pwd) || !pwdRegEx.test(pwd2) || pwd !== pwd2}
          />
          <div>
            <SecondaryButton
              color={"bg-red-600"}
              hover={"hover:bg-red-700"}
              focus={"focus:outline-none focus:ring-2 focus:ring-red-400"}
              label={"Réinitialiser"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
