import {useEffect, useState} from "react";
import DataForm from "../../components/DataForm/DataForm";
import TextInput from "../../components/Selectors/TextInput";
import {canRenderComponent, isEmptyString} from "../../utils/common";
import {storageField} from "../../utils/field";

import Select from "react-select";
import {addInstance} from "../../network/DataManagementApi";
import {COMPONENTS} from "../../constants/component";
import {useSelector} from "react-redux";

const Instance = () => {
  const [authorized, setAuthorized] = useState(
    canRenderComponent(COMPONENTS.INSTANCE_CREATION)
  );
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  useEffect(() => {
    if (!authorized)
      return () => {
        setAuthorized(canRenderComponent(COMPONENTS.INSTANCE_CREATION));
      };
  }, [authorizedComponent]);

  const [storageSystem, setStorageSystem] = useState(-1);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const handleInstance = () => {
    setLoading(true);
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      name: name,
      description: description,
      storage_type: storageSystem,
    };
    addInstance(data, () => setLoading(false));
  };
  const field = [
    {
      id: 1,
      name: "Stockage",
      type: (
        <Select
          className="py-0.5"
          options={storageField}
          onChange={(e, v) => setStorageSystem(e.value)}
          name="storage_type"
        />
      ),
      tooltip: false,
    },
    {
      id: 2,
      name: "nom",
      type: (
        <TextInput
          placeholder={"Nom de l'instance - système de stockage"}
          name={"name"}
          value={name}
          onChange={(e, v) => setName(v)}
        />
      ),
      tooltip: false,
    },
    {
      id: 3,
      name: "Description",
      type: (
        <TextInput
          placeholder={"Description de l'instance"}
          name={"description"}
          value={description}
          onChange={(e, v) => setDescription(v)}
        />
      ),
      tooltip: false,
    },
  ];
  const canAdd =
    storageSystem !== -1 && !isEmptyString(name) && !isEmptyString(description);
  return (
    authorized && (
      <DataForm
        component_id={COMPONENTS.INSTANCE_CREATION}
        isLoading={loading}
        disabled={!canAdd}
        information={"Instance"}
        description="Création d'une nouvelle instance."
        field={field}
        callback={handleInstance}
      />
    )
  );
};
export default Instance;
