import {
  deleteRequest,
  getMultiple,
  getRequest,
  postRequest,
  putRequest,
  uploadFile,
} from "../axios/axios";
import {
  edit as editAdmin,
  remove as removeAdmin,
  set as setAdmin,
} from "../reducers/AdminSlice";
import store from "../store";
import {
  add,
  add as addData,
  edit as editData,
  push,
  remove as removeData,
  set,
} from "../reducers/dataSlice";
import { toast } from "react-toastify";
import { DataLoadingToast } from "../components/Toasts/DataLoadingToast";

const makeUrl = (url) => {
  const { REACT_APP_API } = process.env;
  return REACT_APP_API + url;
};
export const getStructureAll = (callback = () => {}) => {
  getRequest(makeUrl("/structure")).then((res) => {
    store.dispatch(setAdmin({ type: "structures", data: res.data }));
    callback();
  });
};
export const addStructure = (data, callback) => {
  postRequest(makeUrl("/structure"), data).then((res) => {
    if (res.status === 200) {
      toast.success("Structure créée");
      callback(res);
    } else toast.error("Structure non créée - " + res.data.error);
  });
};
export const getEvent = () => {
  return getRequest(makeUrl("event/" + sessionStorage.getItem("structure_id")));
};

export const getModule = () => {
  getRequest(
    makeUrl("module?structure_id=" + sessionStorage.getItem("structure_id"))
  ).then((res) => {
    store.dispatch(
      set({
        type: "moduleName",
        data: res.data.data,
      })
    );
  });
};

export const getThreshold = (callback) => {
  const threshold = {
    formula: {},
    description: "",
    label: "",
    function: {},
    creation_timestamp: "",
    observed_system_id: "",
  };
  getRequest(
    makeUrl("threshold?structure_id=" + sessionStorage.getItem("structure_id"))
  )
    .then((res) => {
      const thresholdList = [];
      const data = res.data.data;
      if (data.label !== undefined)
        for (let i = 0; i < data.label.length; i++) {
          const tmp = Object.create(threshold);
          tmp.label = data.label[i];
          tmp.description = data.description[i];
          tmp.function = data.function[i];
          tmp.formula = data.formula[i];
          tmp.creation_timestamp = data.creation_timestamp[i];
          tmp.observed_system_id = data.observed_system[i];
          thresholdList.push(tmp);
        }
      store.dispatch(
        set({
          type: "threshold",
          data: thresholdList,
        })
      );
      callback();
    })
    .catch(() => callback());
};
/**
 * Get the structure, observed system and storage system information
 * If an observed system is already selected, also get the crude  and configured source information
 * @param {Function} callback
 */
export const getAll = (callback = () => {}) => {
  const init_time = Date.now();
  const toast_id = DataLoadingToast();
  const structure_id = sessionStorage.getItem("structure_id");
  const storage_system_url = makeUrl(
    "storage_system?structure_id=" + structure_id
  );
  const observed_system_url = makeUrl(
    "observed_system?structure_id=" + structure_id
  );
  const structure_url = makeUrl("structure?structure_id=" + structure_id);
  const urls = [structure_url, storage_system_url, observed_system_url];
  getMultiple(urls).then((res) => {
    const res_structure = res[0].data;
    const res_storage_system = res[1].data;
    const res_observed_system = res[2].data;
    store.dispatch(
      set({
        type: "structure",
        data: res_structure.data,
      })
    );

    store.dispatch(
      set({
        type: "observed_system",
        data: res_observed_system.data,
      })
    );
    store.dispatch(
      set({
        type: "storage_system",
        data: res_storage_system.data,
      })
    );
    sessionStorage.setItem(
      "dataLoadingTime",
      (Date.now() - init_time).toString()
    );
    if (sessionStorage.getItem("observed_system_id")) {
      getAllMiddle(sessionStorage.getItem("observed_system_id"), () => {
        toast.dismiss(toast_id);
      });
    }
    callback();
    toast.dismiss(toast_id);
  });
};
/**
 *
 * @param {string} observed_system_id
 * @param {Function} callback
 */
export const getAllMiddle = (observed_system_id, callback) => {
  const init_time = Date.now();
  const crude_source_url = makeUrl(
    "crude_source?observed_system_id=" + observed_system_id
  );
  const configured_source_url = makeUrl(
    "configured_source?observed_system_id=" + observed_system_id
  );
  const urls = [crude_source_url, configured_source_url];
  getMultiple(urls).then((res) => {
    store.dispatch(set({ type: "crude_source", data: res[0]["data"]["data"] }));
    store.dispatch(
      set({
        type: "configured_source",
        data: res[1]["data"]["data"],
      })
    );
    sessionStorage.setItem(
      "dataSourceLoadingTime",
      (Date.now() - init_time).toString()
    );
    callback();
  });
};

/**
 *
 * @param {string} obs_id
 * @param {string} crude_source_id
 * @param {Function} callback
 */
export const getSourceParam = (obs_id, crude_source_id, callback) => {
  getRequest(
    makeUrl(
      "/source_parameters?observed_system_id=" +
        obs_id +
        "&crude_source_id=" +
        crude_source_id
    )
  ).then((res) => {
    if (res.status === 200) callback(res);
  });
};
/**
 *
 * @param {string} obs_id
 * @param {Object} data
 * @param {Function} callback
 */
export const addConfiguredSource = (obs_id, data, callback) => {
  postRequest(
    makeUrl("configured_source?observed_system_id=" + obs_id),
    data
  ).then((res) => {
    callback(res);
  });
};

/**
 *
 * @param {string} observed_system_id
 * @param {Object} data
 * @param {Function} callback
 */
export const addSourceParam = (observed_system_id, data, callback) => {
  postRequest(
    makeUrl("source_parameters?observed_system_id=" + observed_system_id),
    data
  ).then((res) => {
    callback(res);
  });
};
/**
 *
 * @param {string} id
 * @param {Function} callback
 */
export const deleteStructure = (id, callback) => {
  deleteRequest(makeUrl("structure?structure_id=" + id)).then((res) => {
    store.dispatch(removeAdmin({ type: "REMOVE_STRUCTURE", id: id }));
    toast.success("La structure a été supprimé");
    callback(res);
  });
};
export const deleteObs = (id, callback) => {
  deleteRequest(makeUrl("observed_system?observed_system_id=" + id)).then(
    (res) => {
      store.dispatch(removeData({ type: "observed_system", index: id }));
      toast.success("Le système observé a été supprimé");
      callback(res);
    }
  );
};
export const deleteCrudeSource = (id, callback, obs) => {
  deleteRequest(
    makeUrl("crude_source?observed_system_id=" + obs + "&crude_source_id=" + id)
  ).then((res) => {
    store.dispatch(removeData({ type: "crude_source", index: id }));
    toast.success("La source de donnée a été supprimé");
    callback(res);
  });
};

export const addObservedSystem = (data, callback) => {
  postRequest(
    makeUrl(
      "observed_system?structure_id=" + sessionStorage.getItem("structure_id")
    ),
    data
  ).then((res) => {
    if (res.status === 200) {
      store.dispatch(
        add({
          data: {
            name: data.name,
            description: data.description,
            storage_system_id: data.storage_system_id,
          },
          type: "observed_system",
          index: res.data["id"],
        })
      );
      callback(res);
      toast.success("Le système observé " + data.name + " a été créé");
    }
  });
};
export const addDataSource = (data, callback) => {
  postRequest(makeUrl("/configuration/data"), data)
    .then((res) => {
      switch (res.data.status) {
        case "error":
          toast.error(res.data.message.toString());
          callback();
          break;
        case "success":
          toast.success(res.data.message.toString());
          callback();
          break;
        default:
          break;
      }
    })
    .catch(() => toast.error("Erreur."));
};
export const addInstance = (data, callback) => {
  postRequest(
    makeUrl(
      "storage_system?structure_id=" + sessionStorage.getItem("structure_id")
    ),
    data
  )
    .then((res) => {
      if (res.status === 200) {
        const index = res.data["id"];
        toast.success("Système de stockage créé");
        store.dispatch(
          addData({
            data: res.data.data[index],
            type: "storage_system",
            index: index,
          })
        );
        callback(res);
      }
    })
    .catch(() => {
      callback();
      toast.error("Système de stockage non créé.");
    });
};

export const addEvent = (data, callback) => {
  postRequest(
    makeUrl("/event/" + sessionStorage.getItem("structure_id")),
    data
  ).then(() => {
    data["creation_timestamp"] = "N/A";
    store.dispatch(add({ type: "event", data: data }));
    callback();
  });
};
export const addThreshold = (data, obs, callback) => {
  postRequest(makeUrl("threshold?observed_system_id=" + obs), data).then(() => {
    store.dispatch(
      push({
        type: "threshold",
        data: {
          label: data.label,
          observed_system_id: obs,
          description: data.description,
          formula: data.formula,
          function: {},
          creation_timestamp: new Date(Date.now()).toLocaleString(),
        },
      })
    );
    callback();
  });
};

export const editElement = (data, object_type, object_id, callback) => {
  const obs_id = sessionStorage.getItem("observed_system_id");

  if (obs_id === undefined && object_type === "crude_source") {
    toast.warn("Veuillez selectionner un système observé");
    return;
  }
  switch (object_type) {
    case "structure":
      putRequest(makeUrl("structure?structure_i=" + object_id), data).then(
        (res) => {
          store.dispatch(
            editAdmin({ type: "EDIT_STRUCTURE", updates: data, id: object_id })
          );
          toast.success("La structure a été modifié avec succès.");
          callback(res);
        }
      );
      break;
    case "observed_system":
      putRequest(
        makeUrl("observed_system?observed_system_id=" + object_id),
        data
      ).then((res) => {
        store.dispatch(
          editData({ type: object_type, updates: data, id: object_id })
        );
        toast.success("Le système observé a été modifié avec succès.");
        callback(res);
      });
      break;
    case "crude_source":
      putRequest(
        makeUrl(
          "crude_source?observed_system_id=" +
            obs_id +
            "&crude_source_id=" +
            object_id
        ),
        data
      ).then((res) => {
        store.dispatch(
          editData({ type: object_type, updates: data, id: object_id })
        );
        toast.success("La source de données a été modifié avec succès.");
        callback(res);
      });
      break;
    case "storage_system":
      putRequest(
        makeUrl(
          "storage_system?instance_id=" +
            object_id +
            "&structure_id=" +
            sessionStorage.getItem("structure_id")
        ),
        data
      ).then((res) => {
        store.dispatch(
          editData({ type: object_type, updates: data, id: object_id })
        );
        toast.success("L'instance a été modifié avec succès.");
        callback(res);
      });
      break;
    case "configured_source":
      putRequest(
        makeUrl(
          "configured_source?observed_system_id=" +
            obs_id +
            "&configured_source_id=" +
            object_id
        ),
        data
      ).then((res) => {
        store.dispatch(
          editData({ type: object_type, updates: data, id: object_id })
        );
        toast.success("La source configurée a été modifié avec succès.");
        callback(res);
      });
      break;
    case "profile":
      const { REACT_APP_KEYCLOCK_HOST } = process.env;
      putRequest(
        REACT_APP_KEYCLOCK_HOST + "profile?profile_id=" + object_id,
        data
      ).then((res) => {
        toast.success("Le profile a été modifié avec succès.");
        callback(res);
      });
      break;
    default:
      toast.error("Objet non reconnu.");
      callback();
  }
};

export const fileUpload = (obs, file, data, callback) => {
  uploadFile(makeUrl("upload?observed_system_id=" + obs), file, data).then(
    (res) => {
      toast.success("Source de données créée");
      callback(res);
    }
  );
};
export const fileUploadData = (
  observed_system_id,
  configured_source_id,
  measure_identifier,
  file,
  data
) => {
  return uploadFile(
    makeUrl(
      "upload?configured_source_id=" +
        configured_source_id +
        "&observed_system_id=" +
        observed_system_id +
        "&measure_identifier=" +
        measure_identifier
    ),
    file,
    data
  );
};
export const getActionLog = () => {
  const url = makeUrl("log/" + sessionStorage.getItem("structure_id"));
  return getRequest(url);
};

export const editStructure = (data, structure_id) => {
  return putRequest(makeUrl("structure?structure_id=" + structure_id), data);
};
export const editCrudeSource = (data, observed_system_id, crude_source_id) => {
  return putRequest(
    makeUrl(
      "crude_source?observed_system_id=" +
        observed_system_id +
        "&crude_source_id=" +
        crude_source_id
    ),
    data
  );
};

export const editObservedSystem = (data, observed_system_id) => {
  return putRequest(
    makeUrl("observed_system?observed_system_id=" + observed_system_id),
    data
  );
};

export const editStorageSystem = (data, storage_system_id) => {
  return putRequest(
    makeUrl("storage_system?instance_id=" + storage_system_id),
    data
  );
};

export const editConfiguredSource = (
  data,
  observed_system_id,
  configured_source_id
) => {
  return putRequest(
    makeUrl(
      "configured_source?observed_system_id=" +
        observed_system_id +
        "&configured_source_id=" +
        configured_source_id
    ),
    data
  );
};

export const createIndicator = (data, observed_system_id, module) => {
  return putRequest(
    makeUrl("/configuration/" + module + "/" + observed_system_id),
    data
  );
};
