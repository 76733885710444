import IndicatorAccordion from "../IndicatorAccordion";
import FormuleAnalytiqueFormula from "../FormuleAnalytique/FormuleAnalytiqueFormula";
import CorrelationParam from "./CorrelationParam";

const CorrelationRightPanel = () => {
    return <div
      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"Variable à calculer"}
                            composant={<FormuleAnalytiqueFormula/>}/>
        <IndicatorAccordion label={"Paramètre"}
                            composant={<CorrelationParam/>}/>
    </div>
}
export default CorrelationRightPanel;