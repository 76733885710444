//React librairies
import React from "react";

const TextInput = ({
                     color = "dark:bg-gray-900",
                     disabled = false,
                     label = "",
                     name,
                     type = "text",
                     onChange,
                     step = "any",
                     placeholder,
                     extraStyle = "w-full py-1.5 rounded outline-none border border-2px",
                     invalid = "invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500",
                     value,
                     max,
                     min,
                     required = false,
                   }) => {
  return (
    <div>
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        {label}
      </label>
      <input
        step={step}
        max={max}
        min={min}
        type={type}
        disabled={disabled}
        required={required}
        className={`${color} ${extraStyle} ${invalid} `}
        placeholder={placeholder}
        name={name}
        onChange={(e) => {
          onChange(e.target.name, e.target.value, e.target.checkValidity());
        }}
        value={value}
      />
    </div>
  );
};

export default TextInput;
