import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { useState } from "react";
import Select from "react-select";

const FileUploadList = ({
  data,
  header,
  onFileChange,
  columnMap,
  setColumnMap,
}) => {
  console.debug(data);
  console.debug(header);
  console.debug(columnMap);
  const options = header.map((value) => {
    return { value: value, label: value };
  });
  console.debug(options);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[0], {
      id: "header",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].type, {
      id: "type",
      header: "Type",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "target",
      header: "Colonne cible",
      cell: (info) => {
        const row = info.row.original;
        console.debug(row);
        return (
          <Select
            value={options.find((value) => value.value === columnMap[row[0]])}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            options={options}
            onChange={(newValue) =>
              setColumnMap({ ...columnMap, [row[0]]: newValue.value })
            }
          />
        );
      },
    }),
  ];
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3"></div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3 w-1/6"></div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <input
              onChange={onFileChange}
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              type="file"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default FileUploadList;
