//React librairies
import React from "react";
import { Route, Routes } from "react-router-dom";
import ConfiguredSource from "../pages/configuration/ConfiguredSource";
import ObservedSystem from "../pages/add/ObservedSystem";
//Pages
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import AdminProfile from "../pages/admin/Profile";
//Routing
import PrivateRoute from "./PrivateRoute";
import Data from "../pages/configuration/Data";
import Instance from "../pages/configuration/Instance";
import MultiFormFile from "../pages/multi/MultistepFormCSV";
import IndicatorCreator from "../pages/indicator/IndicatorCreator";
import Graph from "../pages/graph/Graph";
import GraphParam from "../pages/graph/GraphParam";
import AdminUser from "../pages/admin/User";
import UserEdit from "../pages/admin/UserEdit";
import User from "../pages/settings/User";
import CreateUser from "../pages/settings/createUser";
import AdminCreateUser from "../pages/admin/createUser";
import EditUser from "../pages/settings/UserEdit";
import Event from "../pages/event/Event";
import EventCreator from "../pages/event/EventCreator";
import RegressionModelCreator from "../pages/RegressionModel/RegressionModelCreator";
import EventCreator2 from "../pages/event/EventCreator2";
import SystemAndSource from "../pages/configuration/SystemAndSource";
import Threshold from "../pages/threshold/Threshold";
import ThresholdCreator from "../pages/threshold/ThresholdCreator";
import Indicator from "../pages/indicator/Indicator";
import IndicatorView from "../pages/indicator/IndicatorView";
import Structure from "../pages/configuration/Structure";
import IndicatorCompute from "../pages/indicator/IndicatorCompute";
import Structures from "../pages/admin/Structures";
import SystemAndFileUpload from "../pages/configuration/SystemAndFileUpload";
import Log from "../pages/admin/Log";
import AdminCreateProfile from "../pages/admin/createProfile";
import ProfilePage from "../pages/ProfilePage";
import StructureEdit from "../components/edit/StructureEdit";
import CrudeSourceEdit from "../components/edit/CrudeSourceEdit";
import ProfileEdit from "../components/edit/ProfileEdit";
import ObservedSystemEdit from "../components/edit/ObservedSystemEdit";
import StorageSystemEdit from "../components/edit/StorageSystemEdit";
import ConfiguredSourceEdit from "../components/edit/ConfiguredSourceEdit";
import FileBatchUpload from "../pages/configuration/FileBatchUpload";
//
const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="analytics">
          <Route
            path="/analytics/indicator/create"
            element={<IndicatorCreator />}
          />
          <Route
            path="/analytics/indicator/compute"
            element={<IndicatorCompute />}
          />
          <Route path="/analytics/indicator" element={<Indicator />} />
          <Route
            path="/analytics/indicator/view/:id_indic"
            element={<IndicatorView />}
          />

          <Route path="/analytics/graph/view" element={<Graph />} />
          <Route path="/analytics/graphOptions" element={<GraphParam />} />

          <Route path="/analytics/event" element={<Event />} />
          <Route path="/analytics/event/create" element={<EventCreator />} />
          <Route path="/analytics/event/create2" element={<EventCreator2 />} />

          <Route path="/analytics/model" element={<RegressionModelCreator />} />

          <Route path="/analytics/threshold" element={<Threshold />} />
          <Route
            path="/analytics/threshold/create"
            element={<ThresholdCreator />}
          />
        </Route>
        <Route path={"/crude_source/edit/:id"} element={<CrudeSourceEdit />} />
        <Route
          path={"/observed_system/edit/:id"}
          element={<ObservedSystemEdit />}
        />
        <Route
          path={"/storage_system/edit/:id"}
          element={<StorageSystemEdit />}
        />
        <Route
          path={"/configured_source/edit/:id"}
          element={<ConfiguredSourceEdit />}
        />
        <Route
          path={"/configured_source/upload/:id"}
          element={<FileBatchUpload />}
        />

        <Route path="settings">
          <Route path="/settings/users" element={<User />} />
          <Route path="/settings/users/create" element={<CreateUser />} />
          <Route path="/settings/users/edit/:id" element={<EditUser />} />
          <Route path="/settings/profile/:id" element={<EditUser />} />
        </Route>

        <Route path="configuration">
          <Route path="/configuration/instance" element={<Instance />} />
          <Route path="/configuration/system" element={<ObservedSystem />} />
          <Route path="/configuration/create" element={<SystemAndSource />} />
          <Route path="/configuration/data" element={<Data />} />
          <Route
            path="/configuration/configured"
            element={<ConfiguredSource />}
          />
          <Route path="/configuration/upload" element={<MultiFormFile />} />
          <Route
            path="/configuration/uploadv2"
            element={<SystemAndFileUpload />}
          />
        </Route>

        <Route path="internal">
          <Route path="/internal/structure" element={<Structures />} />
          <Route path="/internal/users" element={<AdminUser />} />
          <Route path="/internal/users/edit/:id" element={<UserEdit />} />
          <Route path="/internal/users/create" element={<AdminCreateUser />} />
          <Route path="/internal/structure/create" element={<Structure />} />
          <Route
            path="/internal/structure/edit/:id"
            element={<StructureEdit />}
          />

          <Route path="/internal/log" element={<Log />} />
          <Route path="/internal/profile" element={<AdminProfile />} />
          <Route path="/internal/profile/edit/:id" element={<ProfileEdit />} />

          <Route
            path="/internal/profile/create"
            element={<AdminCreateProfile />}
          />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

export default Routing;
