import IndicatorRecap from "./IndicatorRecap";
import IndicatorHeader from "./IndicatorHeader";
import IndicatorBody from "./IndicatorBody";
import {initModule} from "./utils";

import {useDispatch, useSelector} from "react-redux";
import {set} from "../../reducers/indicatorSlice";
import {indicatorField} from "../../utils/field";


const IndicatorCreator = () => {
  const dispatch = useDispatch()
  const module = useSelector(state => state.indicator.moduleData)
  return (<>
    <IndicatorHeader field={indicatorField} title={"Création d'un indicateur"} set={(e) => {
      dispatch(set(initModule(e.value)))
    }}/>
    <IndicatorBody id={module.name}/>
    <IndicatorRecap label={"Créer"}/>
  </>);
};
export default IndicatorCreator;
