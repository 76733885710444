import {flexRender} from "@tanstack/react-table";

export function TableBodySelectable({table, callback, selected}) {
  return (
    <tbody className="bg-white max-h-96 overflow-visible">
    {table.getRowModel().rows.map((row) => {
      let className =
        "px-2 py-2 border overflow-visible dark:bg-gray-900 text-justify ";
      if (selected === row.original[0])
        className =
          "px-2 py-2 border overflow-visible dark:bg-gray-900 text-justify text-theme2";
      return (
        <tr className={"odd:bg-gray-50 cursor-pointer"} key={row.id}>
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                onClick={() => {
                  if (cell.column.columnDef.clickable) callback(row);
                }}
                className={className}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    })}
    </tbody>
  );
}
