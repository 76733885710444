import {InformationCircleIcon as Info} from "@heroicons/react/24/outline";
import {Tooltip} from "react-tooltip";
import {useId} from "react";

const BasicTooltip = ({tip}) => {
    const id = useId();
    return (
      <div>
        <Tooltip className=" p-0 z-40 bg-theme1 opacity-100" id={id}>
          <div className={"bg-theme1 p-2 shadow rounded font-semibold"}>
            {tip}
          </div>
        </Tooltip>
        <Info data-tooltip-id={id} className=" hover:text-theme1 h-5 w-5" />
      </div>
    );
};
export default BasicTooltip;
