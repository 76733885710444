import { Tab } from "@headlessui/react";

/**
 *
 * @param {string[]}  headers
 * @returns {Tab[]}
 * @constructor
 */
const TabHeader = ({ headers }) => {
  return headers.map((value) => {
    return (
      <Tab
        className={
          "mx-1.5 rounded-lg py-2.5  text-sm font-medium leading-5 ui-selected:font-bold  ui-selected:bg-primary-theme-800 bg-primary-theme-900"
        }
      >
        {value}
      </Tab>
    );
  });
};
export default TabHeader;
