//React librairies
import React from "react";

import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
//Component
import {logOutUser} from "../../network/UserManagementApi";
import {
  ArrowRightOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const Profile = ({close, observedSystem}) => {
  const history = useNavigate();
  const structure = useSelector((state) => state.data.structure);
  const profiles = useSelector((state) => state.data.profiles);

  const profile = {
    first_name: sessionStorage.getItem("first_name"),
    last_name: sessionStorage.getItem("last_name"),
    email: sessionStorage.getItem("email"),
    profile_id:
      profiles[parseInt(sessionStorage.getItem("user_profil"))]?.name ?? "N/A",
  };
  return (
    <div className={"p-2"}>
      <span className="font-bold text-md lg:text-xl flex">
        {profile.first_name} {profile.last_name}
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold"}>Structure:</h1>
        <p className={"truncate hover:overflow-visible"}>{structure.name}</p>
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold"}>SO:</h1>
        <p className={"truncate hover:overflow-visible"}>{observedSystem}</p>
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold "}>Role:</h1>
        <p className={"truncate hover:overflow-visible"}>
          {profile.profile_id}
        </p>
      </span>
      <div className={"p-1"}>
        <button
          onClick={() => {
            history("/profile");
            close();
          }}
          className={
            "group flex items-center rounded-md p-2 w-full text-sm hover:bg-primary-theme-100"
          }
        >
          <UserIcon className={"h-5 w-5"} />
          <span className="ml-2">{"Voir profil"}</span>
        </button>
        <button
          onClick={() =>
            logOutUser(() => {
              history("/settings/profile");
            })
          }
          className={
            "group flex items-center rounded-md p-2 w-full text-sm hover:bg-primary-theme-100"
          }
        >
          <ArrowRightOnRectangleIcon className={"h-5 w-5"} />
          <span className="ml-2">{"Se déconnecter"}</span>
        </button>
      </div>
    </div>
  );
};

export default Profile;
