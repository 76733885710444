import {useState} from "react";
import StorageModal from "../Modals/StorageModal";

import CreatableSelect from "react-select/creatable";

const SelectAdd = ({
                     value,
                     callback,
                     selectField,
                     disabled = false,
                     loading = false,
                   }) => {
  const [storageModal, setStorageModal] = useState("");
  return (
    <>
      <StorageModal isOpen={storageModal} setIsOpen={setStorageModal} initName={storageModal}/>
      <CreatableSelect
        className="py-0.5"
        options={selectField}
        name="storage_system_id"
        isLoading={loading}
        isDisabled={disabled}
        value={value}
        onChange={(option, action) => {
          if (action.action === "create-option")
            setStorageModal(action.option.value);
          else callback(action.name, option.value);
        }}
      />
    </>
  );
};
export default SelectAdd;
