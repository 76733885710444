import {useDispatch, useSelector} from "react-redux";
import {setDeep} from "../../reducers/indicatorSlice";
import TextInput from "../../components/Selectors/TextInput";

import Select from "react-select";

const IndicatorMailingForm = () => {
  const mailing = useSelector((state) => state.indicator.moduleData.mailing);
  const users = useSelector((state) => state.data.users);
  console.debug(users)
  const dispatch = useDispatch();
  const onMailingChange = (key, value) => {
    dispatch(setDeep({type: "mailing", first: key, data: value}));
  };
  const usersSelector = () => {
    return users.map(value => {
      return {value: value.email, label: value.email}
    })
  }
  const userOptions = usersSelector()
  return (
    <>
      <TextInput
        name="expediteur"
        onChange={onMailingChange}
        placeholder="Adresse mail de l'expediteur"
        type="email"
        value={mailing["expediteur"]}
        disabled={true}
        label={"Expediteur"}
      />
      <div>
        <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">
          Destinataires
        </label>
        <Select
          placeholder={"Destinataires..."}
          options={userOptions}
          isClearable={true}
          isMulti={true}
          value={mailing["destinataire"]}
          onChange={(options) => onMailingChange("destinataire", options)}
        />
      </div>
      <TextInput
        name="sujet"
        value={mailing["sujet"]}
        onChange={onMailingChange}
        placeholder="Sujet du mail"
        label={"Sujet du mail"}
      />
    </>
  );
};
export default IndicatorMailingForm;
