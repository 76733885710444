import uniqueId from "lodash.uniqueid";
import {verifyInput} from "../../utils/verifyInput";
import Modal from "./Modal";
import {useMemo} from "react";
import TextInput from "../Selectors/TextInput";
import Select from "react-select";
import {mappingField} from "../../utils/field";
import {isEmptyString} from "../../utils/common";
import Button from "../Selectors/Button";

import TimezoneSelect from "react-timezone-select";

const MappingValueModal = ({
                             callback,
                             label,
                             openModal,
                             mappingValue,
                             updateMappingValue,
                           }) => {
  const initRow = () => {
    return {
      id: uniqueId(),
      locked: false,
      name: "",
      format: "",
      type_id: 0,
      type: "",
      param: "",
    };
  };
  const addRow = () => {
    updateMappingValue([...mappingValue, initRow()]);
  };
  const removeRow = (id) => {
    updateMappingValue(
      mappingValue.filter((val) => {
        return val.id !== id;
      })
    );
  };
  const handleInputModal = (id, value) => {
    if (value.includes("-")) return;
    if (value.includes(" ")) return;
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    newValue["name"] = value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const handleTextInput = (id, value) => {
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    newValue["param"] = value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };

  const onTypeChange = (id, value) => {
    const tmpArray = [...mappingValue];
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);

    newValue["type"] = value;
    switch (newValue.type) {
      case "categorial":
        newValue["type_id"] = 1;
        newValue["param"] = "";
        break;
      case "string":
        newValue["type_id"] = 3;
        newValue["param"] = 100;
        break;
      case "numeric":
        newValue["type_id"] = 0;
        newValue["param"] = {minValue: "0", maxValue: "100"};
        break;
      case "date":
        newValue["param"] = {timezone: "Europe/Brussels"};
        newValue["type_id"] = 2;
        break;
      default:
        break;
    }
    newValue["format"] = mappingField[newValue.type_id].format[0].value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const onTimezoneChange = (id, value) => {
    const tmpArray = [...mappingValue];
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    newValue["param"] = {
      timezone: value.value,
      offset: value.label.split(")")[0].replace("(GMT", ""),
    };
    tmpArray[arrayIndex] = newValue;

    updateMappingValue(tmpArray);
  };

  const onFormatChange = (id, value) => {
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    // newValue["param"] = defaultParam(value);
    newValue["format"] = value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const switchType = (field) => {
    console.debug(field);
    switch (field.type) {
      case "numeric":
        return (
          <div className=" grid grid-cols-2 gap-1">
            <TextInput
              placeholder={"min"}
              value={field.param.minValue}
              name={field.id}
              type={"number"}
              onChange={(name, value) =>
                handleTextInput(name, {
                  minValue: value,
                  maxValue: field.param.maxValue,
                })
              }
            />

            <TextInput
              placeholder={"max"}
              value={field.param.maxValue}
              name={field.id}
              type={"number"}
              onChange={(name, value) =>
                handleTextInput(name, {
                  minValue: field.param.minValue,
                  maxValue: value,
                })
              }
            />
          </div>
        );
      case "categorial":
        return (
          <TextInput
            onChange={handleTextInput}
            value={mappingValue.param}
            name={field.id}
            placeholder={"format: "}
          />
        );

      case "date":
        return (
          <TimezoneSelect
            value={field.param.timezone}
            onChange={(e) => onTimezoneChange(field.id, e)}
            id={field.id}
            name={"timezone"}
          />
        );
      case "string":
        return (
          <TextInput
            onChange={handleTextInput}
            value={field.param}
            name={field.id}
            type={"number"}
            placeholder={"maximum size"}
          />
        );
      default:
        break;
    }
  };
  //TODO refactor with createColumn
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          console.debug(row);
          return (
            <TextInput
              value={row.name}
              name={row.id}
              onChange={handleInputModal}
            ></TextInput>
          );
        },

        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/6",
        id: "value",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          console.debug(row);
          return (
            <Select
              isSearchable={false}
              value={mappingField.find((value) => value.value === row.type)}
              menuPosition={"absolute"}
              onChange={(option, action) => onTypeChange(row.id, option.value)}
              options={mappingField}
              id={row.id}
            />
          );
        },

        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/6",
        id: "type",
        header: "Type",
        cell: (info) => info.getValue(),
        Filter: false,
      },
      {
        accessorFn: (row) => {
          return (
            <Select
              isSearchable={false}
              value={mappingField[row.type_id].format.find(
                (value) => value.value === row.format
              )}
              isDisabled={isEmptyString(row.type)}
              menuPosition={"absolute"}
              onChange={(option, action) =>
                onFormatChange(row.id, option.value)
              }
              options={mappingField[row.type_id].format}
              id={row.id + "_" + row.type}
            />
          );
        },
        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/6",
        id: "Format",
        header: "Format",
        cell: (info) => info.getValue(),
        Filter: false,
      },
      {
        accessorFn: (row) => {
          return !isEmptyString(row.format) && switchType(row);
        },
        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/2",
        id: "info",
        header: "Autre",
        cell: (info) => info.getValue(),
        Filter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => {
          return (
            <Button
              label={"-"}
              hover={"hover:bg-red-700"}
              focus={
                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              }
              color={"bg-red-500"}
              callback={() => removeRow(row.id)}
            />
          );
        },
        enableSorting: false,
        id: "action",
        header: (
          <Button
            label={"+"}
            callback={addRow}
            color={"text-blue-900 bg-blue-100"}
            focus={
              "focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            }
            hover={"hover:bg-blue-200"}
          />
        ),
        cell: (info) => info.getValue(),
        Filter: false,
      },
    ],
    //eslint-disable-next-line
    [mappingValue.length]
  );
  const styleOption = (option) => {
    return (
      <div className="flex gap-0.5">
        <p className="font-bold">Nom:</p>
        <p>{option.name}</p>
        <p className="font-bold">Type:</p>
        <p>{option.type}</p>
      </div>
    );
  };

  const handleLock = (tmpArray, key) => {
    const newValue = tmpArray[key];
    newValue["locked"] = verifyInput(newValue);
  };
  return (
    <Modal
      tableName={"Variables"}
      name={"mappingValue"}
      columns={columns}
      lockValues={handleLock}
      callback={callback}
      values={mappingValue}
      updateValues={updateMappingValue}
      label={label}
      openModal={openModal}
      optionsLabel={mappingValue
        .filter((value) => value.locked)
        .map((value) => {
          return {
            id: value.id,
            value: value.name,
            label: styleOption(value),
          };
        })}
    />
  );
};
export default MappingValueModal;
