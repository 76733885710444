import React from "react";

import DataParamView from "./DataParamView";
import TimeParamView from "./TimeParamView";
import {ComputationParamView} from "./ComputationParamView";
import ModelParamView from "./ModelParamView";

const KalmanView = ({data}) => {
    return <div>
        <DataParamView data={data}/>
        <TimeParamView data={data}/>
        <ComputationParamView data={data}/>
        <ModelParamView data={data.parameters.model_params}/>
    </div>
}
export default KalmanView;