import { TableFilter } from "./TableFilter";
import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import DeleteModal from "../Selectors/DeleteModal";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  canRenderAction,
  canRenderComponent,
  makeSelectField,
} from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { deleteUser, getUserAll } from "../../network/UserManagementApi";
import store from "../../store";
import { set as setAdmin } from "../../reducers/AdminSlice";
import ActionButtons from "../Selectors/ActionButtons";
import Select from "react-select";

const UsersList = () => {
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [canDelete] = useState(
    canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.USER_DISPLAY)
  );
  const [canEdit] = useState(
    canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.USER_DISPLAY)
  );
  const [authorizedUserCreation, setAuthorizedUserCreation] = useState(
    canRenderComponent(COMPONENTS.USER_CREATION)
  );

  useEffect(() => {
    return () => {
      setAuthorizedUserCreation(canRenderComponent(COMPONENTS.USER_CREATION));
    };
  }, [authorizedComponent]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const structureList = useSelector((state) => state.admin.structures);
  const userList = useSelector((state) => state.admin.users);
  const profileList = useSelector((state) => state.data.profiles);
  const profileOption = useCallback(() => {
    return makeSelectField(profileList, ["name"]);
  }, [profileList]);
  const [data, setData] = useState([]);
  const [structure, setStructure] = useState(null);
  const history = useNavigate();
  console.debug(data);
  const createUser = () => history("/internal/users/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row.firstName + " " + row.lastName, {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "structure",
      header: "Structure",
      cell: (info) => {
        const row = info.row.original;
        if (structureList !== undefined)
          return Object.keys(structureList).length > 0
            ? structureList[row.attributes.structure_id]?.name
            : "loading";
        else return "N/A";
      },
    }),
    columnHelper.display({
      id: "is_enable",
      header: "Etat",
      cell: (info) => {
        const row = info.row.original;
        return row.enabled ? (
          <CheckIcon className={"w-5 h-5 text-primary-theme-400 "} />
        ) : (
          <XMarkIcon className={"w-5 h-5 text-red-600 "} />
        );
      },
    }),
    columnHelper.display({
      id: "profile",
      header: "Role",
      cell: (info) => {
        const row = info.row.original;
        return profileOption().find(
          (value) => value.value === row.attributes.profile_id[0]
        )?.label;
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/internal/users/edit/" + row.id);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setUserToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  useEffect(() => {
    if (structure === null) setData(userList);
    else {
      setData(
        userList.filter(
          (user) => user.attributes.structure_id[0] === structure.value
        )
      );
    }
  }, [structure]);
  useEffect(() => {
    if (userList.length !== 0) setData(userList);
  }, [userList]);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3 w-1/6">
          <Select
            isClearable={true}
            className={"w-full text-theme3"}
            onChange={(e) => setStructure(e)}
            placeholder={"Tous les utilisateurs"}
            options={makeSelectField(structureList, ["name"])}
          />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getUserAll(() => {}).then((res) => {
                  store.dispatch(
                    setAdmin({ type: "users", data: res.data["user_id"] })
                  );
                  setRefresh(false);
                });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedUserCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button callback={createUser} label="Créer un utilisateur" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {userToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            deleteUser(userToDelete[0], () => {
              setIsDeleting(false);
              setUserToDelete(undefined);
            });
          }}
          loading={isDeleting}
          text={
            "Êtes vous sûr de vouloir supprimer l'utilisaeur " +
            userToDelete[1].name +
            " ?"
          }
          modalVisible={userToDelete}
          setModalVisible={setUserToDelete}
        />
      )}
    </div>
  );
};
export default UsersList;
