import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import React, { useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import {
  canRenderAction,
  canRenderComponent,
  isEmptyObject,
} from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ActionButtons from "../Selectors/ActionButtons";
import Select from "react-select";
import { getThreshold } from "../../network/DataManagementApi";
import { Tooltip } from "react-tooltip";
import { MdInfoOutline as Info } from "react-icons/md";
import { TableFilter } from "./TableFilter";

const ThresholdList = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [canView] = useState(
    canRenderAction(ACTION_FLAGS.CANVIEW, COMPONENTS.THRESHOLD_DISPLAY)
  );
  const [authorizedThresholdCreation, setAuthorizedThresholdCreation] =
    useState(canRenderComponent(COMPONENTS.THRESHOLD_CREATION));

  useEffect(() => {
    return () => {
      setAuthorizedThresholdCreation(
        canRenderComponent(COMPONENTS.THRESHOLD_CREATION)
      );
    };
  }, [authorizedComponent]);

  const thresholdList = useSelector((state) => state.data.threshold);
  const updateThresold = () => {
    getThreshold(() => setRefresh(false));
  };
  useEffect(() => {
    if (isEmptyObject(thresholdList)) updateThresold();
  }, [thresholdList]);
  const id = useId();

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    setData(thresholdList);
  }, [thresholdList]);
  const createThreshold = () => history("/analytics/threshold/create");

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row.label, {
      id: "label",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.creation_timestamp, {
      id: "creation_timestamp",
      header: "Date de création",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "type",
      header: "Type",
      cell: (info) => (
        <div>
          <Tooltip
            place={"left"}
            id={id + info.row.original.creation_timestamp}
          >
            <div>
              <ul>
                {Object.entries(info.row.original.formula).map((value) => (
                  <li>
                    {value[0]} : {value[1]}
                  </li>
                ))}
              </ul>
            </div>
          </Tooltip>
          <div
            data-tooltip-id={id + info.row.original.creation_timestamp}
            className="flex items-center gap-2 hover:text-theme2"
          >
            <Info size={20} />
            {isEmptyObject(info.row.original.formula) ? "fonction" : "formule"}
          </div>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  console.debug(data, thresholdList);
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                updateThresold();
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedThresholdCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button callback={createThreshold} label="Création d'un seuil" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default ThresholdList;
