//React librairies
import React from "react";
import {ToastContainer, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastWrapper = () => {
    return (
        <ToastContainer
            theme="dark"
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
        />
    )
}

export default ToastWrapper;

