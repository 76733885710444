import IndicatorRecurentForm from "../IndicatorRecurentForm";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {setModuleData} from "../../../reducers/indicatorSlice";

const IndicatorMode = () => {

    const dispatch = useDispatch()
    const mode = useSelector(state => state.indicator.moduleData.mode)
    const options = [
        {value: 0, label: "Simple"},
        {value: 1, label: "Récurrent"},
    ]
    return (
        <>

            <h1 className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
                Mode
            </h1>


            <div className="col-span-2">
                <Select
                    className="w-full py-2 rounded-lg outline-none"
                    onChange={(e) =>
                        dispatch(setModuleData({type: "mode", data: e.value}))}
                    value={options[mode]}
                    options={options}
                />
            </div>
            {mode === 1 ? (
                <IndicatorRecurentForm
                />
            ) : (
                ""
            )}
        </>

    )
}
export default IndicatorMode;