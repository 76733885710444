import { useDispatch, useSelector } from "react-redux";
import { setDeeper } from "../../../reducers/indicatorSlice";
import TextInput from "../../../components/Selectors/TextInput";
import ConfigurationAlerteFormula from "./ConfigurationAlerteFormula";

const ConfigurationAlerteCurrent = () => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.indicator.moduleData.current);
  const setTimeParams = (name, value) => {
    const data = {
      type: "current",
      first: "time_params",
      second: name,
      data: name === "bucket_size" ? parseInt(value) : value,
    };

    dispatch(setDeeper(data));
  };
  return (
    <div className="py-2  grid grid-cols-2 gap-2">
      <div className="col-span-3">
        {" "}
        {
          // incompréhensible span est différent que celui du bloc histo c'est bizarre
        }
        <ConfigurationAlerteFormula mode={"current"} />
      </div>
      <TextInput
        name={"bucket_size"}
        value={current["time_params"]["bucket_size"]}
        label={"Taille du bucket"}
        type={"number"}
        step={1}
        onChange={setTimeParams}
      />
    </div>
  );
};
export default ConfigurationAlerteCurrent;
