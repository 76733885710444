import Button from "../Selectors/Button";
import {Fragment} from "react";
import Select from "react-select";
import {Dialog, Transition} from "@headlessui/react";
import Card from "../Card/Card";

const Modal = ({
                 tableName,
                 name,
                 optionsLabel,
                 columns,
                 callback,
                 label,
                 openModal,
                 values,
                 updateValues,
                 lockValues,
               }) => {
  const handleLock = () => {
    const tmpArray = [...values];

    Object.keys(tmpArray).forEach((key) => {
      lockValues(tmpArray, key);
    });
    updateValues(tmpArray);
    callback();
  };

  return (
    <div>
      <Transition appear show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={callback}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
          </Transition.Child>

          <div className="fixed inset-0 ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="h-auto w-3/5  transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Card filter={false} data={values} name={tableName} columns={columns}/>

                  <div className="mt-4">
                    <Button
                      label={"Ajouter"}
                      callback={handleLock}
                      text={"text-blue-900"}
                      color={" bg-blue-100"}
                      extraStyle={
                        "inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium"
                      }
                      focus={
                        "focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      }
                      hover={"hover:bg-blue-200"}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className={"flex"}>
        <Select
          isClearable={false}
          menuIsOpen={false}
          className="w-4/5"
          isMulti={true}
          isSearchable={false}
          name={name}
          placeholder={"Aucune donnée"}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(value, action) =>
            updateValues(
              values.filter((val) => {
                return val.id !== action.removedValue.id;
              })
            )
          }
          value={optionsLabel}
        />
        <Button label={label} callback={callback}></Button>
      </div>
    </div>
  );
};
export default Modal;
