import {useEffect} from 'react';
import {useSelector} from "react-redux";

export function usePolling(callback, interval) {
  const polling = useSelector(state => state.authentication.polling)

  useEffect(() => {
    let intervalId;
    if (polling) {
      intervalId = setInterval(callback, interval);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [polling, callback, interval]);

  return polling;
}

