import Button from "../../components/Selectors/Button";
import { toast } from "react-toastify";
import { indicatorField } from "../../utils/field";
import { getModeInfo } from "./utils";
import { useSelector } from "react-redux";
import { indicatorComputation, putRequest } from "../../axios/axios";
import { useEffect, useState } from "react";

import IndicatorResult from "./IndicatorResult";
import { getSource } from "../../utils/common";
import { createIndicator } from "../../network/DataManagementApi";

const IndicatorRecap = ({ label, showResultButton = false }) => {
  const indicator = useSelector((state) => state.indicator);
  const [isloading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const obs = sessionStorage.getItem("observed_system_id");
  useEffect(() => {
    setResult({});
  }, [indicator.moduleData.name]);

  const transformConf = () => {
    const ret = [];
    indicator["selConfiguration"].forEach((conf) => {
      ret.push(conf.value);
    });
    return ret;
  };

  const transformVariable = () => {
    const ret = [];
    indicator["selVariable"].forEach((variable) => {
      ret.push(variable.value);
    });
    return ret;
  };
  const handleMonitoring = () => {
    const data_parameters = {};
    const module = structuredClone(indicator["moduleData"]);

    data_parameters["data_fields"] = transformVariable();
    data_parameters["timestamp_field"] = module.timestamp.value;
    data_parameters["observed_system"] = obs;
    data_parameters["configured_sources"] = transformConf();
    data_parameters["sender"] = module.mailing.expediteur;
    data_parameters["recipients"] = module.mailing.destinataire.map(
      (value) => value.value
    );
    data_parameters["subject"] = module.mailing.sujet;
    data_parameters["delay"] = parseInt(module.delay);
    data_parameters["control_mode"] = module.control_mode;
    data_parameters["control_mode_parameter"] = parseInt(
      module.control_mode_parameter
    );
    data_parameters["aggregation_mode"] = module.aggregation_mode;
    data_parameters["initial_timestamp"] =
      module.initial_timestamp + ".000" + module.timezoneOffset;
    data_parameters["final_timestamp"] =
      module.final_timestamp + ".000" + module.timezoneOffset;
    data_parameters["timezone"] = module.timezone;
    if (module.mode === 0) {
      // TODO assign index to simple and recurrent
      const data = {};
      data["script_name"] = indicatorField.find(
        (field) => field.value === module.name
      ).script;
      data["script_parameters"] = data_parameters;
      console.log(data);
      createIndicator(data, obs, "simplemodule")
        .then((res) => {
          if (res === 401) return;
          console.log(res.data); //TODO meilleure gestion
          const data = res.data;
          if (data["module_execution_result"][0]["control_status"] === true)
            toast.error(data["module_execution_result"][0]["control_message"], {
              autoClose: false,
            });
          else toast.info("Aucune anomalie détectée");
        })
        .catch((error) => console.debug(error));
    } else if (module.mode === 1) {
      const data = {};
      data["script_name"] = indicatorField.find(
        (field) => field.value === module.name
      ).script;
      data["script_parameters"] = data_parameters;
      data["frequency"] = parseInt(module.parameter.frequency);
      data["validity_days"] = module.parameter.validity_days;
      data["time_slot"] = module.parameter.time_slot;

      console.log(data);
      createIndicator(data, obs, "module")
        .then((res) => {
          if (res === 401) return;
          console.log(res); //TODO handle res
        })
        .catch((error) => console.debug(error));
    }
  };
  const handleDetection = () => {
    const parameters = {};
    const module = structuredClone(indicator["moduleData"]);
    const script_parameters = {};
    const info = getModeInfo(module.name);
    parameters["module_name"] = info.name;
    parameters["alert_settings"] = {};
    parameters["params"] = {};
    parameters["alert_settings"]["type"] =
      module["alert_settings"]["type"]["value"];
    parameters["alert_settings"]["event"] =
      module["alert_settings"]["event"]["value"];
    module["params"]["target_variable"] =
      module["params"]["target_variable"]["value"];
    module["params"]["time_field"] = module["params"]["time_field"]["label"];
    // time_params can have count or time as a dict key
    const tmp = module["historical"]["time_params"]["count"];
    const name = module["historical"]["time_params"]["type"];
    delete module["historical"]["time_params"]["count"];
    module["historical"]["time_params"][name] = tmp;
    delete module["historical"]["time_params"]["type"];

    parameters["module_params"] = {
      historical: module["historical"],
      current: module["current"],
      [info.param]: module["params"],
    };

    script_parameters["parameters"] = parameters;
    script_parameters["observed_system"] = obs;
    script_parameters["initial_timestamp"] =
      module.initial_timestamp + ".000" + module.timezoneOffset;
    delete script_parameters["params"];
    const data = {};
    data["script_name"] = indicatorField.find(
      (field) => field.value === module.name
    ).script;
    data["script_parameters"] = script_parameters;
    data["frequency"] = parseInt(module.parameter.frequency);
    data["validity_days"] = module.parameter.validity_days;
    data["time_slot"] = module.parameter.time_slot;
    console.log(data);

    createIndicator(data, obs, "module")
      .then((res) => {
        if (res === 401) return;
        toast.warn(
          res["data"]["message"] + " - " + res["data"]["module_instance_id"],
          {
            autoClose: false,
            closeOnClick: false,
          }
        );
      })
      .catch((error) => console.debug(error));
  };
  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };
  const handleAnalytique = () => {
    setLoading(true);
    const data = structuredClone(indicator["moduleData"]);
    data.computation_params.formula = parseFormulaV1(
      data.computation_params.formula,
      data.data_params
    );
    data.time_params.start_time =
      data["time_params"].start_time + ".000" + data.timezoneOffset;
    data.time_params.end_time =
      data["time_params"].end_time + ".000" + data.timezoneOffset;
    delete data["isValid"];
    delete data["timezoneOffset"];
    delete data["name"];
    delete data["timezone"];
    console.debug(data);
    if (
      data.computation_params.formula === "" ||
      data.data_params.length === 0 ||
      data.time_params.start_time === "" ||
      data.time_params.end_time === ""
    ) {
      toast.warn("Missing information");
      setLoading(false);
    } else {
      indicatorComputation("indicator/analyticalformula/" + obs, data).then(
        (res) => {
          if (res.data.status === "success") toast.success(res.data.message);
          else toast.error(res.data.message);

          setLoading(false);
          setResult(res.data);
          console.debug(res);
        }
      );
    }
  };
  const handleCorrelation = () => {
    setLoading(true);
    const data = structuredClone(indicator["moduleData"]);
    let formula = data.computation_params.formula;
    data.data_params.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        formula = formula.replace(value, "['" + value + "']");
      });
    });
    data.computation_params.formula = parseFormulaV1(
      data.computation_params.formula,
      data.data_params
    );
    data.time_params.start_time =
      data["time_params"].start_time + ".000" + data.timezoneOffset;
    data.time_params.end_time =
      data["time_params"].end_time + ".000" + data.timezoneOffset;
    console.log(data);
    delete data["isValid"];
    delete data["timezoneOffset"];
    delete data["name"];
    delete data["timezone"];
    indicatorComputation("indicator/correlations/" + obs, data).then((res) => {
      if (res.data.status === "success") toast.success(res.data.message);
      else toast.error(res.data.message);

      setLoading(false);
      setResult(res.data);
      console.debug(res);
    });
  };
  const handleIndustrial = () => {
    const data = structuredClone(indicator["moduleData"]);
    data["computation_params"] = {
      formula: parseFormulaV1(
        data.computation_params.formula,
        data.data_params
      ),
      function: {
        //TODO
        name: "",
        variables: {},
      },
    };
    data.time_params.start_time =
      data["time_params"].start_time + ".000" + data.timezoneOffset;
    data.time_params.end_time =
      data["time_params"].end_time + ".000" + data.timezoneOffset;
    //remove part not used by the module
    delete data["isValid"];
    delete data["timezoneOffset"];
    delete data["name"];
    delete data["timezone"];
    delete data["formula"];
    indicatorComputation("indicator/industrial/" + obs, data).then((res) => {
      setLoading(false);
      console.debug(res);
    });
  };
  const handleConfigurationAlerte = () => {
    const to_send = {};
    const script_parameters = {};
    const data = structuredClone(indicator["moduleData"]);
    const isHist = data["historical"]["data_params"].length !== 0;
    data["current"]["computation_params"] = {
      formula: parseFormulaV1(
        data["current"].computation_params.formula,
        data["current"].data_params
      ),
      function: {
        //TODO
        name: "",
        variables: {},
      },
    };
    if (isHist) {
      data["historical"]["computation_params"] = {
        formula: parseFormulaV1(
          data["historical"].computation_params.formula,
          data["historical"].data_params
        ),
        function: {
          //TODO
          name: "",
          variables: {},
        },
      };
      const tmp = data["historical"]["time_params"]["count"];
      const name = data["historical"]["time_params"]["type"];
      delete data["historical"]["time_params"]["count"];
      data["historical"]["time_params"][name] = tmp;
      delete data["historical"]["time_params"]["type"];
    }

    //data["current"].time_params.start_time = data["current"]["time_params"].start_time + ".000" + data.timezoneOffset
    //data["current"].time_params.end_time = data["current"]["time_params"].end_time + ".000" + data.timezoneOffset

    to_send["script_name"] = indicatorField.find(
      (field) => field.value === data.name
    ).script;
    data["alert_params"]["event"] = data["alert_params"]["event"].value;
    data["alert_params"]["mode"]["type"] =
      data["alert_params"]["mode"]["type"].value;

    script_parameters["observed_system"] = obs;
    script_parameters["parameters"] = data;
    script_parameters["initial_timestamp"] =
      data["initial_timestamp"] + ".000" + data.timezoneOffset;
    script_parameters["timezone"] = data.timezone;
    to_send["script_parameters"] = script_parameters;
    to_send["frequency"] = parseInt(data.parameter.frequency);

    to_send["validity_days"] = data.parameter.validity_days;
    to_send["time_slot"] = data.parameter.time_slot;
    delete script_parameters["parameters"]["isValid"];
    delete script_parameters["parameters"]["mode"];
    delete script_parameters["parameters"]["timezoneOffset"];
    delete script_parameters["parameters"]["isValid"];
    delete script_parameters["parameters"]["execution_interval"];
    delete script_parameters["parameters"]["parameter"];
    delete script_parameters["parameters"]["name"];
    delete script_parameters["parameters"]["dataParam"];
    delete script_parameters["parameters"]["type"];

    delete script_parameters["parameters"]["timezone"];
    delete script_parameters["parameters"]["initial_timestamp"];
    delete script_parameters["parameters"]["params"];
    if (!isHist) delete script_parameters["parameters"]["historical"];
    console.log(to_send);
    createIndicator(to_send, obs, "module")
      .then((res) => {
        if (res === 401) return;
        toast.warn(
          res["data"]["message"] + " - " + res["data"]["module_instance_id"],
          {
            autoClose: false,
            closeOnClick: false,
          }
        );
      })
      .catch((error) => console.debug(error));
  };
  const handleIndustrialHisto = () => {
    setLoading(true);
    const data = structuredClone(indicator["moduleData"]);
    data["historical"]["computation_params"] = {
      formula: parseFormulaV1(
        data["historical"].computation_params.formula,
        data["historical"].data_params
      ),
      function: {
        //TODO
        name: "",
        variables: {},
      },
    };
    const tmp = data["historical"]["time_params"]["count"];
    const name = data["historical"]["time_params"]["type"];
    delete data["historical"]["time_params"]["count"];
    data["historical"]["time_params"][name] = tmp;
    delete data["historical"]["time_params"]["type"];
    data["current"]["computation_params"] = {
      formula: parseFormulaV1(
        data["current"].computation_params.formula,
        data["current"].data_params
      ),
      function: {
        //TODO
        name: "",
        variables: {},
      },
    };
    const type = data["mode"].value;
    data.current.time_params.start_time =
      data["current"].time_params.start_time + ".000" + data.timezoneOffset;
    data.current.time_params.end_time =
      data["current"].time_params.end_time + ".000" + data.timezoneOffset;
    delete data["isValid"];
    delete data["mode"];
    delete data["timezone"];
    delete data["timezoneOffset"];
    delete data["name"];
    if (type === 1) delete data["historical"];
    console.log(data);
    indicatorComputation("indicator/industrial/" + obs, data).then((res) => {
      if (res.data.status === "success") toast.success(res.data.message);
      else toast.error(res.data.message);
      setLoading(false);
      setResult(res.data);
      console.debug(res);
    });
  };
  const handleTimeIndicator = () => {
    const data = structuredClone(indicator["moduleData"]);
    console.debug("data", data);
    const current = data.current;
    current.computation_params.formula = parseFormulaV1(
      current.computation_params.formula,
      current.data_params
    );
    current.time_params.start_time =
      current.time_params.start_time + ".000" + data.timezoneOffset;
    current.time_params.end_time =
      current.time_params.end_time + ".000" + data.timezoneOffset;
    const histo = data.historical;
    const tmp = histo["time_params"]["count"];
    const name = histo["time_params"]["type"];
    delete histo["time_params"]["count"];
    histo["time_params"][name] = tmp;
    delete histo["time_params"]["type"];
    const to_send = {
      script_name: indicatorField.find((field) => field.value === data["name"])
        .script,
      script_parameters: {
        observed_system: obs,
        parameters: {
          current: current,
          indicator_params: data.indicator_params,
          event: data.event.value,
        },
        initial_timestamp:
          data.initial_timestamp + ".000" + data.timezoneOffset,
        timezone: data.timezone,
      },
      frequency: data.parameter.frequency,
      validity_days: data.parameter.validity_days,
      time_slot: data.parameter.time_slot,
    };
    console.debug("to_send", to_send);
    createIndicator(to_send, obs, "module")
      .then((res) => {
        if (res === 401) return;
        toast.warn(
          res["data"]["message"] + " - " + res["data"]["module_instance_id"],
          {
            autoClose: false,
            closeOnClick: false,
          }
        );
      })
      .catch((error) => console.debug(error));
  };
  const handleOutlier = () => {
    const data = structuredClone(indicator["moduleData"]);

    const source_id = indicator["selSource"].value;
    const sources = getSource("crude_source");

    console.debug("data", data);
    data["parameters"]["measure_identifier"] =
      sources[source_id].measure_identifier;
    const to_send = {
      script_name: indicatorField.find((field) => field.value === data["name"])
        .script,
      script_parameters: {
        observed_system: obs,
        parameters: data.parameters,
        initial_timestamp: data.initial_timestamp + data["timezoneOffset"],
        timezone: data.timezone,
      },
      frequency: data.parameter.frequency,
      validity_days: data.parameter.validity_days,
      time_slot: data.parameter.time_slot,
    };
    console.debug("to_send", to_send);
    createIndicator(to_send, obs, "module")
      .then((res) => {
        if (res === 401) return;
        toast.warn(
          res["data"]["message"] + " - " + res["data"]["module_instance_id"],
          {
            autoClose: false,
            closeOnClick: false,
          }
        );
      })
      .catch((error) => console.debug(error));
  };
  const handleRequest = () => {
    switch (indicator["moduleData"]["name"]) {
      case 0:
        handleMonitoring();
        break;
      case 1:
        handleDetection();
        break;
      case 2:
        handleCorrelation();
        break;
      case 3:
        handleIndustrial();
        break;
      case 4:
        handleConfigurationAlerte();
        break;
      case 5:
        handleAnalytique();
        break;
      case 6:
        handleIndustrialHisto();
        break;
      case 7:
        handleTimeIndicator();
        break;
      case 8:
        handleOutlier();
        break;
      default:
        break;
    }
  };
  return (
    <div className={"flex justify-center mb-2 mx-4"}>
      <div className={"flex gap-4  "}>
        <Button isLoading={isloading} callback={handleRequest} label={label} />
        {showResultButton && indicator.selSource !== undefined && (
          <IndicatorResult
            type={indicator["moduleData"]["name"]}
            result={result}
          />
        )}
      </div>
    </div>
  );
};
export default IndicatorRecap;
