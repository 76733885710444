import { isEmptyString } from "./common";
/***
 * Check if some field are empty or incorrect
 * @param value
 */
export const verifyInput = (value) => {
  console.log(value);
  var lock = true;
  if (isEmptyString(value.name)) {
    lock = false;
  }
  switch (value.type) {
    case "numeric":
      if (
        isEmptyString(value.param.minValue.toString()) ||
        isEmptyString(value.param.maxValue.toString())
      ) {
        lock = false;
      } else {
        const min = value.param.minValue;
        const max = value.param.maxValue;

        if (min > max) lock = false;
      }
      break;
    case "date":
      if (isEmptyString(value.param.timezone)) lock = false;
      break;
    default:
      break;
  }

  return lock;
};
