import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const Section = ({icon, name, section}) => {
  const authorized_pages = useSelector(
    (state) => state.authentication.authorized_pages
  );
  return (
    <>
      <li className="px-5 hidden md:block">
        <div className="flex flex-row items-center mt-5 h-8 gap-x-1.5">
          {icon}
          <div className="text-sm font-light tracking-wide text-gray-400 uppercase">
            {name}
          </div>
        </div>
      </li>

      {/* SETTINGS SECTIONS */}
      {section.map((row) => {
        return (
          authorized_pages.includes(row.id) && (
            <li key={row.id}>
              <NavLink
                className="relative flex flex-row items-center py-2 focus:outline-none hover:bg-blue-800 text-white-600
                            hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 pr-6"
                to={row.path}
              >
                <span className="pl-8 ml-2 text-sm tracking-wide truncate">
                  {row.name}
                </span>
              </NavLink>
            </li>
          )
        );
      })}
    </>
  );
};
export default Section;
