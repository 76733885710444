import { useEffect, useState } from "react";
import { TableFilter } from "./TableFilter";
import Button from "../Selectors/Button";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { canRenderAction, canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import ActionButtons from "../Selectors/ActionButtons";
import { ACTION_FLAGS } from "../../constants/action_flags";
import DeleteModal from "../Selectors/DeleteModal";
import { deleteProfile, getProfile } from "../../network/UserManagementApi";

const ProfileList = () => {
  const [profileToDelete, setProfileToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [canDelete] = useState(
    canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.PROFILE_DISPLAY)
  );
  const [canEdit] = useState(
    canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.PROFILE_DISPLAY)
  );
  const [authorizedProfileCreation, setAuthorizedProfileCreation] = useState(
    canRenderComponent(COMPONENTS.PROFILE_CREATION)
  );
  useEffect(() => {
    return () => {
      setAuthorizedProfileCreation(
        canRenderComponent(COMPONENTS.PROFILE_CREATION)
      );
    };
  }, [authorizedComponent]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const profileList = useSelector((state) => state.data.profiles);
  const [data, setData] = useState([]);
  console.debug(data);
  useEffect(() => {
    const newData = Object.entries(profileList);
    setData(newData);
  }, [profileList]);

  const history = useNavigate();

  const createProfile = () => history("/internal/profile/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[1].name, {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor((row) => row[1].description, {
      id: "desc",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/internal/profile/edit/" + row[0]);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setProfileToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getProfile(() => {
                  toast.success("Informations mises à jour.");
                  setRefresh(false);
                });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedProfileCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button callback={createProfile} label="Créer un profil" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {profileToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            deleteProfile(profileToDelete[0], () => {
              setIsDeleting(false);
              setProfileToDelete(undefined);
            });
          }}
          loading={isDeleting}
          text={
            "Êtes vous sûr de vouloir supprimer le profile " +
            profileToDelete[1].name +
            " ?"
          }
          modalVisible={profileToDelete}
          setModalVisible={setProfileToDelete}
        />
      )}
    </div>
  );
};
export default ProfileList;
