import TextInput from "../../../components/Selectors/TextInput";
import Checkbox from "../../../components/Selectors/Checkbox";
import MRDTimestamp from "./MRDTimestamp";

import {useDispatch, useSelector} from "react-redux";
import {setModuleData} from "../../../reducers/indicatorSlice";
import Select from "react-select";

const MRDParameters = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  return (
    <>
      <MRDTimestamp/>
      <div className={"py-2  grid grid-cols-3 gap-x-1.5"}>
        <TextInput
          min={0}
          name={"delay"}
          value={module.delay}
          label={"Délai de controle"}
          type={"number"}
          step={1}
          onChange={(key, value) =>
            dispatch(setModuleData({type: key, data: value}))
          }
        />
        <div>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
            Mode de contrôle
          </label>

          <Select
            name={"control_mode"}
            value={module.control_mode}
            onChange={(options) =>
              dispatch(setModuleData({type: "control_mode", data: options}))
            }
            options={[
              {value: 0, label: "Comptage"},
              {value: 1, label: "Proportion"},
            ]}
          />
        </div>
        <TextInput
          min={0}
          name={"control_mode_parameter"}
          value={module.control_mode_parameter}
          label={"Seuil à considérer"}
          type={"number"}
          step={1}
          onChange={(key, value) =>
            dispatch(setModuleData({type: key, data: value}))
          }
        />
      </div>
      <Checkbox
        label={"Aggrégation"}
        value={module.aggregation_mode === 1}
        onChange={(e) =>
          dispatch(
            setModuleData({
              type: "aggregation_mode",
              data: e.target.checked ? 1 : 0,
            })
          )
        }
      />
    </>
  );
};
export default MRDParameters;
