import {useSelector} from "react-redux";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useState } from "react";
import Select from "react-select";
import { makeSelectField } from "../../utils/common";
import TextInput from "../../components/Selectors/TextInput";
import SwitchButton from "../../components/Selectors/SwitchButton";
import Button from "../../components/Selectors/Button";
import {editUserAll, resetPwd} from "../../network/UserManagementApi";

const UserEdit = () => {

  const {id} = useParams();
  const history = useNavigate();

  const users = useSelector((state) => state.admin.users);
  const [user, setUser] = useState(users.find((value) => value.id === id));
  const profiles = useSelector(state => state.data.profiles)
  const profileOption = useCallback(
    () => {
      return makeSelectField(profiles, ["name"])
    },
    [profiles],
  );
  const [profile, setProfile] = useState(
    profileOption().find((value) => value.value === user?.attributes.profile_id[0])
  );
  console.debug(profile)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const structures = useSelector((state) => state.admin.structures);
  const [structure, setStructure] = useState({
    value: user?.attributes.structure_id,
    label:
      structures[user?.attributes.structure_id] !== undefined
        ? structures[user?.attributes.structure_id]["description"] +
        " - " +
        structures[user?.attributes.structure_id]["name"]
        : "STRUCTURE INCORRECT",
  });
  const handleEditUser = () => {
    setIsLoading2(true);
    const edit = {};
    edit["id"] = user.id;
    edit["payload"] = {
      firstName: user.firstName,
      lastName: user.lastName,
      enabled: user.enabled,
      attributes: {...user.attributes},
    };
    edit["payload"]["attributes"]["structure_id"] = structure.value;
    edit["payload"]["attributes"]["profile_id"] = profile.value.toString();
    editUserAll(edit, () => {
      setIsLoading2(false);
      history("/internal/users");
    });
  };
  const onChange = (name, value) => {
    setUser({
      ...user,
      [name]: value,
    });
  };
  return (
    <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
      <div className="flex gap-x-1.5">
        <IconButton
          bg={true}
          Icon={<Prev size={40}/>}
          value={""}
          callback={() => history("/internal/users")}
        />
        <h2 className="text-theme1 text-3xl font-semibold">
          Modification d'un utilisateur
        </h2>
      </div>
      <div className={"mt-2"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <TextInput
            name={"email"}
            value={user?.email}
            disabled={true}
            onChange={onChange}
            label={"Adresse email"}
            type={"email"}
          />
          <TextInput
            name={"firstName"}
            value={user?.firstName}
            onChange={onChange}
            label={"Prénom"}
            type={"text"}
          />
          <TextInput
            name={"lastName"}
            value={user?.lastName}
            onChange={onChange}
            label={"Nom"}
            type={"text"}
          />
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Type
            </label>
            <Select
              onChange={(e) => setProfile(e)}
              value={profile}
              options={profileOption()}
            />
          </div>
          <div>
            <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Struture
            </label>
            <Select
              onChange={(e) => setStructure(e)}
              value={structure}
              options={makeSelectField(structures, ["description", "name"])}
            />
          </div>
          <SwitchButton
            onChange={() => onChange("enabled", !user?.enabled)}
            value={user?.enabled}
            label={user?.enabled ? "Activé" : "Désactivé"}
          />
        </div>
      </div>
      <div className={"flex justify-end gap-2"}>
        <Button
          label={"Réinitialiser le mot de passe"}
          callback={() => {
            setIsLoading(true);
            resetPwd(
              {
                username: user?.username,
              },
              () => setIsLoading(false)
            );
          }}
          color={"bg-red-700"}
          isLoading={isLoading}
          hover={"hover:bg-red-800"}
          focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
          extraStyle={
            "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
          }
        />
        <Button
          label={"Annuler"}
          callback={() => history("/internal/users")}
          color={"bg-red-700"}
          hover={"hover:bg-red-800"}
          focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
          extraStyle={
            "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
          }
        />
        <Button
          isLoading={isLoading2}
          label={"Modifier"}
          callback={() => handleEditUser()}
          color={"bg-theme1"}
          hover={"hover:bg-theme-3"}
          focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
          extraStyle={
            "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
          }
        />
      </div>
    </div>
  );
};
export default UserEdit;
