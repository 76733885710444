import { useEffect, useState } from "react";

import { checkFileType, initParam } from "../../utils/fileUtils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import FileInformationList from "../../components/Table/FileInformationList";

const FileUpload = ({ data, setData, file, setFile }) => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedFileUpload, setAuthorizedFileUpload] = useState(
    canRenderComponent(COMPONENTS.FILE_UPLOAD)
  );
  useEffect(() => {
    return () => {
      setAuthorizedFileUpload(canRenderComponent(COMPONENTS.FILE_UPLOAD));
    };
  }, [authorizedComponent]);
  const updateData = (data) => {
    setData(data);
  };
  //callback
  /**
   * Update the field param with the value specified of the index.
   * @param {*} value new value to put
   * @param {int} index index of the element to update
   * @param {string} field field to update - one of: column | type | param
   * @param {string} type specitif field to update. Most of the time used with the param param
   */
  const updateParam = (value, index, field, type) => {
    const to_update = [];
    if (type === "") {
      to_update.push(data.col_type[index]);
    } else to_update.push(data.col_type[index][type]);
    const replace = [...data.col_type];
    to_update[0][field] = value;
    if (field === "type") to_update[0]["param"] = initParam(value);
    replace[index][type] = to_update[0];
    console.debug(replace);
    setData({
      ...data,
      col_type: replace,
    });
  };
  console.debug(data);
  const handlefileUpload = (event) => {
    console.log(event.target.files[0].size);
    if (event.target.files[0].size > process.env.REACT_APP_FILE_MAX_SIZE) {
      toast.error(
        "File is too big. \n Size must be less than " +
          process.env.REACT_APP_FILE_MAX_SIZE
      );
      setData({ col_type: [], error: [], preview: [], data: [] });
      return;
    }
    setFile(event.target.files[0]);
    setData({ col_type: [], error: [], preview: [], data: [] });
  };

  useEffect(() => {
    checkFileType(file, data, updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  return (
    authorizedFileUpload && (
      <FileInformationList
        data={data}
        setData={setData}
        callback={updateParam}
        onFileChange={handlefileUpload}
      />
    )
  );
};

export default FileUpload;
