import IndicatorAccordion from "../IndicatorAccordion";
import FormuleAnalytiqueFormula from "./FormuleAnalytiqueFormula";
import FormuleAnalytiqueParam from "./FormuleAnalytiqueParam";

const FormuleAnalytiqueRightPanel = () => {
    return <div
      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh] shadow-lg">
        <IndicatorAccordion label={"Paramètres de calcul"}
                            composant={<FormuleAnalytiqueFormula/>}/>
        <IndicatorAccordion label={"Paramètres"}
                            composant={<FormuleAnalytiqueParam/>}/>
    </div>
}
export default FormuleAnalytiqueRightPanel;