import TextInput from "../../../components/Selectors/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {setDeeper} from "../../../reducers/indicatorSlice";
import Select from "react-select";
import React from "react";

const DetectionAnomalieHistorical = () => {
  const dispatch = useDispatch();
  const historical = useSelector(
    (state) => state.indicator.moduleData.historical
  );

  const setFormula = (name, value) => {
    const data = {
      type: "historical",
      first: "computation_params",
      second: "formula",
      data: value,
    };
    dispatch(setDeeper(data));
  };
  const setCount = (name, value) => {
    const tmp = structuredClone(historical.time_params.count);
    tmp[name] = parseInt(value);
    const data = {
      type: "historical",
      first: "time_params",
      second: "count",
      data: tmp,
    };
    dispatch(setDeeper(data));
  };
  const setBucketSize = (name, value) => {
    const data = {
      type: "historical",
      first: "time_params",
      second: "bucket_size",
      data: parseInt(value),
    };
    dispatch(setDeeper(data));
  };
  return (
    <div className="py-2  ">
      <TextInput
        min={0}
        name={"formula"}
        value={historical.computation_params.formula}
        label={"Formule"}
        type={"text"}
        step={1}
        onChange={setFormula}
      />
      <TextInput
        name={"bucket_size"}
        value={historical.time_params.bucket_size}
        min={"-1"}
        label={"Taille du bucket"}
        type={"number"}
        step={1}
        onChange={setBucketSize}
      />
      <div className={"grid  grid-cols-3 gap-1.5 pt-2"}>
        <div>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
            {"Type"}
          </label>
          <Select
            isSearchable={false}
            value={{
              label: historical["time_params"]["type"],
              value: historical["time_params"]["type"],
            }}
            options={[
              {label: "count", value: "count"},
              {label: "time", value: "time"},
            ]}
            onChange={(e) =>
              dispatch(
                setDeeper({
                  data: e.value,
                  type: "historical",
                  first: "time_params",
                  second: "type",
                })
              )
            }
          />
        </div>

        <TextInput
          min={0}
          name={"delay"}
          value={historical.time_params.count.delay}
          label={"Délai depuis le bloc courant"}
          type={"number"}
          step={1}
          onChange={setCount}
        />
        <TextInput
          min={0}
          name={"length"}
          value={historical.time_params.count.length}
          label={"Nombre de données"}
          type={"number"}
          step={1}
          onChange={setCount}
        />
      </div>
    </div>
  );
};
export default DetectionAnomalieHistorical;
