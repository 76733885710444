import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import StructureList from "../../components/Table/StructureList";

const Structures = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedStructureList, setAuthorizedStructureList] = useState(
    canRenderComponent(COMPONENTS.STRUCTURE_DISPLAY)
  );
  useEffect(() => {
    return () => {
      setAuthorizedStructureList(
        canRenderComponent(COMPONENTS.STRUCTURE_DISPLAY)
      );
    };
  }, [authorizedComponent]);

  return authorizedStructureList && <StructureList />;
};
export default Structures;
