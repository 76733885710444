import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Button from "../../components/Selectors/Button";
import {
  fileUploadData,
  getSourceParam,
} from "../../network/DataManagementApi";

import { toast } from "react-toastify";

import { isEmptyObject } from "../../utils/common";
import { getHeaderFromCSV } from "../../utils/fileUtils";
import FileUploadList from "../../components/Table/FileUploadList";

const FileBatchUpload = () => {
  const [file, setFile] = useState(undefined);
  const [columnMap, setColumnMap] = useState(undefined);

  const [fileData, setFileData] = useState({
    header: [],
    delimiter: "",
  });
  const observedSystemId = useSelector(
    (state) => state.selected.observed_system
  );
  const redirect = "/";
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const ConfiguredSourceList = useSelector(
    (state) => state.data.configured_source
  );
  const history = useNavigate();
  const [item] = useState(ConfiguredSourceList[id]);
  const [sourceParam, setsourceParam] = useState({});

  useEffect(() => {
    if (isEmptyObject(sourceParam) && !isEmptyObject(item)) {
      getSourceParam(observedSystemId, item.crude_source, (res) =>
        setsourceParam(res.data.data)
      );
    }
  }, []);
  const handlefileUpload = (event) => {
    console.log(event.target.files[0].size);
    if (event.target.files[0].size > process.env.REACT_APP_FILE_MAX_SIZE) {
      toast.error(
        "File is too big. \n Size must be less than " +
          process.env.REACT_APP_FILE_MAX_SIZE
      );
      setFileData({
        header: null,
        delimiter: "",
      });
      return;
    }
    setFile(event.target.files[0]);
    getHeaderFromCSV(
      event.target.files[0],
      Object.keys(sourceParam.values)
    ).then((value) => {
      setFileData({ header: value.header, delimiter: value.delimiter });
      setColumnMap(value.columnMap);
    });
  };

  return (
    <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
      <div className="flex gap-x-1.5">
        <IconButton
          bg={true}
          Icon={<Prev size={40} />}
          value={""}
          callback={() => history(redirect)}
        />
        <h2 className="text-theme1 text-3xl font-semibold">
          Source configurée
        </h2>
      </div>
      <FileUploadList
        data={
          isEmptyObject(sourceParam) ? [] : Object.entries(sourceParam.values)
        }
        header={fileData.header}
        columnMap={columnMap}
        setColumnMap={setColumnMap}
        onFileChange={handlefileUpload}
      />

      <div className={"flex justify-end gap-2 pt-2 "}>
        <Button
          isLoading={isLoading}
          label={"Ajouter"}
          callback={() => {
            setIsLoading(true);
            fileUploadData(
              observedSystemId,
              id,
              item.measure_identifier,
              file,
              { columnMap: columnMap, delimiter: fileData.delimiter }
            ).then((res) => {
              toast.success(res.data.message);
              setIsLoading(false);
            });
          }}
          color={"bg-theme1"}
          hover={"hover:bg-theme-3"}
          focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
          extraStyle={
            "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
          }
        />
      </div>
    </div>
  );
};
export default FileBatchUpload;
