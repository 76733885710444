import React, {useState} from "react";

import CreatableSelect from "react-select/creatable";
import IndustrialCreationThreshold from "../../pages/indicator/IndicatorIndustrial/IndustrialCreationThreshold";
import {isEmptyObject} from "../../utils/common";

const SelectCreatable = ({
                           module,
                           field,
                           onChange,
                           value,
                           onCreate,
                           isLoading,
                           setIsLoading,
                           setValue,
                         }) => {
  const handleCreate = (inputValue) => {
    //TODO setvalue vide
    setValue({});
    setIsLoading(true);
    setThresholdBlock({
      ...thresholdBlock,
      label: inputValue,
    });
  };

  const [thresholdBlock, setThresholdBlock] = useState({
    label: "",
    description: "",
    formula: [],
    structure_id: sessionStorage.getItem("structure_id"),
  });
  return (
    <>
      <CreatableSelect
        menuPosition={"fixed"}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={(e, option) => {
          onChange(e, option);
        }}
        onCreateOption={handleCreate}
        options={field}
        value={isEmptyObject(value) ? undefined : value}
      />
      <IndustrialCreationThreshold
        module={module}
        threshold={thresholdBlock}
        add={() => {
          if (onCreate(thresholdBlock) !== true)
            setThresholdBlock({
              label: "",
              description: "",
              formula: [],
              structure_id: sessionStorage.getItem("structure_id"),
            });
        }}
        close={() => {
          setIsLoading(false);
          setThresholdBlock({
            label: "",
            description: "",
            formula: [],
            structure_id: sessionStorage.getItem("structure_id"),
          });
        }}
        setThreshold={setThresholdBlock}
        modal={isLoading}
      />
    </>
  );
};
export default SelectCreatable;
