import {
  EyeIcon,
  TrashIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";

const ActionButtons = ({
  canView = false,
  canDelete = false,
  canEdit = false,
  canCreate = false,
  handleView,
  handleEdit,
  handleDelete,
  handleCreate,
}) => {
  return (
    <div className="flex">
      {canCreate && (
        <div onClick={handleCreate} className="ml-3">
          <PlusCircleIcon
            className={
              "w-6 h-6 cursor-pointer text-primary-theme-900 hover:text-primary-theme-800"
            }
          />
        </div>
      )}
      {canView && (
        <div onClick={handleView} className="ml-3">
          <EyeIcon
            className={
              "w-6 h-6 cursor-pointer text-primary-theme-900 hover:text-primary-theme-800"
            }
          />
        </div>
      )}
      {canEdit && (
        <div onClick={handleEdit} className="ml-3">
          <PencilIcon
            className={
              "w-6 h-6 cursor-pointer text-primary-theme-900 hover:text-primary-theme-800"
            }
          />
        </div>
      )}
      {canDelete && (
        <div onClick={handleDelete} className="ml-3">
          <TrashIcon
            className={
              "w-6 h-6 cursor-pointer text-primary-theme-900 hover:text-primary-theme-800"
            }
          />
        </div>
      )}
    </div>
  );
};
export default ActionButtons;
