import {useMemo} from "react";
import Card from "../components/Card/Card";

const ErrorPanel = ({error}) => {
    const columns = useMemo(() => [{
        accessorFn: row => {
            return row.col
        }, id: "col", header: 'Colonne', cell: info => info.getValue(), Filter: false
    }, {
        accessorFn: row => {
            return row.row
        }, id: "row", header: 'Ligne', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row.value
        }, id: "value", header: 'Valeur', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row.message
        }, id: "message", header: 'Message', cell: info => info.getValue(),
    },], []);
    return <Card data={error} columns={columns} name={"Erreurs"}/>;
};
export default ErrorPanel;
