//React librairies
import React, {Fragment, useEffect, useId, useState} from "react";
//Icons
import {PencilIcon as Edit, UserCircleIcon as ProfileIcon} from "@heroicons/react/24/solid";
import Profile from "./Profile";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Popover, Transition} from "@headlessui/react";

const Header = () => {
  const history = useNavigate()
  const [observedSystem, setObservedSystem] = useState(sessionStorage.getItem("observed_system_name") ?? "");
  const crudeSources = useSelector(state => state.data)

  useEffect(() => {
    setObservedSystem(sessionStorage.getItem("observed_system_name") ?? "")
  }, [crudeSources]);
  const id = useId();
  return (
    <div className="fixed w-full flex items-center justify-between h-14 text-white z-20">
      {/* TITLE */}
      <div className="flex items-center justify-center w-40 md:w-80 h-14 bg-blue-800 border-none">
        <span className="hidden md:block font-bold text-xl">PREDITIC</span>
      </div>
      {observedSystem !== "" && (
        <div className="flex gap-x-2 items-center justify-center w-40 md:w-80 h-14 bg-blue-800 border-none">
          <span className="md:block font-bold text-xl">{observedSystem}</span>
          <button onClick={() => history("/")}>
            <Edit size={20} />
          </button>
        </div>
      )}
      {/* RIGHT SECTION */}
      <div className="w-full flex justify-end items-center h-14 bg-blue-800 header-right pl-10">
        <div className="pr-2">
          <Popover as="div" className="relative inline-block mr-3">
            <Popover.Button className="w-10 h-10 inline-flex rounded-full p-1">
              <ProfileIcon id={id} className={"w-full h-full"} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 mt-2 w-[16rem] text-black dark:text-white origin-top-right divide-y divide-gray-100 bg-white dark:bg-[#0d0c18] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {({ close }) => (
                  <Profile close={close} observedSystem={observedSystem} />
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default Header;
