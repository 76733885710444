import Button from "../Selectors/Button";
import TextInput from "../Selectors/TextInput";
import {typeField} from "../../utils/field";

import uniqueId from "lodash.uniqueid";
import {useMemo} from "react";
import {isEmptyString} from "../../utils/common";
import DateTimezone from "../Selectors/DateTimezone";

import Modal from "./Modal";
import Select from "react-select";

const SourceParametersModal = ({
                                 callback,
                                 label,
                                 openModal,
                                 sourceParametersValue,
                                 updateSourceParametersValue,
                               }) => {
  const addRow = () => {
    updateSourceParametersValue([
      ...sourceParametersValue,
      {
        id: uniqueId(),
        locked: false,
        value: "",
        name: "",
        type: "",
      },
    ]);
  };
  const removeRow = (id) => {
    updateSourceParametersValue(
      sourceParametersValue.filter((val) => {
        return val.id !== id;
      })
    );
  };
  const handleLock = () => {
    const tmpArray = [...sourceParametersValue];
    Object.keys(tmpArray).forEach((key) => {
      const newValue = tmpArray[key];

      newValue["locked"] =
        !isEmptyString(newValue["value"]) &&
        !isEmptyString(newValue["type"]) &&
        !isEmptyString(newValue["name"]);
      if (newValue.type === "date") {
        newValue["locked"] = !isEmptyString(newValue.value.date);
      }
    });
    updateSourceParametersValue(tmpArray);
    callback();
  };
  const handleTextInput = (row, value, type) => {
    const arrayIndex = sourceParametersValue.findIndex(
      (elem) => elem.id === row.id
    );
    const tmpArray = [...sourceParametersValue];
    row[type] = value;
    tmpArray[arrayIndex] = row;
    updateSourceParametersValue(tmpArray);
  };
  const handleNumericInput = (row, value, type) => {
    handleTextInput(row, parseInt(value), type);
  };
  const handleFloatInput = (row, value, type) => {
    handleTextInput(row, parseFloat(value), type);
  };

  const switchType = (field) => {
    switch (field.type) {
      case "integer":
        return (
          <TextInput
            extraStyle={"w-full py-1.5 rounded outline-none "}
            onChange={(id, value) => {
              handleNumericInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"number"}
            step={"1"}
            placeholder={"value"}
          />
        );
      case "date":
        return <DateTimezone handleTextInput={handleTextInput} field={field}/>;

      case "string":
        return (
          <TextInput
            extraStyle={"w-full py-1.5 rounded outline-none "}
            onChange={(id, value) => {
              handleTextInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"text"}
            placeholder={"value"}
          />
        );
      case "float":
        return (
          <TextInput
            extraStyle={"w-full py-1.5 rounded outline-none "}
            onChange={(id, value) => {
              handleFloatInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"number"}
            step={"0.001"}
            placeholder={"value"}
          />
        );
      default:
        break;
    }
  };

  const onTypeChange = (row, value) => {
    const tmpArray = [...sourceParametersValue];
    const arrayIndex = sourceParametersValue.findIndex(
      (elem) => elem.id === row.id
    );
    if (value === "date")
      row["value"] = {date: "", offset: "+1:00", name: "Europe/Brussels"};
    row["type"] = value;
    tmpArray[arrayIndex] = row;
    updateSourceParametersValue(tmpArray);
  };
  const styleOption = (option) => {
    return (
      <div className="flex gap-0.5">
        <p className="font-bold">Nom:</p>
        <p>{option.name}</p>
        <p className="font-bold">Type:</p>
        <p>{option.type}</p>
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return (
            <TextInput
              extraStyle={"w-full py-1.5 rounded outline-none "}
              value={row["name"]}
              name={row.id}
              onChange={(id, value) => {
                handleTextInput(row, value, "name");
              }}
            ></TextInput>
          );
        },

        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/6",
        id: "value",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          return (
            <Select
              value={typeField.find((value) => value.value === row.type)}
              menuPosition={"absolute"}
              onChange={(option, action) => onTypeChange(row, option.value)}
              options={typeField}
              id={row.id}
            />
          );
        },
        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/6",
        id: "type",
        header: "Type",
        cell: (info) => info.getValue(),
        Filter: false,
      },
      {
        accessorFn: (row) => {
          return switchType(row);
        },
        className:
          "group px-2 py-3 text-left text-xs font-medium text-white uppercase tracking-wider w-1/2",
        id: "info",
        header: "Autre",
        cell: (info) => info.getValue(),
        Filter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => {
          return (
            <Button
              label={"-"}
              hover={"hover:bg-red-700"}
              focus={
                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              }
              color={"bg-red-500"}
              callback={() => removeRow(row.id)}
            />
          );
        },
        enableSorting: false,
        id: "action",
        header: (
          <Button
            label={"+"}
            callback={addRow}
            color={"text-blue-900 bg-blue-100"}
            focus={
              "focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            }
            hover={"hover:bg-blue-200"}
          />
        ),
        cell: (info) => info.getValue(),
        Filter: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceParametersValue.length]
  );
  return (
    <Modal
      tableName={"Paramètres de source"}
      name={"mappingValue"}
      columns={columns}
      lockValues={handleLock}
      callback={callback}
      values={sourceParametersValue}
      updateValues={updateSourceParametersValue}
      label={label}
      openModal={openModal}
      optionsLabel={sourceParametersValue
        .filter((value) => value.locked)
        .map((value) => {
          return {
            id: value.id,
            value: value.name,
            label: styleOption(value),
          };
        })}
    />
  );
};
export default SourceParametersModal;
