import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {parser} from "mathjs";
import {variableField} from "../utils";
import {toast} from "react-toastify";
import {setDeeper, setModuleData} from "../../../reducers/indicatorSlice";
import Button from "../../../components/Selectors/Button";
import TextInput from "../../../components/Selectors/TextInput";

const ConfigurationAlerteFormula = ({mode}) => {
  const [toastId, setToastId] = useState("" | 0)
  const dispatch = useDispatch()
  const current = useSelector(state => state.indicator.moduleData[mode])
  const checkFormula = () => {
    const parse = parser()
    variableField(current.data_params).forEach((obj) => parse.set(obj.value, 1))
    try {
      parse.evaluate(current.computation_params.formula)
      toast.dismiss(toastId)
      setToastId(toast.info("Formule valide"))

      dispatch(setModuleData({type: "isValid", data: true}))

    } catch (e) {

      dispatch(setModuleData({type: "isValid", data: false}))
      toast.dismiss(toastId)

      if (e instanceof SyntaxError)
        setToastId(toast.error("Formule incorrect"))
      else {
        const wrongVariable = e.message.split(" ")[2]
        setToastId(toast.error("Variable incorrect: " + wrongVariable))
      }
    }
  }

  return <div className=" w-full flex gap-1.5">
    <div className={"w-5/6"}>
      <TextInput
        placeholder={"Formule"}
        name={"formula"}
        value={current["computation_params"]["formula"]}
        onChange={(name, value) => {
          dispatch(setDeeper({type: mode, first: "computation_params", second: "formula", data: value})
          );
        }}
      />
    </div>
    <Button callback={checkFormula} label={"Vérifier"} extraStyle={"px-2 rounded-md w-1/6"}>Vérifier</Button>
  </div>
}
export default ConfigurationAlerteFormula