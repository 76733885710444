import { useSelector } from "react-redux";

import React, { useEffect, useState } from "react";

import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import EventList from "../../components/Table/EventList";

const Event = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedEvent, setAuthorizedEvent] = useState(
    canRenderComponent(COMPONENTS.EVENT_DISPLAY)
  );
  useEffect(() => {
    return () => {
      setAuthorizedEvent(canRenderComponent(COMPONENTS.EVENT_DISPLAY));
    };
  }, [authorizedComponent]);
  return authorizedEvent && <EventList />;
};
export default Event;
