import React from "react";

import TextInput from "../Selectors/TextInput";
import { handleParamChange } from "../../utils/fileUtils";

const NumericParam = ({ data, setData, id }) => {
  const step = data.col_type[id].type === "float" ? "0.1" : "1";
  return (
    <div className=" grid grid-cols-2 gap-1 ">
      <TextInput
        step={step}
        type={"number"}
        name={"minValue"}
        onChange={(name, value) =>
          handleParamChange(value, id, data, setData, name)
        }
        label={"Borne inférieur"}
        value={data.col_type[id].param.minValue}
      />
      <TextInput
        step={step}
        type={"number"}
        name={"maxValue"}
        onChange={(name, value) =>
          handleParamChange(value, id, data, setData, name)
        }
        label={"Borne supérieur"}
        value={data.col_type[id].param.maxValue}
      />
    </div>
  );
};
export default NumericParam;
