import TextInput from "../../../components/Selectors/TextInput";

export const ComputationParamView = ({data}) => {
    console.debug(data.parameters)
    const current = data.parameters.current.computation_params
    const historical = data.parameters.historical.computation_params
    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètres de calcul</h2>
        <div className="grid grid-cols-2 gap-4">
            <div className="px-4">
                <div className="grid grid-cols-2">
                    <h2 className="text-theme1 text-xl font-semibold">Courant</h2>
                </div>
                <TextInput name={"formula"} value={current.formula}
                           label={"Formule"} disabled={true}/>
            </div>
            <div className="px-4">
                <div className="grid grid-cols-2">
                    <h2 className="text-theme1 text-xl font-semibold">Historique</h2>
                </div>

                <TextInput name={"formula"} value={historical.formula}
                           label={"Formule"} disabled={true}/>
            </div>
        </div>
    </div>
}