import {useDispatch, useSelector} from "react-redux";
import BasicTooltip from "../Tooltip/BasicTooltip";
import {isEmptyObject} from "../../utils/common";
import Button from "../Selectors/Button";
import {useState} from "react";
import {getAll} from "../../network/DataManagementApi";
import {toast} from "react-toastify";
import {setPolling} from "../../reducers/authenticationSlice";
import SwitchButton from "../Selectors/SwitchButton";

const DashBoardHeader = ({structure}) => {
  const dispatch = useDispatch();
  const selectedObservedSystem = useSelector(
    (state) => state.selected.observed_system
  );
  const observedSystems = useSelector((state) => state.data.observed_system);
  const loadingTime = sessionStorage.getItem("dataLoadingTime");
  const [refresh, setRefresh] = useState(false);
  const loadingSourceTime = sessionStorage.getItem("dataSourceLoadingTime");
  const structureId = useSelector((state) => state.selected.structure);
  const polling = useSelector((state) => state.authentication.polling);

  return (
    <div className="px-10 py-2 grid grid-cols-2">
      <div>
        <h2 className="text-theme1 text-gray-800 text-3xl font-semibold ">
          {structure.name} {structureId}
        </h2>
        <h2 className="  truncate  h-[5vh] text-theme1 text-gray-800 text-xl font-medium ">
          {structure.description}
        </h2>
        <div className={"flex gap-x-0.5 items-end"}>
          <BasicTooltip
            tip={
              "Temps de chargement des sources de données liés à ce système."
            }
          />
          Temps d'intilisation:{" "}
          <p className={"text-theme1 font-semibold"}>
            {(loadingTime / 1000).toFixed(2)}
          </p>{" "}
          s.
          <div className="mx-auto flex gap-x-1.5">
            <Button
              disabled={polling}
              isLoading={refresh}
              label={"Mettre à jour"}
              callback={() => {
                setRefresh(true);
                getAll(() => setRefresh(false));
              }}
            />
            <SwitchButton
              label={"Mise à jour automatique des données"}
              value={polling}
              onChange={() => {
                !polling
                  ? toast.info("Mise à jour des données automatique activée")
                  : toast.info(
                      "Mise à jour des données automatique désactivée"
                    );
                dispatch(setPolling(!polling));
              }}
            />
          </div>
        </div>
      </div>
      {selectedObservedSystem === "" || isEmptyObject(observedSystems) ? (
        "N/A"
      ) : (
        <div>
          <h2 className="text-theme1 text-gray-800 text-3xl font-semibold ">
            {observedSystems[selectedObservedSystem]["name"]}
          </h2>
          <h2 className="truncate  h-[5vh] text-theme1 text-gray-800 text-xl font-medium ">
            {observedSystems[selectedObservedSystem]["description"]}
          </h2>
          <div className={"flex gap-x-0.5 items-end"}>
            <BasicTooltip
              tip={
                "Temps de chargement des sources de données liés à ce système."
              }
            />
            Temps de chargement des données liées à ce système:{" "}
            <p className={"text-theme1 font-semibold"}>
              {(loadingSourceTime / 1000).toFixed(2)}
            </p>{" "}
            s.
          </div>
        </div>
      )}
    </div>
  );
};
export default DashBoardHeader;
