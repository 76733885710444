import {InformationCircleIcon as Info} from "@heroicons/react/24/outline";
import {Tooltip} from "react-tooltip";
import {useId} from "react";

const LabelTooltip = ({label, tip}) => {
    const id = useId();
    return (
      <div>
        <Tooltip className="z-50" id={id + label} html={tip} />
        <div className="flex items-center gap-2 hover:text-theme2">
          <label
            className={
              "text-theme1  text-sm font-bold text-gray-700 dark:text-white tracking-wide"
            }
          >
            {" "}
            {label}
          </label>
          <Info
            data-tooltip-id={id + label}
            className=" hover:text-theme1 h-5 w-5"
          />
        </div>
      </div>
    );
};
export default LabelTooltip;
