import TextInput from "../../../components/Selectors/TextInput";
import TimezoneSelect from "react-timezone-select";
import {useDispatch, useSelector} from "react-redux";
import {setDeep, setModuleData} from "../../../reducers/indicatorSlice";
import {hasSeconds} from "../../../utils/common";

const FormuleAnalytiqueParam = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  const setTimeParams = (name, value) => {

    const data = {
      type: "time_params",
      first: name,
      data:
        name === "bucket_size"
          ? parseInt(value)
          : hasSeconds(value)
            ? value
            : value + ":00",
    };
    dispatch(setDeep(data));
  };
  return (
    <div className="py-2 grid grid-cols-2 gap-1.5">
      <TextInput
        name={"bucket_size"}
        value={module.time_params.bucket_size}
        label={"Taille du bucket"}
        type={"number"}
        step={"1"}
        onChange={setTimeParams}
      />
      <div>
        <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
          Fuseau horaire
        </label>

        <TimezoneSelect
          value={module.timezone}
          onChange={(e) => {
            dispatch(
              setModuleData({
                type: "timezoneOffset",
                data: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
              })
            );
            dispatch(
              setModuleData({
                type: "timezone",
                data: e.value,
              })
            );
          }}
        />
      </div>

      <TextInput
        name={"start_time"}
        value={module.time_params.start_time}
        label={"Début"}
        type={"datetime-local"}
        step={"1"}
        onChange={setTimeParams}
      />
      <TextInput
        name={"end_time"}
        value={module.time_params.end_time}
        label={"Fin"}
        type={"datetime-local"}
        step={"1"}
        onChange={setTimeParams}
      />
    </div>
  );
};
export default FormuleAnalytiqueParam;
