import {flexRender} from "@tanstack/react-table";
import {
  MdExpandLess as ArrowDown,
  MdExpandMore as ArrowUp,
} from "react-icons/md";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

export function TableHeader({table}) {
    return (
      <thead className="bg-primary-theme-900">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                className="group px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                {header.isPlaceholder ? null : (
                  <>
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none flex items-center justify-between"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <ChevronUpIcon className="w-4 h-4 text-gray-400" />
                        ),
                        desc: (
                          <ChevronDownIcon className="w-4 h-4 text-gray-400" />
                        ),
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );

}