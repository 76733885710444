import {Disclosure} from "@headlessui/react";
import {HiChevronUp as ChevronUpIcon} from "react-icons/hi";

function IndicatorAccordion({label, composant, open = true}) {
  return (
    <Disclosure defaultOpen={open}>
      {({open}) => (
        /* Use the `open` state to conditionally change the direction of an icon. */
        <>
          <Disclosure.Button
            className="border-b border-theme1 flex w-full justify-between rounded-t-lg px-4 py-2 text-left text-sm font-bold tracking-wide text-theme1 hover:bg-gray-50 focus:outline-none">
            {label}
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-theme1`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className={"px-10 pt-4"}>{composant}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default IndicatorAccordion;
