import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {reset} from "../../reducers/indicatorSlice";


const IndicatorHeader = ({set, title, field}) => {
  const history = useNavigate()
  const indicator = useSelector(state => state.indicator)
  const dispatch = useDispatch()
  useEffect(() => {
    if (field.find(field => field.value === indicator?.moduleData?.name) === undefined)
      dispatch(reset())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="bg-white  pt-2 pb-8 px-8 m-4  shadow-lg rounded-lg">
    <div className="flex gap-x-1.5">
      <IconButton bg={true} Icon={<Prev size={40}/>} value={""}
                  callback={() => history("/analytics/indicator")}/>
      <h2 className="text-theme1 text-3xl font-semibold">{title}</h2>
    </div>
    <div className="grid grid-cols-2 gap-2 mx-20">
      <div>
        <label
          className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">{"Nom du module"}</label>
        <Select
          id="name"
          value={field.find(field => field.value === indicator?.moduleData?.name)}
          onChange={(e) => set(e)}
          options={field}
        />
      </div>
    </div>
  </div>

}
export default IndicatorHeader