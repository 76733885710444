import TextInput from "../../../components/Selectors/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {setDeeper} from "../../../reducers/indicatorSlice";

const DetectionAnomalieCurrent = () => {
    const dispatch = useDispatch()
    const current = useSelector(state => state.indicator.moduleData.current)
    const setFormula = (name, value) => {
        const data = {
            type: "current",
            first: "computation_params",
            second: "formula",
            data: value
        }
        dispatch(setDeeper(data))
    }
    const setBucketSize = (name, value) => {

        const data = {
            type: "current",
            first: "time_params",
            second: "bucket_size",
            data: parseInt(value)
        }
        dispatch(setDeeper(data))
    }
    return <div className="py-2 ">


        <TextInput min={0} name={"formula"}
                   value={current.computation_params.formula}
                   label={"Formule"} type={"text"} step={1} onChange={setFormula}/>
        <TextInput name={"bucket_size"} value={current.time_params.bucket_size}
                   min={"-1"}
                   label={"Taille du bucket"} type={"number"} step={1} onChange={setBucketSize}/>
        {/*
        <div className="grid grid-cols-3 col-span-3 gap-x-2">
            <h1 className="text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
                Début
            </h1>

            <input value={module["current"]["time_params"]["start_time"]} className={" rounded-md"}
                   onChange={(value) => {
                       const newValue = module["current"]
                       newValue.time_params.start_time = (value.target.value).length === 16 ? value.target.value + ":00" : value.target.value
                       setModule({
                           ...module,
                           current: newValue
                       })
                   }} type={"datetime-local"}
                   step={1}/>

            <TimezoneSelect

                value={module.timezone}

                onChange={(e) => {

                    setModule({
                        ...module,
                        timezoneOffset: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
                        timezone: e.value,
                    })
                }}
            />
        </div>
        <h1 className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
            Fin
        </h1>

        <input value={module["current"]["time_params"]["end_time"]} className={"rounded-md"} onChange={(value) => {
            const newValue = module["current"]
            newValue.time_params.end_time = (value.target.value).length === 16 ? value.target.value + ":00" : value.target.value
            setModule({
                ...module,
                current: newValue
            })

        }}
               type={"datetime-local"}
               step={1}/>
        <span className="col-span-1"/>*/}
    </div>
}
export default DetectionAnomalieCurrent;