import React from "react";

import TextInput from "../Selectors/TextInput";
import { handleParamChange } from "../../utils/fileUtils";

const StringParam = ({ data, setData, id }) => {
  return (
    <TextInput
      type={"number"}
      name={"length"}
      onChange={(name, value) =>
        handleParamChange(value, id, data, setData, name)
      }
      label={"Taille de la chaine"}
      value={data.col_type[id].param.length}
    />
  );
};
export default StringParam;
