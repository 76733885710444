import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
  canRenderComponent,
  getConfigurationsFromCrudeSource,
  makeSelectField,
} from "../../utils/common";
import {
  selectSource,
  selectTimestamp,
  selectVariable,
  setGraphTitle,
  setLink,
  setOne,
} from "../../reducers/graphSlice";
import Select from "react-select";
import Button from "../../components/Selectors/Button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import GraphVariable from "./GraphVariable";
import {COMPONENTS} from "../../constants/component";

const GraphParam = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedGraph, setAuthorizedGraph] = useState(
    canRenderComponent(COMPONENTS.GRAPHIC_CREATION)
  );

  useEffect(() => {
    return () => {
      setAuthorizedGraph(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));
    };
  }, [authorizedComponent]);
  const dispatch = useDispatch();
  const history = useNavigate();

  const sources = useSelector((state) => state?.data?.crude_source);
  const data = useSelector((state) => state?.analyticsGraph);

  const selectedSources = data["selectedSource"];

  const sourceSelect = (selected) => {
    dispatch(selectSource(selected));
  };
  const variableSelect = (selected) => {
    dispatch(selectVariable(selected));
  };
  const timestampSelect = (selected) => {
    dispatch(selectTimestamp(selected));
  };

  useEffect(() => {
    let conf = {};
    selectedSources.forEach((source) => {
      conf = Object.assign(
        conf,
        getConfigurationsFromCrudeSource(source.value)
      );
    });
    dispatch(setOne({type: "configurations", data: conf}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSources]);

  const makeOptions = (type) => {
    const group = [];
    selectedSources.forEach((source) => {
      const options = [];
      if (type === 1)
        options.push({
          label: "timestamp interne",
          value: source.value + "_internal",
          crude_source_id: source.value,
        });
      const to_add = sources[source.value];
      Object.keys(to_add.measure).forEach((variable) => {
        if (type === 1 && to_add.measure[variable]["type"] === "date")
          options.push({
            label: variable,
            value: source.value + "_" + variable,
            crude_source_id: source.value,
          });
        else if (type === 0 && to_add.measure[variable]["type"] !== "date")
          options.push({
            label: variable,
            value: source.value + "_" + variable,
            crude_source_id: source.value,
            anomaly_display: true,
            anomaly_parameters: [],
            nocheck_display: true,
            configured_sources: [],
            threshold_display: true,
            threshold_parameters: {
              mode: "lt",
              value: 0.1,
            },
          });
      });
      group.push({
        label: source.label,
        options: options,
        crude_source_id: source.value,
      });
    });
    return group;
  };
  const defValue = (source, variable) => {
    const anomaly_parameters = variable.anomaly_parameters.map((value) => {
      return {anomaly_id: value.value, anomaly_method: value.label};
    });
    const configurations = variable.configured_sources.map(
      (value) => value.value
    );
    return {
      name: variable.value.replace(source + "_", ""),
      anomaly_display: variable.anomaly_display,
      anomaly_parameters: anomaly_parameters,
      nocheck_display: variable.nocheck_display,
      threshold_display: variable.threshold_display,
      threshold_parameters: variable.threshold_parameters,
      configured_sources: configurations,
    };
  };
  const createGraph = () => {
    const copy = structuredClone(data["data_sources"]);
    copy.forEach((value) => {
      const source = value.data_source_id;
      value.timestamp_field = data["selectedTimestamp"]
        .find((value) => value.crude_source_id === source)
        .value.replace(source + "_", "");
      data["selectedVariable"]
        .filter((value) => value.crude_source_id === source)
        .forEach((variable) =>
          value.variables.push(defValue(source, variable))
        );
    });
    const to_send = {
      data_sources: copy,
      graphic_title: data["graphic_title"],
      scale_distribution: data["mode"].value,
      graph_span: data["graph_span"].value,
      timezone: data["timezone"].value,
    };
    console.debug(to_send);
    axios
      .put(
        "https://api.version0.prototype4.research.preditic.com/graphic/complex_curve/" +
        data["selectedObs"].value,
        to_send,
        {
          headers: {
            Authorization: "Bearer 0kdaVC4AjRZVLGfvGqNz",
          },
        }
      )
      .then((res) => {
        dispatch(setLink(res.data["creation_data"]["panel_url"]));
        history("/analytics/graph/view");
      });
  };

  return (
    authorizedGraph && (
      <div className="m-8 grid grid-cols-5 gap-x-4">
        <div className="p-2  bg-white shadow-md col-span-2 ">
          <div className="p-4 col-span-2 grid grid-cols-2 gap-y-2">
            <LabelTooltip
              label={"Source de données"}
              tip={
                "Source de données considérées. <br> Une ou plusieurs peuvent être sélectionnées."
              }
            />
            <Select
              onChange={sourceSelect}
              isMulti={true}
              value={selectedSources}
              options={makeSelectField(sources, ["name"])}
            ></Select>
            <LabelTooltip
              label={"Variable à considérer"}
              tip={
                "Variables qui seront considérées pour réaliser les courbes du graphique. <br> Une ou plusieurs peuvent être séléctionnées"
              }
            />
            <Select
              onChange={variableSelect}
              isMulti={true}
              value={data["selectedVariable"]}
              options={makeOptions(0)}
            ></Select>
            <LabelTooltip
              label={"Variable temporelle"}
              tip={
                "Variable servant d'axe temporel. <br> Une variable par source de donnée doit être sélectionnée"
              }
            />
            <Select
              onChange={timestampSelect}
              value={data["selectedTimestamp"]}
              isMulti={true}
              options={makeOptions(1)}
            ></Select>
            <LabelTooltip
              label={"Timezone"}
              tip={"Timezone considéré: Soit UTC, soit local."}
            />
            <Select
              onChange={(selected) =>
                dispatch(setOne({type: "timezone", data: selected}))
              }
              value={data["timezone"]}
              options={[
                {label: "UTC", value: "UTC"},
                {
                  label: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  value: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
              ]}
            ></Select>
            <LabelTooltip label={"Mode"} tip={"Linéaire ou logarithmique"}/>
            <Select
              onChange={(selected) =>
                dispatch(setOne({type: "mode", data: selected}))
              }
              value={data["mode"]}
              options={[
                {label: "linéaire", value: "linear"},
                {
                  label: "logarithmique",
                  value: "logarithm",
                },
              ]}
            ></Select>
            <label
              className={
                "text-theme1  text-sm font-bold text-gray-700 dark:text-white tracking-wide"
              }
            >
              {" "}
              Etendue du graphique
            </label>
            <Select
              onChange={(selected) =>
                dispatch(setOne({type: "graph_span", data: selected}))
              }
              value={data["graph_span"]}
              options={[
                {label: "7 dernières jours", value: "long"},
                {
                  label: "6 dernières heures",
                  value: "short",
                },
              ]}
            ></Select>
            <label
              className={
                "text-theme1  text-sm font-bold text-gray-700 dark:text-white tracking-wide"
              }
            >
              {" "}
              Nom du graphique
            </label>
            <input
              type={"text"}
              className={"block w-full rounded-md"}
              value={data["graphic_title"]}
              onChange={(e) => dispatch(setGraphTitle(e.target.value))}
            />
          </div>
          <div className="col-span-2 flex justify-center">
            <Button
              label={"Créer"}
              disabled={
                data["graphic_title"] === "" ||
                data["selectedObs"] === "" ||
                data["selectedVariable"].length === 0 ||
                data["timezone"].length === 0 ||
                selectedSources.length === 0
              }
              callback={createGraph}
              extraStyle="block w-fit  h-fit rounded-lg px-3 py-3 shadow-lg cursor-pointer transition ease-in duration-300"
            />
          </div>
        </div>
        <div className="p-2  bg-white shadow-md col-span-3">
          <GraphVariable/>
        </div>
      </div>
    )
  );
};
export default GraphParam;
