import React, { useEffect, useState } from "react";

import TabGroup from "../../components/Tab/TabGroup";
import TableLogConnection from "../../components/Table/TableLogConnection";
import TableLogAction from "../../components/Table/TableLogAction";
import { COMPONENTS } from "../../constants/component";
import { useSelector } from "react-redux";

const Log = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [panel, setPanel] = useState([]);
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    const tmpPanel = [];
    const tmpHeaders = [];
    if (authorizedComponent[COMPONENTS.CONNECTION_LOG_DISPLAY] !== undefined) {
      tmpPanel.push({
        id: 1,
        component: <TableLogConnection />,
      });
      tmpHeaders.push("Connection");
    }
    if (authorizedComponent[COMPONENTS.ACTION_LOG_DISPLAY] !== undefined) {
      tmpPanel.push({
        id: 2,
        component: <TableLogAction />,
      });
      tmpHeaders.push("Action");
    }
    setPanel(tmpPanel);
    setHeaders(tmpHeaders);
  }, [authorizedComponent]);
  return (
    headers.length !== 0 && (
      <div>
        <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
          <h2 className="text-primary-theme-900 text-3xl font-semibold">
            Historique
          </h2>
          <TabGroup
            headers={headers}
            current={selectedIndex}
            changeCurrent={setSelectedIndex}
            panel={panel}
          />
        </div>
      </div>
    )
  );
};
export default Log;
