import React from "react";
import TimeParamHisto from "./TimeParamHisto";
import TimeParam from "./TimeParam";
import {useSelector} from "react-redux";

const TimeParamCreation = () => {
    const histo = useSelector(state => state.event.histo)
    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètres de temps</h2>
        <div className="grid grid-cols-2 gap-4">
            <TimeParam />
            {histo && <TimeParamHisto />}
        </div>
    </div>
}
export default TimeParamCreation;