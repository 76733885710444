import {AiOutlineDelete as Delete} from "react-icons/ai";
import LabelTooltip from "../../../components/Tooltip/LabelTooltip";

const IndicatorChip = ({type, text, callback, tip, color = "bg-gray-200"}) => {
    return <div className={`${color} ${"py-2 px-2  shadow-lg rounded-lg "}`}>
        <div className="flex justify-center md:justify-end ">

            {tip !== "" ? <LabelTooltip tip={tip}/> : <span className="h-[20px]"> </span>}
        </div>
        <div className="min-h-[68px]">
            <h2 data-tip={type} className="truncate text-gray-800 text-xl font-semibold">{type}</h2>
            {text.map(value => {
                return <p className="text-gray-600 text-sm">{value}</p>
            })}

        </div>
        <div className="flex justify-end ">
            <button onClick={callback} className="text-xl font-medium "><Delete/></button>
        </div>
    </div>
}
export default IndicatorChip