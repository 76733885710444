import React, {useState} from "react";
import {getValueFromSelect, mailEvent, redirect} from "./EventUtils";
import TextInput from "../../components/Selectors/TextInput";
import CreatableSelect from "react-select/creatable";
import Button from "../../components/Selectors/Button";
import {useNavigate} from "react-router-dom";
import {addEvent} from "../../network/DataManagementApi";

const MailEvent = () => {

    const history = useNavigate()
    const [data, setData] = useState(mailEvent);
    const set = (name, value) => {
        const param = {...data.parameters}
        param[name] = value
        setData((prevState) => ({
            ...prevState, parameters: param
        }));
    }
    const create = () => {
        const formattedData = structuredClone(data)
      formattedData.parameters.receivers = getValueFromSelect(data.parameters.receivers)
      addEvent(formattedData, () => history(redirect));
    }
    const disabled = data.parameters.receivers.length === 0 || data.parameters.message === "" || data.parameters.subject === ""
    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètre de l'email</h2>
        <TextInput extraStyle="w-full py-2 rounded-lg outline-none border border-2px opacity-50" disabled={true}
                   value={data["parameters"].sender} label={"Expediteur"}/>
        <div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Destinataires</label>
            <CreatableSelect placeholder={"Destinataires..."} openMenuOnClick={false} isClearable={true} isMulti={true}
                             components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                             value={data.parameters.receivers}
                             onChange={(option) => set("receivers", option)}/>
        </div>
        <TextInput value={data["parameters"].subject} label={"Sujet"} name="subject" onChange={set}/>
        <TextInput value={data["parameters"].message} label={"Message"} name="message" onChange={set}/>
        <div className="py-2 flex justify-center align-center">
            <Button label={"Créer"} disabled={disabled} callback={create}/>
        </div>
    </div>
}
export default MailEvent;