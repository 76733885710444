import {add} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {useEffect, useState} from "react";
import FormuleAnalytiqueVariableTable from "./FormuleAnalytiqueVariableVariableTable";
import FormuleAnalytiqueRecap from "./FormuleAnalytiqueRecap";
import {makeVariableField} from "../../../utils/common";


const FormuleAnalytiqueVariable = () => {
  const crudeSources = useSelector(state => state.data.crude_source)
  const selCrudeSource = useSelector(state => state.indicator.selSource)
  const selConfiguration = useSelector(state => state.indicator.selConfiguration)
  const [bloc, setBloc] = useState({
    data: {},
    to_add: {}
  })
  const dispatch = useDispatch()
  useEffect(() => {
    setBloc((prevState) => ({
      ...prevState,
      data: {},
      to_add: {}
    }));
  }, [selCrudeSource])

  const [timeVariable, setTimeVariable] = useState({value: "internal", label: "internal_timestamp"})

  return <div className="px-2 py-2 grid grid-cols-3">
    <h1 className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
      Variable temporelle
    </h1>
    <Select
      options={makeVariableField(1, selCrudeSource.value, crudeSources[selCrudeSource.value]["measure"])}
      className="w-full col-span-2 rounded-lg outline-none"
      onChange={(value) => {
        setTimeVariable(value)
      }}
      value={timeVariable}
    />


    <FormuleAnalytiqueVariableTable
      variables={makeVariableField(0, selCrudeSource.value, crudeSources[selCrudeSource.value]["measure"])}
      bloc={bloc} setBloc={setBloc} add={() =>
      add(crudeSources, selCrudeSource, bloc, selConfiguration, dispatch, timeVariable)}/>
    <div className="col-span-3 grid grid-cols-3 gap-x-4 h-fit">

      <FormuleAnalytiqueRecap/>
    </div>

  </div>

}
export default FormuleAnalytiqueVariable;