import React, {useMemo, useState} from "react";
import {
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {TableFilter} from "../../components/Table/TableFilter";
import {TableHeader} from "../../components/Table/TableHeader";
import {TableBody} from "../../components/Table/TableBody";
import {TablePagination} from "../../components/Table/TablePagination";
import Select from "react-select";
import Checkbox from "../../components/Selectors/Checkbox";
import SwitchButton from "../../components/Selectors/SwitchButton";
import {setBase} from "../../reducers/EventSlice";
import {useDispatch, useSelector} from "react-redux";

const VariableTableHisto = ({data, setData}) => {
    const histo = useSelector(state => state.event.histo)
    const lockedHisto = useSelector(state => state.event.lockedHisto)

    const dispatch = useDispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateFormat = (option) => {
        const rowc = {...option.row}
        rowc[option.format] = option.value
        const index = data.findIndex(value => value[0] === option.name)
        const datac = structuredClone(data)
        datac[index][1] = rowc
        setData(datac)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const check = (name) => {
        const index = data.findIndex(value => value[0] === name)
        const datac = structuredClone(data)
        datac[index][2] = !datac[index][2]
        setData(datac)
    }
    const columns = useMemo(() => [{
        accessorFn: row => {
            return row[0]
        }, id: "name", header: 'Nom', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row[1].type
        }, id: "type", header: 'Type', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return <Select
                menuPosition={"fixed"}
                value={{value: row[1], label: row[1].current}}
                onChange={(option) => updateFormat(option)}
                options={[{
                    format: "current", name: row[0], row: row[1], value: "identity", label: "identity"
                }, {format: "current", name: row[0], row: row[1], value: "max", label: "max"}, {
                    format: "current", name: row[0], row: row[1], value: "avg", label: "avg"
                }, {format: "current", name: row[0], row: row[1], value: "min", label: "min"}]}/>
        }, id: "current", header: 'Format pour le bloc courant', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return <Select
                menuPosition={"fixed"}
                value={{value: row[1], label: row[1].historical}}
                onChange={(option) => updateFormat(option)}
                options={[{
                    format: "historical", name: row[0], row: row[1], value: "identity", label: "identity"
                }, {format: "historical", name: row[0], row: row[1], value: "max", label: "max"}, {
                    format: "historical", name: row[0], row: row[1], value: "avg", label: "avg"
                }, {format: "historical", name: row[0], row: row[1], value: "min", label: "min"}]}/>
        }, id: "historical", header: 'Format pour le bloc historique', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return <Checkbox value={row[2]} onChange={() => check(row[0])}/>
        }, id: "activated", header: '', cell: info => info.getValue(),
    }], [check, updateFormat])
    const [globalFilter, setGlobalFilter] = useState('');
    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });
    return (<div className="bg-white md:col-span-2 2xl:col-span-1  pt-4 pb-8 px-8  shadow-lg rounded-lg">
        <div>
            <div className="flex flex-col lg:flex-row justify-between mt-2">
                <div className=" w-full flex flex-row align-center px-3">
                    <h2 className="w-full text-gray-800 text-3xl font-semibold">Variables</h2>
                    {!lockedHisto && <SwitchButton label={"historique"} value={histo} disabled={lockedHisto}
                                                   onChange={() => dispatch(setBase({type: "histo", value: !histo}))}/>}
                    <TableFilter
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                    />


                </div>
            </div>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="shadow overflow-hidden border-b border-gray-200">
                            <table className="w-full">
                                <TableHeader table={table}/>
                                <TableBody table={table}/>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <TablePagination table={table}/>
        </div>
    </div>);
}
export default VariableTableHisto