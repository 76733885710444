import {flexRender} from "@tanstack/react-table";

export function TableBody({ table }) {
  const colSpan = table.getAllColumns().length;
  return (
    <tbody className="bg-white divide-y divide-gray-200">
      {table.getRowModel().rows.length === 0 && (
        <tr>
          <td
            className="px-6 py-4 whitespace-nowrap bg-white dark:bg-gray-900 text-center"
            colSpan={colSpan}
          >
            Aucune information
          </td>
        </tr>
      )}

      {table.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              className="px-6 py-4 whitespace-nowrap bg-white dark:bg-gray-900"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}