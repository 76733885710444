import { Tab } from "@headlessui/react";

const TabPanel = ({ panel }) => {
  return (
    <Tab.Panels>
      {panel.map((value) => (
        <Tab.Panel key={value.id}>{value.component}</Tab.Panel>
      ))}
    </Tab.Panels>
  );
};
export default TabPanel;
