import OutlierValidationRightPanel from "./OutlierValidationRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const OutlierValidation = () => {
  return (
    <div className="grid grid-cols-2 gap-4 mx-4 py-2">
      <IndicatorDataConfiguration isVariable={false}/>
      <OutlierValidationRightPanel/>
    </div>
  );
};
export default OutlierValidation;
