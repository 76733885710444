import IndicatorAccordion from "../IndicatorAccordion";
import IndustrialParamHisto from "./IndustrialParamHisto";
import IndustrialCurrent from "./IndustrialCurrent";

const IndustrialRightPanelHisto = () => {

    return <div
      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"paramétrage des blocs courants"}
                            composant={<IndustrialCurrent/>}/>
        <IndicatorAccordion label={"Paramètre d'indicateur"}
                            composant={<IndustrialParamHisto/>}/>
    </div>
}
export default IndustrialRightPanelHisto;