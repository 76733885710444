import TextInput from "../../../components/Selectors/TextInput";
import IndustrialTable from "./IndustrialTable";
import Button from "../../../components/Selectors/Button";

const IndustrialCreationThreshold = ({modal, module, threshold, setThreshold, add, close}) => {

    const getVariableList = () => {
        const list = []
        module.data_params.forEach(block => {
            Object.keys(block.preprocess).forEach(key => list.push(key))
        })
        return list
    }
    const handleChange = (key, value) => {

        setThreshold({
            ...threshold, [key]: value
        });
    };
    return modal && <div className=" col-span-2 grid grid-cols-2 ">
        <TextInput label={"name"} value={threshold.label} name={"label"} onChange={handleChange}/>
        <TextInput label={"description"} value={threshold.description} name={"description"} onChange={handleChange}/>
        <div className={"col-span-2 p-2"}>
            <IndustrialTable variableList={getVariableList()} threshold={threshold} setThreshold={setThreshold}
                             header={["label", "formula"]}
            />
        </div>
        <div className="w-full flex justify-end ">

            <Button color={"bg-red-600"} hover={"hover:bg-red-700"}
                    extraStyle={"block w-1/4 rounded-lg shadow-lg cursor-pointer transition ease-in duration-300"}
                    callback={close} label={"Annuler"}/>
        </div>

        <div className=" w-full flex justify-end">
            <Button extraStyle={"block w-1/4 rounded-lg shadow-lg cursor-pointer transition ease-in duration-300"}
                    callback={add} label={"Ajouter"}/>
        </div>

    </div>
}
export default IndustrialCreationThreshold;