import store from "../store";

/**
 * Is empty string
 * @param string
 */
export const isEmptyString = (string) => {
  if (string) {
    return string === "";
  } else {
    return true;
  }
};
/**
 * Dict where keys are object's id, and values are attributes, and return an array used by react select.
 * @param dict
 * @param fields
 * @returns {*[]}
 */
export const makeSelectField = (dict, fields) => {
  if (isEmptyObject(dict ?? {})) return [];
  const options = [];
  Object.keys(dict).forEach((key) => {
    const label = [];
    fields.forEach((field) => {
      if (field === "key") label.push(key);
      else label.push(dict[key][field]);
    });
    options.push({ value: key, label: label.join(" - ") });
  });
  return options;
};
/**
 * Generate the variable aray used by react select.
 * @param type
 * @param crude_source_id
 * @param variables
 * @returns {*[]}
 */
export const makeVariableField = (type, crude_source_id, variables) => {
  const group = [];
  if (crude_source_id === undefined) return group;
  const options = [];
  if (type === 1)
    options.push({
      label: "timestamp interne",
      value: crude_source_id + "_internal",
      crude_source_id: crude_source_id,
    });
  Object.keys(variables).forEach((variable) => {
    if (type === 1 && variables[variable]["type"] === "date")
      options.push({
        label: variable,
        value: crude_source_id + "_" + variable,
        crude_source_id: crude_source_id,
      });
    else if (type === 0 && variables[variable]["type"] !== "date")
      options.push({
        label: variable,
        value: crude_source_id + "_" + variable,
        crude_source_id: crude_source_id,
      });
  });
  group.push({
    label: crude_source_id,
    options: options,
    crude_source_id: crude_source_id,
  });
  return group;
};
/**
 * Parse the string representing the categorical data. Options are ordered and nominal.
 *
 * @param {string} format ordered or nominal
 * @param {string} data str representing the data
 * @returns
 */
export const parseCategorical = (format, data) => {
  switch (format) {
    case "ordered": {
      const split = data.split(",");
      const trimmed = split.map((x) => {
        return x.trim();
      });
      const prep = trimmed.map((x) => {
        return x.split(":");
      });
      const dict = {};
      prep.forEach((x) => {
        dict[parseInt(x[0])] = x[1];
      });
      return dict;
    }
    case "nominal": {
      const split = data.split(",");
      const trimmed = split.map((x) => {
        return x.trim();
      });
      const dict = {};
      let index = 0;
      trimmed.sort().forEach((x) => {
        dict[index] = x;
        ++index;
      });

      return dict;
    }
    default:
      break;
  }
};

export const canRenderComponent = (component_id) => {
  const state = store.getState();
  const authorizedComponent = state.authentication.authorized_components;
  return authorizedComponent[component_id] !== undefined;
};
export const canRenderAction = (action_flags, component_id) => {
  const state = store.getState();
  const authorizedComponent = state.authentication.authorized_components;
  const action = authorizedComponent[component_id];
  return (action & action_flags) === action_flags;
};
export const renderComponent = (component) => {
  const { component_id } = component.props;
  const state = store.getState();
  const authorizedComponent = state.authentication.authorized_components;
  if (authorizedComponent[component_id] !== undefined) {
    return component;
  }
};

export const getSource = (source) => {
  const state = store.getState();
  const sources = state.data[source];
  return Object.fromEntries(
    Object.entries(sources).filter(
      (source) =>
        source[1].observed_system_id ===
        sessionStorage.getItem("observed_system_id")
    )
  );
};

export const getSourceWithMeasure = (source) => {
  const state = store.getState();
  const sources = state.data[source];
  return Object.fromEntries(
    Object.entries(sources).filter(
      (source) =>
        source[1].observed_system_id ===
          sessionStorage.getItem("observed_system_id") &&
        source[1].measure !== undefined
    )
  );
};
/**
 * Verify if an object is empty
 * @param obj object to verify
 * @returns {boolean} true if its empty, false otherwise
 */
export const isEmptyObject = (obj) => {
  if (obj === undefined) return true;
  return Object.keys(obj).length === 0;
};
export const hasSeconds = (dateString) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(dateString);
};
/**
 * Get the configuration information of a crude source
 * @param {string} crude_source_id
 */
export const getConfigurationsFromCrudeSource = (crude_source_id) => {
  const state = store.getState();
  const allConfigurations = state.data.configured_source;
  const allDataSources = state.data.crude_source;
  return Object.fromEntries(
    Object.entries(allConfigurations).filter((value) =>
      allDataSources[crude_source_id]["configuration"].find(
        (configuration) => configuration === value[0]
      )
    )
  );
};

export const getPastDate = (seconds) => {
  let date = new Date();
  return new Date(date.getTime() - seconds * 1000);
};

export const getGridClass = (length) => {
  return `grid-cols-${length}`;
};
export const transitionClasses = {
  enter: "transition ease-out duration-200",
  enterFrom: "opacity-0 -translate-y-1",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease-in duration-150",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-1",
};
