import React, {useEffect, useState} from "react";
import TextInput from "../../components/Selectors/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {setModelParams} from "../../reducers/EventSlice";
import Select from "react-select";
import {makeVariableField} from "../../utils/common";

const ModelParamCreation = () => {
    const dispatch = useDispatch()

    const params = useSelector(state => state.event.data.model_params)
    const data_params = useSelector(state => state.event.data.current.data_params);
    const sources = useSelector(state => state.event.source)
    const [variables, setVariables] = useState([]);
    const [timeVariables, setTimeVariables] = useState([]);
    const configurations = useSelector(state => state.data.configured_source)

    useEffect(() => {
        const tmp = []
        const sourceIds = []
        setVariables([]);
        data_params.forEach(bloc => {
            const measure_identifier = bloc.measure_identifier
            sourceIds.push(Object.entries(sources).find(value => measure_identifier === configurations[value[1].configuration[0]].measure_identifier)[0])
            Object.keys(bloc.preprocess).forEach(variable => tmp.push({
                label: variable, value: variable
            }))
        })
        // if (!tmp.includes({
        //     value: params.target_variable, label: params.target_variable
        // })) 
        dispatch(setModelParams({type: "target_variable", value: ""}))
        setVariables(tmp)
        sourceIds.length !== 0 ? setTimeVariables(makeVariableField(1, sourceIds[0], sources[sourceIds[0]].measure)) : setTimeVariables([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data_params])

    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètres du modèle</h2>
        <div>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Timefield</label>
            <Select options={timeVariables}
                    value={timeVariables.length === 0 ? null : timeVariables[timeVariables.findIndex(value => value.value === params.time_field)]}
                    onChange={(option) => {
                        dispatch(setModelParams({type: "time_field", value: option.label}))
                    }}
                    placeholder={"Index de temps..."}/>
        </div>

        <TextInput name={"threshold"} min={0} max={1} step={"0.01"} label={"Seuil"} value={params.threshold}
                   type={"number"}
                   onChange={(name, value) => dispatch(setModelParams({type: name, value: parseFloat(value)}))}/>
        <div>
            <label
                className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">{"Variable cible"}</label>
            <Select
                value={variables.length === 0 ? null : variables[variables.findIndex(value => value.value === params.target_variable)]}
                isSearchable={true}
                onChange={(option) => {
                    dispatch(setModelParams({type: "target_variable", value: option.label}))
                }}
                options={variables}
            />
        </div>
    </div>
}
export default ModelParamCreation;