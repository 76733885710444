import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import DataForm from "../../components/DataForm/DataForm";
import Checkbox from "../../components/Selectors/Checkbox";

import {canRenderComponent, makeSelectField} from "../../utils/common";
import {
  handleRangeMapping,
  handleSourceParameters,
  handleType,
} from "../../utils/configuredSourceUtils";
import Select from "react-select";
import {useSelector} from "react-redux";
import {
  addConfiguredSource,
  addSourceParam,
  getSourceParam,
} from "../../network/DataManagementApi";
import {COMPONENTS} from "../../constants/component";

const ConfiguredSource = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedDataSource, setAuthorizedDataSource] = useState(
    canRenderComponent(COMPONENTS.CONFIGURED_SOURCE_FROM_EXISTING_ONE)
  );
  useEffect(() => {
    return () => {
      setAuthorizedDataSource(
        canRenderComponent(COMPONENTS.CONFIGURED_SOURCE_FROM_EXISTING_ONE)
      );
    };
  }, [authorizedComponent]);
  const crudeSources = useSelector((state) => state.data.crude_source);

  const observedSystem = sessionStorage.getItem("observed_system_id");

  const [crudeSource, setCrudeSource] = useState(null);
  useEffect(() => {
    if (observedSystem) {
      setCrudeSource(null);
      setSelectedCrudeSource(null);
      setLoadingCrudeSource(false);
    }
  }, [observedSystem]);
  const [crudeSourceOptions, setCrudeSourceOptions] = useState([]);
  useEffect(
    () => setCrudeSourceOptions(makeSelectField(crudeSources, ["description"])),
    [crudeSources]
  );
  const [sourceParameter, setSourceParameter] = useState(null);
  useEffect(() => {
    if (crudeSource === null) setSourceParameter(null);
    if (crudeSource && observedSystem) {
      if (observedSystem && crudeSource) {
        setLoadingCrudeSource(true);
        getSourceParam(observedSystem, crudeSource, (res) => {
          setSourceParameter(res.data.data);
          setLoadingCrudeSource(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudeSource]);
  const [loadingCrudeSource, setLoadingCrudeSource] = useState(false);
  const [selectedCrudeSource, setSelectedCrudeSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const canAdd =
    observedSystem !== null && crudeSource !== null && !loadingCrudeSource;

  const [modifySourceParameter, setModifySourceParameter] = useState(false);
  const [modifyRangeMapping, setModifyRangeMapping] = useState(false);
  const handleSend = () => {
    setLoading(true);
    let send_data = {
      description: sourceParameter.description,
      alert_delay: sourceParameter.alert_delay,
      is_active: sourceParameter.is_active,
      crude_source_id: crudeSource,
      measure_index:
      sourceParameter["source_parameters_info"].measure_identifier,
      source_parameters_identifier:
      sourceParameter["source_parameters_info"].identifier,
      source_parameters_id: sourceParameter["source_parameters_info"].id,
      id: sourceParameter["conf_id"] === "" ? null : sourceParameter["conf_id"],
    };
    const callback = (res) => {
      switch (res.data.status) {
        case "error":
          toast.error(res.data.message.toString());
          setLoading(false);
          break;
        case "success":
          toast.success(res.data.message.toString());
          setLoading(false);
          break;
        default:
          break;
      }
    };
    if (modifyRangeMapping || modifySourceParameter) {
      send_data["source_parameters_data"] = {
        source_parameters_values: sourceParameter.source_parameters_values,
        values: sourceParameter.values,
      };
      send_data["source_parameters_id"] =
        sourceParameter["source_parameters_info"].id;
      addSourceParam(observedSystem, send_data, callback);
    } else addConfiguredSource(observedSystem, send_data, callback);
  };
  const onChange = (name, value) => {
    setSourceParameter((sourceParameter) => ({
      ...sourceParameter,
      [name]: value,
    }));
  };

  const field = [];
  if (observedSystem) {
    field.push({
      id: 9999,
      name: "Source brute",
      type: (
        <Select
          isLoading={loadingCrudeSource}
          isDisabled={loadingCrudeSource}
          options={crudeSourceOptions}
          onChange={(option) => {
            setSelectedCrudeSource(
              crudeSourceOptions.find((value) => value.value === crudeSource)
            );
            setCrudeSource(option.value);
          }}
          value={selectedCrudeSource}
          name={"crude_source"}
        />
      ),
      tooltip: false,
    });
  }
  if (crudeSource) {
    field.push({
      id: 99999998,

      type: (
        <Checkbox
          label="Modifier les paramètres de la source"
          name={"sp_modify"}
          checked={modifySourceParameter}
          onChange={() => {
            setModifySourceParameter(!modifySourceParameter);
          }}
        />
      ),
      tooltip: false,
    });
    field.push({
      id: 99999997,
      type: (
        <Checkbox
          label="Modifier les données des valeurs"
          name={"rm_modify"}
          checked={modifyRangeMapping}
          onChange={() => {
            setModifyRangeMapping(!modifyRangeMapping);
          }}
        />
      ),
      tooltip: false,
    });
  }
  if (sourceParameter) {
    let id = 10;
    if (modifyRangeMapping) {
      field.push({
        id: 790,
        type: <h2 className="text-theme1 text-xl font-semibold">Variables</h2>,
        tooltip: false,
      });
      Object.entries(sourceParameter["values"]).forEach((value) => {
        field.push(
          handleRangeMapping(
            {
              value: value[1],
              id: id,
              name: value[0],
            },
            setSourceParameter,
            sourceParameter,
            onChange
          )
        );
        ++id;
      });
    }
    if (modifySourceParameter) {
      field.push({
        id: 789,
        type: (
          <h2 className="text-theme1 text-xl font-semibold">
            Paramètres de source
          </h2>
        ),
        tooltip: false,
      });
      Object.entries(sourceParameter["source_parameters_values"]).forEach(
        (value) => {
          field.push(
            handleSourceParameters(
              {
                value: value[1],
                id: id,
                name: value[0],
              },
              setSourceParameter,
              sourceParameter,
              onChange
            )
          );
          ++id;
        }
      );
    }
    if (!loadingCrudeSource && crudeSource) {
      field.push({
        id: 791,
        type: (
          <h2 className="text-theme1 text-xl font-semibold">Configuration</h2>
        ),
        tooltip: false,
      });
      field.push(
        handleType(
          {
            id: 0,
            name: "conf_id",
            value: sourceParameter.conf_id,
          },
          onChange
        )
      );
      field.push(
        handleType(
          {
            id: 1,
            name: "is_active",
            value: sourceParameter.is_active,
          },
          onChange
        )
      );
      field.push(
        handleType(
          {
            id: 2,
            name: "description",
            value: sourceParameter.description,
          },
          onChange
        )
      );
    }
  }
  return (
    authorizedDataSource && (
      <DataForm
        isLoading={loading}
        disabled={!canAdd}
        information={"Source configurée"}
        description={
          "Formulaire de création de source configurée. Permet de modifier les paramètres de la dernière configuration et d'en créer une nouvelle"
        }
        field={field}
        callback={handleSend}
      />
    )
  );
};
export default ConfiguredSource;
