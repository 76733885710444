import {useMemo} from "react";
import Card from "../Card/Card";

const TableSP = ({data, name}) => {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => {
          return row.name;
        },
        id: "name",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          return row.type;
        },
        id: "type",
        header: "Type",
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: (row) => {
          if (row.type === "date") return row.value.date + row.value.offset;
          return row.value;
        },
        id: "value",
        header: "Valeur",
        cell: (info) => info.getValue(),
      },
    ],
    []
  );
  return <Card data={data} name={name} columns={columns}/>;
};
export default TableSP;
