import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {current, historical, redirect} from "./EventUtils";
import {
  initEvent,
  removeDataParams,
  setBase,
} from "../../reducers/EventSlice";
import Select from "react-select";
import {getSourceWithMeasure, makeSelectField} from "../../utils/common";
import DataParamCreation from "./DataParamCreation";
import TimeParamCreation from "./TimeParamCreation";
import ComputationParamCreation from "./ComputationParamCreation";
import Button from "../../components/Selectors/Button";
import ModelParamCreationName from "./ModelParamCreationName";
import {useNavigate} from "react-router-dom";
import {addEvent} from "../../network/DataManagementApi";

const AutoregressivepredictionEvent = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const init = {
    current: structuredClone(current),
    historical: structuredClone(historical),
    model_params: {
      model_name: "",
      target_variable: "",
      threshold: 0.95,
      delay: 1,
      order: 1,
      predictor_variables: [],
    },
  };

  const selectedSource = useSelector((state) => state.event.selectedSource);
  const data = useSelector((state) => state.event);
  const source = useSelector((state) => state.event.source);
  const sourceId = useSelector((state) => state.event.sourceId);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    dispatch(
      initEvent({
        data: structuredClone(init),
        source: getSourceWithMeasure("crude_source"),
        selectedSource: null,
        histo: true,
        count: true,
        sourceId: false,
        lockedHisto: true,
      })
    );
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const create = () => {
    const state = structuredClone(data);
    const formattedData = {};
    formattedData["module_name"] = "Autoregressiveprediction";
    formattedData["function_name"] = "getDataLabeled";
    formattedData["parameters"] = state.data;
    state.count
      ? delete formattedData["parameters"]["historical"]["time_params"]["time"]
      : delete formattedData["parameters"]["historical"]["time_params"][
        "count"
        ];
    formattedData["parameters"]["model_params"]["predictor_variables"] =
      formattedData["parameters"]["model_params"]["predictor_variables"].map(
        (value) => value.value
      );

    addEvent(formattedData, () => history(redirect));
  };
  return (
    <div>
      {loaded && (
        <div>
          <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <h2 className="text-theme1 text-2xl font-semibold">
              Paramètres des données
            </h2>
            <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">
              Source de données
            </label>
            <Select
              placeholder={"Source de données..."}
              openMenuOnClick={false}
              isClearable={true}
              isMulti={true}
              value={selectedSource}
              options={makeSelectField(source, ["name"])}
              isDisabled={sourceId}
              isLoading={sourceId}
              onChange={(option, action) => {
                if (action.action === "remove-value") {
                  const measure_identifier =
                    configurations[
                      source[action.removedValue.value].configuration[0]
                      ].measure_identifier;
                  dispatch(removeDataParams(measure_identifier));
                  dispatch(setBase({type: "selectedSource", value: option}));
                }
                if (action.action === "select-option") {
                  dispatch(setBase({type: "selectedSource", value: option}));
                  dispatch(
                    setBase({type: "sourceId", value: action.option.value})
                  );
                }
              }}
            />

            {sourceId && <DataParamCreation/>}
          </div>
          <TimeParamCreation/>
          <ComputationParamCreation/>
          <ModelParamCreationName/>
        </div>
      )}
      <div className="grid grid-cols-7 gap-4 pt-2">
        <span className="col-span-3"/>
        <Button label={"Créer"} disabled={false} callback={create}/>
      </div>
    </div>
  );
};
export default AutoregressivepredictionEvent;
