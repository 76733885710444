//React librairies
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

//Actions
//Components
import Button from "../components/Selectors/Button";
import TextInput from "../components/Selectors/TextInput";

//Utils
import {isEmptyString} from "../utils/common";

import {loginUser} from "../network/UserManagementApi";
import {getAll, getStructureAll} from "../network/DataManagementApi";

const Login = () => {
  //Use navigate
  const history = useNavigate();

  //Use state
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [changePwd, setChangePwd] = useState(false);
  const onChange = (name, value) => {
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const authenticate = () => {
    setIsLoading(true);

    const data = {
      username: login["email"],
      password: login["password"],
    };
    loginUser(data, (res) => {
      console.debug(res);
      res.data.data.id_role !== 1
        ? history("/")
        : history("/internal/structure");
      setIsLoading(false);
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !canLogin) {
      authenticate();
    }
  };

  const canLogin =
    (!changePwd &&
      (isEmptyString(login.email) ||
        isEmptyString(login.password) ||
        isLoading)) ||
    (changePwd && isEmptyString(login.email));

  return (
    <div
      className=" min-h-screen flex  justify-center  py-12 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover relative items-center"
      style={{ backgroundImage: "url(./login.jpg)" }}
    >
      <div
        className="max-w-md w-full space-y-8 p-10 bg-white dark:bg-gray-900 rounded-xl z-10"
        onKeyDown={handleKeyDown}
      >
        <div className="text-center">
          <h2 className="mt-6 text-2xl font-bold text-gray-900 dark:text-white">
            PREDITIC
          </h2>
          <p className="mt-2 text-sm text-gray-600 dark:text-white">
            Veuillez remplir les informations ci-dessous pour vous authentifier
          </p>
        </div>
        <hr />
        <div className="mt-8 space-y-6">
          <div className="content-center">
            <TextInput
              label="Email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              value={login.email}
            />
          </div>
          <div className="content-center">
            {!changePwd && (
              <TextInput
                label="Mot de passe"
                name="password"
                onChange={onChange}
                placeholder="Mot de passe"
                type="password"
                value={login.password}
              />
            )}
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Mot de passe oublié ?
              <button
                className=" pl-1 text-blue-600 decoration-2 hover:underline font-medium"
                onClick={() => setChangePwd(!changePwd)}
              >
                {changePwd ? "Je m'en souviens" : "Réinitialiser le ici"}
              </button>
            </p>
          </div>
          <div className={"mt-3"}>
            <Button
              callback={authenticate}
              disabled={canLogin}
              isLoading={isLoading}
              label={
                changePwd ? "réinitialiser le mot de passe" : "Se connecter"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
