import React from "react";
import Select from "react-select";
import { dateFormat, mappingField } from "../../utils/field";
import TextInput from "../Selectors/TextInput";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { handleParamChange, handleParamsChange } from "../../utils/fileUtils";

const DateParam = ({ data, setData, id }) => {
  console.debug(data.col_type[id]);
  return (
    <div className=" ">
      <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Format de la date
      </label>
      <Select
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        name={id}
        onChange={(e) => {
          handleParamChange(e.value, id, data, setData, "format");
          console.debug(e);
        }}
        value={dateFormat.find(
          (value) => value.value === data.col_type[id].param.format
        )}
        options={dateFormat}
      />

      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Fuseau horaire
      </label>
      <TimezoneSelect
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        value={data.col_type[id].param.timezone}
        onChange={(timezone) =>
          handleParamsChange(
            [
              { value: timezone.value, name: "timezone" },
              { value: timezone.offset, name: "offset" },
            ],
            id,
            data,
            setData
          )
        }
        name={"timezone"}
      />
    </div>
  );
};
export default DateParam;
