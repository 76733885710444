import React, {useState} from "react";
import Recap from "../multi/Recap";
import Button from "../../components/Selectors/Button";
import {useSelector} from "react-redux";
import {parseCategorical} from "../../utils/common";
import ObservedSystemData from "../multi/ObservedSystemData";
import ConfiguredSourceData from "../multi/ConfiguredSourceData";
import {
  addDataSource,
  addObservedSystem,
} from "../../network/DataManagementApi";

const SystemAndSource = () => {
  const [observedSystemInfo, setObservedSystemInfo] = useState({
    storage_system_id: sessionStorage.getItem("storageSystemId"),
    membership: "",
    description: "",
    name: "",
    owner_ids: [],
  });
  const [isLoading, setLoading] = useState(false);
  const data = useSelector((state) => state.data);
  const [mappingValue, updateMappingValue] = useState([]);
  const [sourceParametersValue, updateSourceParametersValue] = useState([]);
  const [configuredSourceInfo, setConfiguredSourceInfo] = useState({
    source_type: "", // TODO correspond to  generic sensor - v2 will bring modifiable source_type, not depending on a structure.
    description: "",
    name: "",
    observed_system_id: sessionStorage.getItem("observedSystemId"),
    alert_delay: 0,
    is_active: false,
    configured_source_description: "",
    storage_system_id: "",
    id: "",
  });
  const handleRequest = () => {
    setLoading(true);
    const mapping = {};
    const mapping_information = {};

    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value: param.value,
        name: param.name,
        type: param.type,
      });
    });
    mappingValue.forEach((val) => {
      mapping[val.name] = val.format;

      if (val.type === "categorial")
        mapping_information[val.name] = parseCategorical(val.format, val.param);
      else if (val.type === "numeric")
        mapping_information[val.name] = {
          min: val.param.minValue,
          max: val.param.maxValue,
          min_included: true,
          max_included: true,
        };
      else if (val.type === "string") mapping_information[val.name] = val.param;
      else mapping_information[val.name] = {};
    });
    const data_obs = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystemInfo["storage_system_id"],
      name: observedSystemInfo["name"],
      description: observedSystemInfo["description"],
      membership: observedSystemInfo["membership"],
      owner_ids:
        observedSystemInfo["membership"] === "structure"
          ? []
          : observedSystemInfo["owner_ids"],
    };
    const data_conf = {
      name: configuredSourceInfo.name,
      description: configuredSourceInfo.description,
      source_type: configuredSourceInfo.source_type,
      configured_source_description:
      configuredSourceInfo.configured_source_description,
      mapping: mapping,
      mapping_information: mapping_information,
      is_active: configuredSourceInfo.is_active,
      alert_delay: parseInt(configuredSourceInfo.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: configuredSourceInfo.storage_system_id,
      id: configuredSourceInfo.id === "" ? null : configuredSourceInfo.id,
    };
    addObservedSystem(data_obs, (res) => {
      data_conf["system"] = res.data["id"];
      addDataSource(data_conf, () => setLoading(false));
    });
  };
  return (
    <div>
      <ObservedSystemData
        observedSystem={observedSystemInfo}
        setObservedSystem={setObservedSystemInfo}
        isMulti={true}
      />
      <ConfiguredSourceData
        selectObs={false}
        isMulti={true}
        source={configuredSourceInfo}
        setSource={setConfiguredSourceInfo}
        mappingValue={mappingValue}
        updateMappingValue={updateMappingValue}
        sourceParametersValue={sourceParametersValue}
        updateSourceParametersValue={updateSourceParametersValue}
      />
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Recap</h2>
        <Recap
          obs_info={observedSystemInfo}
          conf_info={configuredSourceInfo}
          mv_info={mappingValue}
          sp_info={sourceParametersValue}
          storage_info={data.storage_system}
          os_info={data.observed_system}
        />
        <div className="flex justify-center py-2">
          <Button
            isLoading={isLoading}
            callback={handleRequest}
            disabled={false}
            extraStyle={
              "block px-4 py-2 rounded-lg mx-auto shadow-lg cursor-pointer transition ease-in duration-300"
            }
            label={"Ajouter"}
          />
        </div>
      </div>
    </div>
  );
};
export default SystemAndSource;
