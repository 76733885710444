import { useEffect, useState } from "react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
export function TableFilter({value: initialValue, onChange, debounce = 500}) {
    //Use state
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce);

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <div className="flex my-1 w-full">
        <div className="w-8 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
          <MagnifyingGlassCircleIcon className="text-gray-400 text-lg" />
        </div>
        <input
          type="text"
          className="bg-gray-200 dark:bg-gray-900 block rounded-lg border-gray-300 shadow-sm
                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full -ml-10 pl-10 py-2 outline-none"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          placeholder="Chercher..."
        />
      </div>
    );
}