import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  canRenderAction,
  canRenderComponent,
  isEmptyObject,
} from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import store from "../../store";
import ActionButtons from "../Selectors/ActionButtons";
import Select from "react-select";
import { getEvent, getModule } from "../../network/DataManagementApi";
import { set } from "../../reducers/dataSlice";

const IndicatorList = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [canView] = useState(
    canRenderAction(ACTION_FLAGS.CANVIEW, COMPONENTS.INDICATOR_DISPLAY)
  );
  const [authorizedIndicatorCreation, setAuthorizedIndicatorCreation] =
    useState(canRenderComponent(COMPONENTS.INDICATOR_CREATION));
  const [authorizedIndicatorComputation, setAuthorizedIndicatorComputation] =
    useState(canRenderComponent(COMPONENTS.INDICATOR_COMPUTATION));

  useEffect(() => {
    return () => {
      setAuthorizedIndicatorCreation(
        canRenderComponent(COMPONENTS.INDICATOR_CREATION)
      );
      setAuthorizedIndicatorComputation(
        canRenderComponent(COMPONENTS.INDICATOR_COMPUTATION)
      );
    };
  }, [authorizedComponent]);
  const moduleName = useSelector((state) => state.data.moduleName).map(
    (name) => {
      return { value: name, label: name };
    }
  );
  useEffect(() => {
    if (moduleName.length === 0) getModule();
  }, [moduleName]);
  const indicatorList = useSelector((state) => state.data.event);
  const updateIndicator = () => {
    getEvent().then((res) => {
      store.dispatch(
        set({
          type: "event",
          data: res.data.data,
        })
      );
      setRefresh(false);
    });
  };
  useEffect(() => {
    if (isEmptyObject(indicatorList)) updateIndicator();
  }, [indicatorList]);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    setData(Object.entries(indicatorList));
  }, [indicatorList]);
  const createIndicator = () => history("/analytics/indicator/create");
  const computeIndicator = () => history("/analytics/indicator/compute");

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[1].creation_timestamp, {
      id: "timestamp",
      header: "Date de création",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].module_name, {
      id: "name",
      header: "Type",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[0], {
      id: "id",
      header: "id",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          canView={canView}
          handleView={() => {
            const row = info.row.original;
            history("/analytics/indicator/view/" + row[0]);
          }}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  console.debug(data, indicatorList);
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3 w-1/6">
          <Select
            isClearable={true}
            id="name"
            className={"w-full text-theme3"}
            value={globalFilter}
            onChange={(e) => {
              setGlobalFilter(e);
            }}
            options={moduleName}
          />
        </div>

        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                updateIndicator();
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedIndicatorCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button
                callback={createIndicator}
                label="Création d'un indicateur"
              />
            </div>
          )}
          {authorizedIndicatorComputation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button
                callback={computeIndicator}
                label="Calcul d'un indicateur"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default IndicatorList;
