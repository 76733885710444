import CorrelationRightPanel from "./CorrelationRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const Correlation = () => {

  return <div className="grid grid-cols-2 gap-4 mx-4 py-2">
    <IndicatorDataConfiguration/>
    <CorrelationRightPanel/>
  </div>

}
export default Correlation