import React, {useState} from "react";
import TextInput from "../../components/Selectors/TextInput";
import {isEmptyString} from "../../utils/common";
import DataForm from "../../components/DataForm/DataForm";
import {addStructure} from "../../network/DataManagementApi";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const Structure = () => {
  const history = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const onChange = (name, value) => {
    setData((prevState) => {
      return {...prevState, [name]: value};
    });
  };

  const add = () => {
    setLoading(true);
    addStructure(data, () => setLoading(false));
  };

  return (
    <div>
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <div className="flex gap-x-1.5">
          <IconButton
            bg={true}
            Icon={<Prev size={40}/>}
            value={""}
            callback={() => history("/internal/structure")}
          />
          <h2 className="text-theme1 text-3xl font-semibold">
            Création d'une structure
          </h2>
        </div>
      </div>
      <DataForm
        callback={add}
        isLoading={isLoading}
        disabled={isEmptyString(data.name) || isEmptyString(data.description)}
        field={[
          {
            type: (
              <TextInput
                name={"name"}
                value={data["name"]}
                onChange={onChange}
                placeholder={"Nom de la structure"}
              />
            ),
            tooltip: false,
            id: 0,
            name: "Nom",
          },
          {
            type: (
              <TextInput
                name={"description"}
                value={data["description"]}
                onChange={onChange}
                placeholder={"Description de la structure"}
              />
            ),
            tooltip: false,
            id: 1,
            name: "Description",
          },
        ]}
      />
    </div>
  );
};
export default Structure;
