import {Popover, Tab, Transition} from "@headlessui/react";
import Button from "../../components/Selectors/Button";
import {isEmptyObject} from "../../utils/common";
import {useState} from "react";
import {usePopper} from "react-popper";
import TextInput from "../../components/Selectors/TextInput";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";

function IndicatorResult({type, result}) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let {styles, attributes} = usePopper(referenceElement, popperElement);

  const resultField = (type) => {
    switch (type) {
      case 2:
        return (
          <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <div className="grid grid-cols-2 gap-1.5">
              <TextInput
                disabled={true}
                value={result?.nb_samples}
                label={"Nombre de données"}
              />
              <TextInput
                disabled={true}
                value={result?.computation_duration}
                label={"Temps de calcul"}
              />
            </div>
            <EmptyDatesRender dates={result.empty_dates}/>
            <div className={"py-2"}>
              <Tab.Group>
                <Tab.List>
                  <Tab
                    className=" px-4 py-2  mx-1 font-semibold text-white  bg-theme1 rounded-lg  shadow-lg cursor-pointer  ui-selected:ring-2 ui-selected:ring-theme2 ui-not-selected:opacity-90">
                    Kendall
                  </Tab>
                  <Tab
                    className=" px-4 py-2  mx-1 font-semibold text-white  bg-theme1 rounded-lg  shadow-lg cursor-pointer  ui-selected:ring-2 ui-selected:ring-theme2 ui-not-selected:opacity-90">
                    Pearson
                  </Tab>
                  <Tab
                    className=" px-4 py-2  mx-1 font-semibold text-white  bg-theme1 rounded-lg  shadow-lg cursor-pointer  ui-selected:ring-2 ui-selected:ring-theme2 ui-not-selected:opacity-90">
                    Spearman
                  </Tab>
                </Tab.List>
                <Tab.Panels className={"py-2"}>
                  <Tab.Panel>
                    <ResultCorrelation
                      tip={"Kendall"}
                      name={"Kendall"}
                      matrix={result.correlations.kendall}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <ResultCorrelation
                      tip={"Pearson"}
                      name={"Pearson"}
                      matrix={result.correlations.pearson}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <ResultCorrelation
                      tip={"Spearman"}
                      name={"Spearman"}
                      matrix={result.correlations.spearman}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <div className="grid grid-cols-2 gap-1.5">
              <TextInput
                disabled={true}
                value={result?.nb_samples}
                label={"Nombre de données"}
              />
              <TextInput
                disabled={true}
                value={result?.computation_duration}
                label={"Temps de calcul"}
              />
            </div>
            <EmptyDatesRender dates={result.empty_dates}/>
            <ResultFormulaArray array={result.computed_field}/>
          </div>
        );
      case 6:
        return (
          <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
            <div className="grid grid-cols-2 gap-1.5">
              <TextInput
                disabled={true}
                value={result?.computation_duration}
                label={"Temps de calcul (s)"}
              />
            </div>
            <div className="grid grid-cols-3 gap-1.5">
              <TextInput
                disabled={true}
                value={result?.indicators.mtbs}
                label={"MTBS (s)"}
              />
              <TextInput
                disabled={true}
                value={result?.indicators.mtis}
                label={"MTIS (s)"}
              />
              <TextInput
                disabled={true}
                value={result?.indicators.str}
                label={"STR (%)"}
              />
            </div>
          </div>
        );
      default:
        return "WIP";
    }
  };
  return isEmptyObject(result) || result.status === "error" ? (
    <Button disabled={true} label={"Résultat"}/>
  ) : (
    <Popover className="relative">
      <Popover.Button ref={setReferenceElement}>
        <Button label={"Résultat"}/>
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <div className="w-screen flex justify-center ml-14">
            <div className="w-min-1/2 ">{resultField(type)}</div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default IndicatorResult;

function ResultFormulaArray({array}) {
  const [showAll, setShowAll] = useState(false);

  return (
    <div className="flex flex-col pt-4 h-80">
      {array.length !== 0 && (
        <Button
          label={showAll ? "Afficher moins" : "Afficher plus"}
          callback={() => setShowAll(!showAll)}
        />
      )}
      <div className={"overflow-scroll max-h-80 grid grid-cols-4 "}>
        {array.slice(0, showAll ? array.length : 4).map((item, index) => (
          <div key={index} className="p-4 border-2 even:bg-gray-50">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

const renderMatrixCell = (key, value) => {
  let className = "bg-gray-300";
  if (value === 1) {
    className = "text-white bg-red-700";
  } else if (value > 0.80) {
    className = "text-white bg-red-600";
  } else if (value > 0.6) {
    className = "text-white bg-red-500";
  } else if (value > 0.4) {
    className = "text-white bg-red-400";
  } else if (value > 0.2) {
    className = "bg-red-300";
  } else if (value > 0.1) {
    className = "bg-red-200";
  } else if (value > 0) {
    className = "bg-red-100";
  } else if (value === -1) {
    className = "text-white bg-blue-700";
  } else if (value < -0.8) {
    className = "text-white bg-blue-600";
  } else if (value < -0.6) {
    className = "text-white bg-blue-500";
  } else if (value < -0.4) {
    className = "text-white bg-blue-400";
  } else if (value < -0.2) {
    className = "bg-blue-300";
  } else if (value < -0.1) {
    className = "bg-blue-200";
  } else if (value < 0) {
    className = "bg-blue-100";
  }
  return <td key={key} className={`border hover:font-semibold p-2 text-center  ${className}`}>
    {value}
  </td>

};

const ResultCorrelation = ({matrix, name, tip}) => {
  return (
    <table className="table-auto">
      <thead>
      <tr>
        <th className="border p-2 bg-gray-50 ">
          <div className={"flex"}>
            <LabelTooltip label={name} tip={tip}/>
          </div>
        </th>
        {Object.keys(matrix).map((colKey) => (
          <th
            key={colKey}
            className="font-semibold border p-2 bg-theme1 text-white"
          >
            {colKey}
          </th>
        ))}
      </tr>
      </thead>
      <tbody>
      {Object.entries(matrix).map(([rowKey, rowValue]) => (
        <tr key={rowKey} className="odd:bg-gray-50 ">
          <td className=" border p-2 text-white bg-theme1 font-semibold">
            {rowKey}
          </td>
          {Object.entries(rowValue).map(([colKey, colValue]) => renderMatrixCell(colKey, colValue))}
        </tr>
      ))}
      </tbody>
    </table>
  );
};

function transformObject(obj) {
  const transformedObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (!transformedObj[value]) {
      transformedObj[value] = [];
    }
    transformedObj[value].push(key);
  });
  return transformedObj;
}

function EmptyDatesRender({dates}) {
  const formattedDates = transformObject(dates);
  if (formattedDates.length === 0)
    return (
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Aucune valeur manquante
      </label>
    );
  return Object.entries(formattedDates).map((value) => (
    <div>
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        {value[0]}
      </label>
      <div className="grid grid-cols-3 gap-x-1.5 mx-4">
        {value[1].map((date) => (
          <TextInput disabled={true} value={date}/>
        ))}
      </div>
    </div>
  ));
}
