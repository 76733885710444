import {Switch} from "@headlessui/react";

function SwitchButton({
  label,
  onChange,
  value,
  color = "bg-primary-theme-800",
}) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={value}
          onChange={onChange}
          className={`${
            value ? color : "bg-gray-400"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors `}
        >
          <span
            className={`${
              value ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
        <Switch.Label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide px-1.5">
          {label}
        </Switch.Label>
      </div>
    </Switch.Group>
  );
}

export default SwitchButton;
