import React from 'react';
import TextInput from "../../../components/Selectors/TextInput";

const ModelParamView = ({data}) => {
    console.debug(Object.keys(data))
    const makeTextInput = () => {
        return Object.keys(data).map(value => {
            switch (value) {
                case "threshold":
                    return <TextInput name={"threshold"} type={"number"} value={data.threshold}
                                      disabled={true}
                                      label={"Seuil"} step={"0.01"}/>
                case "model_name":
                    return <TextInput name={"model_name"} value={data.model_name}
                                      disabled={true}
                                      label={"Nom"}/>
                case "target_variable":
                    return <TextInput name={"target_variable"} value={data.target_variable}
                                      disabled={true}
                                      label={"Variable cible"}/>
                case "time_field":
                    return <TextInput name={"time_field"} value={data.time_field}
                                      disabled={true}
                                      label={"Variable de temps"}/>
                default:
                    return <p>WIP {value} </p>
            }
        })
    }
    return (<div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètres du modèle</h2>
        <div className={"grid grid-cols-3 gap-4"}>
            {makeTextInput()}
        </div>
    </div>);
}

export default ModelParamView;
