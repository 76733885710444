import {useState} from "react";
import Button from "../../components/Selectors/Button";

import {parseCategorical} from "../../utils/common";
import FileUpload from "../configuration/FileUpload";
import ErrorPanel from "../ErrorPanel";

import RecapCsv from "./RecapCsv";
import {useSelector} from "react-redux";
import ConfiguredSourceData from "./ConfiguredSourceData";
import {fileUpload} from "../../network/DataManagementApi";

const MultiFormFile = ({selectObs = true, callback}) => {
  const storageSystemIds = useSelector((state) => state.data.storage_system);
  const observedSystem = useSelector((state) => state.data.observed_system);

  const [file, setFile] = useState(undefined);
  const [data, setData] = useState({
    col_type: [],
    error: [],
    preview: [],
    data: [],
    delimiter: ""
  });
  const [sourceParametersValue, updateSourceParametersValue] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [configuredSourceInfo, setConfiguredSourceInfo] = useState({
    source_type: "",
    description: "",
    name: "",
    alert_delay: 0,
    is_active: false,
    configured_source_description: "",
    storage_system_id: "",
    id: "",
    sameStorageType: true,
    system: sessionStorage.getItem("observed_system_id"),
  });

  const handleRequest = () => {
    setLoading(true);

    const mapping = {};
    const mapping_information = {};

    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value: param.value,
        name: param.name,
        type: param.type,
      });
    });

    data.col_type.forEach((field) => {
      const param = field.param;
      console.log(field);
      mapping[field["column"]] = field["type"];
      if (field["type"] === "categorical") {
        mapping[field["column"]] = param["type"];
        mapping_information[field["column"]] = parseCategorical(
          param.type,
          param.label
        );
      } else if (field["type"] === "float" || field["type"] === "int")
        mapping_information[field["column"]] = {
          min: param.minValue,
          max: param.maxValue,
          min_included: true,
          max_included: true,
        };
      else if (field["type"] === "string")
        mapping_information[field["column"]] = param.length;
      else if (field["type"] === "date") {
        mapping_information[field["column"]] = {
          timezone: param.timezone,
          format: param.format,
        };
        mapping[field["column"]] = param.type;
      }
    });

    const data_conf = {
      name: configuredSourceInfo.name,
      description: configuredSourceInfo.description,
      source_type: configuredSourceInfo.source_type,
      configured_source_description:
      configuredSourceInfo.configured_source_description,
      mapping: mapping,
      mapping_information: mapping_information,
      is_active: configuredSourceInfo.is_active,
      alert_delay: parseInt(configuredSourceInfo.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: configuredSourceInfo.storage_system_id,
      id: configuredSourceInfo.id === "" ? null : configuredSourceInfo.id,
      delimiter: data.delimiter
    };
    if (callback !== undefined) {
      console.debug(file, data_conf)
      callback(file, data_conf, setLoading);
      return;
    }
    fileUpload(configuredSourceInfo.system, file, data_conf, (res) => {
      console.debug(res);
      setLoading(false);
    });
  };

  return (
    <div>
      <ConfiguredSourceData
        isMulti={true}
        selectObs={selectObs}
        source={configuredSourceInfo}
        setSource={setConfiguredSourceInfo}
        selectMappingValues={false}
        info={configuredSourceInfo}
        setInfo={setConfiguredSourceInfo}
        sourceParametersValue={sourceParametersValue}
        updateSourceParametersValue={updateSourceParametersValue}
        storageSystemIds={storageSystemIds}
      />
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Fichier</h2>
        <FileUpload
          data={data}
          setData={setData}
          file={file}
          setFile={setFile}
        />
      </div>
      <div className="bg-white pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Recap</h2>
        <RecapCsv
          file_data_info={data}
          sp_info={sourceParametersValue}
          conf_info={configuredSourceInfo}
          os_info={observedSystem}
          storage_info={storageSystemIds}
        />
        <div className="flex justify-center py-2">
          <Button
            isLoading={isLoading}
            callback={handleRequest}
            disabled={false}
            extraStyle={
              "block px-4 py-2 rounded-lg mx-auto shadow-lg cursor-pointer transition ease-in duration-300"
            }
            label={"Ajouter"}
          />
        </div>
      </div>
      {data.error.length !== 0 && (
        <div className="bg-white pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
          <ErrorPanel error={data.error}/>
        </div>
      )}
    </div>
  );
};
export default MultiFormFile;
