import IndustrialRightPanelHisto from "./IndustrialRightPanelHisto";
import {useSelector} from "react-redux";
import IndustrialRightPanel from "../IndicatorIndustrial/IndustrialRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const IndustrialHisto = () => {
  const type = useSelector(
    (state) => state.indicator["moduleData"]["mode"]
  ).value;

  return (
    <div className="grid grid-cols-2 gap-4 mx-4 py-2">
      <IndicatorDataConfiguration isTwoBloc={true}/>
      {type === 0 && <IndustrialRightPanel/>}
      {type === 1 && <IndustrialRightPanelHisto/>}
    </div>
  );
};
export default IndustrialHisto;
