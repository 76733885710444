import IndicatorAccordion from "../IndicatorAccordion";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";
import IndustrialParam from "./IndustrialParam";
import DetectionAnomalieHistorical from "../DetectionAnomalie/DetectionAnomalieHistorical";

const IndustrialRightPanel = () => {
    return <div
      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"paramétrage des blocs historiques"}
                            composant={<DetectionAnomalieHistorical/>}/>
        <IndicatorAccordion label={"paramétrage des blocs courants"}
                            composant={<IndustrialCurrent/>}/>
        <IndicatorAccordion label={"Paramètre d'indicateur"}
                            composant={<IndustrialParam/>}/>
    </div>
}
export default IndustrialRightPanel;