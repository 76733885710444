import DetectionAnomalieVariableTable from "./DetectionAnomalieVariableTable";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {useEffect, useState} from "react";
import DetectionAnomalieRecap from "./DetectionAnomalieRecap";
import {makeVariableField} from "../../../utils/common";
import {addTwoBlocs} from "../utils";

const DetectionAnomalieVariable = () => {
    const selCrudeSource = useSelector(state => state.indicator.selSource)
    const mode = useSelector(state => state["indicator"]["moduleData"]["mode"])
    const selConfiguration = useSelector(state => state.indicator.selConfiguration)
    const crudeSources = useSelector(state => state.data.crude_source)
    const [bloc, setBloc] = useState({
        hist: {},
        cur: {},
        to_add: {}
    })
    const dispatch = useDispatch()
    useEffect(() => {
        setBloc((prevState) => ({
            ...prevState,
            hist: {},
            cur: {},
            to_add: {}
        }));
    }, [selCrudeSource])

    const [timeVariable, setTimeVariable] = useState({value: "internal", label: "timestamp interne"})

    return <div className="px-2 py-2 grid grid-cols-3">
        <h1 className="text-sm font-bold text-gray-700 dark:text-white tracking-wide py-2">
            Variable temporelle
        </h1>
        <Select
            options={makeVariableField(1, selCrudeSource.value, crudeSources[selCrudeSource.value]["measure"])}
            className="w-full col-span-2 rounded-lg outline-none"
            onChange={(value) => {
                setTimeVariable(value)
            }}
            value={timeVariable}
        />
        <DetectionAnomalieVariableTable
            variables={makeVariableField(0, selCrudeSource.value, crudeSources[selCrudeSource.value]["measure"])}
            bloc={bloc} setBloc={setBloc}
            mode={mode.value}
            add={() => addTwoBlocs(crudeSources, selCrudeSource, bloc, selConfiguration, timeVariable, dispatch,mode)}/>
        <DetectionAnomalieRecap/>

    </div>

}
export default DetectionAnomalieVariable;