import TimezoneSelect from "react-timezone-select";
import {useDispatch, useSelector} from "react-redux";
import {setModuleData} from "../../../reducers/indicatorSlice";
import Select from "react-select";
import {hasSeconds, makeVariableField} from "../../../utils/common";
import TextInput from "../../../components/Selectors/TextInput";

const MRDTimestamp = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);

  const selSource = useSelector((state) => state.indicator.selSource);
  const sources = useSelector((state) => state.data.crude_source);
  return (
    <div>
      <div className="grid grid-cols-3 gap-x-1.5">
        <div>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
            Variable temporelle
          </label>
          {selSource !== undefined && (
            <Select
              className="w-full rounded-lg outline-none "
              onChange={(value) =>
                dispatch(setModuleData({type: "timestamp", data: value}))
              }
              options={makeVariableField(
                1,
                selSource.value,
                sources[selSource.value]["measure"]
              )}
            />
          )}
        </div>
        <div>
          <TextInput
            value={module.initial_timestamp}
            label={"Date de début"}
            onChange={(name, value) => {
              dispatch(
                setModuleData({
                  type: "initial_timestamp",
                  data: hasSeconds(value) ? value : value + ":00",
                })
              );
            }}
            type={"datetime-local"}
            step={"1"}
          />
        </div>
        <div>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
            Fuseau horaire
          </label>
          <TimezoneSelect
            value={module.timezone}
            onChange={(e) => {
              dispatch(
                setModuleData({
                  type: "timezoneOffset",
                  data: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
                })
              );
              dispatch(setModuleData({type: "timezone", data: e.value}));
            }}
          />
        </div>
        {module.mode === 0 && (
          <div>
            <span className={"col-span-1"}/>

            <TextInput
              value={module.final_timestamp}
              label={"Date de fin"}
              onChange={(name, value) => {
                dispatch(
                  setModuleData({
                    type: "final_timestamp",
                    data: hasSeconds(value) ? value : value + ":00",
                  })
                );
              }}
              type={"datetime-local"}
              step={"1"}
            />
            <span className={"col-span-1"}/>
          </div>
        )}
      </div>
    </div>
  );
};
export default MRDTimestamp;
