import MRDRightPanel from "./MRDRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const MRD = () => {
  return (
    <div className="grid grid-cols-2 gap-4 m-4 py-2">
      <IndicatorDataConfiguration isDataParam={false}/>
      <MRDRightPanel/>
    </div>
  );
};
export default MRD; //
