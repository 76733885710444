import {flexRender} from "@tanstack/react-table";
import {Tooltip} from "react-tooltip";
import {useId} from "react";

export function TableBodyHoverable({table, callback}) {
    const id = useId()
    return (<tbody className="bg-white max-h-96 overflow-hidden">
    {table.getRowModel().rows.map(row => {
        return (<tr
            id={id + row.id}
            className={"odd:bg-gray-50 cursor-pointer"}
            key={row.id}>

            {row.getVisibleCells().map(cell => {
                console.debug(id + row.id)
                return (
                  <td
                    key={cell.id}
                    className={
                      "px-2 py-2 border overflow-hidden dark:bg-gray-900 text-justify "
                    }
                  >
                    <Tooltip
                      className=" p-0 z-40 bg-black opacity-100"
                      id={id + row.id}
                    >
                      {callback(row)}
                    </Tooltip>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
            })}
        </tr>)
    })}
    </tbody>);
}