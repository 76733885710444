import {useMemo} from "react";
import Card from "../Card/Card";

const TableMV = ({data, name}) => {
    const columns = useMemo(() => [{
        accessorFn: row => {
            return row.name
        }, id: "name", header: 'Nom', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row.type
        }, id: "type", header: 'Type', cell: info => info.getValue(),
    }, {
        accessorFn: row => {
            return row.param
        }, id: "param", header: 'Restriction', cell: info => info.getValue(),
    }], [])
    return <Card data={data} name={name} columns={columns}/>
};
export default TableMV;
