import {useState} from "react";
import TextInput from "./TextInput";
import TimezoneSelect from "react-timezone-select";
import {hasSeconds} from "../../utils/common";

const DateTimezoneCS = ({callback, date, name}) => {
  const offset = date.substring(19, 25);
  const newDate = date.substring(0, 19);
  const [timezone, setTimezone] = useState({
    timezone: "Etc/GMT",
    offset: offset,
  });


  return (
    <div className="flex gap-2">
      <span className="col-span-1">
        {" "}
        <TextInput
          extraStyle="w-fit py-1.5 rounded-lg outline-none border border-2px"
          onChange={(name, value) => {
            if (hasSeconds(value)) callback(name, value + timezone.offset);
            else callback(name, value + ":00" + timezone.offset);
          }}
          name={name}
          step={"1"}
          value={newDate}
          type={"datetime-local"}
          max={"2099-12-28T23:59:59"}
        />
      </span>
      <span className="w-full">
        <TimezoneSelect
          value={timezone.timezone}
          onChange={(e) => {
            setTimezone({
              timezone: e.value,
              offset: e.label.split(")")[0].replace("(GMT", ""),
            });
          }}
        />
      </span>
    </div>
  );
};
export default DateTimezoneCS;
