import React, {useCallback, useState} from "react";
import IconButton from "../../components/IconButton";
import {MdArrowLeft as Prev} from "react-icons/md";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import TextInput from "../../components/Selectors/TextInput";
import Button from "../../components/Selectors/Button";
import {createUser} from "../../network/UserManagementApi";
import {useSelector} from "react-redux";
import {makeSelectField} from "../../utils/common";

const CreateUser = () => {
    const profiles = useSelector(state => state.data.profiles)
    const profileOption = useCallback(
      () => {
          return makeSelectField(profiles, ["name"])
      },
      [profiles],
    );
    const [profile, setProfile] = useState(
      profileOption().find((value) => value.value === "1")
    );
    const history = useNavigate();
    const [data, setData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        enabled: true,
        attributes: {
            profile_id: "1",
            structure_id: sessionStorage.getItem("structure_id"),
            locale: ["fr"],
        },
        requiredActions: ["UPDATE_PASSWORD", "UPDATE_PROFILE", "VERIFY_EMAIL"],
        realmRoles: ["user_default"],
    });

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleAdd = () => {
        const attributes = {...data.attributes};
        attributes.profile_id = profile.value.toString();
        const body = {...data};
        body["username"] = body.email;
        body.attributes = attributes;
        createUser(body, (res) => "/settings/users");
    };

    return (
      <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
          <div className="flex gap-x-1.5">
            <IconButton
              bg={true}
              Icon={<Prev size={40}/>}
              value={""}
              callback={() => history("/settings/users")}
            />
            <h2 className="text-theme1 text-3xl font-semibold">
              Création d'un utilisateur
            </h2>
          </div>

          <div className={"mt-2"}>
              <div className={"grid grid-cols-2 gap-4"}>
                  <TextInput
                    name={"email"}
                    value={data.email}
                    onChange={onChange}
                    label={"Adresse email"}
                    type={"email"}
                  />
                  <TextInput
                    name={"firstName"}
                    value={data.firstName}
                    onChange={onChange}
                    label={"Prénom"}
                    type={"text"}
                  />
                  <TextInput
                    name={"lastName"}
                    value={data.lastName}
                    onChange={onChange}
                    label={"Nom"}
                    type={"text"}
                  />
                  <div>
                      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
                          Type de profil
                      </label>
                      <Select
                        className={"py-1"}
                        onChange={(e) => setProfile(e)}
                        value={profile}
                        options={profileOption()}
                      />
                  </div>
              </div>
          </div>
          <div className={"flex justify-end gap-2"}>
              <Button
                label={"Annuler"}
                callback={() => history("/settings/users")}
                color={"bg-red-700"}
                hover={"hover:bg-red-800"}
                focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
                extraStyle={
                    "px-2.5 py-2.5 text-center text-white font-medium rounded-lg text-sm w-fit"
                }
              />
              <Button
                label={"Ajouter"}
                callback={() => handleAdd()}
                color={"bg-theme1"}
                hover={"hover:bg-theme-3"}
                focus={"focus:ring-4 focus:outline-none focus:ring-blue-300 "}
                extraStyle={
                    "px-2.5  py-2.5 text-center  text-white   font-medium rounded-lg text-sm w-fit"
                }
              />
          </div>
      </div>
    );
};
export default CreateUser;
