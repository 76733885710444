import MRD from "./MonitoringRemonteeDonnees/MRD";
import DetectionAnomalie from "./DetectionAnomalie/DetectionAnomalie";
import FormuleAnalytique from "./FormuleAnalytique/FormuleAnalytique";
import Correlation from "./Correlation/Correlation";
import ConfigurationAlerte from "./ConfigurationAlerte/ConfigurationAlerte";
import IndustrialHisto from "./IndicatorIndustrialHisto/IndustrialHisto";
import TimeIndicator from "./TimeIndicator/TimeIndicator";
import OutlierValidation from "./OutlierValidation/OutlierValidation";

const IndicatorBody = ({id}) => {
    /**
     * Render the correct body for the indicator module corresponding to the ID.
     * @param id corresponding to a module ( see indicatorField in field.jsx
     * @returns {JSX.Element}
     */
    const body = (id) => {
        switch (id) {
            case 0:
                return <MRD/>
            case 1:
                return <DetectionAnomalie/>
            case 2:
                return <Correlation/>
            case 4:
                return <ConfigurationAlerte/>
            case 5:
                return <FormuleAnalytique/>
            case 6:
                return <IndustrialHisto/>
            case 7:
                return <TimeIndicator/>
            case 8:
                return <OutlierValidation />
            default:
                break;
        }
    }
    return body(id)
}
export default IndicatorBody;