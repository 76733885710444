import TextInput from "./TextInput";
import TimezoneSelect from "react-timezone-select";

const DateTimezone = ({handleTextInput, field}) => {
  console.debug(field);
  const onTimezoneChange = (id, value) => {
    handleTextInput(
      field,
      {
        offset: value.label.split(")")[0].replace("(GMT", ""),
        date: field.value.date,
        name: value.value,
      },
      "value"
    );
  };
  return (
    <div className="grid grid-cols-2 gap-x-0.5  ">
      <span className=" col-span-1">
        {" "}
        <TextInput
          extraStyle={"w-full py-1.5 rounded outline-none"}
          onChange={(id, value) => {
            handleTextInput(
              field,
              {
                offset: field.value.offset,
                date: value,
                name: field.value.name,
              },
              "value"
            );
          }}
          value={field.value.date}
          name={field.id}
          type={"datetime-local"}
          max={"2099-12-28T23:59"}
        />
      </span>
      <TimezoneSelect
        className={"border-black"}
        value={field.value.name}
        onChange={(e) => onTimezoneChange(field.id, e)}
        id={field.id}
        name={"timezone"}
      />
    </div>
  );
};
export default DateTimezone;
