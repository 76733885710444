import React, { Fragment, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "./TableFilter";
import Button from "../Selectors/Button";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { toast } from "react-toastify";
import { getActionLog } from "../../network/DataManagementApi";
import uniqueId from "lodash.uniqueid";
import {
  BarsArrowUpIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const TableLogAction = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const update = () => {
    setLoading(true);
    getActionLog().then((res) => {
      const formatted = JSON.parse(res.data.data);
      toast.success("Informations mises à jour.");
      setData(formatted);
      setLoading(false);
    });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("user_email", {
      id: "email",
      header: "Utilisateur",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("action_type", {
      id: "action_type",
      header: "Action",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("action_name", {
      id: "action_name",
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("timestamp", {
      id: "timestamp",
      header: "Date",
      cell: (info) => new Date(info.getValue() * 1000).toLocaleString(),
    }),
    columnHelper.display({
      id: "param",
      header: "Paramètre",
      cell: (info) => {
        const row = info.row.original;
        const id = info.cell.id;
        const action = row.display?.action;
        return (
          <div className={"flex justify-center gap-x-1.5"}>
            <Tooltip
              className="z-40 border box-shadow-custom-4-sides border-gray-200"
              id={id}
              variant={"light"}
            >
              {paramHandling(action)}
            </Tooltip>
            <BarsArrowUpIcon
              data-tooltip-id={id}
              className={
                "w-8 h-8 text-primary-theme-900 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              }
            />
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "status",
      cell: (info) => {
        const row = info.row.original;

        const message = row.display?.message ?? "N/A";
        const id_output = "output_" + info.cell.id;
        const status = row.display?.status ?? "N/A";
        return status === "ERROR" ? (
          <Fragment>
            <Tooltip
              className="z-40 border box-shadow-custom-4-sides border-gray-200"
              id={id_output}
              variant={"light"}
            >
              {message}
            </Tooltip>
            <ExclamationTriangleIcon
              data-tooltip-id={id_output}
              className={
                "w-8 h-8 text-red-600 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              }
            />
          </Fragment>
        ) : (
          <span className={"w-8 h-8"} />
        );
      },
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={loading}
              disabled={loading}
              callback={update}
              label="Mettre à jour"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default TableLogAction;

const paramHandling = (param) => {
  if (param === undefined) return "NO PARAM";

  return Object.entries(param).map((value) => {
    return (
      <li key={uniqueId()} className={"flex gap-x-1.5"}>
        <ul className={"font-semibold"}>{value[0]}:</ul>
        <ul>
          {typeof value[1] === "object" && value[1] !== null
            ? paramHandling(value[1])
            : value[1] !== null
            ? value[1].toString()
            : ""}
        </ul>
      </li>
    );
  });
};
