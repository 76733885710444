import IndustrialParam from "../IndicatorIndustrial/IndustrialParam";
import TextInput from "../../../components/Selectors/TextInput";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDeep, setModuleData} from "../../../reducers/indicatorSlice";
import Select from "react-select";
import {makeSelectField} from "../../../utils/common";

const TimeIndicatorParam = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.data.event);
  const time_threshold = useSelector(
    (state) =>
      state.indicator["moduleData"]["indicator_params"]["time_threshold"]
  );
  const selectedEvent = useSelector(
    (state) => state.indicator["moduleData"]["event"]
  );
  return (
    <div>
      <IndustrialParam/>
      <TextInput
        extraStyle={"w-full py-1.5 rounded outline-none border border-2px"}
        name={"time_threshold"}
        value={time_threshold}
        label={"Seuil de temps"}
        type={"number"}
        step={"1"}
        min={"0"}
        onChange={(name, value) => {
          const data = {
            type: "indicator_params",
            first: "time_threshold",
            data: parseInt(value),
          };
          dispatch(setDeep(data));
        }}
      />

      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
        Evenement à déclencher
      </label>
      <Select
        onChange={(e) => dispatch(setModuleData({type: "event", data: e}))}
        value={selectedEvent}
        options={makeSelectField(event, [
          "module_name",
          "function_name",
          "key",
        ])}
      />
    </div>
  );
};
export default TimeIndicatorParam;
