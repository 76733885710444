import FormuleAnalytiqueRightPanel from "./FormuleAnalytiqueRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const FormuleAnalytique = () => {
  return (
    <div className="grid grid-cols-2 gap-4 mx-4 py-2">
      <IndicatorDataConfiguration/>
      <FormuleAnalytiqueRightPanel/>
    </div>
  );
};
export default FormuleAnalytique;
