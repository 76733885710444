import IndicatorAccordion from "../IndicatorAccordion";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";
import DetectionAnomalieHistorical from "../DetectionAnomalie/DetectionAnomalieHistorical";
import {useSelector} from "react-redux";
import TimeIndicatorParam from "./TimeIndicatorParam";
import IndicatorRecurentForm from "../IndicatorRecurentForm";

const TimeIndicatorRightPanel = () => {
    const type = useSelector(state => state.indicator["moduleData"]["mode"]).value
    return <div

      className=" bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"Programmation d'exécution du module"}
                            composant={<IndicatorRecurentForm/>} open={false}/>
        {type === 0 && <IndicatorAccordion label={"paramétrage des blocs historiques"}
                                           composant={<DetectionAnomalieHistorical/>}/>}
        <IndicatorAccordion label={"paramétrage des blocs courants"}
                            composant={<IndustrialCurrent/>}/>
        <IndicatorAccordion label={"Paramètre d'indicateur"}
                            composant={<TimeIndicatorParam/>}/>
    </div>
}
export default TimeIndicatorRightPanel;