import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderAction } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableSelector } from "../TableSelector";
import ActionButtons from "../Selectors/ActionButtons";
import { TableFilter } from "./TableFilter";
import { deleteCrudeSource } from "../../network/DataManagementApi";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import DeleteModal from "../Selectors/DeleteModal";

import { selectedCrudeSource } from "../../reducers/SelectedSlice";

const CrudeSourceList = () => {
  const selectedCrudeSourceId = useSelector(
    (state) => state.selected.crude_source
  );
  const selectedObservedSystemId = useSelector(
    (state) => state.selected.observed_system
  );
  const [crudeSourceToDelete, setCrudeSourceToDelete] = useState(undefined);

  const [isDeleting, setIsDeleting] = useState(false);

  const [canDelete] = useState(
    canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.CRUDE_SOURCE_DISPLAY)
  );
  const [canEdit] = useState(
    canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.CRUDE_SOURCE_DISPLAY)
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const crudeSourceList = useSelector((state) => state.data.crude_source);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const handleCrudeSourceChange = (updater) => {
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const crudeSourceId = data[index][0];
    dispatch(selectedCrudeSource(crudeSourceId));
    sessionStorage.setItem("crude_source_id", crudeSourceId);
    setRowSelection(newSelected);
  };
  useEffect(() => {
    const newData = Object.entries(crudeSourceList);
    const index = newData.findIndex(
      (value) => value[0] === selectedCrudeSourceId
    );
    if (index !== -1) {
      setRowSelection({ [index]: true });
    }
    setData(newData);
  }, [crudeSourceList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "select",
      cell: ({ row }) => (
        <div className="px-1">
          <TableSelector
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => row[1].name, {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].description, {
      id: "desc",
      header: "Description",
      cell: (info) => (
        <p className={"w-full whitespace-normal"}>{info.getValue()}</p>
      ),
    }),
    columnHelper.accessor((row) => row[1].configuration.length, {
      id: "configurations",
      header: "Nombre de configuration",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/crude_source/edit/" + row[0]);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setCrudeSourceToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleCrudeSourceChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {crudeSourceToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            if (crudeSourceToDelete[0] === selectedCrudeSourceId) {
              dispatch(selectedCrudeSource(""));
            }
            deleteCrudeSource(
              crudeSourceToDelete[0],
              () => {
                setIsDeleting(false);
                setCrudeSourceToDelete(undefined);
              },
              selectedObservedSystemId
            );
          }}
          loading={isDeleting}
          text={
            "Êtes vous sûr de vouloir supprimer la structure " +
            crudeSourceToDelete[1].name +
            " ?"
          }
          modalVisible={crudeSourceToDelete}
          setModalVisible={setCrudeSourceToDelete}
        />
      )}
    </div>
  );
};
export default CrudeSourceList;
