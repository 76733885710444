import React from "react";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
/*

 */
const PrimaryButton = ({
  disabled = false,
  isLoading = false,
  label = "",
  callback,
}) => {
  return (
    <button
      disabled={disabled}
      className="flex gap-x-2 disabled:cursor-not-allowed disabled:opacity-50 bg-primary-theme-800 hover:bg-primary-theme-900 text-primary-theme-50 font-bold py-2 px-4 rounded"
      onClick={callback}
    >
      {isLoading ? (
        <div className={"animate-spin"}>
          <Icon path={mdiLoading} size={1} />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

const SecondaryButton = ({
  disabled = false,
  isLoading = false,
  label = "",
  callback,
}) => {
  return (
    <button
      disabled={disabled}
      className="flex gap-x-2 disabled:cursor-not-allowed disabled:opacity-50 border border-gray-300 hover:bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded"
      onClick={callback}
    >
      {isLoading ? (
        <div className={"animate-spin"}>
          <Icon path={mdiLoading} size={1} />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

const TertiaryButton = ({
  disabled = false,
  isLoading = false,
  label = "",
  callback,
}) => {
  return (
    <button
      disabled={disabled}
      className="flex gap-x-2 disabled:cursor-not-allowed disabled:opacity-50 text-primary-theme-200 hover:underline font-bold py-2 px-4 rounded"
      onClick={callback}
    >
      {isLoading ? (
        <div className={"animate-spin"}>
          <Icon path={mdiLoading} size={1} />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export { PrimaryButton, SecondaryButton, TertiaryButton };
