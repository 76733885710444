import TextInput from "./TextInput"

const NumericDataCS = ({
                           onChange, id, value, step = "1"
                       }) => {

    return (<div className=" grid grid-cols-2 gap-1">


        <TextInput placeholder={"min"}
                   value={value.min}
                   name={"min"}
                   type={"number"}
                   onChange={onChange}
                   step={step}/>

        <TextInput placeholder={"max"}
                   value={value.max}
                   name={"max"}
                   type={"number"}
                   onChange={onChange}
                   step={step}/>

    </div>)
}
export default NumericDataCS