import uniqueId from "lodash.uniqueid";

export const DetectionAnomalieMode = [
  {value: 0, label: "Kalman"},
  {value: 999, label: "Test"},
];
export const configurationAlerteMode = [
  {value: 0, label: "Historique"},
  {value: 1, label: "Courant"},
];
export const indicatorField = [
  {
    value: 0,
    script: "indicator_1_module",
    label: "Monitoring de remontée de données",
    multi: false,
  },
  {
    value: 1,
    script: "outliers_detection_module",
    label: "Détection d'anomalie",
    multi: false,
  },

  {
    value: 4,
    script: "alert_monitoring",
    label: "Configuration d'alerte",
    multi: true,
  },
  {
    value: 7,
    script: "time_indicator",
    label: "Indicateur temporel",
    multi: true,
  },
  {
    value: 8,
    script: "outliers_validation_module",
    label: "validation des valeurs aberrantes",
    multi: true,
  },
];
export const computationField = [
  {
    value: 2,
    script: "TO_FILL",
    label: "Corrélation",
    multi: true,
  },

  {
    value: 5,
    script: "TO_FILL",
    label: "Formule analytique",
    multi: true,
  },
  {
    value: 6,
    script: "TO_FILL",
    label: "Indicateur industriel",
    multi: true,
  },
];
export const dateFormat = [
  {
    value: "UNIX timestamp",
    label: "UNIX timestamp",
    type: "date-number",
  },

  {
    value: "%Y-%m-%d %H:%M:%S",
    label: "yyyy-MM-dd HH:mm:ss",
    type: "date-time",
  },
  {
    value: "%Y-%m-%d",
    label: "yyyy-MM-dd",
    type: "date",
  },
  {
    value: "%Y-%m-%dT%H:%M:%S",
    label: "yyyy-MM-ddTHH:mm:ss",
    type: "date-time",
  },
];
export const mappingField = [
  {
    id: 1,
    value: "numeric",
    label: "Numerique",
    format: [
      {id: uniqueId(), value: "int", label: "Entier"},
      {id: uniqueId(), value: "float", label: "Flottant"},
    ],
  },
  {
    id: 2,
    value: "categorial",
    label: "Catégoriel",
    format: [
      {id: uniqueId(), value: "nominal", label: "Nominal"},
      {id: uniqueId(), value: "ordered", label: "Ordonné"},
    ],
  },
  {
    id: 3,
    value: "date",
    label: "Date",
    format: [
      {id: 1, value: "date-number", label: "timestamp"},
      {id: 2, value: "date-time", label: "Date et heure"},
      {id: 3, value: "date", label: "Date"},
    ],
  },
  {
    id: 4,
    value: "string",
    label: "Autre",
    format: [{id: 1, value: "string", label: "Str"}],
  },
];
export const ownerField = [
  {id: 1, value: "structure", label: "Structure"},
  {id: 2, value: "observed_system", label: "Système observé"},
];
export const storageField = [
  {id: uniqueId(), value: 1, label: "Postgresql"},
  {id: uniqueId(), value: 0, label: "Elasticsearch"},
  {id: uniqueId(), value: 2, label: "MongoDB"},
];

export const sourceField = [
  {value: 2, label: "Generic Sensor"},
  {value: 1, label: "IS"},
  {value: 0, label: "Operator"},
];
export const typeField = [
  {id: 0, value: "date", label: "Date"},
  {id: 1, value: "string", label: "Autre"},
  {id: 2, value: "integer", label: "Entier"},
  {id: 3, value: "float", label: "flottant"},
];


export const tzInts = [
  {id: 0, name: "(GMT-12:00) International Date Line West", value: "-12:00"},
  {id: 1, name: "(GMT-11:00) Midway Island, Samoa", value: "-11:00"},
  {id: 2, name: "(GMT-10:00) Hawaii", value: "-10:00"},
  {id: 3, name: "(GMT-09:00) Alaska", value: "-09:00"},
  {
    id: 4,
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "-08:00",
  },
  {id: 5, name: "(GMT-08:00) Tijuana, Baja California", value: "-08:00"},
  {
    id: 6,
    name: "(GMT-07:00) Arizona",
    value: "-07:00",
  },
  {id: 7, name: "(GMT-07:00) Chihuahua, La Paz, Mazatlan", value: "-07:00"},
  {
    id: 8,
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    value: "-07:00",
  },
  {id: 9, name: "(GMT-06:00) Central America", value: "-06:00"},
  {
    id: 10,
    name: "(GMT-06:00) Central Time (US & Canada)",
    value: "-06:00",
  },
  {
    id: 11,
    name: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
    value: "-05:00",
  },
  {id: 12, name: "(GMT-05:00) Eastern Time (US & Canada)", value: "-05:00"},
  {
    id: 13,
    name: "(GMT-05:00) Indiana (East)",
    value: "-05:00",
  },
  {id: 14, name: "(GMT-04:00) Atlantic Time (Canada)", value: "-04:00"},
  {
    id: 15,
    name: "(GMT-04:00) Caracas, La Paz",
    value: "-04:00",
  },
  {id: 16, name: "(GMT-04:00) Manaus", value: "-04:00"},
  {
    id: 17,
    name: "(GMT-04:00) Santiago",
    value: "-04:00",
  },
  {id: 18, name: "(GMT-03:30) Newfoundland", value: "-03:30"},
  {
    id: 19,
    name: "(GMT-03:00) Brasilia",
    value: "-03:00",
  },
  {id: 20, name: "(GMT-03:00) Buenos Aires, Georgetown", value: "-03:00"},
  {
    id: 21,
    name: "(GMT-03:00) Greenland",
    value: "-03:00",
  },
  {id: 22, name: "(GMT-03:00) Montevideo", value: "-03:00"},
  {
    id: 23,
    name: "(GMT-02:00) Mid-Atlantic",
    value: "-02:00",
  },
  {id: 24, name: "(GMT-01:00) Cape Verde Is.", value: "-01:00"},
  {
    id: 25,
    name: "(GMT-01:00) Azores",
    value: "-01:00",
  },
  {
    id: 26,
    name: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
    value: "+00:00",
  },
  {
    id: 27,
    name: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
    value: "+00:00",
  },
  {
    id: 28,
    name: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "+01:00",
  },
  {
    id: 29,
    name: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "+01:00",
  },
  {
    id: 30,
    name: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "+01:00",
  },
  {
    id: 31,
    name: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "+01:00",
  },
  {id: 32, name: "(GMT+01:00) West Central Africa", value: "+01:00"},
  {
    id: 33,
    name: "(GMT+02:00) Amman",
    value: "+02:00",
  },
  {id: 34, name: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "+02:00"},
  {
    id: 35,
    name: "(GMT+02:00) Beirut",
    value: "+02:00",
  },
  {id: 36, name: "(GMT+02:00) Cairo", value: "+02:00"},
  {
    id: 37,
    name: "(GMT+02:00) Harare, Pretoria",
    value: "+02:00",
  },
  {
    id: 38,
    name: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: "+02:00",
  },
  {id: 39, name: "(GMT+02:00) Jerusalem", value: "+02:00"},
  {
    id: 40,
    name: "(GMT+02:00) Minsk",
    value: "+02:00",
  },
  {id: 41, name: "(GMT+02:00) Windhoek", value: "+02:00"},
  {
    id: 42,
    name: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
    value: "+03:00",
  },
  {
    id: 43,
    name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    value: "+03:00",
  },
  {id: 44, name: "(GMT+03:00) Nairobi", value: "+03:00"},
  {
    id: 45,
    name: "(GMT+03:00) Tbilisi",
    value: "+03:00",
  },
  {id: 46, name: "(GMT+03:30) Tehran", value: "+03:30"},
  {
    id: 47,
    name: "(GMT+04:00) Abu Dhabi, Muscat",
    value: "+04:00",
  },
  {id: 48, name: "(GMT+04:00) Baku", value: "+04:00"},
  {id: 49, name: "(GMT+04:00) Yerevan", value: "+04:00"},
  {
    id: 50,
    name: "(GMT+04:30) Kabul",
    value: "+04:30",
  },
  {id: 51, name: "(GMT+05:00) Yekaterinburg", value: "+05:00"},
  {
    id: 52,
    name: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    value: "+05:00",
  },
  {id: 53, name: "(GMT+05:30) Sri Jayawardenapura", value: "+05:30"},
  {
    id: 54,
    name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "+05:30",
  },
  {id: 55, name: "(GMT+05:45) Kathmandu", value: "+05:45"},
  {
    id: 56,
    name: "(GMT+06:00) Almaty, Novosibirsk",
    value: "+06:00",
  },
  {id: 57, name: "(GMT+06:00) Astana, Dhaka", value: "+06:00"},
  {
    id: 58,
    name: "(GMT+06:30) Yangon (Rangoon)",
    value: "+06:30",
  },
  {id: 59, name: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "+07:00"},
  {
    id: 60,
    name: "(GMT+07:00) Krasnoyarsk",
    value: "+07:00",
  },
  {
    id: 61,
    name: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    value: "+08:00",
  },
  {id: 62, name: "(GMT+08:00) Kuala Lumpur, Singapore", value: "+08:00"},
  {
    id: 63,
    name: "(GMT+08:00) Irkutsk, Ulaan Bataar",
    value: "+08:00",
  },
  {id: 64, name: "(GMT+08:00) Perth", value: "+08:00"},
  {id: 65, name: "(GMT+08:00) Taipei", value: "+08:00"},
  {
    id: 66,
    name: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    value: "+09:00",
  },
  {id: 67, name: "(GMT+09:00) Seoul", value: "+09:00"},
  {
    id: 68,
    name: "(GMT+09:00) Yakutsk",
    value: "+09:00",
  },
  {id: 69, name: "(GMT+09:30) Adelaide", value: "+09:30"},
  {
    id: 70,
    name: "(GMT+09:30) Darwin",
    value: "+09:30",
  },
  {id: 71, name: "(GMT+10:00) Brisbane", value: "+10:00"},
  {
    id: 72,
    name: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "+10:00",
  },
  {id: 73, name: "(GMT+10:00) Hobart", value: "+10:00"},
  {
    id: 74,
    name: "(GMT+10:00) Guam, Port Moresby",
    value: "+10:00",
  },
  {id: 75, name: "(GMT+10:00) Vladivostok", value: "+10:00"},
  {
    id: 76,
    name: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
    value: "+11:00",
  },
  {id: 77, name: "(GMT+12:00) Auckland, Wellington", value: "+12:00"},
  {
    id: 78,
    name: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
    value: "+12:00",
  },
  {id: 79, name: "(GMT+13:00) Nuku'alofa", value: "+13:00"},
];
