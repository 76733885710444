//React librairies
import React from "react";

//Spinners
import Spinner from "../Spinners/Spinner";

const Button = ({
  callback,
  color = "bg-primary-theme-900",
  disabled = false,
  focus = "focus:outline-none focus:ring-2 focus:ring-theme2",
  hover = "hover:bg-primary-theme-700",
  isLoading = false,
  label,
  extraStyle = " px-4 py-2 mx-auto block  rounded-lg  shadow-lg cursor-pointer transition ease-in duration-300",
  text = "font-semibold text-white",
}) => {
  return (
    <button
      type="submit"
      className={`${color} ${focus} ${hover} ${extraStyle} ${text} ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
      onClick={callback}
    >
      {isLoading && <Spinner />}
      {label}
    </button>
  );
};

export default Button;