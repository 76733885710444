import Checkbox from "../../../components/Selectors/Checkbox";
import Select from "react-select";
import Button from "../../../components/Selectors/Button";
import {variableOptions} from "../utils";

const FormuleAnalytiqueVariableTable = ({variables, bloc, setBloc, add}) => {
  const onCheckbox = (index) => {
    const tmp = {...bloc.to_add};
    tmp[index] = !tmp[index];
    if (bloc.data[index] === undefined) {
      const tmpData = {...bloc.data};
      tmpData[index] = {
        id: index,
        value: "identity",
        label: "identity",
        name: variables[0].options[index].label
      };

      setBloc({
        ...bloc,
        data: tmpData,
        to_add: tmp,
      });
    } else {
      setBloc({
        ...bloc,
        to_add: tmp,
      });
    }
  };
  const onTypeSelect = (value) => {
    const tmp = {...bloc.data};
    tmp[value.id] = value;
    setBloc({
      ...bloc,
      data: tmp,
    });
  };
  return (
    <div className="flex flex-col col-span-3 max-h-[35vh]">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="border-b  shadow-sm">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                >
                  Variable
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                >
                  Agrégation
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                >
                  <Button
                    disabled={
                      !Object.keys(bloc.to_add).some(
                        (value) => bloc.to_add[value] === true
                      )
                    }
                    extraStyle="px-2 h-fit w-fit"
                    label={"Ajouter"}
                    callback={add}
                  />
                </th>
              </tr>
              </thead>
              <tbody>
              {variables[0].options.map((variable) => {
                const label = variable.label;
                return (
                  <tr className="border-b  ">
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {label}
                    </td>

                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <Select
                        value={bloc.data[variables[0].options.indexOf(variable)]}
                        menuPosition={"fixed"}
                        className="rounded-lg outline-none"
                        onChange={onTypeSelect}
                        options={variableOptions(
                          variables[0].options.indexOf(variable),
                          label,
                          0
                        )}
                      />
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      <Checkbox
                        value={
                          bloc.to_add[variables[0].options.indexOf(variable)]
                        }
                        onChange={() =>
                          onCheckbox(variables[0].options.indexOf(variable))
                        }
                      />
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormuleAnalytiqueVariableTable;
