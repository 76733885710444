import uniqueId from "lodash.uniqueid";
import TextInput from "../../../components/Selectors/TextInput";
import Button from "../../../components/Selectors/Button";

const IndustrialTable = ({threshold, setThreshold, variableList}) => {
    const addRow = () => {
        const tmp = threshold.formula
        tmp.push({id: uniqueId(), name: "", formula: ""})
        setThreshold({
            ...threshold, formula: tmp
        });
    };
    const removeRow = (id) => {
        const formulas = threshold.formula
        setThreshold({
            ...threshold, formula: formulas.filter((val) => val.id !== id)
        });
    }
    const handleName = (id, value) => {
        const index = threshold.formula.findIndex(elem => elem.id === id)
        const tmp = threshold.formula
        tmp[index].name = value
        setThreshold({
            ...threshold, formula: tmp
        });
    }
    const handleFormula = (id, value) => {
        const index = threshold.formula.findIndex(elem => elem.id === id)
        const tmp = threshold.formula
        tmp[index].formula = value
        setThreshold({
            ...threshold, formula: tmp
        });
    }
    const headerRow = (header) => {
        return <tr className={"bg-theme3  sticky top-0 z-10 backdrop:bg-theme1"}>
            <th
                className=" font-semibold text-white"
            >
                Etat
            </th>

            <th
                className=" grid grid-cols-6 gap-1 text-white font-semibold ">
                <label className="col-span-5">
                    Formule
                </label>
                <div className="col-span-1 w-full flex justify-center ">

                    <Button extraStyle={" w-1/2 block  rounded-lg shadow-lg cursor-pointer transition ease-in duration-300"}
                            label={"+"} callback={() => addRow()}/>
                </div>
            </th>

        </tr>
    }

    const itemRow = (data) => {
        return data.map((elem) => {
            return (<tr
                key={elem.id}
                className="even:bg-slate-100"
            >
                <td >
                    <div className="">

                        <TextInput
                            extraStyle={"appearance-none bg-transparent w-full text-gray-700  leading-tight "}
                            value={elem.name}
                            name={elem.id}
                            onChange={handleName}
                        />
                    </div>
                </td>
                <td className="grid grid-cols-6 gap-1  ">

                    <div className="col-span-5  ">
                        <TextInput
                            extraStyle={"appearance-none bg-transparent w-full text-gray-700  leading-tight "}
                            value={elem.formula}
                            name={elem.id}
                            onChange={handleFormula}
                        ></TextInput>
                    </div>
                    <div className="col-span-1 w-full flex justify-center pt-2">
                        <Button label={"-"}
                                extraStyle={"w-1/2 h-2/3 rounded-lg shadow-lg cursor-pointer transition ease-in duration-300"}
                                color={"bg-red-600"} hover={"hover:bg-red-700"} callback={() => removeRow(elem.id)}/>
                    </div>
                </td>
            </tr>);
        });
    };

    return (<div className="isolate max-h-72 overflow-auto ">
        <table className="w-full table-fixed ">
            <thead>
            {headerRow()}
            </thead>
            <tbody>{itemRow(threshold.formula)}</tbody>
        </table>
    </div>);
};
export default IndustrialTable;
