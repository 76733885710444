import Checkbox from "../components/Selectors/Checkbox";
import DateTimezoneCS from "../components/Selectors/DateTimezoneConfiguredSource";
import NumericDataCS from "../components/Selectors/NumericDataConfiguredSource";
import TextInput from "../components/Selectors/TextInput";

const onChangeRangeMapping = (
  sourceParam,
  name,
  value,
  callback,
  arg = null
) => {
  const to_update = structuredClone(sourceParam);
  if (arg) to_update["values"][name]["arg"][arg] = value;
  else to_update["values"][name]["arg"] = value;
  callback("values", to_update.values);
};
const onChangeSourceParam = (sourceParam, name, value, callback) => {
  const to_update = structuredClone(sourceParam);
  to_update["source_parameters_values"][name]["value"] = value;
  callback("source_parameters_values", to_update["source_parameters_values"]);
};
export const handleType = (e, onChange) => {
  switch (e.name) {
    case "conf_id":
      return {
        id: e.id,
        name: e.name,
        type: <TextInput name={e.name} value={e.value} onChange={onChange}/>,
        tooltip: false,
      };
    case "is_active":
      return {
        id: e.id,
        type: (
          <Checkbox
            label={e.name}
            name={"is_active"}
            value={e.value}
            checked={e.value}
            onChange={(e) => onChange("is_active", e.target.checked)}
          />
        ),
        tooltip: false,
      };
    case "alert_delay":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            type={"number"}
            name={e.name}
            value={e.value}
            onChange={onChange}
          />
        ),
        tooltip: false,
      };
    case "description":
      return {
        id: e.id,
        name: e.name,
        type: <TextInput name={e.name} value={e.value} onChange={onChange}/>,
        tooltip: false,
      };
    default:
      break;
  }
};

export const handleRangeMapping = (e, setIds, ids, onChange) => {
  const value = e.value.arg;
  switch (e.value["type"]) {
    case "int":
      return {
        id: e.id,
        name: e.name,
        type: (
          <NumericDataCS
            onChange={(name, value) =>
              onChangeRangeMapping(ids, e.name, value, onChange, name)
            }
            id={e.id}
            value={value}
            step={"1"}
          />
        ),
        tooltip: false,
      };
    case "float":
      return {
        id: e.id,
        name: e.name,
        type: (
          <NumericDataCS
            onChange={(name, value) =>
              onChangeRangeMapping(ids, e.name, value, onChange, name)
            }
            id={e.id}
            value={value}
            step={"0.01"}
          />
        ),
        tooltip: false,
      };
    case "date":
      return {
        id: e.id,
        name: e.name,
        type: <p> Non modifiable</p>,
        tooltip: false,
      };
    case "string":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            type={"number"}
            step={"1"}
            min={"0"}
            onChange={(name, value) =>
              onChangeRangeMapping(ids, e.name, value, onChange)
            }
            name={e.id}
            value={value}
          />
        ),
        tooltip: false,
      };
    case "categorical":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            onChange={(name, value) =>
              onChangeRangeMapping(ids, e.name, value, onChange)
            }
            name={e.id}
            value={value}
          />
        ),
        tooltip: false,
      };
    default:
      return {
        id: e.id,
        name: e.name,
        type: e.value.type,
        tooltip: false,
      };
  }
};

export const handleSourceParameters = (e, setIds, ids, onChange) => {
  const value = e.value["value"];
  const type = e.value["type"];
  switch (type) {
    case "date":
      return {
        id: e.id,
        name: e.name,
        type: (
          <DateTimezoneCS
            callback={(name, value) =>
              onChangeSourceParam(ids, name, value, onChange)
            }
            date={value}
            name={e.name}
          />
        ),
        tooltip: false,
      };
    case "string":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            onChange={(name, value) =>
              onChangeSourceParam(ids, name, value, onChange)
            }
            name={e.name}
            value={value}
          />
        ),
        tooltip: false,
      };
    case "int":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            type="number"
            step={"1"}
            onChange={(name, value) =>
              onChangeSourceParam(ids, name, parseInt(value), onChange)
            }
            name={e.name}
            value={value}
          />
        ),

        tooltip: false,
      };
    case "float":
      return {
        id: e.id,
        name: e.name,
        type: (
          <TextInput
            type="number"
            step={"0.01"}
            onChange={(name, value) =>
              onChangeSourceParam(ids, name, parseFloat(value), onChange)
            }
            name={e.name}
            value={value}
          />
        ),

        tooltip: false,
      };

    default:
      return {
        id: e.id,
        name: e.name,
        type: "N/A",
        tooltip: false,
      };
  }
};
