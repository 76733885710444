import DetectionAnomalieRightPanel from "./DetectionAnomalieRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const DetectionAnomalie = () => {
  return (
    <div className="grid grid-cols-2 gap-4 mx-4 py-2">
      <IndicatorDataConfiguration isMethod={true} isTwoBloc={true}/>
      <DetectionAnomalieRightPanel/>
    </div>
  );
};
export default DetectionAnomalie;
