import Papa from "papaparse";
import DateParam from "../components/FileParam/DateParam";

import { formatOffset } from "../pages/indicator/utils";
import StringParam from "../components/FileParam/StringParam";
import CategoricalParam from "../components/FileParam/CategoricalParam";
import NumericParam from "../components/FileParam/NumericParam";

/**
 * Check the type of a value of a  csv file.
 * @param {*} value
 * @returns
 */
const checkType = (value) => {
  switch (typeof value) {
    case "number":
      if (Number.isInteger(value)) return "int";
      else return "float";
    case "string":
      const date = new Date(value);
      if (date.toUTCString() === "Invalid Date") return "string";
      return "date";
    default:
      return "";
  }
};
/**
 * Return an object corresponding to the default param for the field.
 * @param {string} type
 * @returns
 */
export const initParam = (type) => {
  switch (type) {
    case "int":
    case "float":
      return {
        minValue: 0,
        maxValue: 1000,
      };
    case "string":
      return { length: 100 };
    case "date":
    case "date-number":
    case "date-time":
      return {
        timezone: "Etc/GMT",
        offset: formatOffset(0),
        format: "%Y-%m-%d",
        type: "date",
      };
    case "categorical":
      return {
        type: "nominal",
        label: "",
      };
    default:
      break;
  }
};

export const checkFileType = (file, state, setState) => {
  if (file === undefined) return false;
  Papa.parse(file, {
    dynamicTyping: true,
    header: true,
    preview: 4,
    skipEmptyLines: true,
    complete: function (results) {
      const res = [];
      console.log(results);
      const data = results.data;
      for (const column in data[0]) {
        const value = data[0][column];
        const type = checkType(value);
        if (column !== "__parsed_extra")
          res.push({ column: column, type: type, param: initParam(type) });
      }
      console.log(res);
      setState({
        ...state,
        col_type: res,
        error: results.errors,
        preview: data,
        delimiter: results.meta.delimiter,
      });
      return true;
    },
  });
  return true;
};
export const getHeaderFromCSV = (csvFile, columnName) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      header: true,
      complete: (results) => {
        if (results.meta && results.meta.fields) {
          const headers = results.meta.fields;
          const columnMap = {};
          columnName.forEach((name) => {
            const columnIndex = headers.indexOf(name);
            if (columnIndex !== -1) {
              columnMap[name] = name;
            } else {
              columnMap[name] = null;
            }
          });
          resolve({
            header: results.meta.fields,
            columnMap: columnMap,
            delimiter: results.meta.delimiter,
          });
        } else {
          reject(new Error("CSV file has no headers"));
        }
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const typeRender = (
  type,
  id,
  callbackModal,
  data,
  closeModal,
  setData
) => {
  switch (type) {
    case "int":
    case "float":
      return <NumericParam data={data} setData={setData} id={id} />;
    case "date-number":
    case "date":
    case "date-time":
      return <DateParam data={data} setData={setData} id={id} />;
    case "string":
      return <StringParam data={data} setData={setData} id={id} />;
    case "categorical":
      return <CategoricalParam data={data} setData={setData} id={id} />;
    default:
      break;
  }
};
/**
 * Get the header of the csv file
 * @param {*} preview
 * @returns
 */
export const getColumn = (preview) => {
  const res = [];
  preview.forEach((element) => {
    res.push(element.column);
  });
  return res;
};

export const handleTypeChange = (newValue, index, data, setData) => {
  const newData = { ...data.col_type[index] };
  const newcolData = [...data.col_type];

  newData.type = newValue;
  newData.param = initParam(newValue);
  newcolData[index] = newData;
  setData({
    ...data,
    col_type: newcolData,
  });
};
export const handleParamChange = (newValue, index, data, setData, name) => {
  const newcolData = [...data.col_type];
  newcolData[index].param[name] = newValue;
  setData({
    ...data,
    col_type: newcolData,
  });
};

export const handleParamsChange = (newValues, index, data, setData) => {
  const newcolData = [...data.col_type];
  newValues.forEach((value) => {
    newcolData[index].param[value.name] = value.value;
  });
  setData({
    ...data,
    col_type: newcolData,
  });
};
