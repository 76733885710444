import {useDispatch, useSelector} from "react-redux";
import Checkbox from "../../components/Selectors/Checkbox";
import {setVariableParam} from "../../reducers/graphSlice";
import Select from "react-select";
import React, {useMemo} from "react";
import {
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {TableHeader} from "../../components/Table/TableHeader";
import {TableBody} from "../../components/Table/TableBody";
import {getConfigurationsFromCrudeSource, makeSelectField} from "../../utils/common";

const GraphVariable = () => {
    const dispatch = useDispatch()
    const data = useSelector(state => state?.analyticsGraph["selectedVariable"])
    const anomalyParam = [{label: "Kalman", value: "id_kalman"}, {label: "Kalman2", value: "id_kalman2"}]

    const onCheckbox = (name, value, id) => {
        dispatch(setVariableParam({
            id: id,
            type: name,
            data: value
        }))
    }
    const onThresholdParam = (id, value, name) => {
        dispatch(setVariableParam({
            id: id,
            type: "threshold_parameters",
            type2: name,
            data: value
        }))
    }
    const columns = useMemo(() => [
        {
            accessorFn: row => {
                return row.label
            },
            id: "label",
            header: 'Variable',
            className: "w-1/6 group text-left text-xs font-medium text-white uppercase tracking-wider",
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <Select isMulti={true}
                               menuPlacement={"auto"}
                               menuPosition={"fixed"}
                               options={makeSelectField(getConfigurationsFromCrudeSource(row.crude_source_id), ["description"])}
                               value={row["configured_sources"]}
                               onChange={(e) => dispatch(setVariableParam({
                                   id: row.value,
                                   type: "configured_sources",
                                   data: e
                               }))}/>

            },
            id: "configured_sources",
            header: 'Configurations',
            className: "w-1/6 group p-1  text-left text-xs font-medium text-white uppercase tracking-wider",
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <div className="flex justify-center">
                    <Checkbox name={"anomaly_display"}
                              onChange={(e) => onCheckbox(e.target.name, e.target.checked, row.value)}
                              value={row["anomaly_display"]}/>
                </div>
            },
            enableSorting: false,
            className: "w-auto p-1 group text-left text-xs font-medium text-white uppercase tracking-wider",
            id: "anomaly_display",
            header: "Affichage d'anomalie",
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <div className="flex justify-center ">
                    <Select isMulti={true}
                            className={"w-full"}
                            options={anomalyParam}
                            menuPosition={"fixed"}
                            value={row["anomaly_parameters"]}
                            onChange={(e) => dispatch(setVariableParam({
                                id: row.value,
                                type: "anomaly_parameters",
                                data: e
                            }))}
                    />
                </div>
            },
            className: "w-1/6 p-1 group text-left text-xs font-medium text-white uppercase",
            enableSorting: false,
            id: "anomaly_parameters",
            header: 'Paramètre d\'anomalie',
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <div className="flex justify-center">
                    <Checkbox
                        name="nocheck_display"
                        onChange={(e) => onCheckbox(e.target.name, e.target.checked, row.value)}
                        value={row["nocheck_display"]}/>
                </div>
            },
            className: "w-auto p-1  group  text-xs font-medium text-white uppercase",
            enableSorting: false,
            id: "nocheck_display",
            header: 'Affichage des valeurs non vérifiées',
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <div className="flex justify-center">
                    <Checkbox
                        name="threshold_display"
                        onChange={(e) => onCheckbox(e.target.name, e.target.checked, row.value)}
                        value={row["threshold_display"]}/>
                </div>
            },
            className: "w-1/8 p-1 group text-left text-xs font-medium text-white uppercase tracking-wider",
            enableSorting: false,
            id: "threshold_display",
            header: 'Affichage des seuils',
            cell: info => info.getValue(),
        },
        {
            accessorFn: row => {
                return <div className="grid grid-cols-2  gap-x-0.5 flex justify-center ">
                    <Select
                        onChange={(e) => onThresholdParam(row.value, e.value, "mode")}
                        menuPosition={"fixed"}
                        options={[
                            {"value": "lt", label: "<"},
                            {"value": "gt", label: ">"}
                        ]}
                        value={{
                            value: row["threshold_parameters"]["mode"],
                            label: row["threshold_parameters"]["mode"] === "lt" ? "<" : ">"
                        }}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                    />
                    <input value={row["threshold_parameters"]["value"]} required
                           className="invalid:border-red-500 rounded-md h-[38px] border-gray-300 border"
                           type={"number"}
                           step={0.1}
                           name="value"
                           onChange={(e) => onThresholdParam(row.value, e.target.value, e.target.name)}/>
                </div>
            },
            className: "w-1/6 p-1 group  text-left text-xs font-medium text-white uppercase tracking-wider",
            enableSorting: false,
            id: "threshold_parameters",
            header: 'Paramètres des seuils',
            cell: info => info.getValue(),
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },], [])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });
    return <div className="flex flex-col">
        <div className="overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200">
                    <table className="w-full">
                        <TableHeader table={table}/>
                        <TableBody table={table}/>
                    </table>
                </div>
            </div>
        </div>
    </div>

}
export default GraphVariable;