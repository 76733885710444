import IndicatorChip from "../Selector/IndicatorChip";
import {getBlockInfo} from "../utils";
import {useDispatch, useSelector} from "react-redux";
import {delBlock} from "../../../reducers/indicatorSlice";

const FormuleAnalytiqueRecap = () => {

    const dispatch = useDispatch()
    const dataParam = useSelector(state => state.indicator.moduleData.data_params)
    const configurations = useSelector(state => state.data.configured_source)
    const sources = useSelector(state => state.data.crude_source)
    return dataParam.map((bloc) => {
        let conf = {}
        Object.keys(configurations).forEach(id => {

            if (configurations[id]["measure_identifier"] === bloc.measure_identifier) {
                conf = configurations[id]
            }
        })
        const source = sources[conf["crude_source"]]
        const textConf = bloc["configured_sources"].length === 0 ? "Toutes les configurations sont séléctionnées" : bloc["configured_sources"].length + " configuration(s) séléctionnée(s)"
        const textVariables = Object.keys(bloc["preprocess"]).length === 1 ? Object.keys(bloc["preprocess"]).length + " variable considérée" : Object.keys(bloc["preprocess"]).length + " variables considérées"
        return <IndicatorChip text={[textConf, textVariables]}
                              type={source.description}
                              callback={() => dispatch(delBlock(bloc.measure_identifier))}
                              tip={getBlockInfo(bloc)}/>

    })
}
export default FormuleAnalytiqueRecap;