import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../axios/axios";
import {
  edit as editData,
  remove as removeData,
  set,
  setData,
} from "../reducers/dataSlice";
import {
  add as addAdmin,
  edit as editAdmin,
  remove as removeAdmin,
  set as setAdmin,
} from "../reducers/AdminSlice";
import store from "../store";
import {
  login as log,
  logout,
  setAuthorizedElement,
} from "../reducers/authenticationSlice";
import { toast } from "react-toastify";
import { isEmptyString } from "../utils/common";
import { createAction } from "../utils/axios.utils";

const makeUrl = (url) => {
  const { REACT_APP_KEYCLOCK_HOST } = process.env;
  return REACT_APP_KEYCLOCK_HOST + url;
};
/**
 * Retrieve the user of the structure and store them into the data store
 */
export const getUser = () => {
  getRequest(
    makeUrl("management?structure_id=" + sessionStorage.getItem("structure_id"))
  ).then((res) => {
    store.dispatch(set({ type: "users", data: res.data["user_id"] }));
  });
};

export const getUserAll = () => {
  return getRequest(makeUrl("management"));
};

export const createUser = (body, callback) => {
  const structure_id = sessionStorage.getItem("structure_id");

  postRequest(
    makeUrl("management?mangement_action=adduser&structure_id=" + structure_id),
    body
  ).then((res) => {
    if (res.data.status === "success") {
      body["id"] = res.data["new_user_id"];
      store.dispatch(
        addAdmin({
          type: "ADD_USER",
          data: body,
        })
      );
    }
    callback();
  });
};

export const deleteUser = (id, callback) => {
  const structure_id = sessionStorage.getItem("structure_id");

  deleteRequest(
    makeUrl("management?user_id=" + id + "&structure_id=" + structure_id),
    {}
  ).then((res) => {
    console.debug(res);
    if (res.data.status === "success") {
      store.dispatch(removeAdmin({ type: "REMOVE_USER", id: id }));
    }
    callback();
  });
};

export const editUser = (data, callback = () => {}) => {
  const structure_id = sessionStorage.getItem("structure_id");
  putRequest(
    makeUrl(
      "management?mangement_action=edituser&structure_id=" + structure_id
    ),
    data
  ).then((res) => {
    store.dispatch(
      editData({ type: "EDIT_USER", id: data.id, updates: data.payload })
    );
    callback(res);
  });
};

export const editUserAll = (data, callback = () => {}) => {
  const structure_id = sessionStorage.getItem("structure_id");

  putRequest(
    makeUrl(
      "management?management_action=edituser&structure_id=" + structure_id
    ),
    data
  ).then((res) => {
    store.dispatch(
      editAdmin({ type: "EDIT_USER", id: data.id, updates: data.payload })
    );
    callback(res);
  });
};

export const loginUser = (data, callback = () => {}) => {
  postRequest(makeUrl("user/login"), data).then((res) => {
    console.debug(res);
    switch (res.status) {
      case 200:
        const profile = res?.data?.data?.id_role;
        sessionStorage.setItem("profile", JSON.stringify(res.data.data));
        //Redux update object
        //used to store user information
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("token", res?.data?.data?.refresh_token);
        sessionStorage.setItem(
          "structure_id",
          profile !== 1 ? res?.data?.data?.structure_id : ""
        );
        sessionStorage.setItem("first_name", res?.data?.data?.first_name);
        sessionStorage.setItem("last_name", res?.data?.data?.last_name);
        sessionStorage.setItem("user_profil", profile);
        sessionStorage.setItem("id", res?.data?.data?.id_keycloak);
        sessionStorage.setItem("email", res?.data?.data?.email);
        // store.dispatch(set({ data: res.data.data, type: "profile" }));
        store.dispatch(setData(createAction("profile", res.data.data)));
        store.dispatch(
          log({
            isAuthenticated: true,
            token: res?.data?.data?.refresh_token,
            structure: sessionStorage.getItem("structure_id"),
            user_profil: sessionStorage.getItem("user_profil"),
          })
        );
        toast.success("Connecté");
        callback(res);
        break;

      default:
        callback(res);
        toast.error(res["message"]);
        break;
    }
  });
};

export const resetPwd = (data, callback = () => {}) => {
  const structure_id = sessionStorage.getItem("structure_id");
  postRequest(
    makeUrl(
      "management?mangement_action=regenerateuser&structure_id=" + structure_id
    ),
    data
  )
    .then((res) => {
      callback(res);
      toast.success("Email de réinitialisation de mot de passe envoyé.");
    })
    .catch(() => {
      toast.error("Utilisation introuvable");
    });
};

export const changePwd = (pwd) => {
  const structure_id = sessionStorage.getItem("structure_id");
  putRequest(
    makeUrl(
      "management?mangement_action=changepwd&structure_id=" + structure_id
    ),
    {
      id: sessionStorage.getItem("id"),
      password: pwd,
    }
  )
    .then((r) => {
      if (r.data.status === "success") toast.success("Mot de passe modifié");
      else if (r.data.message["error_description"])
        toast.error(r.data.message["error_description"]);
      else toast.error(r.data.message.error);
    })
    .catch((e) => console.debug(e));
};

export const logOutUser = (callback) => {
  postRequest(makeUrl("user/logout"), {}).then(() => {
    store.dispatch(logout());
    sessionStorage.clear();
    callback();
  });
};

export const getProfile = (callback) => {
  const structure_id = sessionStorage.getItem("structure_id");
  getRequest(
    makeUrl(
      isEmptyString(structure_id)
        ? "profile"
        : "profile?structure_id=" + structure_id
    )
  ).then((res) => {
    store.dispatch(set({ data: res.data.data, type: "profiles" }));
    if (callback) callback(res);
  });
};

export const deleteProfile = (profile_id, callback) => {
  const structure_id = sessionStorage.getItem("structure_id");
  deleteRequest(
    makeUrl("profile?profile_id=") +
      profile_id +
      "&structure_id=" +
      structure_id
  ).then((res) => {
    if (res.status === 200)
      store.dispatch(removeData({ type: "profiles", index: profile_id }));
    toast.error(res.data.message);
    if (callback !== undefined) callback(res);
  });
};
export const editProfile = (updates, profile_id) => {
  const structure_id = sessionStorage.getItem("structure_id");
  return putRequest(
    makeUrl(
      "profile?profile_id=" + profile_id + "&structure_id=" + structure_id
    ),
    updates
  );
};
export const getMethod = (callback = () => {}) => {
  getRequest(makeUrl("method")).then((res) => {
    console.debug(res);
    store.dispatch(set({ data: res.data.data, type: "methods" }));
    callback(res);
  });
};

export const getPages = (pathname, redirectCallback) => {
  console.debug(pathname);
  getRequest(
    makeUrl(
      "pages/" +
        sessionStorage.getItem("user_profil") +
        "/" +
        pathname.replaceAll("/", "_")
    )
  ).then((res) => {
    if (res.status === 200) {
      if (pathname === "all")
        store.dispatch(
          setAuthorizedElement({
            type: "authorized_pages",
            data: res.data.data,
          })
        );
      else {
        store.dispatch(
          setAuthorizedElement({
            type: "authorized_components",
            data: res.data.data,
          })
        );
      }
    } else {
      toast.error("Session expirée. ");
      sessionStorage.clear();
      store.dispatch(logout());
      redirectCallback();
    }
  });
};

export const postProfile = (
  name,
  description,
  pages,
  components,
  callback = () => {}
) => {
  const data = {
    structure_id: sessionStorage.getItem("structure_id"),
    name: name,
    description: description,
    pages: pages,
    components: components,
  };
  postRequest(makeUrl("/profile"), data).then((res) => {
    toast.success("Le profile " + name + " a été créé");
    callback(res);
  });
};

export const getConnectionLog = () => {
  const profile_id = sessionStorage.getItem("user_profil");
  const structure_id = sessionStorage.getItem("strcture_id");
  console.debug(profile_id);
  const url =
    profile_id === "1"
      ? makeUrl("users")
      : makeUrl("users?structure_id=" + structure_id);
  return getRequest(url);
};

export const updatePreference = (preference, userId) => {
  return putRequest(makeUrl("preference?userId=" + userId), preference);
};