import IndicatorTextInput from "./IndicatorTextInput";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOneRecurrent} from "../../reducers/indicatorSlice";
import {getTrackBackground, Range} from "react-range";

const IndicatorRecurentForm = () => {
    const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
    const param = useSelector(state => state.indicator.moduleData.parameter)
    const validityDays = param.validity_days.split("-").map(value => parseInt(value))
    const time_slot = param.time_slot.split("-").map(value => parseInt(value))
    const dispatch = useDispatch()
    return (
        <div className="col-span-2">
            <div className="grid gap-y-1 grid-cols-3">
                <IndicatorTextInput value={param.frequency} name="frequency" onChange={(key, value) =>
                    dispatch(setOneRecurrent({type: key, data: value}))}
                                    label="Fréquence (en minute)" placeholder="entier" type="number"/>

                <label
                    className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Jour(s)
                    d'activité</label>
                <div
                    className="flex justify-center flex-wrap col-span-2"
                >
                    <Range
                        values={validityDays}
                        step={1}
                        min={0}
                        max={6}
                        onChange={(values) => dispatch(setOneRecurrent({
                            type: "validity_days",
                            data: values.join("-")
                        }))}

                        renderTrack={({props, children}) => (<div
                            className={"h-10 flex w-full"}
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                        >
                            <div
                                ref={props.ref}
                                className="h-1.5 w-full rounded self-center"
                                style={{
                                    background: getTrackBackground({
                                        values: validityDays, colors: ['#cbd5e1', '#349ed0', '#cbd5e1'], min: 0, max: 6,
                                    })
                                }}
                            >
                                {children}
                            </div>
                        </div>)}
                        renderThumb={({index, props, isDragged}) => (<div
                            {...props}
                            className="flex justify-center items-center h-8 w-8 shadow rounded bg-white"
                        >
                            <div
                                className="absolute w-7 flex justify-center items-center -top-8 font-bold text-sm text-white bg-theme2 p-1 rounded"
                                style={{}}
                            >
                                {days[validityDays[index]]}
                            </div>
                            <div
                              className={`${"h-4 w-1.5"} ${isDragged ? "bg-theme2" : "bg-slate-300"}`}
                            />
                        </div>)}
                    />
                </div>
                <label
                  className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Plage horaire</label>
                <div
                  className="flex justify-center flex-wrap col-span-2"
                >
                    <Range
                      values={time_slot}
                      step={1}
                      min={0}
                      max={23}
                      onChange={(values) => dispatch(setOneRecurrent({
                          type: "time_slot",
                            data: values.join("-")
                        }))}

                        renderTrack={({props, children}) => (<div
                            className={"h-10 flex w-full"}
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                        >
                            <div
                                ref={props.ref}
                                className="h-1.5 w-full rounded self-center"
                                style={{
                                    background: getTrackBackground({
                                        values: time_slot, colors: ['#cbd5e1', '#349ed0', '#cbd5e1'], min: 0, max: 23,
                                    })
                                }}
                            >
                                {children}
                            </div>
                        </div>)}
                        renderThumb={({index, props, isDragged}) => (<div
                            {...props}
                            className="flex justify-center items-center h-8 w-8 shadow rounded bg-white"
                        >
                            <div
                                className="absolute w-6 flex justify-center items-center -top-8 font-bold text-sm text-white bg-theme2 p-1 rounded"
                                style={{}}
                            >
                                {time_slot[index] + "h"}
                            </div>
                            <div
                                className={`${"h-4 w-1.5"} ${isDragged ? "bg-theme2" : "bg-slate-300"}`}
                            />
                        </div>)}
                    />
                </div>

            </div>
        </div>

    );
};
export default IndicatorRecurentForm;
