import React from "react";
import {useSelector} from "react-redux";
import EventFormulaVerification from "./EventFormulaVerification";

const ComputationParamCreation = () => {
    const histo = useSelector(state => state.event.histo)

    return <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
        <h2 className="text-theme1 text-2xl font-semibold">Paramètres de calcul</h2>
        <div className="grid grid-cols-2 gap-4">
            <div>
                <h2 className="text-theme1 text-xl font-semibold">Courant</h2>
                <EventFormulaVerification type={"current"}/>
            </div>
            {histo && <div>

                <h2 className="text-theme1 text-xl font-semibold">Historique</h2>
                <EventFormulaVerification type={"historical"}/>
            </div>}
        </div>
    </div>
}
export default ComputationParamCreation;