import {useDispatch, useSelector} from "react-redux";
import {current, historical, redirect} from "./EventUtils";
import {useEffect, useState} from "react";
import {initEvent, removeDataParams, setBase, setData} from "../../reducers/EventSlice";
import {getSourceWithMeasure, makeSelectField} from "../../utils/common";
import Select from "react-select";
import DataParamCreation from "./DataParamCreation";
import TimeParamCreation from "./TimeParamCreation";
import ComputationParamCreation from "./ComputationParamCreation";

import Button from "../../components/Selectors/Button";
import {useNavigate} from "react-router-dom";
import {addEvent} from "../../network/DataManagementApi";


const makeValue = (optionList) => {
    console.debug(optionList)
    return optionList.map(value => value.value)
}
const CorrelationEvent = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [loaded, setLoaded] = useState(false)
    const init = {
        current: structuredClone(current), target_variables: [], historical: structuredClone(historical)
    }
    const selectedSource = useSelector(state => state.event.selectedSource)
    const data = useSelector(state => state.event)

    const source = useSelector(state => state.event.source)
    const sourceId = useSelector(state => state.event.sourceId)
    const configurations = useSelector(state => state.data.configured_source)
    const [variables, setVariables] = useState([]);
    useEffect(() => {

        dispatch(initEvent({
            data: structuredClone(init),
            source: getSourceWithMeasure("crude_source"),
            selectedSource: null,
            histo: false,
            count: true,
            sourceId: false,
            lockedHisto: true
        }))
        setLoaded(true)


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const tmp = []
        setVariables([]);
        data.data.current.data_params.forEach(bloc => {
            Object.keys(bloc.preprocess).forEach(variable => tmp.push({
                label: variable, value: variable
            }))
        })
        dispatch(setData({name: "target_variables", value: []}))
        setVariables(tmp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.data?.current?.data_params])
    const create = () => {
        const state = structuredClone(data);
        const formattedData = {}
        console.debug(state)
        formattedData["module_name"] = "Correlation"
        formattedData["function_name"] = "process"
      formattedData["parameters"] = {
        computation_params: state.data.current.computation_params,
        data_params: state.data.current.data_params,
        time_params: state.data.current.time_params,
        target_variables: makeValue(state.data.target_variables)
      }
      addEvent(formattedData, () => history(redirect));
    }
    return <div>
        {loaded && <div>
            <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">

                <h2 className="text-theme1 text-2xl font-semibold">Paramètres des données</h2>
                <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">Source de
                    données</label>
                <Select placeholder={"Source de données..."} openMenuOnClick={false} isClearable={true}
                        isMulti={true}
                        value={selectedSource}
                        options={makeSelectField(source, ["name"])}
                        isDisabled={sourceId}
                        isLoading={sourceId}
                        onChange={(option, action) => {
                            if (action.action === "remove-value") {
                                const measure_identifier = configurations[source[action.removedValue.value].configuration[0]].measure_identifier
                                dispatch(removeDataParams(measure_identifier))
                                dispatch(setBase({type: "selectedSource", value: option}))
                                dispatch(setBase({type: "selectedSource", value: option}))
                            }
                            if (action.action === "select-option") {
                                dispatch(setBase({type: "selectedSource", value: option}))
                                dispatch(setBase({type: "sourceId", value: action.option.value}))

                            }
                        }}

                />

                {sourceId && <DataParamCreation/>}
            </div>
            <TimeParamCreation/>
            <ComputationParamCreation/>
            <div className="bg-white  pt-4 pb-8 px-8 m-8  shadow-lg rounded-lg">
                <h2 className="text-theme1 text-2xl font-semibold">Paramètres</h2>
                <div>
                    <label
                        className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">{"Variable cible"}</label>
                    <Select
                        value={variables.length === 0 ? null : variables[variables.findIndex(value => value.value === data.data.target_variable)]}
                        isMulti={true}
                        onChange={(option) => {
                            dispatch(setData({name: "target_variables", value: option}))
                        }}
                        options={variables}
                    />
                </div>
            </div>
        </div>

        }
        <div className="grid grid-cols-7 gap-4 pt-2">
            <span className="col-span-3"/>
            <Button label={"Créer"} disabled={false} callback={create}/>
        </div>
    </div>
}

export default CorrelationEvent