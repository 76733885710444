import {toast} from "react-toastify";
import Spinner from "../Spinners/Spinner";
import React from "react";


export const DataLoadingToast = () =>
  toast(
    <div>
      <Spinner /> Chargement des données{" "}
    </div>,
    {
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
      toastId: "loading_data",
    }
  );