import {
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {useEffect, useState} from "react";
import {TableFilter} from "../Table/TableFilter";
import {TableHeader} from "../Table/TableHeader";
import {TableBody} from "../Table/TableBody";
import {TablePagination} from "../Table/TablePagination";
import {TableBodySelectable} from "../Table/TableBodySelectable";
import {TableBodyHoverable} from "../Table/TableBodyHoverable";

const Card = ({name, data, columns, state = 0, callback, selected = null, filter = true}) => {
    const [globalFilter, setGlobalFilter] = useState('');
    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    });
    useEffect(() => {
        table.setPageSize(5)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleTable = () => {
        switch (state) {
            case 1:
                return <TableBodySelectable selected={selected} table={table} callback={callback}/>
            case 2:
                return <TableBodyHoverable table={table} callback={callback}/>
            default:
                return <TableBody table={table}/>
        }
    }
    return (
        <div className="bg-white md:col-span-2 2xl:col-span-1  pt-4 pb-8 px-8  shadow-lg rounded-lg">
            <div>
                <div className="flex flex-col lg:flex-row justify-between mt-2">
                    <div className=" w-full flex flex-row align-center px-3">
                        <h2 className="w-full text-gray-800 text-3xl font-semibold">{name}</h2>
                        {filter && <TableFilter
                          value={globalFilter ?? ''}
                          onChange={value => setGlobalFilter(String(value))}
                        />}


                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="">
                        <div className="py-2 align-middle inline-block min-w-full">
                            <div className="shadow  border-b border-gray-200">
                                <table className="w-full">
                                    <TableHeader table={table}/>
                                    {handleTable()}

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <TablePagination table={table}/>
            </div>
        </div>
    );
};
export default Card;
