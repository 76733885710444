//React librairies
import React from "react";

const IndicatorTextInput = ({
                                color = "dark:bg-gray-900",
                                disabled = false,
                                label,
                                min,
                                name,
                                type = "text",
                                onChange,
                                step = "any",
                                placeholder,
                                style = "w-full  rounded-md outline-none  border",
                                invalid = "invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500",
                                value,
                                max
                            }) => {
    return (
        <>
            <label className="py-2 text-sm font-bold text-gray-700 dark:text-white tracking-wide">{label}</label>
            <div className=" col-span-2 py-1">
                <input step={step} min={min} max={max} type={type} disabled={disabled} className={`${color} ${style} ${invalid} `}
                       placeholder={placeholder} name={name} onChange={(e) => {
                    onChange(e.target.name, e.target.value)
                }} value={value}/>
            </div>
        </>
    );
}

export default IndicatorTextInput;