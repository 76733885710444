import IconButton from "../IconButton";
import {MdClose as Close, MdWarningAmber as Warning} from "react-icons/md";
import Button from "./Button";
import {Fragment} from "react";

import { Transition, Dialog } from "@headlessui/react";
import { isEmptyObject } from "../../utils/common";


const DeleteModal = ({loading, callback, text, modalVisible, setModalVisible}) => {


  return (
    <Transition appear show={!isEmptyObject(modalVisible)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setModalVisible(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <IconButton
                    callback={() => setModalVisible(undefined)}
                    Icon={
                      <Close
                        className={
                          "absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                        }
                        size={40}
                      />
                    }
                  />
                </Dialog.Title>
                <div className="p-6 text-center text-theme1">
                  <Warning size={40} className={"w-full"} />
                  <h3 className="mb-5 text-lg font-normal">{text}</h3>
                  <Button
                    callback={callback}
                    isLoading={loading}
                    label={"Oui, supprimer"}
                    focus={"focus:ring-4 focus:outline-none focus:ring-red-300"}
                    hover={"hover:bg-red-800"}
                    color={"bg-red-600"}
                    extraStyle={
                      "rounded-lg inline-flex items-center px-5 py-2.5 text-center mr-2"
                    }
                    text={"text-white font-medium text-sm"}
                  />
                  <Button
                    callback={() => setModalVisible(false)}
                    label={"Non, annuler"}
                    focus={
                      "focus:ring-4 focus:z-10 focus:outline-none focus:ring-gray-200"
                    }
                    hover={"hover:bg-gray-100 hover:text-gray-900"}
                    color={"bg-white"}
                    text={"text-theme1 font-medium text-sm"}
                    extraStyle={
                      " rounded-lg border border-gray-200  font-medium px-5 py-2.5"
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default DeleteModal;
