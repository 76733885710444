const TableWrapper = ({ label, component }) => {
  return (
    <div
      className={
        "bg-white border rounded-lg  box-shadow-custom-4-sides border-gray-200 mx-2"
      }
    >
      <h2 className="ml-4 text-theme1 text-3xl font-semibold">{label}</h2>
      {component}
    </div>
  );
};
export default TableWrapper;
