import {useDispatch, useSelector} from "react-redux";
import {setDeeper, setModuleData} from "../../../reducers/indicatorSlice";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import TextInput from "../../../components/Selectors/TextInput";
import TimezoneSelect from "react-timezone-select";
import {hasSeconds} from "../../../utils/common";
import React from "react";

const IndustrialCurrent = () => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.indicator.moduleData.current);
  const timezone = useSelector((state) => state.indicator.moduleData.timezone);
  const setTimeParams = (name, value) => {
    const data = {
      type: "current",
      first: "time_params",
      second: name,
      data: name === "bucket_size" ? parseInt(value) : value,
    };

    dispatch(setDeeper(data));
  };
  return (
    <div className="py-2">
      <div className="">
        <ConfigurationAlerteFormula mode={"current"}/>
      </div>
      <TextInput
        name={"bucket_size"}
        value={current["time_params"]["bucket_size"]}
        label={"Taille du bucket"}
        type={"number"}
        step={"1"}
        onChange={setTimeParams}
      />
      <div className="grid grid-cols-3 gap-x-1.5">
        <TextInput
          value={current["time_params"]["start_time"]}
          label={"Début"}
          onChange={(name, value) => {
            const datetime = hasSeconds(value) ? value : value + ":00";
            setTimeParams("start_time", datetime);
          }}
          type={"datetime-local"}
          step={"1"}
        />

        <TextInput
          value={current["time_params"]["end_time"]}
          label={"Fin"}
          onChange={(name, value) => {
            const datetime = hasSeconds(value) ? value : value + ":00";
            setTimeParams("end_time", datetime);
          }}
          type={"datetime-local"}
          step={"1"}
        />
        <div>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">Fuseau horaire</label>
          <TimezoneSelect
            value={timezone}
            onChange={(e) => {
              dispatch(
                setModuleData({
                  type: "timezoneOffset",
                  data: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
                })
              );
              dispatch(
                setModuleData({
                  type: "timezone",
                  data: e.value,
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default IndustrialCurrent;
