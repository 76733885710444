import TextInput from "../../components/Selectors/TextInput";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setTimeParams} from "../../reducers/EventSlice";

const TimeParam = () => {
    const bucket_size = useSelector(state => state.event.data.current.time_params.bucket_size)
    const dispatch = useDispatch()
    return <div className="px-4">
        <h2 className="text-theme1 text-xl font-semibold">Courant</h2>
        <TextInput name={"bucket_size"} value={bucket_size}
                   label={"Taille du bucket"} type={"number"} step={"1"}
                   onChange={(name, value) => dispatch(setTimeParams({
                       type: "current",
                       type2: name,
                       value: parseInt(value)
                   }))}/>
    </div>
}
export default TimeParam;