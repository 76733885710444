import IndicatorAccordion from "../IndicatorAccordion";
import IndicatorRecurentForm from "../IndicatorRecurentForm";
import OutlierValidationParameter from "./OutlierValidationParameter";

const OutlierValidationRightPanel = () => {
    return <div
      className="bg-white grid grid-flow-row auto-rows-max min-h-[80vh]  shadow-lg">
        <IndicatorAccordion label={"Programmation d'exécution du module"}
                            composant={<IndicatorRecurentForm/>}/>
        <IndicatorAccordion label={"Paramètrage"}
                            composant={<OutlierValidationParameter/>}/>
    </div>
}
export default OutlierValidationRightPanel;