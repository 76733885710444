import {useDispatch, useSelector} from "react-redux";
import {setDeeper} from "../../../reducers/indicatorSlice";
import TextInput from "../../../components/Selectors/TextInput";
import Select from "react-select";
import React from "react";
import ConfigurationAlerteFormula from "./ConfigurationAlerteFormula";

const ConfigurationAlerteHistorical = () => {
    const dispatch = useDispatch()
    const historical = useSelector(state => state.indicator.moduleData.historical)
    const setCount = (name, value) => {
        const tmp = structuredClone(historical.time_params.count)
        tmp[name] = parseInt(value)
        const data = {
            type: "historical",
            first: "time_params",
            second: "count",
            data: tmp
        }
        dispatch(setDeeper(data))

    }
    return <div className="py-2  grid grid-cols-2 gap-2">

        <ConfigurationAlerteFormula mode={"historical"}/>


        <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">{"Type"}</label>
        <Select
            value={{
                label: historical["time_params"]["type"] === "time" ? "Temps (en seconde)" : "Compte (en nombre de donnée)",
                value: historical["time_params"]["type"]
            }}
            options={[
                {label: "Compte", value: "count"},
                {label: "Temps", value: "time"}
            ]}
            onChange={(e) => dispatch(setDeeper({
                data: e.value,
                type: "historical",
                first: "time_params",
                second: "type"
            }))}/>

        <TextInput min={0} name={"delay"} value={historical.time_params.count.delay}
                   label={"Délai depuis le bloc courant"} type={"number"} step={1} onChange={setCount}/>
        <TextInput min={0} name={"length"} value={historical.time_params.count.length}
                   label={"Taille du bloc de donnée"} type={"number"} step={1} onChange={setCount}/>
    </div>
}
export default ConfigurationAlerteHistorical;