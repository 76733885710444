//React
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";


import {canRenderComponent} from "../../utils/common";
import {COMPONENTS} from "../../constants/component";

import IndicatorList from "../../components/Table/IndicatorList";

const Indicator = () => {
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  const [authorizedIndicator, setAuthorizedIndicator] = useState(
    canRenderComponent(COMPONENTS.INDICATOR_DISPLAY)
  );
  useEffect(() => {
    return () => {
      setAuthorizedIndicator(canRenderComponent(COMPONENTS.INDICATOR_DISPLAY));
    };
  }, [authorizedComponent]);

  return authorizedIndicator && <IndicatorList />;
};
export default Indicator;
