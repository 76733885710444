import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  structure: sessionStorage.getItem("structure_id") ?? "",
  observed_system: sessionStorage.getItem("observed_system_id") ?? "",
  crude_source: sessionStorage.getItem("crude_source_id") ?? "",
};

export const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    selectedStructure: (state, action) => {
      state.structure = action.payload;
      state.observed_system = "";
      state.crude_source = "";
    },

    selectedObservedSystem: (state, action) => {
      state.observed_system = action.payload;
    },

    selectedCrudeSource: (state, action) => {
      state.crude_source = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
});
export const {
  selectedStructure,
  selectedObservedSystem,
  selectedCrudeSource,
} = selectedSlice.actions;
export default selectedSlice.reducer;