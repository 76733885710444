import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

const initialState = {
    indicators: {},
    selIndicator: null,
    selSystem: sessionStorage.getItem("observed_system_id") ?? "",
    selSource: undefined,
    selConfiguration: [],
    moduleData: {}
}

export const indicatorSlice = createSlice({
    name: "indicator", initialState: initialState, reducers: {
        reset: () => {
            return initialState
        }, set: (state, action) => {
            state.moduleData = action.payload
            state.selSource = undefined
            state.selConfiguration = []
            state.selVariable = []
        }, setObs: (state, action) => {
            state.systems = action.payload
        }, setModuleData: (state, action) => {
            state.moduleData[action.payload.type] = action.payload.data
        }, setDeep: (state, action) => {
            state.moduleData[action.payload.type][action.payload.first] = action.payload.data
        }, setDeeper: (state, action) => {
            state.moduleData[action.payload.type][action.payload.first][action.payload.second] = action.payload.data
        }, setOne: (state, action) => {
            state[action.payload.type] = action.payload.data
            if (action.payload.type === "selSystem") {
                state.configurations = []
                state.selConfiguration = []
                state.selSource = []
                state.variables = []
                state.selVariable = []
                state.sources = {}
            }
        }, setOneRecurrent: (state, action) => {
            state.moduleData.parameter[action.payload.type] = action.payload.data
        }, setDataParam: (state, action) => {
            if (!state.moduleData.current.data_params.some(value => value.measure_identifier === action.payload.cur.measure_identifier)) {

                state.moduleData.current.data_params.push(action.payload.cur)
                if (action.payload.hist !== undefined) state.moduleData.historical.data_params.push(action.payload.hist)
            } else {
                toast.warn("Source de données déjà ajoutée")
            }
        }, removeDataParam: (state, action) => {
            state.moduleData.historical.data_params = state.moduleData.historical.data_params.filter(bloc => bloc.measure_identifier !== action.payload)
            state.moduleData.current.data_params = state.moduleData.current.data_params.filter(bloc => bloc.measure_identifier !== action.payload)
        }, addBlock: (state, action) => {
            if (!state.moduleData.data_params.some(value => value.measure_identifier === action.payload.measure_identifier)) {
                state.moduleData.data_params.push(action.payload)
            } else {
                console.log("toast")
                toast.warn("Source de données déjà ajoutée")
            }
        }, delBlock: (state, action) => {
            state.moduleData.data_params = state.moduleData.data_params.filter(bloc => bloc.measure_identifier !== action.payload)
        }
    }
})

export const {
    reset,
    set,
    setObs,
    setOne,
    setDataParam,
    removeDataParam,
    setModuleData,
    setOneRecurrent,
    setDeeper,
    setDeep,
    delBlock,
    addBlock
} = indicatorSlice.actions
export default indicatorSlice.reducer