import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import {
  setDeep,
  setDeeper,
  setModuleData,
} from "../../../reducers/indicatorSlice";
import { getThreshold, variableField } from "../utils";
import { parser } from "mathjs";
import { toast } from "react-toastify";
import SelectCreatable from "../../../components/Selectors/SelectReactCreate";
import SelectMulti from "../../../components/Selectors/SelectMulti";
import TextInput from "../../../components/Selectors/TextInput";
import Select from "react-select";
import TimezoneSelect from "react-timezone-select";
import { hasSeconds, makeSelectField } from "../../../utils/common";
import { addThreshold } from "../../../network/DataManagementApi";

const ConfigurationAlerteParam = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  const current = useSelector((state) => state.indicator.moduleData.current);
  const historical = useSelector(
    (state) => state.indicator.moduleData.historical
  );
  const isHist = historical["data_params"].length !== 0;
  const event = useSelector((state) => state.data.event);
  const alert_params = useSelector(
    (state) => state.indicator.moduleData.alert_params
  );
  const obs = sessionStorage.getItem("observed_system_id");
  const threshold = getThreshold(obs);
  const initial_timestamp = useSelector(
    (state) => state.indicator.moduleData.initial_timestamp
  );
  const timezone = useSelector((state) => state.indicator.moduleData.timezone);
  const [selectValue, setSelectValue] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [modal, setModal] = useState(false);
  console.debug(module);
  const setLabel = (value, option) => {
    if (option.action === "clear") value = "";
    console.debug(value, option);
    const data = {
      type: "alert_params",
      first: "threshold_label",
      data: value.value,
    };
    dispatch(setDeep(data));
    const data2 = {
      type: "alert_params",
      first: "state_i",
      data: [],
    };
    dispatch(setDeep(data2));
    setSelectValue([]);
    setSelectedField(value);
  };

  const createLabel = (data) => {
    setSelectValue([]);
    setSelectedField({});
    let error = false;
    const to_send = { ...data };
    const formulas = {};
    const parse = parser();
    if (isHist) {
      //TODO peut-être rendre cette partie plus générique et éviter la duplication de code
      variableField(current.data_params).forEach((obj) =>
        parse.set("c_" + obj.value, 1)
      );
      variableField(historical.data_params).forEach((obj) =>
        parse.set("h_" + obj.value, 1)
      );
      to_send["formula"].forEach((formula) => {
        try {
          parse.evaluate(formula.formula);
          let tmpFormula = formula.formula;
          current.data_params.forEach((block) => {
            const tmp = Object.assign({}, block);
            Object.keys(tmp.preprocess).forEach((value) => {
              if (!tmpFormula.includes("c['" + value + "']"))
                tmpFormula = tmpFormula.replaceAll(
                  "c_" + value,
                  "c['" + value + "']"
                );
            });
          });
          historical.data_params.forEach((block) => {
            const tmp = Object.assign({}, block);
            Object.keys(tmp.preprocess).forEach((value) => {
              if (!tmpFormula.includes("h['" + value + "']"))
                tmpFormula = tmpFormula.replaceAll(
                  "h_" + value,
                  "h['" + value + "']"
                );
            });
          });

          formulas[formula.name] = tmpFormula;
        } catch (e) {
          if (e instanceof SyntaxError) {
            toast.error("Formule incorrect");
            error = true;
          } else {
            const wrongVariable = e.message.split(" ")[2];
            toast.error(
              "Variable incorrect: " +
                wrongVariable +
                " Formule: " +
                formula.formula
            );
            error = true;
          }
        }
      });
    } else {
      variableField(current.data_params).forEach((obj) =>
        parse.set(obj.value, 1)
      );
      to_send["formula"].forEach((formula) => {
        try {
          parse.evaluate(formula.formula);
          let tmpFormula = formula.formula;
          current.data_params.forEach((block) => {
            const tmp = Object.assign({}, block);
            Object.keys(tmp.preprocess).forEach((value) => {
              if (!tmpFormula.includes("['" + value + "']"))
                tmpFormula = tmpFormula.replaceAll(value, "['" + value + "']");
            });
          });

          formulas[formula.name] = tmpFormula;
        } catch (e) {
          if (e instanceof SyntaxError) {
            toast.error("Formule incorrect");
            error = true;
          } else {
            const wrongVariable = e.message.split(" ")[2];
            toast.error(
              "Variable incorrect: " +
                wrongVariable +
                " Formule: " +
                formula.formula
            );
            error = true;
          }
        }
      });
    }
    if (!error) {
      console.log(formulas);
      to_send.formula = formulas;
      addThreshold(to_send, obs, () => setModal(false));
    }
    return error;
  };
  const setField = () => {
    const field = [];
    threshold.forEach((value) => {
      field.push({ label: value.label, value: value.label });
    });
    console.debug(field);
    return field;
  };
  const setMultiField = () => {
    const options = [];
    const index = threshold.findIndex(
      (value) => value.label === module.alert_params.threshold_label
    );
    const formula = threshold[index].formula;
    Object.keys(formula).forEach((value) =>
      options.push({ value: value, label: value + " : " + formula[value] })
    );
    return options;
  };
  const setStates = (options) => {
    setSelectValue(options);
    const state_i = [];
    options.forEach((item) => {
      state_i.push(item.value);
    });
    const data = {
      type: "alert_params",
      first: "state_i",
      data: state_i,
    };
    dispatch(setDeep(data));
  };
  return (
    <div>
      <TextInput
        label={"Nom de l'alerte"}
        name="alert_name"
        value={alert_params["alert_name"]}
        onChange={(key, value) => {
          dispatch(
            setDeep({
              data: value,
              type: "alert_params",
              first: key,
            })
          );
        }}
      />

      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
        Seuil à considérer
      </label>
      <SelectCreatable
        isLoading={modal}
        setIsLoading={setModal}
        onCreate={createLabel}
        value={selectedField}
        module={current}
        onChange={setLabel}
        field={setField()}
        setValue={setSelectedField}
      />

      {alert_params["threshold_label"] !== "" && !modal ? (
        <>
          <h1 className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide ">
            Etat à considérer
          </h1>
          <SelectMulti
            value={selectValue}
            onChange={setStates}
            options={setMultiField()}
          />{" "}
        </>
      ) : (
        ""
      )}
      <div className="grid grid-cols-6 gap-x-1.5">
        <div className={"col-span-2"}>
          <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
            {"Mode"}
          </label>
          <Select
            value={alert_params["mode"]["type"]}
            options={[
              { value: "PROPORTION", label: "Proportion" },
              { value: "COUNTING", label: "Comptage" },
            ]}
            onChange={(value) => {
              dispatch(
                setDeeper({
                  data: value,
                  type: "alert_params",
                  first: "mode",
                  second: "type",
                })
              );
            }}
          />
        </div>

        <TextInput
          label={"Valeur"}
          name="value"
          type={"number"}
          min={-1}
          max={100}
          value={alert_params["mode"]["value"]}
          onChange={(key, value) => {
            dispatch(
              setDeeper({
                data: parseInt(value),
                type: "alert_params",
                first: "mode",
                second: key,
              })
            );
          }}
        />
        <div className={"col-span-3"}>
          <label className="text-sm font-bold text-gray-700 dark:text-white tracking-wide">
            Événement à déclencher
          </label>

          <Select
            onChange={(value) =>
              dispatch(
                setDeep({
                  data: value,
                  type: "alert_params",
                  first: "event",
                })
              )
            }
            value={event[alert_params.event]}
            menuPosition={"fixed"}
            options={makeSelectField(event, [
              "module_name",
              "function_name",
              "key",
            ])}
          />
        </div>
      </div>
      <label className=" text-sm font-bold text-gray-700 dark:text-white tracking-wide">
        Date de début
      </label>
      <div className={"grid grid-cols-2 gap-x-1.5"}>
        <TextInput
          name={"initial_timestamp"}
          value={initial_timestamp}
          type={"datetime-local"}
          step={"1"}
          onChange={(name, value) => {
            const datetime = hasSeconds(value) ? value : value + ":00";
            dispatch(setModuleData({ type: name, data: datetime }));
          }}
        />

        <TimezoneSelect
          value={timezone}
          onChange={(e) => {
            dispatch(
              setModuleData({
                type: "timezoneOffset",
                data: e.label.slice(4, 5) + "0" + e.label.slice(5, 9),
              })
            );
            dispatch(
              setModuleData({
                type: "timezone",
                data: e.value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};
export default ConfigurationAlerteParam;
