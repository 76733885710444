import { useEffect, useMemo, useState } from "react";
import { TableFilter } from "./TableFilter";
import Button from "../Selectors/Button";
import { deleteStructure, getAll, getStructureAll } from "../../network/DataManagementApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { canRenderAction, canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import ActionButtons from "../Selectors/ActionButtons";
import { ACTION_FLAGS } from "../../constants/action_flags";
import DeleteModal from "../Selectors/DeleteModal";
import { reset } from "../../reducers/dataSlice";
import { setObservedSystem } from "../../reducers/authenticationSlice";
import { getMethod, getPages, getProfile } from "../../network/UserManagementApi";
import { TableSelector } from "../TableSelector";
import { selectedStructure } from "../../reducers/SelectedSlice";

const StructureList = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );
  const [structureToDelete, setStructureToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const [structureId, setStructureId] = useState(sessionStorage.getItem("structure_id"));
  const authorizedComponent = useSelector((state) => state.authentication.authorized_components);
  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.STRUCTURE_DISPLAY));
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.STRUCTURE_DISPLAY));
  const [authorizedStructureCreation, setAuthorizedStructureCreation] = useState(canRenderComponent(COMPONENTS.STRUCTURE_CREATION));
  useEffect(() => {
    return () => {
      setAuthorizedStructureCreation(canRenderComponent(COMPONENTS.STRUCTURE_CREATION));
    };
  }, [authorizedComponent]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const structureList = useSelector((state) => state.admin.structures);
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const dispatch = useDispatch();

  const handleStructureChange = (updater) => {
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const structureId = data[index][0];
    dispatch(reset());
    dispatch(selectedStructure(structureId));
    sessionStorage.setItem("structure_id", structureId);
    sessionStorage.removeItem("observed_system_desc");
    sessionStorage.removeItem("observed_system_id");
    sessionStorage.removeItem("observed_system_name");
    setStructureId(structureId);
    dispatch(setObservedSystem(""));
    getAll();
    history("/");
    getProfile();
    getPages("all");
    getMethod();
    setRowSelection(newSelected);
  };
  useEffect(() => {
    const newData = Object.entries(structureList);
    const index = newData.findIndex((value) => value[0] === structureId);
    if (index !== -1) {
      setRowSelection({ [index]: true });
    }
    setData(newData);
  }, [structureList]);
  const history = useNavigate();

  const createStructure = () => history("/internal/structure/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "select",
      cell: ({ row }) => (
        <div className="px-1">
          <TableSelector
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => row[0], {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].name, {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor((row) => row[1].description, {
      id: "desc",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/internal/structure/edit/" + row[0]);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setStructureToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  console.debug(pagination);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleStructureChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="block bg-white rounded-lg border box-shadow-custom-4-sides border-gray-200 m-4">
      <div className="flex flex-col lg:flex-row justify-between mt-2">
        <div className="flex flex-row align-center px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
          <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
            <Button
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getStructureAll(() => {
                  toast.success("Informations mises à jour.");
                  setRefresh(false);
                });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedStructureCreation && (
            <div className="sm:flex sm:flex:column mt-3 lg:mt-0 flex-row items-center px-3">
              <Button callback={createStructure} label="Créer une structure" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {structureToDelete && (
        <DeleteModal
          callback={() => {
            if (safeStructure.includes(structureToDelete[0])) {
              toast.warn("Structure importante, ne pas supprimer");
              return;
            }
            setIsDeleting(true);
            deleteStructure(structureToDelete[0], () => {
              setIsDeleting(false);
              setStructureToDelete(undefined);
            });
          }}
          loading={isDeleting}
          text={"Êtes vous sûr de vouloir supprimer la structure " + structureToDelete[1].name + " ?"}
          modalVisible={structureToDelete}
          setModalVisible={setStructureToDelete}
        />
      )}
    </div>
  );
};
export default StructureList;

const safeStructure = ["3ezO6YIBzrBpdS7jYrKA", "BAC9coUBzrBpdS7jXoCC", "Zv25vYQBzrBpdS7jfA7u", "PjqSiH8BmY0NB0MdU8Vt"];
