import { useDispatch, useSelector } from "react-redux";
import { logOutUser, updatePreference } from "../network/UserManagementApi";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import PreferenceSection from "../components/PreferenceSection";
import { setData } from "../reducers/dataSlice";
import { createAction } from "../utils/axios.utils";
import { Transition } from "@headlessui/react";
import { transitionClasses } from "../utils/common";
import { PrimaryButton, TertiaryButton } from "../components/Buttons/Button";

const ProfilePage = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const dispatch = useDispatch();

  const structure = useSelector((state) => state.data.structure);
  const profile = useSelector((state) => state.data.profile);
  const [isLoading, setIsLoading] = useState(false);
  const profiles = useSelector((state) => state.data.profiles);
  const history = useNavigate();
  console.debug(profile);

  function handleSavePreferences(updatedPreference) {
    setIsLoading(true);
    updatePreference(updatedPreference, profile.id_keycloak).then((res) => {
      console.debug(res);
      setIsLoading(false);
      dispatch(
        setData(
          createAction("profile", { ...profile, preference: updatedPreference })
        )
      );
      setShowPreferences(false);
    });
  }

  console.debug(profile.preference);
  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="w-2/3 m-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="grid grid-cols-12">
            <div className="col-span-4 bg-primary-theme-800  profile-card text-center text-white p-3 rounded-l-lg space-y-4">
              <h1 className="font-bold text-xl">
                {profile.first_name} {profile.last_name}
              </h1>
              <p>
                {profile.id_role === 1 ? "PREDITIC ADMIN" : "WIP FIND ROLE"}
              </p>
              <p>{structure.name || "Aucune structure"}</p>
              <div className={"flex justify-center"}>
                <TertiaryButton
                  callback={() =>
                    logOutUser(() => {
                      history("/settings/profile");
                    })
                  }
                  label="Déconnexion"
                />
              </div>
            </div>
            <div className="col-span-8">
              <div className="p-4">
                <h5 className="mb-2 text-xl font-bold tracking-tight">
                  Informations
                </h5>

                <div className="flex border-t border-gray-400" />

                <div className="my-3 grid grid-cols-1 lg:grid-cols-2 font-normal">
                  <div>
                    <span className="font-semibold">Email: </span>
                    <span>{profile.email || "NA"}</span>
                  </div>
                </div>

                <div className="flex border-t border-gray-400" />

                <div className="my-3  flex justify-end  font-normal ">
                  <div>
                    <PrimaryButton
                      disabled={isLoading}
                      isLoading={isLoading}
                      label={"Preferences"}
                      callback={() => setShowPreferences(!showPreferences)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex justify-center w-full"}>
        <Transition
          className="w-2/3 m-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700"
          show={showPreferences}
          as={"div"}
          {...transitionClasses}
        >
          <div className="shadow-md p-2">
            <PreferenceSection
              preference={profile.preference}
              onSave={handleSavePreferences}
              isLoading={isLoading}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
};
export default ProfilePage;
