import TimeIndicatorRightPanel from "./TimeIndicatorRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const TimeIndicator = () => {
  return (
    <div className="grid grid-cols-2 gap-4 mx-4 py-2">
      <IndicatorDataConfiguration isTwoBloc={true}/>
      <TimeIndicatorRightPanel/>
    </div>
  );
};
export default TimeIndicator;
