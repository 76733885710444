import {
  CheckIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";

export function TablePagination({ table }) {
  return (
    <div className="p-3 flex items-center justify-between">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex gap-x-2">
          <span className="text-sm text-gray-700 dark:text-white self-center">
            Page{" "}
            <span className="font-medium">
              {" "}
              {table.getState().pagination.pageIndex + 1}
            </span>{" "}
            / <span className="font-medium">{table.getPageCount()}</span>
          </span>
          <PageSelector table={table} />
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <PageButton
              className="rounded-l-md dark:bg-gray-900"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronDoubleLeftIcon
                className="h-5 w-5 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={"dark:bg-gray-900"}
            >
              <ChevronLeftIcon
                className="h-5 w-5 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={"dark:bg-gray-900"}
            >
              <ChevronRightIcon
                className="h-5 w-5 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
            <PageButton
              className="rounded-r-md dark:bg-gray-900"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <ChevronDoubleRightIcon
                className="h-5 w-5 dark:text-white"
                aria-hidden="true"
              />
            </PageButton>
          </nav>
        </div>
      </div>
    </div>
  );
}

const options = [
  { id: 0, page: "5" },
  { id: 1, page: "10" },
  { id: 2, page: "20" },
  { id: 3, page: "50" },
];
const PageSelector = ({ table }) => {
  const [selected, setSelected] = useState(options[1]);
  const handleSelection = (element) => {
    setSelected(element);
    table.setPageSize(element.page);
  };
  return (
    <div className="">
      <Listbox value={selected} by="id" onChange={handleSelection}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">
              Element par page: {selected.page}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={
                    "relative cursor-default select-none py-2 pl-2 pr-4 ui-active:bg-primary-theme-100"
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <div className={"flex gap-x-0.5"}>
                      {selected ? (
                        <CheckIcon
                          className={"block w-4 text-primary-theme-900"}
                        />
                      ) : (
                        <span className={"block w-4"}></span>
                      )}
                      {option.page}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
const PageButton = ({ children, className, ...rest }) => {
  return (
    <button
      type="button"
      className={
        "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      }
      {...rest}
    >
      {children}
    </button>
  );
};
