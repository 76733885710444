import TextInput from "../../components/Selectors/TextInput";
import React from "react";
import SwitchButton from "../../components/Selectors/SwitchButton";
import {useDispatch, useSelector} from "react-redux";
import {setBase, setTimeParams} from "../../reducers/EventSlice";

const TimeParamHisto = () => {
    const params = useSelector(state => state.event.data.historical.time_params)
    const count = useSelector(state => state.event.count)

    const dispatch = useDispatch()

    return <div className="px-4">
        <div className="grid grid-cols-2">
            <h2 className="text-theme1 text-xl font-semibold">Historique</h2>
            <SwitchButton label={"Mode compte"} value={count} name={"count"}
                          onChange={(name, value) => dispatch(setBase({type: name, value: value}))}/>
        </div>
        <div className={"grid grid-cols-3 gap-4"}>
            <TextInput name={"bucket_size"} value={params.bucket_size}
                       label={"Taille du bucket"} type={"number"} step={"1"}
                       onChange={(name, value) => dispatch(setTimeParams({
                           type: "historical",
                           type2: name,
                           value: parseInt(value)
                       }))}/>
            <TextInput name={"delay"} value={count ? params.count.delay : params.time.delay}
                       label={"Délai"} type={"number"} step={"1"}
                       onChange={(name, value) => dispatch(setTimeParams({
                           type: "historical",
                           type2: name,
                           value: parseInt(value)
                       }))}/>
            <TextInput name={"length"} value={count ? params.count.length : params.time.length}
                       label={"Taille"} type={"number"} step={"1"}
                       onChange={(name, value) => dispatch(setTimeParams({
                           type: "historical",
                           type2: name,
                           value: parseInt(value)
                       }))}/>
        </div>
    </div>
}
export default TimeParamHisto;