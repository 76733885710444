import React from "react";
import {
  isEmptyObject,
  isEmptyString,
  renderComponent,
} from "../../utils/common";
import { useSelector } from "react-redux";
import DashBoardHeader from "./DashBoardHeader";
import { COMPONENTS } from "../../constants/component";
import ObservedSystemList from "../Table/ObservedSystemList";
import StoragesystemList from "../Table/StoragesystemList";
import CrudeSourceList from "../Table/CrudeSourceList";
import ConfiguredSourceList from "../Table/ConfiguredSourceList";
import TableWrapper from "../Table/TableWrapper";

const DashboardData = ({}) => {
  const selectedCrudeSourceId = useSelector(
    (state) => state.selected.crude_source
  );
  const selectedObservedSystemId = useSelector(
    (state) => state.selected.observed_system
  );
  const crudeSourceList = useSelector((state) => state.data.crude_source);

  const structure = useSelector((state) => state.data.structure);
  console.debug(selectedCrudeSourceId);
  // this allows to re render the component
  const authorizedComponent = useSelector(
    (state) => state.authentication.authorized_components
  );
  return (
    <div>
      <DashBoardHeader structure={structure} />
      <div className="grid xl:grid-cols-1 2xl:grid-cols-2  ">
        {renderComponent(
          <TableWrapper
            label={"Système observé"}
            component_id={COMPONENTS.OBSERVED_SYSTEM_DISPLAY}
            component={<ObservedSystemList />}
          />
        )}
        {renderComponent(
          <TableWrapper
            label={"Système de stockage"}
            component_id={COMPONENTS.INSTANCE_DISPLAY}
            component={<StoragesystemList />}
          />
        )}
        {!isEmptyObject(crudeSourceList) &&
          renderComponent(
            <TableWrapper
              label={"Source brute"}
              component_id={COMPONENTS.CRUDE_SOURCE_DISPLAY}
              component={<CrudeSourceList />}
            />
          )}
        {!isEmptyString(selectedCrudeSourceId) &&
          renderComponent(
            <TableWrapper
              label={"Source configurée"}
              component_id={COMPONENTS.CONFIGURED_SOURCE_DISPLAY}
              component={<ConfiguredSourceList />}
            />
          )}
      </div>
    </div>
  );
};

export default DashboardData;
