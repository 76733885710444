import {useDispatch, useSelector} from "react-redux";
import IndicatorChip from "../Selector/IndicatorChip";
import {getBlockInfo} from "../utils";
import {removeDataParam} from "../../../reducers/indicatorSlice";

const DetectionAnomalieRecap = () => {
    const dispatch = useDispatch()
    const configurations = useSelector(state => state.data.configured_source)
    const sources = useSelector(state => state.data.crude_source)
    const hist = useSelector(state => state.indicator.moduleData.historical.data_params)
    const cur = useSelector(state => state.indicator.moduleData.current.data_params)
    const tuto = "TODO"
    return <div className="col-span-3 grid gap-2 grid-cols-2 px-14">


        {cur.length === 0 &&
            <>
                <IndicatorChip type={"Historique"} color="bg-blue-200 opacity-50"
                               text={["Aucune configuration", "Aucune variable considérée"]}
                               callback={() => console.log()}
                               tip={tuto}/>
                <IndicatorChip type={"Courant"} color="bg-green-200 opacity-50"
                               text={["Aucune configuration", "Aucune variable considérée"]}
                               callback={() => console.log()}
                               tip={tuto}/>
            </>
        }

        {cur.map(bloc => {
            const histBloc = hist.find(value => value.measure_identifier === bloc.measure_identifier)
            let conf = {}
            Object.keys(configurations).forEach(id => {

                if (configurations[id]["measure_identifier"] === bloc.measure_identifier) {
                    conf = configurations[id]
                }
            })
            const source = sources[conf["crude_source"]]
            const textConf = bloc["configured_sources"].length === 0 ? "Toutes les configurations sont séléctionnées" : bloc["configured_sources"].length + " configuration(s) séléctionnée(s)"
            const textVariables = Object.keys(bloc["preprocess"]).length === 1 ? Object.keys(bloc["preprocess"]).length + " variable considérée" : Object.keys(bloc["preprocess"]).length + " variables considérées"

            if (histBloc !== undefined) {
                const textConfHist = histBloc["configured_sources"].length === 0 ? "Toutes les configurations sont séléctionnées" : histBloc["configured_sources"].length + " configuration(s) séléctionnée(s)"
                const textVariablesHist = Object.keys(histBloc["preprocess"]).length === 1 ? Object.keys(histBloc["preprocess"]).length + " variable considérée" : Object.keys(histBloc["preprocess"]).length + " variables considérées"

                return <>
                    <IndicatorChip text={[textConfHist, textVariablesHist]}
                                   color="bg-blue-200"
                                   type={source.description}
                                   callback={() => dispatch(removeDataParam(bloc.measure_identifier))}
                                   tip={getBlockInfo(histBloc)}/>
                    <IndicatorChip text={[textConf, textVariables]}
                                   color="bg-green-200"
                                   type={source.description}
                                   callback={() => dispatch(removeDataParam(bloc.measure_identifier))}
                                   tip={getBlockInfo(bloc)}/>
                </>

            } else {

                return <>
                    <IndicatorChip text={["Pas de bloc historique"]}
                                   color="bg-blue-200 opacity-50 pointer-events-none"
                                   type={source.description}
                                   callback={() => dispatch(removeDataParam(bloc.measure_identifier))}
                                   tip={""}/>
                    <IndicatorChip text={[textConf, textVariables]}
                                   color="bg-green-200"
                                   type={source.description}
                                   callback={() => dispatch(removeDataParam(bloc.measure_identifier))}
                                   tip={getBlockInfo(bloc)}/>
                </>

            }

        })}

    </div>
}

export default DetectionAnomalieRecap