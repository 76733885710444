import TextInput from "../../components/Selectors/TextInput";
import MailEvent from "./MailEvent";
import AnalyticalFormulaEvent from "./AnalyticalFormulaEvent";
import KalmanEvent from "./KalmanEvent";
import AutoregressivepredictionEvent from "./AutoregressivepredictionEvent";
import CorrelationEvent from "./CorrelationEvent";

export const discover = (mapping, set) => {
    Object.keys(mapping).forEach(property => {
        return getItem(property, mapping[property], set)
    })
}
const getItem = (name, property, set) => {
    switch (property.type) {
        case "object":
            // if(name ==="current")
            // {
            //     set(oldArray => [...oldArray, <h1> IS CURRENT</h1>]);
            //     break;
            // }
            // if(name ==="historical")
            // {
            //     set(oldArray => [...oldArray, <h1> IS historical</h1>]);
            //     break;
            // }

            if (property.properties) {
                set(oldArray => [...oldArray, <h1> {name} OBJECT</h1>]);
                return discover(property.properties, set)
            } else {
                set(oldArray => [...oldArray, <p> {name} - new specific component </p>]);
                break;
            }

        case "string":
            set(oldArray => [...oldArray, <TextInput label={name} type={"text"}/>]);
            break;
        case "number":
            set(oldArray => [...oldArray, <TextInput label={name} type={"number"}/>]);
            break;
        case "array":
            set(oldArray => [...oldArray, <p> {name} IS ARRAY</p>]);
            return getItem(name, property.items, set)

        default:
            set(oldArray => [...oldArray, <p> default case - ??? </p>]);

    }
}

export const mailEvent = {
    module_name: "Email", parameters: {
        sender: "notifications-retd@preditic.com", receivers: [], subject: "", message: "", server_param: {
            "smtp_password": "3011aa9335d91de2b0f7780b55d29f80",
            "smtp_server": "in-v3.mailjet.com",
            "smtp_login": "8e3e8a8be2d6e67d77da73f927dd0bf6",
            "port": 587
        }
    }, function_name: "send_notification"
}
export const analyticalFormulaEvent = {
    module_name: "Analyticalformula", function_name: "??", parameters: {
        data_params: []
    }
}
export const data_params = {
    measure_identifier: "", time_field: "", preprocess: {}, configured_sources: []
}
export const time_params = {
    start_time: "", end_time: "", bucket_size: -1,
}
export const computation_params = {
    function: {
        variables: {}, name: ""
    }, formula: ""
}
export const current = {
    data_params: [], time_params: structuredClone(time_params), computation_params: structuredClone(computation_params)
}
export const historical = {
    data_params: [], time_params: {
        count: {
            delay: -1, length: -1
        }, time: {
            delay: -1, length: -1
        }, bucket_size: -1,
    }, computation_params: structuredClone(computation_params)

}
export const renderEvent = (event) => {
    switch (event) {
        case "Analyticalformula":
            return <AnalyticalFormulaEvent/>
        case "Email":
            return <MailEvent/>
        case "Kalman":
            return <KalmanEvent/>
        case "Autoregressiveprediction":
            return <AutoregressivepredictionEvent/>
        case "Correlation":
            return <CorrelationEvent/>
        // case "IndicatorCreator":
        //     return <IndicatorEvent />
        default:
            return <p>{event} -WIP</p>

    }
}

export const getValueFromSelect = (options) => {
    return options.map(option => option.value)

}
export const makeOptions = (options) => {
    const formattedOptions = []
    for (let i = 0; i < options.length; i++) {
        formattedOptions.push({value: i, label: options[i]})
    }
    return formattedOptions
}

export const redirect = "/analytics/event"